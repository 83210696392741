import React, {useState, useEffect} from 'react';
import EditModal from "components/Modal/EditModal/EditModal";
import {FlightSelectSearchable, FlightButton} from "@flybits/webapp-design-system-react";
import './ScheduleEditModal.scss';
import {
  getTimezoneList,
  getDuration,
  convertDateToTimezone,
  transformScheduleData,
  cleanupTimezone,
  defineStartDate,
  convertTimezoneToDate
} from 'helpers/templated-experience.helper';
import 'date-fns';
import moment from 'moment';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import { FlightDateTimePicker } from '@flybits/webapp-design-system-react';
import momentTZ from "moment-timezone";
import {useSelector} from "react-redux";
import { isEmpty } from 'lodash';

interface IProps {
  title: string,
  instance: TExperienceInstance | object,
  type?: string,
  isVisible: boolean,
  primaryButton: {
    name: string,
    onClick: (scheduleData:scheduleDataType) => void,
    disabled: boolean,
    loading: boolean,
  },
  secondaryButton?: {
    name: string,
    onClick: (scheduleData:scheduleDataType) => void,
    disabled: boolean,
  } | undefined,
  cancelButton?: {
    name: string,
    onClick: () => void,
  } | undefined,
  schedule: any
}

export interface scheduleDataType {
  start: Date | null,
  end: Date | null,
  timezone: {
    key: number,
    name: string,
    offset: number,
  },
  runtime?: string,
  minDate?: Date,
}


export default function ScheduleEditModal(props: IProps) {
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const experience = !isEmpty(reduxTemplatedExperienceState.template) ? reduxTemplatedExperienceState.template : reduxTemplatedExperienceState.instance;
  const scheduleState = props.schedule;
  const [scheduleData, setScheduleData] = useState<scheduleDataType>({
    start: null,
    end: null,
    timezone: {
      key: 0,
      name: '',
      offset: 0,
    },
    runtime: 'None',
    minDate: new Date(),
  });
  const [timezones, setTimezones] = useState<any>([]);
  const [startDateExists, setStartDateExists] = useState<boolean>(false);
  const [endDateExists, setEndDateExists] = useState<boolean>(false);
  const dateFormat = "MMM dd yyyy, hh:mma";
  const transformedSchedule = transformScheduleData(scheduleData);
  let convertStart = transformedSchedule.start!! ? convertDateToTimezone(transformedSchedule.start!!, scheduleData.timezone.name) : null;
  let convertEnd = transformedSchedule.end!! ? convertDateToTimezone(transformedSchedule.end!!, scheduleData.timezone.name) : null;
  const manualActivation = reduxTemplatedExperienceState?.instance?.activation?.manualActivationAt;
  const autoActivation = reduxTemplatedExperienceState?.instance?.activation?.automaticActivationAt;
  const [isBroadcast, setIsBroadcast] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const reservedStartTime = 300;
  const reservedEndTime = 600;

  function handleTimezoneSelect(option: any) {
    let trSchedule = transformScheduleData({...scheduleData, timezone: option});
    const minDateFormat = momentTZ().tz(cleanupTimezone(trSchedule?.timezone!!)).format('YYYY-MM-DD HH:mm:ss');
    const minDate = new Date(minDateFormat);
    const minDateUnix = moment(minDate).unix();
    let start = scheduleData.start;
    let startUnix = moment(start!!).unix();
    if(minDateUnix > startUnix) {
      start = new Date((minDateUnix+reservedStartTime) * 1000);
    }
    setScheduleData({...scheduleData, start, timezone: {...option}, minDate});
  }

  function handleSearch(searchZone: string) {
    setTimezones(getTimezoneList(searchZone));
  }

  function changeStartDate(date: any | null) {
    let selectedDate = date;
    let selectedDateUnix = moment(moment(date).format('YYYY-MM-DD HH:mm')).unix();
    let startDate = scheduleData.start || date;
    let startDateUnix = moment(startDate).unix();
    let endDate = scheduleData.end || date;
    let endDateUnix = moment(endDate).unix();
    let minDateUnix = moment(scheduleData.minDate).unix();
    if(minDateUnix >= selectedDateUnix) {
      selectedDateUnix = minDateUnix;
      selectedDate = new Date(selectedDateUnix * 1000);
    }
    if(selectedDateUnix >= endDateUnix) {
      endDate = new Date((selectedDateUnix + reservedStartTime)*1000);
    }
    if(selectedDateUnix !== startDateUnix) {
      selectedDate = new Date(selectedDateUnix*1000);
    }
    let runtime:any = getDuration(selectedDate, endDate);
    if(endDateExists) {
      setScheduleData({...scheduleData, start: selectedDate, runtime, end: endDate});
    } else {
      runtime = 'ongoing';
      setScheduleData({...scheduleData, start: selectedDate, runtime});
    }
  }

  function changeEndDate(date: any | null) {
    let selectedDate = date;
    let selectedDateUnix = moment(date).unix();
    let minDateUnix = moment(scheduleData.minDate).unix();
    let startDate = scheduleData.start || scheduleData.minDate;
    let startDateUnix = startDate ? moment(startDate).unix() : 0;
    if(minDateUnix >= selectedDateUnix) {
      selectedDateUnix = minDateUnix + reservedEndTime;
      selectedDate = new Date(selectedDateUnix * 1000);
    }
    if(selectedDateUnix < startDateUnix) {
      selectedDate = new Date((startDateUnix + reservedEndTime)*1000);
    }
    if((!!manualActivation || !!autoActivation) && experience.status === 'Active') {
      startDateUnix = autoActivation > manualActivation ? autoActivation : manualActivation;
      startDate = new Date(startDateUnix * 1000);
    }
    let runtime:any = getDuration(startDate!!, selectedDate);
    setScheduleData({...scheduleData, end: selectedDate, runtime});
  }

  function showStartdate(option: boolean) {
    setModalHeight(option ? 260 : 0);
    let myZone = moment.tz.guess().replace(/_/g, ' ');
    let myOffset = moment.tz(myZone).format('Z');
    myZone = myZone+' (UTC '+myOffset+')';
    if(option) {
      let runtime: any = 'Ongoing';
      if(scheduleData.end && !scheduleData.start) {
        runtime = getDuration(new Date((moment().unix()+reservedStartTime)*1000), scheduleData.end);
      }

      convertStart = convertDateToTimezone(moment().unix()+reservedStartTime, myZone);
      setScheduleData({
        ...scheduleData,
        start: scheduleData.start!! ? scheduleData.start : new Date((moment().unix()+reservedStartTime)*1000),
        timezone: {key: 0, name: myZone, offset: parseInt(myOffset)},
        runtime: runtime
      });
    } else {
      setScheduleData({
        ...scheduleData,
        start: null,
        end: null,
        timezone: {key: 0, name: '', offset: 0},
        runtime: 'None',
      });
      setEndDateExists(option);
    }
    setStartDateExists(option);
  }

  function showEndDate(option: boolean) {
    let endDate = scheduleData.end || new Date();
    let startDateUnix = moment(scheduleData.start || new Date()).unix();
    let endDateUnix = moment(endDate).unix();
    let myZone = scheduleData.timezone.name ? cleanupTimezone(scheduleData.timezone.name) : moment.tz.guess().replace(/_/g, ' ');
    let myOffset = moment.tz(myZone).format('Z');
    myZone = myZone+' (UTC '+myOffset+')';

    if(startDateUnix >= endDateUnix) {
      endDate = new Date((startDateUnix + reservedStartTime)*1000);
      convertEnd = convertDateToTimezone(startDateUnix + reservedStartTime, scheduleData.timezone.name);
    } else {
      endDate = new Date((moment().unix() + reservedStartTime)*1000);
      convertEnd = convertDateToTimezone(moment().unix() + reservedStartTime, scheduleData.timezone.name);
    }
    let runtime: any = 'Ongoing';
    if(option) {
      runtime = getDuration(new Date((startDateUnix)*1000), endDate);
    }
    if(manualActivation && option) {
      runtime = getDuration(new Date((manualActivation)*1000), endDate);
      setScheduleData({
        ...scheduleData,
        timezone: {key: 0, name: myZone, offset: parseInt(myOffset)},
        end: option ? endDate : null,
        runtime: `${runtime}`
      });
    } else {
      setScheduleData({
        ...scheduleData,
        end: option ? endDate : null,
        runtime: `${runtime}`
      });
    }
    setEndDateExists(option);
  }

  function onCancel() {
    let myZone = moment.tz.guess().replace(/_/g, ' ');
    let myOffset = moment.tz(myZone).format('Z');
    myZone = myZone+' (UTC '+myOffset+')';
    setScheduleData({
      start: null,
      end: null,
      timezone: {
        key: 0,
        name: '',
        offset: 0,
      },
      runtime: 'None',
      minDate: new Date()
    });
    setStartDateExists(false);
    setEndDateExists(false);
    props.cancelButton?.onClick();
  }

  function renderAlreadyActivated() {
    if(((!!manualActivation || !!autoActivation) && !experience?.schedule?.start && !startDateExists) || (!!autoActivation && experience.status === 'Active')) {
      const timezone = experience.schedule.timezone ? experience.schedule.timezone : moment.tz.guess().replace(/_/g, ' ');
      const activationDate = autoActivation > manualActivation ? autoActivation : manualActivation;
      const activationTZDate = convertTimezoneToDate(activationDate, timezone);
      return (
        <div className="schedule-edit-modal__manual-activ">
          {autoActivation ? 'Automatically' : 'Manually'} launched {activationTZDate.tz_date} {activationTZDate.tz_time} ({timezone})
        </div>
      )
    }
  }

  useEffect(() => {
    let runtime: any = 'None';
    let startDate = defineStartDate(scheduleState?.start!!, autoActivation!!, manualActivation!!, experience?.status!!);
    let endDate = scheduleState?.end || 0;
    let convertStartTime = startDate ? convertTimezoneToDate(startDate!!, scheduleState?.timezone!!) : null;
    let convertEndTime = endDate ? convertTimezoneToDate(endDate!!, scheduleState?.timezone!!) : null;
    if(convertStartTime) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      convertStart = convertStartTime;
    }
    if(convertEndTime) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      convertEnd = convertEndTime;
    }
    if(startDate) {
      runtime = 'Ongoing';
    }
    if(endDate && convertEnd) {
      runtime = getDuration(new Date(convertStart?.local_unix!!*1000), new Date(convertEnd?.local_unix!!*1000));
    }
    setTimezones(getTimezoneList());
    let myZone = moment.tz.guess().replace(/_/g, ' ');
    let myOffset = moment.tz(myZone).format('Z');
    myZone = myZone+' (UTC '+myOffset+')';
    let minDate = new Date();
    if(scheduleState?.start!!) {
      const minDateFormat = momentTZ().tz(cleanupTimezone(scheduleState.timezone!!)).format('YYYY-MM-DD HH:mm:ss');
      minDate = new Date(minDateFormat);
    }

    setScheduleData({
      ...scheduleData,
      start: (convertStartTime?.local_unix || startDate) ? new Date((convertStartTime?.local_unix || startDate) * 1000) : null,
      end: scheduleState?.end ? new Date(scheduleState.end * 1000) : null,
      timezone: {
        ...scheduleData.timezone,
        name: scheduleState?.timezone || myZone,
      },
      runtime,
      minDate,
    });
    setStartDateExists((!!scheduleState?.start && experience.status !== 'Active') || (!!startDate && experience.status !== 'Active'));
    setEndDateExists(scheduleState?.end!!);
    if(props.type === 'broadcast' || props?.type === 'list-broadcast') {
      setIsBroadcast(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[scheduleState, props.isVisible]);

  function isPrimaryButtonDisabled() {
    if((convertStart?.local_unix! > 0 && convertStart?.local_unix! < moment().unix()+reservedStartTime-60 && experience.status !== 'Active') ||
      (convertEnd?.local_unix! > 0 && convertEnd?.local_unix! < moment().unix()+reservedEndTime-60)) {
      return true;
    }
    return false;
  }


  return (
    <EditModal
      size="medium"
      icon=""
      title={props.title}
      isVisible={props.isVisible}
      primaryButton={{
        name: props.primaryButton.name,
        onClick: ()=>props.primaryButton.onClick(scheduleData),
        disabled: isPrimaryButtonDisabled(),
      }}
      secondaryButton={props.secondaryButton ? {
        name: props.secondaryButton.name,
        onClick: () => props.secondaryButton?.onClick(scheduleData),
        disabled: false,
      } : undefined}
      cancelButton={props.cancelButton ? {
        name: props.cancelButton?.name,
        onClick: onCancel,
      } : undefined}
    >
      <div className="schedule-edit-modal" style={{height: modalHeight || '100%'}}>
        {!isBroadcast &&
        <div className="schedule-edit-modal__runtime">Experience duration: {scheduleData.runtime}</div>}
        {renderAlreadyActivated()}
        {startDateExists ? (
          <div className="schedule-edit-modal__start">
            <div className="schedule-edit-modal__label">{isBroadcast ? 'Send at' : 'Starts'}</div>
            <div className="schedule-edit-modal__start-date-box">
              <div className="schedule-edit-modal__datepicker">
                <FlightDateTimePicker
                  format={dateFormat}
                  value={scheduleData.start}
                  onChange={(e:any)=>changeStartDate(e)}
                  showTodayButton
                  minutesStep={5}
                  minDate={scheduleData.minDate}
                  isError={convertStart?.local_unix! < moment().unix()}
                />
              </div>
              <FlightSelectSearchable
                className="schedule-edit-modal__select"
                label=""
                options={timezones}
                selected={scheduleData.timezone}
                handleOptionClick={handleTimezoneSelect}
                handleSearch={handleSearch}
                dropdownMaxHeight="150px"
                dropdownDirection="bottom"
              />
              {!endDateExists && (
                <FlightButton
                  className="schedule-edit-modal__remove-icon"
                  theme="minor"
                  onClick={()=>showStartdate(false)}
                  label="X"
                />
              )}
            </div>
            {convertStart?.local_unix! > moment().unix()+reservedStartTime-60 ? (
              <div className="schedule-edit-modal__comment">
                Experience will start at {convertStart?.local_time} {convertStart?.local_date} your local time.
              </div>
            ) : (
              <div className="schedule-edit-modal__comment__error">
                Start date {convertStart?.local_unix! < moment().unix()+reservedStartTime && convertStart?.local_unix! > moment().unix() ? 'must be at least 5 minutes in the future' : 'expired'}. Edit to current date/time to launch experience.
              </div>
            )}
          </div>
        ) : (
          ((!manualActivation && !autoActivation) || experience.status !== 'Active') &&
            <FlightButton
              theme="link"
              className="schedule-edit-modal__add-end"
              onClick={()=>showStartdate(true)}
              label={isBroadcast ? "+ Add send date" : "+ Add start date"}
            />
        )}
        {endDateExists ? (
          <div className="schedule-edit-modal__end">
            <div className="schedule-edit-modal__label">Ends</div>
            <div className="schedule-edit-modal__start-date-box">
              <div className="schedule-edit-modal__datepicker">
                <FlightDateTimePicker
                  format={dateFormat}
                  value={scheduleData.end}
                  onChange={(e:any)=>changeEndDate(e)}
                  showTodayButton
                  minutesStep={5}
                  minDate={scheduleData.minDate}
                  isError={convertEnd?.local_unix! < moment().unix()}
                />
              </div>
              <FlightSelectSearchable
                className="schedule-edit-modal__select"
                label=""
                options={timezones}
                selected={scheduleData.timezone}
                handleOptionClick={handleTimezoneSelect}
                handleSearch={handleSearch}
                dropdownMaxHeight="150px"
                disabled={!manualActivation}
              />
              <FlightButton
                className="schedule-edit-modal__remove-icon"
                theme="minor"
                onClick={()=>showEndDate(false)}
                label="X"
              />
            </div>
            {convertEnd?.local_unix! > moment().unix() + reservedEndTime-60 ? (
              <div className="schedule-edit-modal__comment">
                Experience will end at {convertEnd?.local_time} {convertEnd?.local_date} your local time.
              </div>
            ) : (
              <div className="schedule-edit-modal__comment__error">
                End date expired. Edit to current date/time to launch experience.
              </div>
            )}
          </div>
        ) : (
          (startDateExists || ((!!manualActivation || !!autoActivation) && experience.status === 'Active')) && !isBroadcast && (
            <FlightButton
              theme="link"
              className="schedule-edit-modal__add-end"
              onClick={()=>showEndDate(true)}
              label="+ Add end date"
            />
          )
        )}
      </div>
    </EditModal>
  )
}


import React  from 'react';

export default function PerformanceGraphNoData() {

  return (
    <div className="no-data">
      <div className="no-data__center">
        <h2>No data to show</h2>
        <p>Nothing to show yet, check back after users have been targeted.</p>
      </div>
    </div>
  )
}

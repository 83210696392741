import React, {useEffect, useState} from 'react';
import './TriggerEditModal.scss';
import EditModal from "../EditModal";
import {
  FlightButton,
  // FlightCheckbox,
  FlightNumberInput, FlightSelect,
  FlightSelectSearchable
} from "@flybits/webapp-design-system-react";
import {getTimezoneList, getMyTimezone} from "helpers/templated-experience.helper";
import TextField from "@material-ui/core/TextField";
import { TimePicker, LocalizationProvider } from "@material-ui/pickers";
import { fetchPluginsThunk } from 'redux/plugin/plugin.thunk';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';

export const triggerOptions = [
  {key: 'onEvent', name: 'When a user joins the target audience', descr: ''},
  {key: 'before', name: 'Before a unique event to each target audience member', descr: 'Expose target audience to a limited-time experience that starts at a predefined time before the event and ends on the event date.'},
  {key: 'after', name: 'After a unique event to each target audience member', descr: 'Expose target audience to a limited-time experience that starts at the event date and ends at a the predefined time after the event.'},
];


export default function TriggerEditModal(props: any) {

  const dispatch = useDispatch();
  let propsTrigger = props.body.trigger;
  const plugins = useSelector((state: any) => state.plugin.plugins);
  const [timezones, setTimezones] = useState<any>([]);
  const weekDays = [
    {key: 'monday', name: 'Monday'},
    {key: 'tuesday', name: 'Tuesday'},
    {key: 'wednesday', name: 'Wednesday'},
    {key: 'thursday', name: 'Thursday'},
    {key: 'friday', name: 'Friday'},
    {key: 'saturday', name: 'Saturday'},
    {key: 'sunday', name: 'Sunday'},
  ];
  const periods = [
    {key: 'second', name: 'Seconds'},
    {key: 'minute', name: 'Minutes'},
    {key: 'hour', name: 'Hours'},
    {key: 'day', name: 'Days'},
    {key: 'week', name: 'Weeks'},
    {key: 'month', name: 'Months'},
    {key: 'year', name: 'Years'},
  ];
  const [timeContextPlugins, setTimeContextPlugins] = useState<any>([]);
  const [triggerData, setTriggerData] = useState<any>({
    trigger: propsTrigger.trigger || triggerOptions[0],
    dayparting: 'UNSELECTED',
    timezone: {
      key: 0,
      name: '',
      offset: 0
    },
    repeats: [],
    context: {
      value: propsTrigger.context?.value || '',
      period: propsTrigger.context?.period || periods[1],
      plugin: {
        key: 0,
        name: '',
      },
    }
  });

  useEffect(() => {setTimezones(getTimezoneList())},[]);
  useEffect(() => {dispatch(fetchPluginsThunk())},[dispatch]);
  useEffect(() => {
    let filteredPlugins:Array<any> = [];
    plugins?.map((plugin:any) => {
      for(let p in plugin.values) {
        if(plugin.values[p].isTimeContext) {
          filteredPlugins.push({key: plugin.values[p].uid, name: `${plugin.name} / ${plugin.values[p].name}`});
        }
      }
    });
    if(propsTrigger.context?.plugin?.key) {
      let findPlugin = filteredPlugins.find(p => p.key === propsTrigger.context?.plugin?.key);
      setTriggerData({
        ...triggerData,
        context: {
          ...triggerData.context,
          plugin: {
            key: findPlugin?.key || '',
            name: findPlugin?.name || ''
          }
        }
      })
    }
    setTimeContextPlugins(filteredPlugins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[plugins, propsTrigger]);

  function handleTriggerSelect(e: any) {
    setTriggerData({
      ...triggerData,
      trigger: e,
      context: {
        ...triggerData.context,
        value: (e.key === 'before' || e.key === 'after') ? '1' : '',
        period: (e.key === 'before' || e.key === 'after') ? periods[1] : null,
        plugin: (e.key === 'before' || e.key === 'after') ? timeContextPlugins[0] : null,
      }
    })
  }

  function toggleDayParting() {
    let myZone = getMyTimezone();
    setTriggerData({
      ...triggerData,
      dayparting: triggerData.dayparting === 'UNSELECTED' ? 'SELECTED' : 'UNSELECTED',
      repeats: triggerData.dayparting === 'UNSELECTED' ? [{
        weekday: weekDays[0],
        from: new Date(),
        to: new Date(),
      }] : [],
      timezone: {...myZone}
    });
  }

  function handleTimezoneSelect(option: any) {
    setTriggerData({
      ...triggerData,
      timezone: option
    });
  }

  function handleTimezoneSearch(searchZone: string) {
    setTimezones(getTimezoneList(searchZone));
  }

  function handleWeekDaySelect(day: string, idx: number) {
    triggerData.repeats[idx].weekday = day;
    setTriggerData({
      ...triggerData,
      repeats: [
        ...triggerData.repeats
      ]
    });
  }

  function addDayOfWeek() {
    setTriggerData({
      ...triggerData,
      repeats: [
        ...triggerData.repeats,
        {weekday: weekDays[0], from: new Date(), to: new Date()}
      ]
    });
  }

  function removeDayOfWeek(idx:number) {
    let filteredRepeats = triggerData.repeats.filter((r: any, i: number) => i !== idx);
    setTriggerData({
      ...triggerData,
      repeats: [...filteredRepeats]
    });
    if(!filteredRepeats.length) {
      toggleDayParting();
    }
  }

  function handleTimeUpdate(time:any, idx:number, type:string) {
    triggerData.repeats[idx][type] = time;
    setTriggerData({
      ...triggerData,
      repeats: [...triggerData.repeats]
    })
  }

  function handlePluginSelect(option: any) {
    setTriggerData({
      ...triggerData,
      context: {
        ...triggerData.context,
        plugin: option,
      }
    })
  }

  return (
    <EditModal
      size="medium"
      className="trigger-edit-modal"
      icon={props.icon}
      title={props.title}
      isVisible={props.isVisible}
      primaryButton={{
        name: props.primaryButton.name,
        onClick: ()=>props.primaryButton.onClick(triggerData),
        disabled: false,
      }}
      cancelButton={props.cancelButton ? {
        name: props.cancelButton?.name,
        onClick: props.cancelButton?.onClick,
      } : undefined}
    >
      <div className="trigger-edit-modal__content">
        <div className="trigger-edit-modal__label">Triggered delivery options:</div>
        <FlightSelectSearchable
          className="trigger-edit-modal__select"
          label=""
          options={triggerOptions}
          selected={triggerData.trigger}
          handleOptionClick={handleTriggerSelect}
          dropdownMaxHeight="150px"
        />
        {triggerData.trigger.descr && (
          <div className="trigger-edit-modal__descr">{triggerData.trigger.descr}</div>
        )}
        {/* TODO: Uncomment when dayparting functionality will be required and supported by backend */}
        {/*{(triggerData.trigger.key === 'onEvent' && (propsTrigger.type === 'triggered' || propsTrigger.type === '')) && (*/}
        {/*  <div className="trigger-edit-modal__inline">*/}
        {/*    <FlightCheckbox*/}
        {/*      checkState={triggerData.dayparting}*/}
        {/*      onSelect={()=>toggleDayParting()}*/}
        {/*      disabled={false}*/}
        {/*      className="trigger-edit-modal__checkbox"*/}
        {/*    />*/}
        {/*    Only send this experience during specific days/times*/}
        {/*  </div>*/}
        {/*)}*/}
        {triggerData.dayparting === 'SELECTED' && triggerData.trigger.key === 'onEvent' && (
          <div>
            <div className="trigger-edit-modal__inline">
              Time zone
              <FlightSelectSearchable
                className="trigger-edit-modal__select-timezone"
                label=""
                options={timezones}
                selected={triggerData.timezone}
                handleOptionClick={handleTimezoneSelect}
                handleSearch={handleTimezoneSearch}
                dropdownMaxHeight="150px"
              />
            </div>
            {!!triggerData.repeats.length && triggerData.repeats.map((repeat: any, idx: number) => (
              <div key={`repeat-${idx}-${repeat.weekday.key}`} className="trigger-edit-modal__inline">
                Every
                <FlightSelectSearchable
                  className="trigger-edit-modal__select-day"
                  label=""
                  options={weekDays}
                  selected={repeat.weekday}
                  handleOptionClick={(day)=>handleWeekDaySelect(day, idx)}
                  dropdownMaxHeight="150px"
                />
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <TimePicker
                    className="trigger-edit-modal__select-time"
                    value={repeat.from ? repeat.from : new Date()}
                    minutesStep={5}
                    onChange={(time)=>handleTimeUpdate(time, idx, 'from')}
                    renderInput={(props) => <TextField {...props} />}
                  />
                  <div>to</div>
                  <TimePicker
                    className="trigger-edit-modal__select-time"
                    value={repeat.to ? repeat.to : new Date()}
                    minutesStep={5}
                    onChange={(time)=>handleTimeUpdate(time, idx, 'to')}
                    renderInput={(props) => <TextField {...props} />}
                  />
                  <FlightButton
                    className="trigger-edit-modal__remove-icon"
                    theme="minor"
                    onClick={()=>removeDayOfWeek(idx)}
                    label="X"
                  />
                </LocalizationProvider>
              </div>
            ))}
            <div className="trigger-edit-modal__link-box">
              <a href='/' className="trigger-edit-modal__link" onClick={addDayOfWeek}>+ Add day of the week</a>
            </div>
          </div>
        )}
        {(triggerData.trigger.key === 'before' || triggerData.trigger.key === 'after') && (
          <div>
            <div className="trigger-edit-modal__inline">
              <div
                className="trigger-edit-modal__inline__text">{triggerData.trigger.key === 'before' ? 'Start' : 'End'} experience
              </div>
              <FlightNumberInput
                className="trigger-edit-modal__number"
                placeholderText={'1'}
                width={'50px'}
                maxValue={999}
                minValue={1}
                hasError={false}
                errorMessage={''}
                onChange={(e) => setTriggerData({
                  ...triggerData,
                  context: {...triggerData.context,
                    value: e.target.value
                  }
                })}
                disabled={false}
                value={triggerData.context.value}
              />
              <FlightSelect
                label=""
                options={periods}
                selected={triggerData.context.period}
                hasLabelAnimation={true}
                handleOptionClick={(option)=>setTriggerData({
                  ...triggerData,
                  context: {
                    ...triggerData.context,
                    period: option
                  }
                })}
                dropdownMaxHeight={'200px'}
                disabled={false}
                className="trigger-edit-modal__select-period"
                dropdownDirection={'top'}
              />
              <div className="trigger-edit-modal__inline__text">{triggerData.trigger.key} each user&apos;s</div>
              <FlightSelect
                label=""
                options={timeContextPlugins}
                selected={triggerData.context.plugin}
                hasLabelAnimation={true}
                handleOptionClick={(option) => handlePluginSelect(option)}
                dropdownMaxHeight={'200px'}
                disabled={false}
                className="trigger-edit-modal__select-event"
                dropdownDirection={'top'}
              />
            </div>
          </div>
        )}
      </div>
    </EditModal>
  )
}


import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import {
  SetErrorAction,
  AnalyticsContentEngagementBySourceActionType,
  SetLoadingAction,
  SetDataAction,
} from 'redux/analytics/content-engagement-by-source/content-engagement-by-source.type';
import { TEASingleValue } from 'interface/templated-experience/analytics.interface';

// Fetch total content views count from a push for Content Engagement by Source Graph
export function fetchTotalContentViewsCountFromPush(opts: { pushID: string, contentID: string, params: any }) {
  const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_PUSH_LOADING));
      dispatch(setError(''));
      templatedExperienceAnalyticsAPI.getTotalContentViewsCountFromPush(opts.pushID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setData(res.data, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_PUSH_DATA));
        resolve(res);
      }).catch((err: any) => {
        dispatch(setError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setLoading(false, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_PUSH_LOADING));
      });
    })
  }
}

// Fetch total content clicks count from a push for Content Engagement by Source Graph
export function fetchTotalContentClicksCountFromPush(opts: { pushID: string, contentID: string, params: any }) {
  const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_PUSH_LOADING));
      dispatch(setError(''));
      templatedExperienceAnalyticsAPI.getTotalContentClicksCountFromPush(opts.pushID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setData(res.data, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_PUSH_DATA));
        resolve(res);
      }).catch((err: any) => {
        dispatch(setError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setLoading(false, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_PUSH_LOADING));
      });
    })
  }
}

// Fetch total content views count from an experience for Content Engagement by Source Graph
export function fetchTotalContentViewsCountFromExperience(opts: { experienceID: string, contentID: string, params: any }) {
  const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_EXPERIENCE_LOADING));
      dispatch(setError(''));
      templatedExperienceAnalyticsAPI.getTotalContentViewsCountFromExperience(opts.experienceID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setData(res.data, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_EXPERIENCE_DATA));
        resolve(res);
      }).catch((err: any) => {
        dispatch(setError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setLoading(false, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_EXPERIENCE_LOADING));
      });
    })
  }
}

// Fetch total content clicks count from an experience for Content Engagement by Source Graph
export function fetchTotalContentClicksCountFromExperience(opts: { experienceID: string, contentID: string, params: any }) {
  const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_EXPERIENCE_LOADING));
      dispatch(setError(''));
      templatedExperienceAnalyticsAPI.getTotalContentClicksCountFromExperience(opts.experienceID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setData(res.data, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_EXPERIENCE_DATA));
        resolve(res);
      }).catch((err: any) => {
        dispatch(setError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setLoading(false, AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_EXPERIENCE_LOADING));
      });
    })
  }
}

// set loading state for Content Engagement By Source Graph
function setLoading(payload: boolean, graphType: string): SetLoadingAction {
  return {
    type: graphType,
    payload,
  }
}

// set data for Content Engagement By Source Graph
function setData(payload: TEASingleValue, graphType: string): SetDataAction {
  return {
    type: graphType,
    payload,
  }
}

// set Error for Content Engagement By Source Graph
function setError(payload: string): SetErrorAction {
  return {
    type: AnalyticsContentEngagementBySourceActionType.SET_ERROR,
    payload,
  }
}

import React, { useContext } from 'react';
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';
import ScheduleIcon from 'assets/icons/calendar-with-clock-24.svg';
import { convertTimezoneToDate } from 'helpers/templated-experience.helper';
interface IProps {
  onClick: () => void;
}

export default function Schedule(props: IProps) {
  const multiStepContext: any = useContext(MultiStepContext);
  const { start, end, timezone } = multiStepContext.template.entry.schedule;

  function renderBody() {
    let convertStart = convertTimezoneToDate(start, timezone);
    let convertEnd = convertTimezoneToDate(end, timezone);
    return (
      <>
        {start === 0 && end === 0 ? (
          <div> None Set </div>
        ) : (
          <>
            <div style={{ paddingTop: '16px' }}>
              <b> Timezone </b>
              <p> {timezone} </p>
            </div>
            <div style={{ paddingTop: '16px' }}>
              <b> Start </b>
              <p> {start > 0 ? `${convertStart.local_date} - ${convertStart.local_time}` : 'None set'} </p>
            </div>
            <div style={{ paddingTop: '16px' }}>
              <b> End </b>
              <p> {end > 0 ? `${convertEnd.local_date} - ${convertEnd.local_time}` : 'None set'} </p>
            </div>
          </>
        )}
      </>
    );
  }

  return <MiniBox {...props} icon={ScheduleIcon} title="Schedule" body={renderBody()} />;
}

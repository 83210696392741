import { AnalyticsFunnelState } from 'redux/analytics/experience-funnel/experience-funnel.type';
import {
  AnalyticsFunnelActionType
} from 'redux/analytics/experience-funnel/experience-funnel.type';

let initialState: AnalyticsFunnelState = {
  targetAudience: { value: 0 },
  pushSent: { value: 0 },
  pushDelivered: { value: 0 },
  openedPush: { value: 0 },
  clickedOnContentFromPush: { value: 0 },
  viewedContentInApp: { value: 0 },
  clickedOnContentInApp: { value: 0 },
  viewedContentWithoutPushDelivered: { value: 0 },
  viewedContentWithoutPushOpened: { value: 0 },
  engagedContentWithoutPushOpened: { value: 0 },
  isTargetAudienceLoading: false,
  isPushSentLoading: false,
  isPushDeliveredLoading: false,
  isOpenedPushLoading: false,
  isClickedOnContentFromPushLoading: false,
  isViewedContentInAppLoading: false,
  isClickedOnContentInAppLoading: false,
  isViewedContentWithoutPushDeliveredLoading: false,
  isEngagedContentWithoutPushOpenedLoading: false,
  isViewedContentWithoutPushOpenedLoading: false,
  errorMessage: ''
};

export default (state: AnalyticsFunnelState = initialState, action: any) => {
  switch (action.type) {
    case AnalyticsFunnelActionType.SET_FUNNEL_TA_DATA:
      return {
        ...state,
        targetAudience: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_SP_DATA:
      return {
        ...state,
        pushSent: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_PD_DATA:
      return {
        ...state,
        pushDelivered: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_OP_DATA:
      return {
        ...state,
        openedPush: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_COCP_DATA:
      return {
        ...state,
        clickedOnContentFromPush: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_VC_DATA:
      return {
        ...state,
        viewedContentInApp: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_COCA_DATA:
      return {
        ...state,
        clickedOnContentInApp: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FLOW_PND_DATA:
      return {
        ...state,
        viewedContentWithoutPushDelivered: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FLOW_PNOV_DATA:
      return {
        ...state,
        viewedContentWithoutPushOpened: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FLOW_PNOE_DATA:
      return {
        ...state,
        engagedContentWithoutPushOpened: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_TA_LOADING:
      return {
        ...state,
        isTargetAudienceLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_OP_LOADING:
      return {
        ...state,
        isOpenedPushLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_SP_LOADING:
      return {
        ...state,
        isPushSentLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_PD_LOADING:
      return {
        ...state,
        isPushDeliveredLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_COCP_LOADING:
      return {
        ...state,
        isClickedOnContentFromPushLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_VC_LOADING:
      return {
        ...state,
        isViewedContentInAppLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_COCA_LOADING:
      return {
        ...state,
        isClickedOnContentInAppLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FLOW_PND_LOADING:
      return {
        ...state,
        isViewedContentWithoutPushDeliveredLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FLOW_PNOV_LOADING:
      return {
        ...state,
        isViewedContentWithoutPushOpenedLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FLOW_PNOE_LOADING:
      return {
        ...state,
        isEngagedContentWithoutPushOpenedLoading: action.payload,
      }
    case AnalyticsFunnelActionType.SET_FUNNEL_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return {
        ...state
      };
  }
};

import BaseAPI from './base.api';
import { TEASingleValue, TEATimeline, TEAContentEngagementAnalytics } from 'interface/templated-experience/analytics.interface';

export default class TemplatedExperienceAnalyticsAPI extends BaseAPI {
  private ROUTES = {
    // Single value
    totalUsers: '/analytics/analytics/users/total',
    targetAudience: '/analytics/analytics/rules/:ruleID/targetAudience/count',
    openedPush: '/analytics/analytics/pushes/:pushID/openedPush/count',
    sentPush: '/analytics/analytics/pushes/:pushID/sentPush/count',
    deliveredPush: '/analytics/analytics/pushes/:pushID/deliveredPush/count',
    clickedOnContentFromPush: '/analytics/analytics/pushes/:pushID/contents/:contentID/uniqueEngaged/count',
    viewedContentInApp: '/analytics/analytics/experiences/:experienceID/contents/:contentID/uniqueViewed/count',
    clickedOnContentInApp: '/analytics/analytics/experiences/:experienceID/contents/:contentID/uniqueEngaged/count',
    viewedContentWithoutPushDelivered: '/analytics/analytics/experiences/:experienceID/contents/:contentID/uniqueViewed/pushes/:pushID/pushNotDelivered/count',
    viewedContentWithoutPushOpened: '/analytics/analytics/experiences/:experienceID/contents/:contentID/uniqueViewed/pushes/:pushID/pushNotOpened/count',
    engagedContentWithoutPushOpened: '/analytics/analytics/experiences/:experienceID/contents/:contentID/uniqueEngaged/pushes/:pushID/pushNotOpened/count',
    // Timeline
    totalUsersTimeline: '/analytics/analytics/users/timeline/total',
    targetAudienceTimeline: '/analytics/analytics/rules/:ruleID/targetAudience/timeline',
    openedPushTimeline: '/analytics/analytics/pushes/:pushID/openedPush/timeline',
    contentViewsTimeline: '/analytics/analytics/contents/:contentID/uniqueViewed/timeline',
    contentClicksTimeline: '/analytics/analytics/contents/:contentID/uniqueEngaged/timeline',
    deliveredPushTimeline: '/analytics/analytics/pushes/:pushID/deliveredPush/timeline',
    // Content engagement table
    contentEngagementExperiencesRecords: '/analytics/analytics/reports/experiences/:journeyActionID/contents',
    contentEngagementPushesRecords: '/analytics/analytics/reports/pushes/:journeyActionID/contents',
    // Content engagement by source (raw count)
    contentViewsCountFromPush: '/analytics/analytics/pushes/:pushID/contents/:contentID/totalViewed/count',
    contentClicksCountFromPush: '/analytics/analytics/pushes/:pushID/contents/:contentID/totalEngaged/count',
    contentViewsCountFromExperience: '/analytics/analytics/experiences/:experienceID/contents/:contentID/totalViewed/count',
    contentClicksCountFromExperience: '/analytics/analytics/experiences/:experienceID/contents/:contentID/totalEngaged/count',
  };

  getTotalUsersCount(): Promise<TEASingleValue> {
    return this.GET(this.ROUTES.totalUsers);
  }

  getTargetAudienceCount(id: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.targetAudience, {ruleID: id}), params);
  }

  getOpenedPushCount(id: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.openedPush, {pushID: id}), params);
  }

  getSentPushCount(id: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.sentPush, {pushID: id}), params);
  }

  getDeliveredPushCount(id: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.deliveredPush, {pushID: id}), params);
  }

  getClickedOnContentFromPush(id: string, cId: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.clickedOnContentFromPush, {pushID: id, contentID: cId}), params);
  }

  getViewedContentInAppCount(experienceID: string, contentID: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.viewedContentInApp, {experienceID, contentID}), params);
  }

  getClickedOnContentInApp(experienceID: string, contentID: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.clickedOnContentInApp, {experienceID, contentID}), params);
  }

  getContentViewedCountWithoutPushDelivered(experienceID: string, pushID: string, contentID: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.viewedContentWithoutPushDelivered, {experienceID, contentID, pushID}), params);
  }

  getContentViewedCountWithoutPushOpened(experienceID: string, pushID: string, contentID: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.viewedContentWithoutPushOpened, {experienceID, contentID, pushID}), params);
  }

  getContentEngagedCountWithoutPushOpened(experienceID: string, pushID: string, contentID: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.engagedContentWithoutPushOpened, {experienceID, contentID, pushID}), params);
  }

  getTotalUsersTimeline(params: any): Promise<TEATimeline> {
    return this.GET(this.ROUTES.totalUsersTimeline, params);
  }

  getTargetAudienceTimeline(ruleID: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.targetAudienceTimeline, {ruleID}), params);
  }

  getOpenedPushTimeline(id: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.openedPushTimeline, {pushID: id}), params);
  }

  getDeliveredPushTimeline(id: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.deliveredPushTimeline, {pushID: id}), params);
  }

  getContentViewsTimeline(contentID: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.contentViewsTimeline, {contentID}), params);
  }

  getContentClicksTimeline(contentID: string, params: any): Promise<TEATimeline> {
    return this.GET(this.buildURL(this.ROUTES.contentClicksTimeline, {contentID}), params);
  }

  getContentEngagementRecordsByExperience(experienceID: string, params: any): Promise<TEAContentEngagementAnalytics> {
    return this.GET(this.buildURL(this.ROUTES.contentEngagementExperiencesRecords, { journeyActionID: experienceID }), params);
  }

  getContentEngagementRecordsByPush(pushID: string, params: any): Promise<TEAContentEngagementAnalytics> {
    return this.GET(this.buildURL(this.ROUTES.contentEngagementPushesRecords, { journeyActionID: pushID }), params);
  }

  getTotalContentViewsCountFromPush(id: string, cId: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.contentViewsCountFromPush, {pushID: id, contentID: cId}), params);
  }

  getTotalContentClicksCountFromPush(id: string, cId: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.contentClicksCountFromPush, {pushID: id, contentID: cId}), params);
  }

  getTotalContentViewsCountFromExperience(id: string, cId: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.contentViewsCountFromExperience, {experienceID: id, contentID: cId}), params);
  }

  getTotalContentClicksCountFromExperience(id: string, cId: string, params: any): Promise<TEASingleValue> {
    return this.GET(this.buildURL(this.ROUTES.contentClicksCountFromExperience, {experienceID: id, contentID: cId}), params);
  }
}

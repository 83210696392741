import React, { useEffect, useState } from 'react';
import { ruleToString } from 'helpers/ao-demo.helper';
import FlowBox from 'components/FlowBox/FlowBox';
import PushBox from "components/FlowBox/PushBox/PushBox";
import ContentBox from "components/FlowBox/ContentBox/ContentBox";
import AudienceIcon from "assets/icons/audience.svg";
import { FlightButton } from "@flybits/webapp-design-system-react";

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import AudienceEditModal from 'components/Modal/EditModal/AudienceEditModal/AudienceEditModal';

export default function AODemoAudienceBox(props: any) {

  const restrictionString: any = {
    and: 'All of the following restrictions define your audience reach',
    or: 'Any of the following restrictions define your audience reach',
  }

  const preferredString: any = {
    and: 'All of the following conditions define your preferred criteria',
    or: 'Any of the following conditions define your preferred criteria'
  }

  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [ruleStringObj, setRuleStringObj] = useState<any>(undefined); // restriction
  const [seedRuleStringObj, setSeedRuleStringObj] = useState<any>(undefined); // preferred (seed)
  const [isAudienceEditModalVisible, setIsAudienceEditModalVisible] = useState(false);

  useEffect(() => {
    setRuleStringObj(ruleToString(props.ruleStringRepresentation));
    setSeedRuleStringObj(ruleToString(props.seedRuleStringRepresentation));
  }, [props.ruleStringRepresentation, props.seedRuleStringRepresentation]);

  function audienceRow(stringObj: any, idx: number) {
    return (
      <div key={`stringRep_${idx}`} className="flow-box__card-container__card__step-body__plugins">
        <div className="flow-box__card-container__card__step-body__plugins__plugin-left">
          <b> { idx + 1 }. </b>
        </div>
        <div className="flow-box__card-container__card__step-body__plugins__plugin-right">
          {stringObj.arguments[0]} {stringObj.predicate} <b> &nbsp; {stringObj.arguments[1]} </b>
        </div>
      </div>
    )
  }

  function AudienceSection() {
    const audience = ruleStringObj.data.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    const seedAudience = seedRuleStringObj.data.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));

    // Empty Restrictions
    if (!ruleStringObj.data.length && !seedRuleStringObj.data.length) {
      return (
        <>
          <div className="flow-box__card-container__card__step-body__descr">
            <div className="anyone">
              <span className="anyone__heading"> Anyone </span>
              <p> With no audience restrictions selected, this experience may reach every user </p>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          {ruleStringObj.data.length > 0 &&
            <div className="flow-box__card-container__card__step-body__content">
              <b> Restrictions </b>
              <div className="flow-box__card-container__card__step-body__descr">
                <i> {restrictionString[ruleStringObj.operator.toLowerCase()]} </i>
              </div>
              {audience}
            </div>
          }
          {seedRuleStringObj.data.length > 0 &&
            <div className="flow-box__card-container__card__step-body__content">
              <b> Preferred criteria </b>
              <div className="flow-box__card-container__card__step-body__descr">
                <i> {preferredString[seedRuleStringObj.operator.toLowerCase()]} </i>
              </div>
              {seedAudience}
            </div>
          }
        </>
      )
    }
  }

  function body(props: any) {
    return (
      <>
        <div> {ruleStringObj && AudienceSection()} </div>
          <FlightButton
            theme="secondary"
            onClick={() => setIsAudienceEditModalVisible(true)}
            label="Configure Audience"
          />
      </>
    )
  }

  const renderStepActions = (props: any) => {
    return props.actions?.map((action: any, i: number) => {
      const actionProps = { ...action, key: `action_${i}` };
      switch (action.actionType) {
        case 'push':
          return <PushBox {...actionProps} />
        case 'experience':
          return <ContentBox {...actionProps} />
        default:
          break;
      }
    })
  };

  function onApplyHandle(payload: {ruleStringRepresentation: string, seedRuleStringRepresentation: string}) {
    reduxTemplatedExperienceAction.setAudience(payload);
    setIsAudienceEditModalVisible(false);
  }

  return (
    <>
      {!props.isRuleHidden && (
        <FlowBox
          {...props}
          type="Audience"
          icon={AudienceIcon}
          body={body(props)}
        />
      )}
      {renderStepActions(props)}
      {isAudienceEditModalVisible && (
        <AudienceEditModal
          action={props}
          isVisible={isAudienceEditModalVisible}
          onApply={onApplyHandle}
          onCancel={() => setIsAudienceEditModalVisible(false)}
        />
      )}
    </>
  )
}

import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';

import { times, isEqual, isEmpty } from 'lodash';
import Skeleton from 'react-loading-skeleton';

import { FlightModal, FlightButton, FlightCheckbox, FlightSnackbar } from '@flybits/webapp-design-system-react';
import './ContentListModal.scss';
import { Content, ContentPreview } from 'interface/content/content.interface';
import StarterContentPreview from './ContentModalPreview/StarterContentPreview';
import headerImg from 'assets/images/button-preview-ios.svg';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { ReactComponent as AmplificationIcon } from 'assets/icons/megaphone.svg';
import { ReactComponent as EditPen } from 'assets/icons/edit-pen.svg';
import { ReactComponent as Shadow } from 'assets/icons/ellipse.svg';
import { ReactComponent as FlybitsIcon } from 'assets/icons/icon_flybits.svg';
import { ReactComponent as ButtonLinkIcon } from 'assets/icons/buttonlink.svg';
import { ReactComponent as TemplateIcon } from 'assets/icons/template.svg';
import { ReactComponent as TextLinkIcon } from 'assets/icons/textlink.svg';
import ContentAPI from 'services/api/content.api';

import InfiniteScroll from 'react-infinite-scroller';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CircularProgress } from '@material-ui/core';
import SearchBar from 'components/Shared/SearchBar/SearchBar';
import useActions from 'hooks/useActions';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useSelector } from 'react-redux';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';

interface IProps {
  icon: string;
  title: string;
  isVisible: boolean;
  listData: Array<Content>;
  isLoading: boolean;
  selectedId?: string;
  isAfterCreate: boolean;
  page: number;
  amplifiedContentId?: string;
  hasMore: boolean;
  isCalledFromPush: boolean;
  isCreateContentVisible: boolean;
  onHandleSearch: (searchTerm: string) => void;
  fetchMoreData: () => void;
  onCreateContent?: () => void;
  onEditInstance: (content: Content | null) => void;
  onApply: (content: Content[] | Content | null) => void;
  onCancel: () => void;
  actionBody?: any;
  selectedContent: Content | null;
  setSelectedContent: Dispatch<SetStateAction<Content | null>>;
  isNearByTemplate?: boolean;
}

export default function ContentListModal(props: IProps) {
  const { selectedContent, setSelectedContent } = props;
  const contentAPI = new ContentAPI();
  const settings = useSettings();
  const [contentForPreview, setContentForPreview] = useState<Content | null>(null);
  const [tabState, setTabState] = useState(1);
  const [sortedContent, setSortedContent] = useState<Content[] | undefined>();
  const [previewContent, setPreviewContent] = useState<ContentPreview>();
  const [alertRemoveAmplification, setAlertRemoveAmplification] = useState(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [rememberMe, setRemeberMe] = useState(false);
  const { traits, setTrait } = useFeatureFlag();
  const hideSnackbarTraitKey = `tx_never_show_delete_amplification_snackbar`;
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);

  const isContentAmplified = (selectedId: string) => {
    if (selectedId === props?.amplifiedContentId) return true;
    return false;
  };

  const handleSelection = async (item: Content | null, isCalledByClick?: boolean) => {
    if (props.isCreateContentVisible) {
      //early return prevent repeated api calls on redundant clicks
      const fetchForSameId = selectedContent?.id === item?.id;
      if (fetchForSameId && isCalledByClick) {
        return;
        //only fetch if selectedId exists and the modal is open
      } else if (item?.id && props.isVisible) {
        fetchContentInstance(item?.id);
      }
    }
    setSelectedContent(item);
  };

  function renderCardIcon(item: Content) {
    let className = 'content-list-modal__content__left__container__item__header-icon';
    switch (item.type) {
      case 'concierge-card-link':
        return <TextLinkIcon className={className} />;
      case 'concierge-card-buttons':
        return <ButtonLinkIcon className={className} />;
      default:
        return <TemplateIcon className={className} />;
    }
  }

  function ListItem(item: Content, index: number, isContentInstance: boolean) {
    let className = 'content-list-modal__content__left__container__item';

    const handleCheckBoxClick = (item: Content) => {
      if (
        isContentAmplified(item.id) &&
        !alertRemoveAmplification &&
        checkboxState.find((selectedItem) => selectedItem.id === item.id)
      ) {
        setAlertRemoveAmplification(true);
      }

      if (checkboxState.find((selectedItem) => selectedItem.id === item.id)) {
        const preSelected = checkboxState.filter((itemExists) => itemExists.id !== item.id);
        setCheckboxState(preSelected);
        return;
      }

      handleSelection(item);
      setCheckboxState(checkboxState.concat(item));
    };

    return !isContentInstance ? (
      <div
        tabIndex={0}
        className={selectedContent?.id === item.id ? className + '--selected' : className}
        onClick={() => handleSelection(item, true)}
        key={`modal__item-${index}`}
      >
        <div className={className + '__title'}>
          {renderCardIcon(item)}
          {item?.localizations?.en?.name}
        </div>
        <div className={className + '__body'}>{item?.localizations?.en?.description || ''}</div>
        {item.labels.includes('starter') ? <FlybitsIcon className={className + '__icon'} /> : null}
      </div>
    ) : (
      <div key={`modal__item-${index}`}>
        {(!props.isCalledFromPush && reduxTemplatedExperienceState?.temp?.viewMode !== 'journey') ? (
          <FlightCheckbox
            checkState={checkboxState?.find((x) => x.id === item.id) ? 'SELECTED' : 'UNSELECTED'}
            className={'content-list-modal__content__left__checkbox'}
            onSelect={() => handleCheckBoxClick(item)}
          />
        ) : null}
        <div
          tabIndex={0}
          className={selectedContent?.id === item.id ? className + '--selected' : className}
          onClick={() => handleSelection(item, true)}
        >
          <span>
            {item?.localizations?.en?.name}
            {isContentAmplified(item.id) ? <AmplificationIcon className={className + `__amplification-icon`} /> : null}
            <div className={className + `--edit`} onClick={(e) => handleEditInstance(e, item)}>
              <Shadow className={className + `--edit__shadow`} />
              <EditPen className={className + `--edit__pen`} />
            </div>
          </span>
        </div>
      </div>
    );
  }

  const handleEditInstance = (e: any, editContentInstance: Content) => {
    e.stopPropagation();
    props.onEditInstance(editContentInstance);
    setSelectedContent(null);
  };

  const handleCreateContent = () => {
    setSortedContent(undefined);
    setSelectedContent(null);
    props.onCreateContent && props.onCreateContent();
  };

  const handleApply = () => {
    if (props.isCalledFromPush || !props.isCreateContentVisible || props.isNearByTemplate || reduxTemplatedExperienceState?.temp?.viewMode === 'journey') {
      props.onApply(selectedContent);
    } else {
      if (checkboxState.find((item: any) => item.id === props.amplifiedContentId)) {
        props.onApply(checkboxState);
        return;
      }
      reduxTemplatedExperienceAction.removeAmplification();
      props.onApply(checkboxState);
    }
    setSelectedContent(null); // isCreateContentVisible
  };

  const handleCancel = () => {
    props.onCancel();
    setSelectedContent(null);
    setCheckboxState([]);
    setAlertRemoveAmplification(false);
    setContentForPreview(null);
  };

  useEffect(() => {
    let createdInstance: Content | undefined;
    if (props.selectedId && props.selectedId !== '' && !selectedContent) {
      createdInstance = props.listData.find((instance) => instance.id === props.selectedId);
      if (props.isCalledFromPush && props.isAfterCreate) {
        createdInstance = props.listData[0];
      }
      handleSelection(createdInstance || null);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible, props.listData, props.selectedId]);

  const fetchContentInstance = async (id: string) => {
    if (id !== '') {
      await contentAPI.getInstance(id).then((res: any) => {
        setContentForPreview(res);
      });
    }
  };

  useEffect(() => {
    let langCode = getDefaultLanguage(settings.languages);
    if (!contentForPreview?.content) {
      return;
    }
    if (contentForPreview?.content?.data?.[0]?.localizations) {
      const langKeys = Object.keys(contentForPreview?.content?.data[0].localizations);
      // use default lang index type (if it exists, else default to first)
      langKeys.find((lang) => lang === langCode) ?? (langCode = langKeys[0]);
    }

    setPreviewContent({
      headerImg: contentForPreview?.content?.data?.[0]?.localizations?.[langCode]?.image
        || contentForPreview?.content?.data?.[0]?.media?.localizations?.[langCode]?.resourceURL,
      header: contentForPreview?.content?.data?.[0]?.localizations?.[langCode]?.header,
      title: contentForPreview?.content?.data?.[0]?.localizations?.[langCode]?.title,
      body: contentForPreview?.content?.data?.[0]?.localizations?.[langCode]?.description,
      type: contentForPreview?.templateType,
      isContentInstance: props.isCreateContentVisible,
      isStarter: contentForPreview?.labels?.includes('starter') ? true : false,
      primaryButtonLabel:
        contentForPreview?.templateType === 'concierge-card-buttons' &&
        contentForPreview?.content?.data?.[0]?.buttons?.length !== 0
          ? contentForPreview?.content?.data?.[0]?.buttons[0]?.localizations?.[langCode]?.text
          : '',
      secondaryButtonLabel:
        contentForPreview?.templateType === 'concierge-card-buttons' &&
        contentForPreview?.content?.data?.[0]?.buttons?.length !== 0
          ? contentForPreview?.content?.data?.[0]?.buttons[1]?.localizations?.[langCode]?.text
          : '',
      details:
        contentForPreview?.templateType === 'concierge-card-buttons'
          ? contentForPreview?.content?.data?.[0]?.details?.localizations?.[langCode]?.body
          : '',
      linkLabel:
        contentForPreview?.templateType === 'concierge-card-link'
          ? contentForPreview?.content?.data?.[0]?.link?.localizations?.[langCode]?.text
          : '',
      unSupportedTitle: contentForPreview?.labels?.includes('starter') ? '' : contentForPreview?.localizations?.en.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentForPreview]);

  useEffect(() => {
    //populate default template preview on OOTB starter templates
    if (!props.isCreateContentVisible) {
      setPreviewContent({
        headerImg: props.isCreateContentVisible ? '' : headerImg,
        header: props.isCreateContentVisible ? '' : 'Inform Customers',
        title: selectedContent?.localizations?.en?.name,
        body: selectedContent?.localizations?.en?.description,
        type: selectedContent?.type,
        isContentInstance: props.isCreateContentVisible,
        isStarter: selectedContent?.labels?.includes('starter') ? true : false,
        primaryButtonLabel: props.isCreateContentVisible ? '' : 'Activate',
        secondaryButtonLabel: props.isCreateContentVisible ? '' : 'Learn More',
        linkLabel: props.isCreateContentVisible ? '' : 'Learn More',
      });
    } else {
      setPreviewContent({
        isContentInstance: true,
        isStarter: selectedContent?.labels?.includes('starter') ? true : false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContent]);

  function handleFetchAdditional() {
    props.fetchMoreData();
  }

  useEffect(() => {
    setSortedContent(props.listData.sort((a) => (a.id === selectedContent?.id ? -1 : 1)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listData]);

  const handleSearchInput = (searchTrm: any) => {
    props.onHandleSearch(searchTrm);
  };

  const [checkboxState, setCheckboxState] = useState<Content[]>([]);
  useEffect(() => {
    // This function only sets pre-selected values from redux state, it must not overwrite checkboxState
    // based on user selected values, we have an early return here to account for that.
    // the condition also checks for non-zero inequalities in array lengths.
    let contentFromFetchedData: any = [];

    if (
      props?.actionBody?.contentIds?.length === 0 //redux state is nil, return
    )
      return;

    if (props?.actionBody?.contentIds?.length !== 0) {
      props?.actionBody?.contentIds?.map((idFromState: string) => {
        props?.listData?.forEach((item) => {
          if (item.id === idFromState) {
            contentFromFetchedData.push(item);
          }
        });
      });
    }

    if (props?.actionBody?.contentIds?.length === contentFromFetchedData.length) {
      if (!isEqual(checkboxState, contentFromFetchedData)) {
        setCheckboxState(contentFromFetchedData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    // function appends newly created content to checkboxState array
    if (!isEmpty(props?.listData) && props.isAfterCreate && !props.isCalledFromPush && !props.isNearByTemplate) {
      if (isEmpty(checkboxState?.filter((item: Content) => item?.id === props?.listData?.[0]?.id))) {
        setCheckboxState(checkboxState.concat(props?.listData?.[0]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxState, props?.listData]);

  const disableApply = () => {
    if (props.isCalledFromPush || !props.isCreateContentVisible || reduxTemplatedExperienceState?.temp?.viewMode === 'journey') {
      return !selectedContent ? true : false;
    } else {
      return checkboxState.length === 0 ? true : false;
    }
  };

  useEffect(() => {
    if (checkboxState.length === 0) {
      setTabState(1);
    }
  }, [checkboxState]);

  const setRemeberMeTrait = () => {
    setRemeberMe((rememberMe) => !rememberMe);
  };

  const handleDismiss = () => {
    if (rememberMe) {
      setTrait(hideSnackbarTraitKey, true);
    }
    setAlertRemoveAmplification(false);
  };
  return (
    <FlightModal
      isVisible={props.isVisible}
      size="large"
      className="content-list-modal"
      scrollable={true}
      toggleModalShown={() => handleCancel()}
      header={
        <>
          <div className="content-list-modal__header">
            <img
              src={require(`assets/icons/${props.icon}`)}
              className="content-list-modal__header-icon"
              alt="modal icon"
            />
            <h4> {props.title} </h4>
          </div>
        </>
      }
      content={
        <>
          {alertRemoveAmplification && !traits[hideSnackbarTraitKey] && (
            <>
              <FlightCheckbox
                checkState={rememberMe ? 'SELECTED' : 'UNSELECTED'}
                label="Don't show this again"
                className="content-list-modal__content__error-checkbox"
                onSelect={() => setRemeberMeTrait()}
              />
              <FlightSnackbar
                isVisible={alertRemoveAmplification}
                className="content-list-modal__content__error-banner"
                icon="warning"
                content="The Push notification's configurations will be lost if you proceed to remove the amplified In-App Content."
                type="error"
                isAutoDismiss
                action={() => handleDismiss()}
                actionName="Dismiss"
              />
            </>
          )}
          <div className="content-list-modal__content">
            <div className="content-list-modal__content__left">
              <div className="content-list-modal__content__left__container">
                {/* {Loading states before rendering initial list} */}
                {props.isLoading && props?.listData.length < 7 && (
                  <>
                    {times(3, (i) => (
                      <div key={i} style={{ marginLeft: 15 }}>
                        <Skeleton width={'100%'} height={50} />
                      </div>
                    ))}
                  </>
                )}
                <InfiniteScroll
                  pageStart={props.page}
                  initialLoad={false}
                  loadMore={handleFetchAdditional}
                  hasMore={props.hasMore}
                  threshold={20}
                  element={'div'}
                  useWindow={false}
                  loader={
                    tabState !== 0 ? (
                      <div className="content-list-modal__content__left__container__item__loader" key={0}>
                        <CircularProgress size={20} />
                        <span className="content-list-modal__content__left__container__item__loader-text">
                          Loading...
                        </span>
                      </div>
                    ) : (
                      <div key={1}></div>
                    )
                  }
                >
                  <Tabs
                    className="flight-tab"
                    selectedTabClassName="flight-tab--selected"
                    selectedIndex={tabState}
                    onSelect={(tabIndex: React.SetStateAction<number>) => setTabState(tabIndex)}
                  >
                    <TabList className="flight-tab--list">
                      {checkboxState.length !== 0 && props.isCreateContentVisible ? (
                        <Tab key={'tab-selected-tabs'}>{`Selected (${checkboxState?.length})`}</Tab>
                      ) : (
                        <Tab key={'tab-selected-tabs'} style={{ visibility: 'hidden' }} />
                      )}
                      {!props.isCalledFromPush && props.isCreateContentVisible ? (
                        <Tab key={'tab-selected-tabs-all'}>{`All Content (${props.listData?.length})`}</Tab>
                      ) : (
                        <Tab key={'tab-selected-tabs-all'} style={{ visibility: 'hidden' }} />
                      )}
                      <span className="flight-tab__search">
                        <SearchBar searchText={(e) => handleSearchInput(e)} />
                      </span>
                    </TabList>
                    <TabPanel>
                      {checkboxState &&
                        checkboxState.map((data: Content, idx: number) => {
                          return ListItem(data, idx, props.isCreateContentVisible);
                        })}
                    </TabPanel>
                    <TabPanel>
                      <div>
                        {!sortedContent
                          ? props.listData &&
                            props.listData.map((data: Content, idx: number) => {
                              return ListItem(data, idx, props.isCreateContentVisible);
                            })
                          : sortedContent.map((data: Content, idx: number) => {
                              return ListItem(data, idx, props.isCreateContentVisible);
                            })}
                      </div>
                      {/* {Loading states for progressive rendering of paginatedlist} */}
                      {props.isLoading && (
                        <>
                          {times(3, (i) => (
                            <div key={i} style={{ marginLeft: 15 }}>
                              <Skeleton width={'100%'} height={50} />
                            </div>
                          ))}
                        </>
                      )}
                    </TabPanel>
                  </Tabs>
                </InfiniteScroll>
                {props.listData && props.listData.length === 0 && !props.isLoading ? (
                  <div>No results to display</div>
                ) : null}
              </div>
            </div>
            <div className="right">
              <StarterContentPreview
                isContentInstance={previewContent?.isContentInstance || false}
                headerImg={previewContent?.headerImg}
                header={previewContent?.header}
                title={previewContent?.title}
                body={previewContent?.body}
                primaryButtonLabel={previewContent?.primaryButtonLabel}
                secondaryButtonLabel={previewContent?.secondaryButtonLabel}
                details={previewContent?.details}
                type={previewContent?.type}
                isStarter={previewContent?.isStarter || false}
                unSupportedTitle={previewContent?.unSupportedTitle || ''}
                linkLabel={previewContent?.linkLabel}
              />
            </div>
          </div>
        </>
      }
      footer={
        <div className="content-list-modal__footer">
          {props.isCreateContentVisible && (
            <FlightButton
              theme="secondary"
              iconLeft={'add'}
              onClick={() => handleCreateContent()}
              label="Create new content"
            />
          )}
          <FlightButton
            theme="secondary"
            label={
              (props.isCreateContentVisible && !props.isCalledFromPush) || props.isNearByTemplate
                ? 'Cancel'
                : props.isCalledFromPush
                ? 'Back: Configure push notification'
                : 'Back'
            }
            onClick={() => handleCancel()}
          />
          <FlightButton
            theme="primary"
            disabled={disableApply()}
            label={props.isCreateContentVisible ? 'Apply' : 'Next'}
            onClick={() => handleApply()}
          />
        </div>
      }
    />
  );
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {useSelector} from 'react-redux';
import queryString from 'query-string';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import ControlBar from 'components/ControlBar/ControlBar';
import Performance from 'components/Performance/Performance';

import './TemplatedExperienceView.scss';
import { history } from 'redux/store';
import Storage from "services/storage";
import {trackEvent} from "helpers/analytics.helper";
import ScheduleEditModal, {scheduleDataType} from "components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal";
import {transformScheduleData} from "helpers/templated-experience.helper";
import useFeatureFlag from 'hooks/useFeatureFlag';
import SettingsTab from "components/SettingsTab/SettingsTab";
import ActionTab from 'components/ActionTab/ActionTab';
import ConfirmModal from '../../../components/Modal/ConfirmModal/ConfirmModal';
const storage = new Storage();

interface param {
  [key: string]: string | undefined
}

export default function TemplatedExperienceView(props:any) {
  const { flags } = useFeatureFlag();
  const { id }: param = useParams();
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const confirmModalProps = {
    isVisible: false,
    title: "Your Experience will be Deactivated",
    icon: "warning.svg",
    content: "You can't edit experiences that are Active. " +
      "Proceeding will deactivate your experience immediately " +
      "and it will no longer be visible to your customers.",
    primaryButton: {
      name: "Deactivate and Edit",
      onClick: () => onDeactivateHandle(),
      disabled: false,
    },
    cancelButton: {
      name: "Cancel",
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  };
  const [modalProps, setModalProps] = useState(confirmModalProps);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);

  const pathName = history.location.pathname.split('/')[2];

  const scheduleModalProps = {
    isVisible: false,
    title: 'Schedule this Experience',
    instance: {},
    primaryButton: {
      name: 'Confirm',
      onClick: (scheduleData: scheduleDataType) => setScheduleData(scheduleData),
      disabled: false,
      loading: false,
    },
    cancelButton: {
      name: '',
      onClick: () => onScheduleCancel(),
    }
  }
  const [scheduleProps, setScheduleProps] = useState(scheduleModalProps);
  const queryParams = queryString.parse(props.location?.search);

  useEffect(() => {
    reduxTemplatedExperienceAction.fetchInstance(id, flags['tx_journey_builder'] && reduxTemplatedExperienceState?.temp?.viewMode === 'journey');
    return () => reduxTemplatedExperienceAction.clearInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxTemplatedExperienceState?.temp?.viewMode]);

  useEffect(() => {
    const startDate = (reduxTemplatedExperienceState.instance ? new Date(reduxTemplatedExperienceState.instance.createdAt * 1000) : null);
    const endDate = (reduxTemplatedExperienceState.instance ? new Date() : null);
    if (startDate && endDate) {
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(0, 0, 0, 0);
      setDateRange([startDate, endDate]);
    }
  }, [reduxTemplatedExperienceState.instance]);

  function onDeactivateHandle() {
    reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(id).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Deactivate and Edit',
      });
      history.push(`/project/${pathName}/edit/${id}${queryParams ? '/?'+queryString.stringify(queryParams) : ''}`);
    });
    setModalProps({...modalProps, isVisible: false});
  }

  function onSaveHandle() {
    const schedule = reduxTemplatedExperienceState.instance.schedule;
    reduxTemplatedExperienceAction.updateInstanceSchedule({schedule}).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Change schedule on Active and Save',
      });
      history.push(`/project/${pathName}${queryParams ? '/?'+queryString.stringify(queryParams) : ''}`);
    });
  }

  function onEditHandle() {
    if (reduxTemplatedExperienceState?.instance?.status === 'Active') {
      setModalProps({...modalProps, isVisible: true});
    } else {
      history.push(`/project/${pathName}/edit/${id}${queryParams ? '/?'+queryString.stringify(queryParams) : ''}`);
    }
  }

  function onCancelHandle() {
    storage.setItem('instance', null);
    history.push(`/project/${pathName}${queryParams ? '/?'+queryString.stringify(queryParams) : ''}`);
  }

  function onScheduleHandle() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: true
    });
  }

  function setScheduleData(scheduleData:scheduleDataType) {
    reduxTemplatedExperienceAction.setSchedule(transformScheduleData(scheduleData));
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }

  function onScheduleCancel() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }

  function onTabSwitch(isPerformanceTab: boolean) {
    const queryParamsMap: Record<string, string> = {};
    if (props.location?.search) {
      props.location?.search.slice(1).split('&').forEach((param: string) => {
        const paramNameValuePair = param.split('=');
        queryParamsMap[paramNameValuePair[0]] = paramNameValuePair[1];
      });
    }
    if (isPerformanceTab) {
      queryParamsMap['tab'] = 'performance';
    } else {
      delete queryParamsMap['tab'];
    }

    history.push({
      pathname: props.location.pathname || `/project/${pathName}/edit/${reduxTemplatedExperienceState.instance.id}`,
      search: `?${Object.keys(queryParamsMap).map(paramKey => `${paramKey}=${queryParamsMap[paramKey]}`).join('&')}`,
    });
  }

  const displayPerformanceTab = () => {
    return flags['tx_performance_tab_tea_graph'] || flags['tx_ao_enabled'] 
  }
 
  return (
    <>
      <ControlBar
        name={reduxTemplatedExperienceState.instance?.name || ''}
        status={reduxTemplatedExperienceState.instance?.status || 'Draft'}
        onCancel={onCancelHandle}
        onSave={onSaveHandle}
        onDeactivate={onEditHandle}
        onNameEdit={()=>{}}
        showScheduleModal={onScheduleHandle}
        experience={reduxTemplatedExperienceState.instance}
        actionTab={<ActionTab />}
        performanceTab={displayPerformanceTab() && <Performance dateRange={dateRange} setDateRange={setDateRange}/>}
        onTabSwitch={onTabSwitch}
        isShowPerformanceTab={!!queryParams?.tab}
        settingsTab={<SettingsTab/>}
      />
      <ConfirmModal {...modalProps} setModalProps={setModalProps} />
      <ScheduleEditModal
        {...scheduleProps}
        type={reduxTemplatedExperienceState?.template?.steps?.[0]?.trigger?.type}
        schedule={reduxTemplatedExperienceState?.template?.schedule}
      />
    </>
  )
}


import React from 'react'
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';
import ScheduleIcon from "assets/icons/calendar-with-clock-24.svg";

interface IProps {
  node: any;
  onClick: () => void;
}

export default function Preview(props: IProps) {
  function renderBody() {
    return (
      <>
        Action Preview
      </>
    )
  }

  return (
    <MiniBox
      {...props}
      icon={ScheduleIcon}
      title="Action"
      body={renderBody()}
    />
  )
}

import React, { useCallback, useState } from 'react';
import { FlightTextInput } from '@flybits/webapp-design-system-react';
import { ReactComponent as ClearIcon } from 'assets/icons/clear-icon-outline.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg';
import debounce from 'lodash/debounce';
import './SearchBarPage.scss';

interface Props {
  initialValue?: string;
  handleSearchTermChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  width?: string;
}

export default function SearchBarPage(props: Props) {
  const { initialValue, handleSearchTermChange, className, width, placeholder } = props;
  const [searchTerm, setSearchTerm] = useState(initialValue || '');
  const MAIN_CLASS = 'search-bar-page';

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      handleSearchTermChange(value);
    }, 500),
    [],
  );

  function handleSearchInput(value: string) {
    setSearchTerm(value);
    debouncedSearch(value);
  }

  return (
    <div className={MAIN_CLASS + (className ? ` ${className}` : '')}>
      <FlightTextInput
        type="text"
        width={width}
        value={searchTerm}
        placeholderText={placeholder}
        onChange={(e) => handleSearchInput(e.target.value)}
      />
      {searchTerm?.length > 0 ? (
        <ClearIcon
          className={`${MAIN_CLASS}__icon`}
          onClick={() => {
            handleSearchInput('');
          }}
        />
      ) : (
        <SearchIcon className={`${MAIN_CLASS}__icon`} />
      )}
    </div>
  );
}

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import './PreviewPush.scss';
import PushPreviewFrameFull from 'assets/images/Push-Preview-Frame-Full.svg';
import { useSelector } from 'react-redux';
import { tokenCreator } from 'helpers/dynamic-attribute-token.helper';

export default function PreviewPush(props: any) {
  const plugins = useSelector((state: any) => state.plugin.plugins);
  return (
    <div
      className="preview-push"
      style={{backgroundImage: `url(${PushPreviewFrameFull})`}}
    >
      <div className="preview-push__container">
        <div className="preview-push__container__preview-body">
          <div className="preview-push__container__preview-body__header">
            <img src={require('assets/icons/your-app-icon.svg')} alt="" className="preview-push__container__preview-body__your-app-icon" /> Your app
          </div>
          {
            props.title || props.message ? (
              <div>
                <div className="preview-push__container__preview-body__title">{ReactHtmlParser(tokenCreator(props.title, plugins))}</div>
                <div className="preview-push__container__preview-body__body">{ReactHtmlParser(tokenCreator(props.message, plugins))}</div>
              </div>
            ) : props.placement === 'box' ? (
              <div className="preview-push__container__preview-body__placeholder">Add Notification</div>
            ): null
          }
        </div>
      </div>
    </div>
  )
}

import React, {useState, useEffect} from 'react';
import {FlightButton, FlightModal} from "@flybits/webapp-design-system-react";
import LabelInput from "components/LabelInput/LabelInput";
import { isEmpty } from 'lodash';
import './LabelsModal.scss';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import {TExperienceInstance} from "interface/templated-experience/templated-experience.interface";
import LabelsAPI from "services/api/labels.api";
import {useDispatch, useSelector} from "react-redux";
import {setInstances} from "redux/templated-experience/templated-experience.action";
import {showSnackbar} from "redux/snackbar/snackbar.action";
import FolderAPI from "services/api/folder.api";
import { findFolderById } from 'helpers/folder.helper';

interface IProps {
  entities?: Set<string>
  isVisible?: boolean
  onCancel?: () => void
}

export default function LabelsModal(props: IProps) {

  const [labels, setLabels] = useState<string[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  const labelsAPI = new LabelsAPI();
  const folderAPI = new FolderAPI();
  const dispatch = useDispatch();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);


  const onAddLabels = async () => {
    let isErrorExist = false;
    if(!isEmpty(props.entities) && !isEmpty(labels)) {
      const folders = await folderAPI.getFoldersTree().then(res => res.data).catch((error: Error) => {
          setErrorMessage(error.message);
        isErrorExist = true;
        });
      let areLabelsAdded = false;
      setSaving(true);

      for(let i of props.entities!) { // loop through selected entities (experiences and folders)
        const isFolder = await findFolderById(i, folders); // check if selected entity is a folder
        if (!isFolder) {
          let instance = await templatedExperienceAPI.getTemplatedExperienceInstance(i)
            .then((_instance: TExperienceInstance) => {
              // filter out selected labels, that already exist on experience instance
              const filteredLabels = labels.filter(label => !_instance.labels?.includes(label));
              // update attribute labels with filtered out labels
              _instance.labels = [..._instance.labels, ...filteredLabels].sort();
              return _instance;
            }).catch((error) => {setErrorMessage(error.message); return undefined;});
          if(instance) {
            const updatedInstance:TExperienceInstance|undefined = await templatedExperienceAPI.updateTemplatedExperienceInstance(instance)
              .catch((error) => {setErrorMessage(error.message); return undefined; });
            if(updatedInstance) {
              // if the instance was updated, then update redux store for existing array of instances
              const updatedReduxInstances = await reduxTemplatedExperienceState?.instances?.data?.map((_instance: TExperienceInstance) => {
                if(_instance.id === updatedInstance?.id) { _instance.labels = updatedInstance?.labels || []; }
                return _instance;
              });
              // dispatch a redux action in order to update the array of instances in redux store
              await dispatch(setInstances({ instances: {
                  ...reduxTemplatedExperienceState?.instances,
                  data: updatedReduxInstances
                }}));
              areLabelsAdded = true;
            } else {
              break; // break the loop if the instance was not updated
            }
          }
        } else {
          setErrorMessage(`You can not add labels to the folder "${isFolder?.name}". Please, unselect the folder.`);
          isErrorExist = true;
        }
      }
      if(!isErrorExist) {
        await labelsAPI.putLabels(labels?.sort()).catch((error) => {
          setErrorMessage(error.message);
          areLabelsAdded = false;
        });
        setSaving(false);
        if(areLabelsAdded) {
          props.onCancel!();
          dispatch(showSnackbar({
            content: `Successfully added ${labels.length > 1 ? 'multiple labels' : `label "${labels[0]}"`}`
          }));
        }
      }
    } else {
      setSaving(false);
      props.onCancel!();
    }
  }

  useEffect(() => {
    return () => {
      setLabels([]);
      setSaving(false);
      setErrorMessage('');
    }
  },[props.isVisible]);

  return (
    <FlightModal
      size="medium"
      className="labels-modal"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={() => props.onCancel!()}
      header={
        <div className="labels-modal__header">
          <h3> Add labels </h3>
        </div>
      }
      content={
        <div className="labels-modal__content">
          <div className="labels-modal__content__title">Labels</div>
          <LabelInput entities={props.entities} labels={labels} setLabels={setLabels}/>
          {!!errorMessage && <div className="labels-modal__error">{errorMessage}</div>}
        </div>
      }
      footer={
        <div className="labels-modal__footer">
          <FlightButton
            size="large"
            theme="secondary"
            onClick={() => props.onCancel!()}
            label="Cancel"
          />
          <FlightButton
            size="large"
            onClick={() => onAddLabels()}
            label="Add"
            disabled={isEmpty(labels) || saving}
            loading={saving}
          />
        </div>
      }
    />
  )
}

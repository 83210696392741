import React from 'react';
import { getBezierPath, getMarkerEnd, ArrowHeadType, Position } from 'react-flow-renderer';

interface IProps {
  id: string
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
  style: any
  data: {
    label: string
    events: number
    conversion: number
  }
  arrowHeadType: ArrowHeadType
  markerEndId: string
}

export default function Line({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    data,
    arrowHeadType,
    markerEndId,
  }:IProps) {
  const edgePath = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  let textWidth = 110;
  let textHeight = 40;
  let textStartX = targetX - (targetX - sourceX)/2 - textWidth/2;
  let textStartY = targetY - (targetY - sourceY)/2 - textHeight/2;

  return (
    <>
      <path d={edgePath} className="react-flow__edge-path" markerEnd={markerEnd} style={{...style, strokeWidth: 2}}></path>
      <g
        transform={`translate(${textStartX} ${textStartY})`}
        className="react-flow__edge-textwrapper"
        dominantBaseline="middle"
      >
        <rect width={textWidth} height={textHeight} x="0" y="0" className="react-flow__edge-textbg" rx="2" ry="2" style={{opacity: 0.7}}></rect>
        <svg width={textWidth} height={textHeight}>
          <text className="react-flow__edge-text" x="50%" y="10" dy="0.3em" dominantBaseline="middle" textAnchor="middle">{data.label}</text>
          {!!data.events && <text className="react-flow__edge-text" x="50%" y="20" dy="0.9em" dominantBaseline="middle" textAnchor="middle" style={{fontWeight: 'bold'}}>{data.events} ({data.conversion}%)</text>}
        </svg>
      </g>
    </>
  );
}

import React, {useEffect, useState} from 'react';
import {FlightButton, FlightCheckbox, FlightModal, FlightTextInput} from "@flybits/webapp-design-system-react";
import InfiniteScroll from "react-infinite-scroller";
import {CircularProgress} from "@material-ui/core";
import {times} from "lodash";
import Skeleton from "react-loading-skeleton";
import {TExperienceInstance} from 'interface/templated-experience/templated-experience.interface';
import GroupAPI from "services/api/group.api";
import {Group} from 'interface/group.interface';
import './GroupsModal.scss';
import useDebounce from 'hooks/useDebounce';

interface IProps {
  experience?: TExperienceInstance
  isVisible?: boolean
  icon?: string
  title?: string
  content?: object
  cancelButton?: {
    name?: string
    disabled?: boolean
    onClick: () => void
  }
  primaryButton?: {
    name?: string
    disabled?: boolean
    onClick: (selectedGroups: any[], experience: TExperienceInstance, groups: Group[]) => void
  }
}


export default function GroupsModal(props: IProps) {

  const groupAPI = new GroupAPI();
  const [searchText, setSearchText] = useState<string>('');
  const [groups, setGroups] = useState<Group[]>([]);
  const [changedGroups, setChangedGroups] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<number>(groups?.length || 0);
  let page = 0;
  let limit = 10000;
  const debouncedSearchTerm = useDebounce(searchText, 500);


  function fetchGroups(page: number) {
    setIsLoading(true);
    groupAPI.getGroups(page, limit, debouncedSearchTerm).then((res: any) => {
      setGroups(groups.concat(res.data));
      setTotal(res.pagination.totalRecords);
      setIsLoading(false);
    });
  }


  function fetchMoreData() {
    if (props.isVisible) {
      page = page + 1;
      fetchGroups(page);
    }
  }


  useEffect(() => {
    if (props.isVisible) {
      fetchGroups(page);
      if (props.experience?.steps[0]?.groups?.length) {
        let transformedGroups = props.experience.steps[0].groups.map((group:any) => ({id: group.id, status: 'selected'}));
        setChangedGroups(transformedGroups);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible, debouncedSearchTerm])


  useEffect(() => {
    return () => {
      setGroups([]);
      setTotal(0);
      setChangedGroups([]);
      setSearchText('');
    }
  },[props]);


  function handleCheckBoxClick(group: Group) {
    let isGroupSelected = changedGroups.find(g => g.id === group.id && g.status === 'selected');
    if (isGroupSelected) {
      setChangedGroups(changedGroups.map(g => {
        if(g.id === group.id) g['status'] = 'unselected';
        return g;
      }));
    } else {
      setChangedGroups(changedGroups.concat({id: group.id, name: group.localizations?.en?.name!!, status: 'selected'}));
    }
  }


  function ListItem(group: Group, idx: number) {
    return (
      <div className="groups-modal__item" key={`group_$${idx}`} onClick={() => handleCheckBoxClick(group)}>
        <FlightCheckbox
          checkState={changedGroups?.find(g => g.id === group.id && g.status === 'selected') ? 'SELECTED' : 'UNSELECTED'}
          className={'groups-modal__checkbox'}
          onSelect={() => {}}
        />
        <div>{group.localizations?.en?.name}</div>
      </div>
    )
  }


  function updateSearchText(e: React.ChangeEvent<HTMLInputElement>) {
    setGroups([]);
    setTotal(0);
    setSearchText(e.target.value);
  }


  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={() => props.cancelButton?.onClick()}
      header={
        <div className="groups-modal__header">
          {props.icon && (
            <img src={require(`assets/icons/${props.icon}`)} className="confirm-modal__header-icon" alt="modal icon"/>
          )}
          <h3> {props.title} </h3>
        </div>
      }
      content={
        <div className="groups-modal">
          <div className="groups-modal__content">
            <div className="groups-modal__search-title">Select groups</div>
            <div className="groups-modal__selected">{changedGroups.filter((group) => group.status === 'selected').length} selected</div>
            <FlightTextInput
              width="100%"
              iconInput="search"
              value={searchText}
              hasClearIcon={true}
              onChange={updateSearchText}
            />
            <div className="groups-modal__rule-list-container">
              <InfiniteScroll
                className="groups-modal__rule-list-container__infinite-scroll"
                pageStart={page}
                initialLoad={false}
                loadMore={fetchMoreData}
                hasMore={total > (groups?.length || 0)}
                threshold={20}
                useWindow={false}
                loader={<div className="audience-searchable-modal__rule-list-container__loader" key={0}>
                  <CircularProgress size={20}/>
                  <span className="audience-searchable-modal__rule-list-container__loader-text">Loading...</span>
                </div>}
              >
                {groups && (
                  groups.map((group: Group, idx: number) => {
                    return ListItem(group, idx);
                  })
                )}
              </InfiniteScroll>
              {isLoading && !groups.length && (
                <>
                  {times(7, (i) => (
                    <div key={i}>
                      <Skeleton width={'100%'} height={50}/>
                    </div>
                  ))}
                </>
              )}
              {!isLoading && !groups.length && (
                <div>No results found</div>
              )}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="groups-modal__footer">
          {props.cancelButton! && (
            <FlightButton
              theme="secondary"
              onClick={() => props.cancelButton?.onClick()}
              label={props.cancelButton?.name}
            />
          )}
          {props.primaryButton! && (
            <FlightButton
              onClick={() => props.primaryButton?.onClick(changedGroups, props.experience!!, groups)}
              disabled={props.primaryButton?.disabled}
              label={props.primaryButton?.name}
            />
          )}
        </div>
      }
    />
  )
}

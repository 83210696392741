
import React, { useState, useEffect, useContext, useRef } from 'react'
import { FlightButton, FlightTextInput } from '@flybits/webapp-design-system-react';
import { parse, stringify } from 'flatted';
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import AudienceIframe from 'components/Audience/AudienceIframe/AudienceIframe';

import DelayEdit from 'components/MultiStepDemo/StepCriteriaEdit/DelayEdit/DelayEdit';
import TriggerEdit from 'components/MultiStepDemo/StepCriteriaEdit/TriggerEdit/TriggerEdit';
import ActionEdit from 'components/MultiStepDemo/StepCriteriaEdit/ActionEdit/ActionEdit';

import './StepCriteriaEdit.scss';

type tabType = 'trigger' | 'delay' | 'audience' | 'action';

const filterStringRep = (rep: string) => {
  return rep === '()' ? '' : rep;
}

interface IProps {
  step: any;
  initialTab: tabType;
  onSubmit: any;
  onCancel: any;
}

interface IFrame {
  iframe: any;
  stringRepresentation: string;
  ruleBody: object;
  hasReceived: boolean;
}

export default function StepCriteriaEdit(props: IProps) {
  const multiStepContext: any = useContext(MultiStepContext);
  const [selectedTab, setSelectedTab] = useState(props.initialTab);
  const [hasRestrictionError, setHasRestrictionError] = useState(false);

  const [delayData, setDelayData] = useState({});
  const [triggerData, setTriggerData] = useState({});
  const [contentData] = useState({});

  const [selectedActionIndex, setSelectedActionIndex] = useState(-1);

  const [restrictionData, _setRestrictionData] = useState<IFrame>({
    iframe: undefined,
    stringRepresentation: '',
    ruleBody: {},
    hasReceived: false
  });

  const restrictionDataRef = useRef(restrictionData);
  const setRestrictionData = (data: any) => {
    restrictionDataRef.current = data;
    _setRestrictionData(data);
  }

  const ruleBody = props.step?.audience?.ruleBody || '';

  useEffect(() => {
    window.addEventListener("message", messageListener, false);
    return () => window.removeEventListener("message", messageListener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is where we catch asynchronous submit function.
  // If ember rule builder has no errors, we invoke passed in onSubmit function with all of form's data
  useEffect(() => {
    if (!restrictionData.iframe) return;
    if (!restrictionData.hasReceived) return;
    if (hasRestrictionError) {
      setSelectedTab('audience');
      return;
    };
    const cb = () => {
      props.onSubmit({
        trigger: triggerData,
        delay: delayData,
        content: contentData, // content todo
        audience: {
          ruleStringRepresentation: restrictionData.stringRepresentation,
          ruleBody: stringify(restrictionData.ruleBody) || '',
        },
      });
    }
    cb();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    restrictionData,
    hasRestrictionError
  ]);

  const messageListener = (e: any) => {
    switch (e.data.command) {
      case 'setRule:restriction':
        setRestrictionData({...restrictionDataRef.current, ruleBody: parse(unescape(e.data.rule)), stringRepresentation: filterStringRep(e.data.stringRepresentation), hasReceived: true});
        setHasRestrictionError(false);
        break;
      case 'setError:restriction':
        setHasRestrictionError(true);
        setSelectedTab('audience');
        break;
      default:
        break;
    }
  }

  function onTriggerUpdate(trigger: any) {
    setTriggerData(trigger);
  }

  function onDelayUpdate(delay: any) {
    setDelayData(delay);
  }

  function onApply() {
    setRestrictionData({...restrictionData, hasReceived: false});
    setTimeout(() => {
      if (restrictionData.iframe) {
        restrictionData.iframe.sendApply();
      }
    });
  }


  return (
    <>
      <div className="step-criteria-edit">
        <div className="step-criteria-edit-title">
          <h4> Step Name: </h4>
          <FlightTextInput
            className="step-criteria-edit-title-input"
            width="250px"
            value={props.step.name}
            placeholderText={"Enter a step title"}
            onChange={(e) => multiStepContext.updateStepName(props.step.id, e.target.value)}
          />
        </div>
        <div className="step-criteria-edit-main">
          <div className="step-criteria-edit-main__nav">
            <a
              href="# "
              className={selectedTab === 'trigger' ? 'selected' : ''}
              onClick={() => setSelectedTab('trigger')}
            >
              Trigger
            </a>
            <a
              href="# "
              className={selectedTab === 'delay' ? 'selected' : ''}
              onClick={() => setSelectedTab('delay')}
            >
              Delay
            </a>
            <a
              href="# "
              className={selectedTab === 'audience' ? 'selected' : ''}
              onClick={() => setSelectedTab('audience')}
            >
              Audience
            </a>
            <a
              href="# "
              className={selectedTab === 'action' ? 'selected' : ''}
              onClick={() => {
                setSelectedTab('action')
                setSelectedActionIndex(-1); // set to default (unselected)
              }}
            >
              Action
            </a>
            <ul>
              {props.step.actions.map((action: any, index: number) =>
                <li
                  key={index}
                  className={selectedActionIndex === index ? 'selected' : ''}
                  onClick={() => {
                    setSelectedActionIndex(index)}
                  }> {action.type} #{index + 1} <FlightButton theme="minor" iconRight="remove" onClick={() => {
                    props.step.actions.splice(index, 1);
                    multiStepContext.updateStep({id: props.step.id, body: props.step});
                    setSelectedActionIndex(-1);
                  }} /> </li>
              )}
            </ul>
          </div>
          <div className="step-criteria-edit-main__content">
            {/* Trigger */}
            <div className={selectedTab !== 'trigger' ? 'hidden' : 'show'}>
              <TriggerEdit step={props.step} onChange={onTriggerUpdate}/>
            </div>
            {/* Delay */}
            <div className={selectedTab !== 'delay' ? 'hidden' : 'show'}>
              <DelayEdit step={props.step} onChange={onDelayUpdate}/>
            </div>
            {/* Audience */}
            <AudienceIframe
              type="restriction"
              rule={ruleBody}
              isVisible={selectedTab === 'audience'}
              onInit={(iframe: any) => {
                setRestrictionData({...restrictionData, iframe})
              }}
            />
            {/* Action */}
            <div className={selectedTab !== 'action' ? 'hidden' : 'show'}>
              {/* Pass in selected action */}
              <ActionEdit step={props.step}
                selectedActionIndex={selectedActionIndex}
                setSelectedActionIndex={setSelectedActionIndex}
              />
            </div>
          </div>
        </div>
        <div className="step-criteria-edit-footer">
          <FlightButton
            theme="secondary"
            label="Cancel"
            onClick={() => props.onCancel()}
          />
          <FlightButton
            theme="primary"
            label="Save"
            onClick={() => onApply()}
          />
        </div>
      </div>
    </>
  )
}

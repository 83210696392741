import React, { useState, useRef, useEffect } from 'react';
import './SearchBar.scss';

import Close from 'assets/icons/close.svg';
import Search from 'assets/icons/search-icon.svg';
import { debounce } from 'lodash';

interface IProps {
  searchText: (e: any) => void;
}

export default function SearchBar(props: IProps) {
  const [openSearch, setOpenSearch] = useState(false);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    delayCallBack(e.target.value);
  };

  const delayCallBack = debounce(
    (searchTerm: string) => {
      props.searchText(searchTerm);
    },
    250,
    { maxWait: 1000 },
  );

  const focusableRef = useRef<any>();
  
  useEffect(() => {
    focusableRef?.current?.focus()
  }, [openSearch])

  return openSearch ? (
    <div className="searchBox">
      <input type="text" placeholder="Search" onChange={(e) => handleInput(e)} ref={focusableRef} />
      <img
        alt="close-search"
        src={Close}
        className="closeSearch"
        onClick={() => {
          setOpenSearch(false); props.searchText('');
        }}
      />
    </div>
  ) : (
    <div className="search" onClick={() => setOpenSearch(true)}>
      <img alt="search" src={Search} />
    </div>
  );
}

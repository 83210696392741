import React, { useState } from 'react';
import './TemplateCard.scss';
import { FlightButton } from '@flybits/webapp-design-system-react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import placeholder from 'assets/images/lib-placeholder.svg';
import useCheckValidImage from "../../../hooks/useCheckValidImage";

interface TemplateCardProps {
  library?: string;
  name?: string;
  className?: string;
  selectedTemplateCard?: boolean;
  libColor?: string;
  headerImage?: string;
  id?: string;
  location?: string;
  onClick: () => void; // Handle selection of template
}

export const TemplateCard = (props: TemplateCardProps) => {
  const { library, name, className, headerImage, libColor, location, id, selectedTemplateCard, onClick } = props;
  const MAIN_CLASS = 'template-card';
  const history = useHistory();
  const queryParams = queryString.parse(location || '');
  const [showDirectCreate, setShowDirectCreate] = useState(false);
  const isValidImage = useCheckValidImage(headerImage || '');
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  const handleCreate = () => {
    delete queryParams.template_id;
    return history.push(
      `/project/${history?.location?.pathname?.split('/')[2]}/create/?template_id=${id}${queryParams ? '&' + queryString.stringify(queryParams) : ''}`,
    );
  };

  return (
    <div
      className={`template-card ${selectedTemplateCard && `template-card--selected`} ${className}`}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onMouseEnter={() => setShowDirectCreate(true)}
      onMouseLeave={() => setShowDirectCreate(false)}
    >
      <div className="inner-wrap">
        <div className="template-card__direct-create">
          <FlightButton
            className="template-card__direct-create__create-btn"
            theme="primary"
            label={'Create'}
            onClick={handleCreate}
          />
        </div>
        <div
          style={{backgroundImage:'url('+headerImage+')' || 'url('+placeholder+')'}}
          className={`${MAIN_CLASS}__img` + (!name ? ' blur' : '')}
        ></div>
        {library && (
          <span className={`${MAIN_CLASS}__library`} style={{ backgroundColor: libColor }}>
            {library}
          </span>
        )}
        {name ? (
          <div className={`${MAIN_CLASS}__name`}>
            <span>{name}</span>
          </div>
        ) : (
          <span className={`${MAIN_CLASS}__no-template`}>No Templates Available</span>
        )}
      </div>
    </div>
  );
};


import { FeatureFlagActionType } from 'redux/feature-flag/feature-flag.type';

let initialState = {
  isInitialized: false,
  flags: {},
  traits: {},
}

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FeatureFlagActionType.INITIALIZE_FLAGS:
      return {
        isInitialized: true,
        flags: action.payload.flags,
        traits: action.payload.traits,
      }
    case FeatureFlagActionType.SET_FLAGS:
      return {
        ...state,
        flags: action.payload.flags,
      }
    case FeatureFlagActionType.SET_TRAITS:
      return {
        ...state,
        traits: action.payload.traits,
      }
    default:
      return {
        ...state
      };
  }
}
import React, { useState, useContext } from 'react';
import FlowBoxV2 from 'components/MultiStepDemo/FlowBox/FlowBoxV2';
import FullPageOverlay from 'components/FullPageOverlay/FullPageOverlay';
import StepCriteriaEdit from 'components/MultiStepDemo/StepCriteriaEdit/StepCriteriaEdit';

import Trigger from 'components/MultiStepDemo/MiniBox/Action/Trigger/Trigger';
import Delay from 'components/MultiStepDemo/MiniBox/Action/Delay/Delay';
import Audience from 'components/MultiStepDemo/MiniBox/Action/Audience/Audience';
import Preview from 'components/MultiStepDemo/MiniBox/Action/Preview/Preview';

import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';

import './ActionBox.scss';

interface IProps {
  step: any;
}

function ActionBox(props: IProps) {

  const multiStepContext: any = useContext(MultiStepContext);
  type tabType = 'trigger' | 'delay' | 'audience' | 'action';

  interface overlayProps {
    isVisible: boolean;
    selectedTab: tabType;
  }

  const [overlayProps, _setOverlayProps] = useState<overlayProps>({isVisible: false, selectedTab: 'trigger'});

  function openEditWindow(tabType: tabType) {
    _setOverlayProps({
      isVisible: true,
      selectedTab: tabType
    });
  }

  function closeEditWindow() {
    _setOverlayProps({
      ...overlayProps,
      isVisible: false
    });
  }

  function onSubmitHandle(payload: {
    trigger: object,
    delay: object,
    audience: object,
    content: object,
  }) {
    multiStepContext.updateStep({
      id: props.step.id,
      body: payload,
    });
    closeEditWindow();
  }

  function renderBody() {
    return (
      <div className="action-box">
        <div className="left">
          <Trigger  node={props.step} onClick={() => openEditWindow('trigger')} />
          <Delay    node={props.step} onClick={() => openEditWindow('delay')} />
          <Audience node={props.step} onClick={() => openEditWindow('audience')} />
        </div>
        <div className="right">
          <Preview  node={props.step} onClick={() => openEditWindow('action')} />
        </div>
      </div>
    )
  }

  return (
    <>
      <FlowBoxV2
        type="action"
        body={renderBody()}
        step={props.step}
      />
      <FullPageOverlay isVisible={overlayProps.isVisible}>
        <StepCriteriaEdit
          step={props.step}
          initialTab={overlayProps.selectedTab}
          onSubmit={onSubmitHandle}
          onCancel={closeEditWindow}
        />
      </FullPageOverlay>
    </>
  )
}

export default ActionBox;

import { useState, useEffect } from 'react';

const useCheckValidImage = (image : string) => {
	const [isValidImage, setIsValidImage] = useState(false);

	const checkImage = (image : string) => {
		const img = new Image();
		img.src = image;
		img.onload = () => {
			setIsValidImage(true);
		};
	}
	useEffect(() => {
		checkImage(image);
	}, [image]);

	if (!image) {
		return false;
	}
	return isValidImage;
}

export default useCheckValidImage;


import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import {
  Sankey,
  Hint,
  DiscreteColorLegend,
  makeVisFlexible } from 'react-vis';
import { FlowGraphNode, FlowGraphLink } from 'interface/templated-experience/graph-data.interface';
import './ExperienceFlow.scss';
import { numberWithCommas } from 'helpers/templated-experience.helper';

interface FunnelGraphData {
  experienceFlowNodes: FlowGraphNode[];
  experienceFlowLinks: FlowGraphLink[];
}

const FOCUSED_LINK_OPACITY = 1;

export default function ExperienceFunnelGraph(props: FunnelGraphData) {
  const [activeLink, setActiveLink] = useState<any>(null);
  const reduxAnalyticsState = useSelector((state: any) => state.analytics.analyticsFunnel);
  const FlexibleSankey = makeVisFlexible(Sankey);
  const legendGraphData = [{title:'Target Audience',color:'#A999FF'},{title:'Push Sent',color:'#7CB4FF'},
    {title:'Push Delivered',color:'#72DAFF'},{title:'Opened Push',color:'#50E8BB'},
    {title:'Viewed Content (from in-app)',color:'#FEE961'},{title:'Clicked on Content',color:'#F65D7E'}];
  
  const renderHint = () => {
    if (!activeLink) return null;
    // calculate center x,y position of link for positioning of hint
    const x = activeLink.source.x1 + (activeLink.target.x0 - activeLink.source.x1) / 2;
    const y = activeLink.y0 - (activeLink.y0 - activeLink.y1) / 2;
    return (
      <Hint x={x} y={y} value={{}}>
        <div className="experience-flow-active-link-hint">
          <div className="experience-flow-active-link-hint__title">
            {`${activeLink.source.hintValue} -> ${activeLink.target.hintValue}:`}
          </div>
          <div className="experience-flow-active-link-hint__data">
            {`${((activeLink.value / reduxAnalyticsState.targetAudience?.value) * 100 || 0).toFixed(0)}%
            (${numberWithCommas(activeLink.value)} users)`}
          </div>
        </div>
      </Hint>
    );
  };
  return (
    <div className="experience-flow-graph-container">
      <div className="experience-flow-legend-container">
        <DiscreteColorLegend items={legendGraphData} />
      </div>
      <FlexibleSankey
        nodes={props.experienceFlowNodes}
        links={props.experienceFlowLinks.map((d, i) => ({
          ...d,
          opacity: (activeLink && i === activeLink.index ?
            FOCUSED_LINK_OPACITY : d.opacity),
        }))}
        align={'justify'}
        height={400}
        layout={0}
        nodeWidth={20}
        onLinkMouseOver={(node: any) => {
          if (!(node.source.index === activeLink?.source.index && node.target.index === activeLink?.target.index))
          setActiveLink(node);
        }}
        onLinkMouseOut={() => setActiveLink(null)}
    >
      {activeLink && renderHint()}
    </FlexibleSankey>
    </div>
  )
}


import React, { useState } from 'react';
import moment from 'moment';
import { LineSeries } from 'react-vis';
import { useSelector } from 'react-redux';
import MultiInstanceGraph from 'components/Performance/MultiInstance/MultiInstanceGraph';
import { GraphData } from 'interface/templated-experience/graph-data.interface';
import { TimestampValue, TEAContentTimeline } from 'interface/templated-experience/analytics.interface';
import ErrorState from '../ErrorState/ErrorState';
import LoadingState from '../LoadingState/LoadingState';

interface MultiInstanceData {
  id: number;
  data: TimestampValue[];
  label: string;
  color: string;
  className: string;
}

const COLORS = ['#A999FF', '#7CB4FF', '#72DAFF', '#51E7BB'];
const CLASSNAME = ['left-color left-color-first', 'left-color left-color-second', 'left-color left-color-third', 'left-color left-color-fourth'];

export default function MultiInstance(props: { selectedTab: string }) {
  const reduxAnalyticsState = useSelector((state: any) => state.analytics.analyticsContentPerformance);
  const [hoveredNode, setHoveredNode] = useState<GraphData>({x:0,y:0});
  const [emptyGraph, setEmptyGraph] = useState<boolean>(true);
  // loading state and error state
  if (!!reduxAnalyticsState.errorMessage) {
    return (
      <ErrorState errorMessage={reduxAnalyticsState.errorMessage} />
    );
  } else if (reduxAnalyticsState.isClickLoading || reduxAnalyticsState.isViewLoading) {
    return <LoadingState />;
  }

  const tabContentData = props.selectedTab === 'views' ?
  reduxAnalyticsState.viewRecords :
  reduxAnalyticsState.clickRecords;

  const multiInstanceData: MultiInstanceData[] = tabContentData.map((contentTimeline: TEAContentTimeline, idx: number) => ({
    id: idx,
    data: contentTimeline.data,
    label: contentTimeline.contentName,
    color: COLORS[idx % COLORS.length],
    className: CLASSNAME[idx % CLASSNAME.length],
  }));

  const legendGraphData:any[] = [];
  const lineGraphData:any[] = [];
  const xAxisLabels: string[] = [];
  const timestampData: number[] =[];
  multiInstanceData.forEach((contentData: MultiInstanceData) => {
    legendGraphData.push({ title: contentData.label || '',
      color: contentData.color });

    const lineGraphValue:any[] = [];

    contentData.data.forEach((timelineData: TimestampValue, idx: number) => {
      lineGraphValue.push({
        x: idx,
        y: timelineData.value,
      });
    });
    const emptyGraphData = contentData.data.find((d: TimestampValue) => (d.value));
    if(emptyGraphData && emptyGraph){
      setEmptyGraph(false);
    }
    lineGraphData.push(<LineSeries key={contentData.id} style={{ fill: 'none',strokeWidth: '2px' }}
        onNearestXY={(value: GraphData) => setHoveredNode(value)}
        data={lineGraphValue}
        color={contentData.color}
        className={contentData.className}
      />);
  });

  multiInstanceData[0]?.data.forEach((timelineData: TimestampValue, idx, arr) => {
    xAxisLabels.push(moment(timelineData.timestamp * 1000).utc().format('MMM D'));
    timestampData.push(timelineData.timestamp);
  });

  return (
    <div className="multi-instance-container">
      <MultiInstanceGraph
        legendGraphData={legendGraphData}
        lineGraphData={lineGraphData}
        tabselected={props.selectedTab}
        xAxisLabels={xAxisLabels}
        emptyGraph={emptyGraph}
        hoveredNode={hoveredNode}
        timestampData={timestampData}
        reduxAnalyticsState={reduxAnalyticsState}
      />
    </div>
  )
};

import React, { useState, useEffect, useContext } from 'react';
import { StreamlineContentPreview } from 'components/Content/PreviewContent/StreamLinedContentPreview';
import { Content } from 'interface/content/content.interface';
import { ReactComponent as PolyIcon } from 'assets/icons/collapse-polygon.svg';
import { ReactComponent as EmptyAdd } from 'assets/icons/empty_add_content.svg';

import { useSelector } from 'react-redux';

import './MiniCardContent.scss';
import { AmplifiedPushPreview } from 'components/Content/AmplifiedPush/AmplifiedPushPreview';
import { MultiActionContainerContext } from 'components/Shared/MultiActionContainer/MultiActionContainer';
import { isEmpty } from 'lodash';

interface Props {
  content?: Content;
  hideCollapse: boolean;
  isNearByTemplate?: boolean;
}

const NoContent = (props: Props) => {
  return (
    <>
      <div className="mini-card-content__no-ctnt">
        <EmptyAdd className="mini-card-content__no-ctnt__icon" />
        <div className="mini-card-content__no-ctnt__icon__body">None</div>
      </div>
      {props.isNearByTemplate && (<AmplifiedPushPreview contentId={''} />)}
    </>
  );
};

const UnsupportedPreview = (props: Content) => {
  return (
    <div className="mini-card-content__unsupported-content">
      <div className="mini-card-content__unsupported-content__header">{props?.localizations?.en?.name || props?.name}</div>
      <div className="mini-card-content__unsupported-content__body">
        Preview not available
      </div>
    </div>
  );
};

interface InAppContentProps {
  content: Content | any;
  hideCollapse: boolean;
  isNearByTemplate?: boolean;
}

const InAppContent = (props: InAppContentProps) => {
  const templateType = props.content?.templateType || props.content?.templateType || '';
  const title = props?.content?.content?.data?.[0]?.localizations?.en?.title || props.content?.name || '';
  const { collapse } = useContext(MultiActionContainerContext); // global collapse from Context
  const [_collapse, _setCollapse] = useState(false); // internal collapse state
  const [showAmplificationBar, setShowAmplificationBar] = useState(false);


  const toggleCollapse = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    _setCollapse((collapse) => !collapse);
  };

  useEffect(() => {
    if(props.hideCollapse) {
      _setCollapse(true);
      return;
    }
    _setCollapse(collapse);
    return ()=>_setCollapse(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse]);

  enum StreamlinedContentTypes {
    'concierge-card-buttons',
    'concierge-card-link',
  }

  enum DisableAmplificationTypes {
    'Inactive',
    'Active',
  }

  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  let contentInstanceOrTemplate =
    (!isEmpty(reduxTemplatedExperienceState?.instance) && reduxTemplatedExperienceState?.instance) ||
    reduxTemplatedExperienceState?.template;
    
  useEffect(() => {
    contentInstanceOrTemplate?.steps?.[0]?.actions?.find(
      (item: any) => {
        if(item?.actionType === 'experience' || item?.actionType === 'content-blueprint') {
          if(contentInstanceOrTemplate.status in DisableAmplificationTypes &&
            //if the experience was already amplified at initial launch, 
            //it will have two actions
            contentInstanceOrTemplate?.steps?.[0]?.actions?.length !== 2 
          ) {
            return setShowAmplificationBar(false)
          }
          setShowAmplificationBar(true);
        }
      },
    );
    return ()=>setShowAmplificationBar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contentInstanceOrTemplate]);

  const isLocked = contentInstanceOrTemplate.status === 'Active';
  return templateType in StreamlinedContentTypes ? (
    <>
      <div className="mini-card-content__supported-content">
        {!_collapse && <div className="mini-card-content__supported-content__title">{title}</div>}
      </div>
      {_collapse ? (
        <>
          <StreamlineContentPreview {...props.content} />
          <div className={isLocked ? 'mini-card-content__not-clickable' : ''}>
            {showAmplificationBar &&  <AmplifiedPushPreview contentId={props?.content?.id} content={props?.content?.[0]!} />}
          </div>
        </>
      ) : (
        <div className={isLocked ? 'mini-card-content__not-clickable' : ''}>
          {showAmplificationBar && <AmplifiedPushPreview contentId={props?.content?.id} content={props?.content?.[0]!} hidePreview={!_collapse} />}
        </div>
      )}
      {!props.hideCollapse && (
        <div className="streamlined-content__collapse" onClick={toggleCollapse}>
          <PolyIcon
            className="streamlined-content__collapse__polygon"
            style={_collapse ? { transform: `rotate(360deg)` } : { transform: `rotate(180deg)` }}
          />
        </div>
      )}
    </>
  ) : (
    <>
      <UnsupportedPreview {...props.content} />
      <div className={isLocked ? 'mini-card-content__not-clickable' : ''}>
        {showAmplificationBar && <AmplifiedPushPreview contentId={props.content?.id} content={props.content} />}
      </div>
    </>
  );
};

export function MiniCardContent(props: Props) {
  return !isEmpty(props.content) ? <InAppContent content={props.content} {...props} /> : <NoContent {...props} />;
}

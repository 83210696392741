
import React, { useRef, useState } from 'react';
import { numberWithCommas } from "helpers/templated-experience.helper";
import PerformanceGraphNoData from 'components/Performance/PerformanceGraphNoData';
import { useSelector } from 'react-redux';
import LoadingState from '../LoadingState/LoadingState';
import {
  DiscreteColorLegend,
  FlexibleXYPlot,
  HorizontalGridLines,
  YAxis,
  LabelSeries,
  VerticalBarSeries,
  Hint,
} from 'react-vis';
import './ContentEngagementBySource.scss';
import ErrorState from '../ErrorState/ErrorState';

const BAR_CATEGORIES = {
  PUSH: 'PUSH',
  EXPERIENCE: 'EXPERIENCE',
};

export default function ContentEngagementBySource() {
  const [activeBar, setActiveBar] = useState<any>(null);
  const plotRef = useRef<any>(null);
  const reduxAnalyticsState = useSelector((state: any) => state.analytics.analyticsContentEngagementBySource);
  // loading state and error state
  if (!!reduxAnalyticsState.errorMessage) {
    return (
      <ErrorState errorMessage={reduxAnalyticsState.errorMessage} />
    );
  } else if (reduxAnalyticsState.isTotalContentClicksCountFromExperienceLoading ||
    reduxAnalyticsState.isTotalContentClicksCountFromPushLoading ||
    reduxAnalyticsState.isTotalContentViewsCountFromExperienceLoading ||
    reduxAnalyticsState.isTotalContentViewsCountFromPushLoading) {
      return <LoadingState />;
  }

  const legendGraphData = [
    {title:'From push notification',color:'#A999FF'},
    {title:'From in-app',color:'#72DAFF'},
  ];
  const contentFromPushData = [
    {x: 0, y: reduxAnalyticsState.totalContentViewsCountFromPush.value},
    {x: 1, y: reduxAnalyticsState.totalContentClicksCountFromPush.value},
  ];
  const contentFromExperienceData = [
    {x: 0, y: reduxAnalyticsState.totalContentViewsCountFromExperience.value},
    {x: 1, y: reduxAnalyticsState.totalContentClicksCountFromExperience.value},
  ];
  const xAxisTitleLabel = [
    {x: 0, y: 0, label: 'Content Views', yOffset: 30},
    {x: 1, y: 0, label: 'Content Clicks', yOffset: 30},
  ];
  const totalContentViews: number = reduxAnalyticsState.totalContentViewsCountFromPush.value + reduxAnalyticsState.totalContentViewsCountFromExperience.value;
  const totalContentClicks: number = reduxAnalyticsState.totalContentClicksCountFromPush.value + reduxAnalyticsState.totalContentClicksCountFromExperience.value;
  const xAxisTotalLabel = [
    {x: 0, y: totalContentViews, label: numberWithCommas(totalContentViews), yOffset: -20},
    {x: 1, y: totalContentClicks, label: numberWithCommas(totalContentClicks), yOffset: -20},
  ];

  // barSeriesMapArray maps activeBar to active data based on activeBar's xAxis value and category
  const barSeriesMapArray = [{
    [BAR_CATEGORIES.PUSH]: reduxAnalyticsState.totalContentViewsCountFromPush.value,
    [BAR_CATEGORIES.EXPERIENCE]: reduxAnalyticsState.totalContentViewsCountFromExperience.value,
  }, {
    [BAR_CATEGORIES.PUSH]: reduxAnalyticsState.totalContentClicksCountFromPush.value,
    [BAR_CATEGORIES.EXPERIENCE]: reduxAnalyticsState.totalContentClicksCountFromExperience.value,
  }];

  const renderBarHint = () => {
    if (!activeBar) return null;
    // using width and height of the chart's bounding rect to hardcode where
    // the tooltip will be displayed on the chart
    const plotRect = plotRef?.current?.container?.getBoundingClientRect();
    const hintXMultiplier = activeBar.x ? 0.85 : 0.29;
    const hintYMultiplier = activeBar.category ===  BAR_CATEGORIES.PUSH ? 0.7 : 0.1;
    const activeBarValue = barSeriesMapArray[activeBar.x][activeBar.category];
    return (
      <Hint
        x={plotRect.width * hintXMultiplier}
        y={plotRect.height * hintYMultiplier}
        value={{}}
      >
        <div className="content-engagement-by-source-active-bar-hint">
          {`${numberWithCommas(activeBarValue)}
          (${(activeBarValue / (activeBar.x ? totalContentClicks : totalContentViews) * 100 || 0).toFixed(0)}%
          of total ${activeBar.x ? 'clicks' : 'views'})`}</div>
      </Hint>
    );
  };
  return (
    <div className="content-engagement-by-source">
      {(totalContentViews || totalContentClicks) ? (
        <div className="content-engagement-by-source-graph">
          <div className="content-engagement-by-source-legend">
            <DiscreteColorLegend items={legendGraphData} />
          </div>
          <FlexibleXYPlot height={300} margin={{left: 70, right:70, top: 30}} stackBy="y" ref={plotRef}>
            <HorizontalGridLines />
            <YAxis style={{ strokeWidth: '0px', fontSize: '14px'}}/>
            <LabelSeries data={xAxisTitleLabel} labelAnchorX='middle'/>
            <LabelSeries data={xAxisTotalLabel} labelAnchorX='middle'/>
            <VerticalBarSeries
              barWidth={0.25}
              colorType='literal'
              color='#A999FF'
              data={contentFromPushData}
              onValueMouseOver={(bar) => {
                if (!(activeBar?.x === bar.x && activeBar?.y === bar.y))
                setActiveBar({
                  ...bar,
                  category: BAR_CATEGORIES.PUSH,
                });
              }}
              onValueMouseOut={() => setActiveBar(null)}
            />
            <VerticalBarSeries
              barWidth={0.25}
              colorType='literal'
              color='#72DAFF'
              data={contentFromExperienceData}
              onValueMouseOver={(bar) => {
                if (!(activeBar?.x === bar.x && activeBar?.y === bar.y))
                setActiveBar({
                  ...bar,
                  category: BAR_CATEGORIES.EXPERIENCE,
                });
              }}
              onValueMouseOut={() => setActiveBar(null)}
            />
            {activeBar ? renderBarHint() : null}
          </FlexibleXYPlot>
        </div>
      ) : <PerformanceGraphNoData />}
    </div>
  )
}

import { TExperienceActionType, TExperienceState } from 'redux/templated-experience/templated-experience.type';
import {updateTemplatedInstanceStatus} from "helpers/templated-experience.helper";
import { updateFolderName } from 'helpers/folder.helper';

let initialState: TExperienceState = {
  instances: {
    data: [],
    pagination: {
      limit: 0,
      offset: 0,
      sortBy: '',
      sortOrder: '',
      totalRecords: 0,
    }
  }, // instances in the list view
  instance: null,
  template: null, // individual template for /create/:id route
  isLoading: true,
  errorMessage: '',
  isModified: false,
  pushTypes: [],
  currentFolder: undefined,
  temp: {
    delivery: {
      trigger: '',
      frequencyTitle: '',
      frequencyDescr: '',
      frequencyString: [''],
      frequency: {
        total: '',
        value: '',
        period: {
          key: '',
          name: '',
        },
      },
      limitString: [''],
      limit: {
        key: '',
        name: ''
      },
      remain: 'UNSELECTED',
      delayString: [''],
      delay: {
        selected: 'UNSELECTED',
        value: '',
        period: {
          key: '',
          name: '',
        },
      },
    },
    trigger: undefined,
    instance: undefined,
    viewMode: ''
  },
  initial: {
    instance: undefined,
    template: undefined
  }
};

export default (state: TExperienceState = initialState, action: any) => {
  switch (action.type) {
    case TExperienceActionType.SET_INSTANCES:
      return {
        ...state,
        instances: Object.assign({}, action.payload.instances),
      }
    case TExperienceActionType.SET_TEMPLATE:
      return {
        ...state,
        template: Object.assign({}, action.payload.template),
      }
    case TExperienceActionType.SET_INSTANCE:
      return {
        ...state,
        instance: Object.assign({}, action.payload.instance),
      }
    case TExperienceActionType.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.flag
      }
    case TExperienceActionType.SET_MODIFIED:
      return {
        ...state,
        isModified: action.payload,
      }
    case TExperienceActionType.UPDATE_INSTANCES:
      const updatedInstances = updateTemplatedInstanceStatus(state.instances, action.payload.id, action.payload.status);
      return {
        ...state,
        instances: {...state.instances, data: [...updatedInstances]},
      }
    case TExperienceActionType.SET_TEMP:
      return {
        ...state,
        temp: action.payload,
      }
    case TExperienceActionType.SET_INITIAL:
      return {
        ...state,
        initial: action.payload,
      }
    case TExperienceActionType.SET_PUSH_TYPES:
      return {
        ...state,
        pushTypes: action.payload,
      }
    case TExperienceActionType.SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      }
    case TExperienceActionType.SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload.currentFolder,
      }
    case TExperienceActionType.UPDATE_FOLDER_NAME:
      const updatedInstancesAndFolders = updateFolderName(state.instances, action.payload.folderId, action.payload.folderName);
      return {
        ...state,
        instances: {...state.instances, data: [...updatedInstancesAndFolders]},
      }
    default:
      return {
        ...state
      };
  }
};

import TemplatedExperienceAPI from "services/api/templated-experience.api";
import {setPushTypes} from "./templated-experience.action";

export function fetchPushTypesThunk() {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return async (dispatch: any, getState: any) => {
    const { templatedExperience } = getState();
    return new Promise((resolve, reject) => {
      if (templatedExperience.pushTypes.length) {
        resolve(templatedExperience.pushTypes);
      } else {
        templatedExperienceAPI.getPushTypes().then(pushTypes => {
          dispatch(setPushTypes(pushTypes.data));
          resolve(pushTypes.data);
        }).catch(() => {
          reject();
        })
      }
    })
  }
}

import BaseAPI from './base.api';
import { PreferenceResponse } from 'interface/preferences/preferences.interface';

export default class UserAPI extends BaseAPI {
  private ROUTES = {
    userPreferences: 'kernel/userpref',
  };

  getUserPreferences(): Promise<PreferenceResponse> {
    return this.GET(this.ROUTES.userPreferences);
  }

  createUserPreference(payload: {}): Promise<PreferenceResponse> {
    return this.POST(this.ROUTES.userPreferences, {data: payload});
  }

  updateUserPreference(payload: string): Promise<PreferenceResponse> {
    return this.PUT(this.ROUTES.userPreferences, {data: payload});
  }
}

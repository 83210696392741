import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Position } from 'react-flow-renderer';
import { isEmpty } from 'lodash';
import './PushMessage.scss';
import Handler from 'components/Journey/Handler/Handler';
import MultiActionPushPreview from 'components/PushMessage/PreviewPush/MultiActionPushPreview';
import { StepTrigger, StepWrapper } from 'interface/journey.interface';
import { getTriggerPosition } from 'helpers/journey.helper';
import PushIcon from 'assets/icons/push.svg';
import { FlightTooltip, getIcon } from '@flybits/webapp-design-system-react';
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen-no-fill.svg';
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
import LockIcon from 'assets/icons/icons8-lock.svg';
import LockIconOutline from 'assets/icons/lock-outline.svg';
const ErrorIcon = getIcon('warning', '');


export default function PushMessage(step:StepWrapper = {}) {
  let className = 'push-message';
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const experience = !isEmpty(reduxTemplatedExperienceState.template)
    ? reduxTemplatedExperienceState.template
    : reduxTemplatedExperienceState.instance;
  const pushMessage = experience?.steps?.find((expStep: any) => expStep.type === 'push' && expStep.id === step?.id);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const isNotEditable = experience?.status === 'Active';
  const title = pushMessage?.data?.actionBody?.body?.[0]?.payload?.title?.en || '';
  const message = pushMessage?.data?.actionBody?.body?.[0]?.payload?.alert?.en || '';

  if (isNotEditable) {
    className += ' not-clickable';
  }

  if ( pushMessage?.data?.hasError && pushMessage?.data?.showError) {
    className += ' error';
  }

  // TODO: uncomment after concept approval
  // const onRemove = () => {
  //   step?.data?.onRemove?.(step);
  // }


  const onApplyHandle = (form: any, type: any, pushTypeId: string) => {
    switch (type) {
      case 'basic':
        reduxTemplatedExperienceAction.setBasicPush(pushMessage?.data, form);
        break;
      case 'weblink':
        reduxTemplatedExperienceAction.setWebLinkPush(pushMessage?.data, form, pushTypeId);
        break;
      case 'content':
        reduxTemplatedExperienceAction.setContentPush(pushMessage?.data, form, pushTypeId);
        break;
      default:
        reduxTemplatedExperienceAction.setCustomPush(pushMessage?.data, form, pushTypeId);
        break;
    }
    step?.data?.setPushModalData({
      ...step.data.pushModalData,
      isVisible: false,
    })
  }


  const onTriggerContentModal = (form: any, type: any, typeID: string) => {
    onApplyHandle(form, type, typeID);
  };


  const showPushEditModal = (event:any) => {
    if(event.key === 'Enter' || event.type === "dblclick") {
      if (!isNotEditable) {
        step?.data?.setPushModalData({
          ...step?.data?.pushModalData,
          isVisible: true,
          action: pushMessage?.data,
          amplified: false,
          triggerContentModal: onTriggerContentModal,
          onApply: onApplyHandle
        })
      }
    }
    // TODO: uncomment after concept approval
    // if(event.key === 'Backspace') {
    //   onRemove();
    // }
  }


  const handleShowEditIcon = (option: boolean) => {
    if (className.indexOf('not-clickable') === -1) {
      setShowEditIcon(option);
      if(className.indexOf('error') !== -1) {
        setShowErrorIcon(!option);
      }
    }
  }


  return (
    <>
      <button
        className={className}
        onDoubleClick={showPushEditModal}
        onKeyDown={showPushEditModal}
        onClick={showPushEditModal}
        onMouseEnter={() => handleShowEditIcon(true)}
        onMouseLeave={() => handleShowEditIcon(false)}
      >
        <Handler action="in" type="target" position={Position.Left} />
        {/*// TODO: uncomment after concept approval*/}
        {/*<a href={'/'} className="push-message__remove" onClick={onRemove}>X</a>*/}
        <div className="push-message__header">
          <div className="push-message__header__left">
            <img className="push-message__header__icon" src={PushIcon} alt="PushIcon" />
            <div className="push-message__header__title">Push notification</div>
          </div>
          {isNotEditable ? (
            <FlightTooltip
              className="flow-box__card-container__card__step-header__tooltip"
              direction="top"
              isEnabled={true}
              description={isNotEditable && "You can't edit experiences that are Active. " +
              "You need to deactivate your experience in order to edit this component." }
              delay={0}
            >
              <img
                src={isNotEditable ? LockIcon : LockIconOutline}
                alt="QuestionIcon"
                className="flow-box__card-container__card__step-header__question"
              />
            </FlightTooltip>
          ) : showErrorIcon ? (
            <div className="flow-box__card-container__card__step-header__error-icon">{ErrorIcon}</div>
          ) : (
            <EditIcon
              style={{fill: '#4992FD'}}
              className={`flow-box__card-container__card__step-header__${showEditIcon ? 'edit-show' : 'edit'}`}
            />
          )}
        </div>
        <div className="push-message__body">
          <MultiActionPushPreview
            title={title}
            message={message}
            placement="box"
          />
        </div>
        {step?.data?.stepData?.triggers?.map((trigger: StepTrigger) => {
          return <Handler
            key={trigger.action}
            triggerLevel={getTriggerPosition(step?.data?.stepData?.triggers, trigger)}
            type="source"
            position={Position.Right}
            action={trigger.action}
          />
        })}
      </button>
    </>
  );
}

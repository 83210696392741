
import React, { useState, useEffect } from 'react';

import RuleAPI from 'services/api/rule.api';
import { Rule } from 'interface/rule/rule.interface';
import { stringRepFinal } from 'helpers/rule.helper';

import {times} from "lodash";
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@material-ui/core';

import { getIcon } from '@flybits/webapp-design-system-react';
import { FlightModal, FlightTextInput, FlightButton } from '@flybits/webapp-design-system-react';
import './AudienceSearchableModal.scss';
import LocationPreview from 'components/AudiencePreview/LocationPreview/LocationPreview';
import ComplexPreview from "components/AudiencePreview/ComplexRulePreview/ComplexRulePreview";
import Skeleton from 'react-loading-skeleton';
import {useSelector} from "react-redux";
import ReactHtmlParser from 'react-html-parser';

interface IProps {
  isVisible: boolean;
  onApply: (rule: Rule) => void,
  onCancel: () => void
}

export default function AudienceSearchableModal(props: any) {
  const ruleAPI = new RuleAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [rules, setRules] = useState<Rule[]>([]);
  const [selectedRule, setSelectedRule] = useState<Rule | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [total,setTotal] = useState<number>(rules?.length||0);
  let page = 0;
  let limit = 10;

  function ListItem(rule: Rule, idx: number) {
    let className = ['audience-searchable-modal__left__item'];
    if (selectedRule === rule) className.push('audience-searchable-modal__left__item--selected');
    return (
      <div className={className.join(' ')} key={`rule_$${idx}`} onClick={() => setSelectedRule(rule)}>
        {getIcon('people', null)} {rule.name}
      </div>
    )
  }

  function fetchRules(page:number) {
    setIsLoading(true);
    ruleAPI.getRules(page,limit,searchText).then((res: any) => {
      setRules(rules.concat(res.data));
      setTotal(res.pagination.totalRecords);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (props.isVisible) {
      fetchRules(page);
    }

    return ()=>{
      setRules([]);
      setTotal(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible,searchText])

  // Update Rule Preview
  useEffect(() => {
    if (!selectedRule) return;
    const isIncluded = !!rules?.some((rule: Rule) => rule.name === selectedRule.name);
    setShowPreview(isIncluded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRule]);

  function fetchMoreData() {
    if (props.isVisible) {
      page = page+1;
      fetchRules(page);
    }
  }

  function updateSearchText(e:React.ChangeEvent<HTMLInputElement>) {
    e.persist();
    setSearchText(e.target.value);
    setRules([]);
    setTotal(0);
  }


  return (
    <FlightModal
      isVisible={props.isVisible}
      size="large"
      className="audience-searchable-modal"
      scrollable={true}
      toggleModalShown={() => props.onCancel()}
      header={
        <div className="audience-searchable-modal__header">
          Choose an audience template
        </div>
      }
      content={
        <div className="audience-searchable-modal__main">
          <div className="audience-searchable-modal__left">
            <div>
              <FlightTextInput
                width="100%"
                iconInput="search"
                value={searchText}
                hasClearIcon={true}
                onChange={updateSearchText}
              />
              <div className="audience-searchable-modal__rule-list-container">
                <InfiniteScroll
                  className="audience-searchable-modal__rule-list-container__infinite-scroll"
                  pageStart={page}
                  initialLoad={false}
                  loadMore={fetchMoreData}
                  hasMore={total > (rules?.length || 0)}
                  threshold={20}
                  useWindow={false}
                  loader={<div className="audience-searchable-modal__rule-list-container__loader" key={0}>
                      <CircularProgress size={20}/>
                      <span className="audience-searchable-modal__rule-list-container__loader-text">Loading...</span>
                  </div>}
                >
                  {rules && (
                    rules.map((rule: Rule, idx: number) => {
                      return ListItem(rule, idx);
                    })
                  )}
                </InfiniteScroll>
                {isLoading && !rules.length && (
                  <>
                    {times(7, (i) => (
                      <div key={i}>
                        <Skeleton width={'100%'} height={50} />
                      </div>
                    ))}
                  </>
                )}
                {!isLoading && !rules.length && (
                  <div>No results found</div>
                )}
              </div>
            </div>
          </div>
          <div className="audience-searchable-modal__right">
            <div className="audience-searchable-modal__preview-container">
            <AudiencePreview rule={showPreview ? selectedRule : null}/>
            </div>
          </div>
        </div>
      }
      footer={
        <div className="audience-searchable-modal__footer">
          <FlightButton
            theme="secondary"
            label="Cancel"
            onClick={props.onCancel}
          />
          <FlightButton
            theme="primary"
            label="Apply Selected Template"
            disabled={!showPreview}
            onClick={() => props.onApply(selectedRule)}
          />
        </div>
      }
    />
  )
}

interface IAudiencePreviewProp {
  rule: Rule | null
}

function AudiencePreview(props: IAudiencePreviewProp) {
  const [ruleData, setRuleData] = useState<any>(undefined);
  const plugins = useSelector((state: any) => state.plugin?.plugins);

  function audienceRowType(audienceObj: any) {
    let locationText = 'User is in a location';
    if(audienceObj?.arguments?.[0]?.includes('Approximate')){
      locationText = 'User is in the approximate area of';
    }
    let _audienceObj = audienceObj;
    if(_audienceObj.length === 1) {
      _audienceObj.type = 'complexRule'
    }

    switch (audienceObj.type) {
      case 'location:map':
        return (
          <span>{locationText} &nbsp; <LocationPreview fullScreen={true} location={audienceObj.location} args={audienceObj?.arguments}
          /> </span>
        )
      case 'location:label':
        return (
          <span> User is in a location with the label {'"' + audienceObj.label + '"'}</span>
        )
      case 'general':
        return (
          <span> {ReactHtmlParser(audienceObj.arguments[0])} {audienceObj.predicate} &nbsp; <b> {audienceObj.arguments[1]} </b> </span> // plugin
        )
      case 'complexRule':
        return (
          <span>
            {' '}
            <ComplexPreview ruleName={_audienceObj} showAsModal={true} journeyRule={ruleData} />
          </span>
        );
      case 'dateTime':
        return (
          <>
            {' '}
            <span>
              {audienceObj.arguments[0]} {audienceObj.predicate} &nbsp; <b> {audienceObj.arguments[1]} </b>{' '}
            </span>
          </>
        );
      default:
        break;
    }
  }



  function audienceSection() {
    const rows = ruleData.predicates.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    return (
      <div className="audience-searchable-modal__right__audience-preview__description__audience-section">
        <p className="audience-searchable-modal__right__audience-preview__description__audience-section__condition"> Conditions {ruleData.toString} </p>
        {rows}
      </div>
    )
  }

  // we can probably memoize this and only update if stringObj arguments change
  function audienceRow(stringObj: any, idx: number = 1) {
    return (
      <div key={`stringRep_${idx}`} className="flow-box__card-container__card__step-body__plugins">
        <div className="flow-box__card-container__card__step-body__plugins__plugin-left">
          <b> { idx + 1 }. </b>
        </div>
        <div className="flow-box__card-container__card__step-body__plugins__plugin-right">
          {Array.isArray(stringObj) ? (
            <> {stringObj[0]}</> // rule
          ) : (
            audienceRowType(stringObj)
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (!props.rule) return;
    const stringRep = props.rule.stringRepresentation.split(":-")[1];
    stringRepFinal(stringRep, props.rule, plugins).then((r: any) => {
      setRuleData(r);
    });
  }, [props.rule, plugins])

  return (
    props.rule && (
      <div className="audience-searchable-modal__right__audience-preview">
        <p className="audience-searchable-modal__right__audience-preview__name"> {props.rule.name} </p>
        <div className="audience-searchable-modal__right__audience-preview__description">
          {ruleData && audienceSection()}
        </div>
      </div>
    )
  )
}

import React, { useState, createContext, useEffect } from 'react';
import {useSelector} from "react-redux";

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import MultiActionArrowIcon from 'assets/icons/multiactionarrow.svg';

import ContentModal from 'components/Modal/ContentModal/ContentModal';
import FullPageOverlay from 'components/FullPageOverlay/FullPageOverlay';
import ContentIframeParent from 'components/Content/ContentIframeParent/ContentIframeParent';
import InAppContentIcon from 'assets/icons/in-app.svg';
import LocationIcon from 'assets/icons/location.svg';

import MultiActionContainer from 'components/Shared/MultiActionContainer/MultiActionContainer';
import { merge } from 'lodash';
import MiniCard from 'components/Shared/MiniCard/MiniCard';
import { isContentValid } from 'validator/content.validator';
import { MiniCardContent } from 'components/Content/MiniCardContent/MiniCardContent';
import { Content } from 'interface/content/content.interface';
import { Action } from 'interface/experience/experience.interface';
import {prepareContentForIframe} from "helpers/content.helper";
import { isEmpty } from 'lodash';

interface IframeState {
  status: boolean;
  id: string;
}

interface ContentContextProps {
  contents: [];
  onClick: any;
  selectedContent: string;
  setSelectedContent: React.Dispatch<React.SetStateAction<string>>;
  hasError: boolean;
  isNearByTemplate: boolean;
}

export const ContentContext = createContext({
  contents: [],
  onClick: (selectedContent?: any) => {
    return selectedContent;
  },
  selectedContent: '',
  setSelectedContent: () => {},
  hasError: false,
  userClickedOnPush: false,
  setUserClickedOnPush: () => {},
  isNearByTemplate: false
} as ContentContextProps);

const RenderMiniCards = () => {
  return (
    <ContentContext.Consumer>
      {(valuesFromContext) => {
        let cardTitle = 'In-app content';
        let cardIcon = InAppContentIcon;
        if(valuesFromContext.isNearByTemplate) {
          cardTitle = 'Nearby content';
          cardIcon = LocationIcon;
        }
        return valuesFromContext.contents ? (
          //render multi content instances if they exist.
          valuesFromContext?.contents?.sort((a:Content,b: Content) => b?.createdAt - a?.createdAt)
          .map((content: Content, index) => {
            return (
              <div key={`multi-content-instance-${index}`} style={{width:'100%', marginBottom: 10}} >
                <MiniCard title={cardTitle} icon={cardIcon} type="content" id={content.id} onClick={() => valuesFromContext.onClick(content)}>
                  <MiniCardContent content={content} hideCollapse={valuesFromContext?.contents?.length <= 2} isNearByTemplate={valuesFromContext.isNearByTemplate} />
                </MiniCard>
              </div>
            );
          })
        ) : (
          //else render 'add content' instance
          <MiniCard title={cardTitle} icon={cardIcon} onClick={valuesFromContext.onClick} type="content">
            <MiniCardContent hideCollapse={true} isNearByTemplate={valuesFromContext.isNearByTemplate}/>
          </MiniCard>
        )
      }}
    </ContentContext.Consumer>
  )
}

export default function ContentBox(props: any) {
  const [showContentInstances, setShowContentInstances] = useState(false);
  const [showContentTemplates, setShowContentTemplates] = useState(false);
  const [isContentIframeVisible, setIsContentIframeVisible] = useState(false);
  const [selectedContent, setSelectedContent] = useState('');
  const experience = useSelector((state: any) => isEmpty(state.templatedExperience.instance) ? state.templatedExperience.template : state.templatedExperience.instance);
  const isNearByTemplate = experience?.steps?.[0]?.trigger?.type === "nearby-location";
  const [contentForIframe, setContentForIframe] = useState<any>({
    id: '',
    isEdit: false,
    isNearByTemplate,
    payload: { name: '', description: '', iconUrl: '', labels: [], isContextLocked: false, values: null },
  });
  const [isIframeSuccess, setIsFrameSuccess] = useState<IframeState | null>(null);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);

  const handleCreateContent = () => {
    setShowContentTemplates((showContentTemplates) => !showContentTemplates);
  };

  const triggerReduxAction = (content: any, deselect?: boolean) => {
    if (props!!.isCalledFromPush) {
      let _content = {
        value: deselect ? '' : content.id,
        entity: 'content',
      };
      merge(props.actionBody?.body?.[0]?.payload?.data?.contentId, _content);
      reduxTemplatedExperienceAction.setContentPush(props);
    } else {
      if(isNearByTemplate) {
        const push = experience?.steps?.[0]?.actions?.find((action: Action) => action.actionType === 'push');
        delete(push.actionBody?.body?.[0]?.payload?.data?.contentId);
        merge(push.actionBody?.body?.[0]?.payload?.data, {contentBlueprintId: content?.[0]?.contentTemplateId});

        reduxTemplatedExperienceAction.setContent(props.id, content);
        setSelectedContent(content?.[0]?.contentTemplateId);
        props.setContent(content);
      } else {
        reduxTemplatedExperienceAction.setContent(props.actionID, content);
      }
    }
  };

  const onApplyHandle = (content: any) => {
    setShowContentInstances(false);
    setShowContentTemplates(false);
    setIsFrameSuccess(null);
    if (props?.isCalledFromPush) {
      props?.onApply();
    }
    if (content?.templateId || content?.[0]?.templateId) {
      triggerReduxAction(content);
    } else {
      setContentForIframe({ ...contentForIframe, id: content?.[0]?.id, isEdit: false, payload: content?.[0] });
      setIsContentIframeVisible(true);
    }
  };

  const onEditInstance = (content: any) => {
    setShowContentInstances(false);
    setShowContentTemplates(false);
    setContentForIframe({ ...contentForIframe, id: content?.[0]?.id, isEdit: true, payload: content?.[0] });
    setIsContentIframeVisible(true);
    setIsFrameSuccess(null);
  };

  const handleCancel = () => {
    if (props?.isCalledFromPush) {
      props?.modalClosed();
    }
    setShowContentInstances(false);
    setShowContentTemplates(false);
    setIsFrameSuccess(null);
  };

  const handleBack = () => {
    setIsFrameSuccess(null);
    setShowContentTemplates(false);
  };

  const onIframeCancel = () => {
    setIsContentIframeVisible(false);
    setIsFrameSuccess(null);
  };

  const onIframeSuccess = (id: string, content: any) => {
    if(!isNearByTemplate) {
      setShowContentInstances(true);
    }
    if(content && isNearByTemplate) {
      triggerReduxAction([content]);
    }
    setIsFrameSuccess({ status: true, id: id });
    if(props?.requestUpdatedInstance) {
      props.requestUpdatedInstance();
    }
    setIsContentIframeVisible(false);
  };

  const openContentModal = (content: Content) => {
    // if (!props.isNotEditable) {
      if(isNearByTemplate) {
        if(content.id || content.templateId) {
          let preparedContent = prepareContentForIframe(content, false);
          setContentForIframe({
            id: content.id || content.templateId,
            isEdit: true,
            payload: preparedContent,
            isContentBlueprint: props.actionType === 'content-blueprint'
          });
          setIsContentIframeVisible(true);
          setIsFrameSuccess(null);
        } else {
          setShowContentTemplates(true);
          setShowContentInstances(true);
        }
      } else {
        setSelectedContent(content?.id);
        setShowContentInstances(true);
      }
    // }
  };

  const contentProps = {
    ...props,
    isVisible: showContentInstances,
    showTemplates: showContentTemplates,
    selectedId:
      (props!!.actionBody?.body?.[0]?.payload?.data?.contentId?.value) ||
      props!!.contentInstance?.id ||
      isIframeSuccess?.id ||
      selectedContent,
    editedInstanceId: isIframeSuccess?.id,
    onCancel: handleCancel,
    isAfterCreate: isIframeSuccess?.status || false,
    onBack: handleBack,
    onApply: (content: any) => onApplyHandle(content),
    onEditInstance: (content: any) => onEditInstance(content),
    onCreateContent: handleCreateContent,
    isNearByTemplate,
  };

  const contentIframeProps = {
    onSubmit: (id: string, content: any) => onIframeSuccess(id, content),
    onCancel: onIframeCancel,
    id: contentForIframe.id,
    payload: contentForIframe.payload,
    isEdit: contentForIframe.isEdit,
    isNearByTemplate
  };

  useEffect(() => {
    if (props.openModal) {
      setShowContentInstances(true);
    }
    return () => setShowContentInstances(false);
  }, [props.openModal]);

  const getCollapseState = () => {
    return props?.contents?.length > 2 ? false : true
  }

  return (
    <>
      {!props.hideBox && (
        <ContentContext.Provider
          value={{
            contents: props.contents,
            onClick: openContentModal,
            selectedContent,
            setSelectedContent,
            hasError: isContentValid(props),
            isNearByTemplate
          }}
        >
          <MultiActionContainer {...props} title="Actions" icon={MultiActionArrowIcon} hideCollapse={getCollapseState()}>
            <RenderMiniCards />
          </MultiActionContainer>
        </ContentContext.Provider>
      )}
      {contentProps.isVisible && <ContentModal {...contentProps} />}
      <FullPageOverlay isVisible={isContentIframeVisible}>
        <ContentIframeParent {...contentIframeProps} />
      </FullPageOverlay>
    </>
  );
}

import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {times, isEmpty} from "lodash";
import {
  FlightButton,
  FlightModal,
  FlightTextInput,
} from "@flybits/webapp-design-system-react";
import InfiniteScroll from "react-infinite-scroller";
import {CircularProgress} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import {TExperienceInstance} from 'interface/templated-experience/templated-experience.interface';
import FolderAPI from "services/api/folder.api";
import useDebounce from 'hooks/useDebounce';
import FolderIcon from "assets/icons/icons8-folder.svg";
import PolygonIcon from "assets/icons/collapse-polygon.svg";
import TrashIcon from "assets/icons/trash-can.png";
import CheckCircleIcon from "assets/icons/check-circle-24.svg";
import { Folder } from 'interface/folder.interface';
import './FoldersModal.scss';
import { findFolderById, getSubFolderIds } from 'helpers/folder.helper';
import {setCurrentFolder} from "redux/templated-experience/templated-experience.action";
interface IProps {
  entities?: Set<never>
  experience?: TExperienceInstance
  isVisible?: boolean
  onCancel: () => void
  onMove?: (parentFolderId: string| undefined) => void
}


export default function FoldersModal(props: IProps) {

  const folderAPI = new FolderAPI();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>('');
  const [folders, setFolders] = useState<Folder[]>([]);
  const [allFolders, setAllFolders] = useState<Folder[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<number>(folders?.length || 0);
  const [selectedFolder, setSelectedFolder] = useState<Folder|undefined>(undefined);
  const [newFolder, setNewFolder] = useState<Folder|undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false);
  const [isEditingNewFolder, setIsEditingNewFolder] = useState(false);
  const [experience, setExperience] = useState<TExperienceInstance|undefined>(undefined);
  const [levels, setLevels] = useState<Map<string,number>>(new Map());
  const debouncedSearchTerm = useDebounce(searchText, 100);
  const debouncedFolder = useDebounce(newFolder, 500);
  const levelPadding = 24;
  let level = 0;
  let expanded = true;
  let page = 0;


  const handleCancel = () => {
    props.onCancel();
  }


  const handleMove = () => {
    setErrorMessage('');
    // experience move
    if(props.experience?.id) {
      folderAPI.bulkMoveToFolder({
        destination: selectedFolder?.id!,
        experiences:[props?.experience?.id!],
        folders:[]
      }).then(() => {
        handleCancel();
        setExperience({...experience!, parentFolderID: selectedFolder?.id});
        setNewFolder(undefined);
        if(props.onMove) props.onMove(selectedFolder?.id);
        dispatch(setCurrentFolder({ currentFolder: selectedFolder }));
      }).catch((error: Error) => {
        setErrorMessage(error.message);
      });
    }
    // bulk folders & experiences move
    if(props.entities) {
      const payload: {destination: string, folders: string[], experiences: string[]} = {
        destination: selectedFolder?.id || '',
        folders: [],
        experiences: []
      };
      props.entities.forEach((entity:string) => {
        const folder: Folder|undefined = findFolderById(entity, folders);
        if(folder) {
          payload.folders.push(folder?.id!);
        } else {
          payload.experiences.push(entity);
        }
      });
      folderAPI.bulkMoveToFolder(payload)
        .then(() => {
          handleCancel();
          if(props.onMove) props.onMove('');
        })
        .catch((error: Error) => {
          setErrorMessage(error.message);
        });
    }
  }


  const serializeFolders = (records: Folder[], expanded:boolean, levels: Map<string,number>, level: number, disabledFolders: Set<string>) => {
    for(let i = 0; i < records.length; i++) {
      // Set the folder's level of nesting if it was not set yet
      if(!levels.has(records[i].parentFolderID!)) {
        levels.set(records[i].parentFolderID!, levelPadding*level);
        setLevels(levels);
        level++;
      }
      // Preselect the folder if experience is inside this folder
      if(experience?.parentFolderID && records[i]?.id === experience?.parentFolderID) {
        setSelectedFolder(records[i]);
      }
      // Disable subFolders for selected folder in order to not allow move folder into it's subFolder
      records[i].disabled = disabledFolders.has(records[i].id!);
      records[0].expanded = level === 1; // set first folder expanded by default
      if(records[i].subFolders?.length) {
        serializeFolders(records[i].subFolders!, expanded, levels, level, disabledFolders);
      } else {
        records[i].subFolders = [];
      }
    }
  }


  const expandSelectedFolder = (experience: TExperienceInstance, folders:Folder[]) => {
    const parentFolder = findFolderById(experience?.parentFolderID!, folders);
    if(parentFolder) {
      parentFolder.expanded = true;
      expandSelectedFolder(parentFolder, folders);
    }
    setFolders(folders);
  }


  const fetchFolders = () => {
    setIsLoading(true);
    setErrorMessage('');
    folderAPI.getFoldersTree()
      .then(async (res: any) => {
        setSelectedFolder({...res.data?.[0]!});
        serializeFolders(res.data, expanded, new Map([]),0, getDisabledFolders(res.data));
        setFolders(res.data);
        setAllFolders(res.data);
        setIsLoading(false);
      })
      .catch((error: Error) => {
        setErrorMessage(error.message);
        setIsLoading(false);
      });
  }


  const matchedFolders: Folder[] = [];
  const searchFolders = (folders: Folder[]) => {
    for(let i = 0; i < folders.length; i++) {
      if(folders[i].name.includes(debouncedSearchTerm)) {
        matchedFolders.push({
          ...folders[i],
          name: folders[i].pathName!,
          parentFolderID: '',
          pathName: folders[i].pathName!,
          expanded: false,
          disabled: getDisabledFolders(folders)?.has(folders[i].id!),
          subFolders: []
        });
      }
      if(folders[i].subFolders?.length) {
        searchFolders(folders[i].subFolders!);
      }
    }
    setFolders(matchedFolders);
  }


  const fetchMoreData = () => {
    if (props.isVisible) {
      page = page + 1;
      fetchFolders();
    }
  }

  useEffect(() => {
    if(props.experience) {
      setExperience(props.experience);
    }
  },[props.experience]);


  useEffect(() => {
    if (props.isVisible) {
      if(debouncedSearchTerm) {
        searchFolders(allFolders);
      } else {
        fetchFolders();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible, debouncedSearchTerm]);


  useEffect(() => {
    return () => {
      setFolders([]);
      setSelectedFolder(undefined);
      setTotal(0);
      setSearchText('');
      setNewFolder(undefined);
    }
  },[]);


  useEffect(() => {
    if(debouncedFolder?.name) {
      folderAPI.updateFolder(debouncedFolder!)
        .then(() => {
          const debouncedFolderObject = {
            ...debouncedFolder,
            expanded: false,
            disabled: false,
            subFolders: []
          }
          updateNewFolder(debouncedFolderObject, selectedFolder, folders);
        })
        .catch((error: Error) => setErrorMessage(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFolder?.name]);


  useEffect(() => {
    if(experience) {
      expandSelectedFolder(experience, folders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, experience]);


  const getDisabledFolders = (folders: Folder[]) => {
    let disabledFolders:Set<string> = new Set([]);
    props?.entities?.forEach((id:string) => {
      if(!disabledFolders.has(id)) {
        disabledFolders.add(id);
      }
      const subFolders = getSubFolderIds(id, folders);
      if(!isEmpty(subFolders)) {
        disabledFolders = new Set([...disabledFolders, ...subFolders]);
      }
    });
    return disabledFolders;
  }


  const handleFolderClick = async (folder: Folder) => {
    if(!folder.disabled) {
      setSelectedFolder({...folder});
      setIsEditingNewFolder(false);
    }
  }


  const handleArrowClick = async (e:React.MouseEvent, folder: Folder) => {
    e.preventDefault();
    folder.expanded = !folder.expanded;
  }


  const handleFolderRemove = async (folder: Folder) => {
    const parentFolder = await findFolderById(folder.parentFolderID, folders);
    setErrorMessage('');
    folderAPI.bulkDelete({folders: [folder.id!], experiences:[]})
      .then(() => {
        parentFolder.subFolders = parentFolder.subFolders?.filter((f:Folder) => f?.id !== folder?.id);
        setSelectedFolder(parentFolder);
        setIsEditingNewFolder(false);
      })
      .catch((error: Error) => {
        setErrorMessage(error.message);
      });
  }


  const updateFolderName = (e: React.ChangeEvent<HTMLInputElement>, folder: Folder) => {
    setNewFolder({...folder, name: e.target.value});
  }


  const confirmFolderName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter') {
      setIsEditingNewFolder(false);
    }
  }


  const renderSubFolders = (folder: Folder, level: number) => {
    if (folder.subFolders?.length && folder.expanded) {
      return folder.subFolders?.map(subFolder => {
        let arrowIcon = null;
        if (subFolder?.subFolders?.length) {
          arrowIcon = PolygonIcon;
        }

        return (
          <div key={subFolder?.id} className={'folders-modal__folder-wrapper'}>
            <button
              onClick={() => handleFolderClick(subFolder)}
              className={`folders-modal__item__subfolder ${(selectedFolder?.id === subFolder?.id) && 'selected'}`}
              style={{paddingLeft: levels.get(subFolder?.parentFolderID!)}}
              disabled={subFolder?.disabled}
            >
              {arrowIcon && <a
                href={'/'}
                onClick={(e:React.MouseEvent) => handleArrowClick(e, subFolder)}
                className="folders-modal__button-arrow"
              >
                <img
                  src={arrowIcon}
                  alt={subFolder?.name}
                  className={`folders-modal__arrow ${(subFolder?.expanded && !subFolder?.disabled) && 'expanded'} ${subFolder?.disabled && 'disabled'}`}
                />
              </a>}
              <img src={FolderIcon} alt={subFolder?.name} className={`folders-modal__icon ${subFolder?.disabled && 'disabled'}`}/>
              {(subFolder?.id === newFolder?.id) ? isEditingNewFolder ? (
                <div className="folders-modal__new-folder">
                  <FlightTextInput
                    width="100%"
                    label=""
                    value={newFolder?.name}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => updateFolderName(e, subFolder)}
                    onKeyPress={(e:React.KeyboardEvent<HTMLInputElement>) => confirmFolderName(e)}
                    className="folders-modal__new-folder__input"
                    autoFocus
                  />
                  <img
                    src={TrashIcon}
                    alt={'Remove'}
                    onClick={()=>handleFolderRemove(subFolder)}
                    className="folders-modal__new-folder__icon"
                  />
                </div>) : (<div className="folders-modal__new-folder">{newFolder?.name} (0) <img src={CheckCircleIcon} alt="Created" className="folders-modal__new-folder__icon"/></div>
              ) : <div>{subFolder?.name} ({subFolder?.numExperiences})</div>}
            </button>
            {renderSubFolders(subFolder, level)}
          </div>
        )
      })
    }
  }


  const ListItem = (folder: Folder, selectedFolder: Folder|undefined) => {
    let arrowIcon:any = null;
    if(folder?.subFolders?.length) {
      arrowIcon = PolygonIcon;
    }
    let folderName = folder?.name?.slice(0,-1);

    return (
      <div key={folder?.id}>
        <button
          onClick={() => handleFolderClick(folder)}
          className={`folders-modal__item ${selectedFolder?.id === folder?.id && 'selected'}`}
        >
          {arrowIcon && <a href={'/'} onClick={(e:React.MouseEvent) => handleArrowClick(e, folder)} className="folders-modal__button-arrow">
            <img src={arrowIcon} alt={folder?.name} className={`folders-modal__arrow ${folder?.expanded && 'expanded'}`}/>
          </a>}
          <img src={FolderIcon} alt={folder?.name} className="folders-modal__icon"/>
          <div>{folder?.name === '/' ? 'All experiences' : folderName}</div>
        </button>
        {renderSubFolders(folder, level)}
      </div>
    )
  }


  const updateSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFolder(undefined);
    setSearchText(e.target.value);
  }


  const updateNewFolder = (newFolder: Folder, selectedFolder: Folder|undefined, folders:Folder[]) => {
    let newFolderExists = findFolderById(newFolder?.id, folders);
    for(let i = 0; i < folders.length; i++) {
      if(folders[i]?.id === newFolder?.parentFolderID && !newFolderExists) {
        let parentLevel = levels.get(newFolder?.parentFolderID!);
        if(!parentLevel) {
          parentLevel = levels.get(selectedFolder?.parentFolderID!);
          levels.set(selectedFolder?.id!, parentLevel!+levelPadding);
          setLevels(levels);
        }
        folders[i].expanded = true;
        folders[i].subFolders?.unshift(newFolder);
      }
      if(folders[i].id === newFolder.id && newFolderExists) {
        folders[i] = newFolder;
      }
      if(folders[i].subFolders?.length) {
        updateNewFolder(newFolder, selectedFolder, folders[i].subFolders!);
      }
    }
  }


  const createNewFolder = async () => {
    setIsCreatingNewFolder(true);
    folderAPI.createFolder({name: 'Untitled', parentFolderID: selectedFolder?.id!})
      .then((createdFolder:Folder) => {
        const newFolderObject = {
          ...createdFolder,
          expanded: false,
          disabled: false,
          subFolders: []
        }
        updateNewFolder(newFolderObject, selectedFolder, folders);
        setNewFolder(newFolderObject);
        setSelectedFolder(newFolderObject);
        setIsEditingNewFolder(true);
        setIsCreatingNewFolder(false);
      })
      .catch((error: Error) => {
        setErrorMessage(error.message);
      });
  }


  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={handleCancel}
      header={
        <div className="folders-modal__header">
          <h3>Move to folder</h3>
        </div>
      }
      content={<div className="folders-modal">
        <div className="folders-modal__content">
          <FlightTextInput
            width="100%"
            label="Search by folder name"
            iconInput="search"
            value={searchText}
            hasClearIcon={true}
            onChange={updateSearchText}
          />
          <div className="folders-modal__rule-list-container">
            {(!isLoading && errorMessage) && <div className={'folders-modal__error-message'}>{errorMessage}</div>}
            {(!isLoading && !folders?.length && !errorMessage) && <div>No folders found</div>}
            <InfiniteScroll
              className="folders-modal__rule-list-container__infinite-scroll"
              pageStart={page}
              initialLoad={false}
              loadMore={fetchMoreData}
              hasMore={total > (folders?.length || 0)}
              threshold={20}
              useWindow={false}
              loader={<div className="audience-searchable-modal__rule-list-container__loader" key={0}>
                <CircularProgress size={20}/>
                <span className="audience-searchable-modal__rule-list-container__loader-text">Loading...</span>
              </div>}
            >
              {folders?.map(folder => ListItem(folder, selectedFolder))}
            </InfiniteScroll>
            {isLoading && !folders?.length && (
              <>
                {times(7, (i) => (
                  <div key={i}>
                    <Skeleton width={'100%'} height={50}/>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>}
      footer={
        <div className="folders-modal__footer">
          <div>
            <FlightButton
              theme="link"
              onClick={createNewFolder}
              label="+ New folder"
              disabled={isEditingNewFolder || isCreatingNewFolder}
              loading={isCreatingNewFolder}
            />
          </div>
          <div>
            <FlightButton
              theme="secondary"
              onClick={handleCancel}
              label="Cancel"
            />
            <FlightButton
              onClick={handleMove}
              label="Move"
              disabled={!selectedFolder}
            />
          </div>
        </div>
      }
    />
  )
}

import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FlightButton, FlightTextArea } from '@flybits/webapp-design-system-react';

import './SmsActionEdit.scss';

interface IProps {
  onApply: (formData: any) => void;
  step: any;
  selectedActionIndex: number;
}

// Import the same style used in PushEdit.tsx
export default function SmsActionEdit({ onApply, step, selectedActionIndex }: IProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  const languages = [
    { name: 'English', id: 'en', isDefault: true },
    { name: 'French', id: 'fr', isDefault: false },
  ];

  const defaultFormData = {
    en: {
      description: '',
      url: '',
    },
    fr: {
      description: '',
      url: '',
    },
  };

  const [formData, setFormData] = useState<any>(defaultFormData);

  useEffect(() => {
    if (selectedActionIndex === -1) {
      setFormData(defaultFormData);
    } else if (step.actions[selectedActionIndex].type === 'sms') {
      setFormData(step.actions[selectedActionIndex].data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionIndex]);

  function updateField(languageID: 'en' | ' fr', field: 'title' | 'description' | 'url', value: string) {
    formData[languageID][field] = value;
    setFormData({ ...formData });
  }

  return (
    <div className="push-edit">
      <div className="tab-section">
        <Tabs
          forceRenderTabPanel={true}
          selectedIndex={selectedTab}
          onSelect={(newValue: number) => setSelectedTab(newValue)}
          className="flight-tab"
          selectedTabClassName="flight-tab--selected"
        >
          <TabList className="flight-tab--list">
            {/* Tabs */}
            {languages.map((language: any, index: number) => (
              <Tab className="flight-tab--list__item" key={`tab-${index}`}>
                {language.name}
                {language.isDefault && <span className="required">*</span>}
              </Tab>
            ))}
          </TabList>
          {/* Tab Content */}
          <div className="tab-section__panels">
            {languages.map((language: any, index: number) => {
              return (
                <TabPanel key={`tab-panel-${index}`}>
                  <div className={index === selectedTab ? 'visible' : 'hidden'}>
                    <FlightTextArea
                      className="create-edit-push__input"
                      label="Message"
                      maxLength={170}
                      width="100%"
                      value={formData[language.id].description}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        updateField(language.id, 'description', e.target.value)
                      }
                    />
                  </div>
                </TabPanel>
              );
            })}
          </div>
          <div></div>
          <FlightButton
            label="Apply"
            onClick={() => {
              onApply(formData);
              setFormData(defaultFormData);
            }}
          />
        </Tabs>
      </div>
      <div></div>
    </div>
  );
}

import { Language } from 'interface/settings/settings.interface';

export const SettingActionType = {
  SET_LANGUAGES: "SET_LANGUAGES"
}

export interface SettingState {
  languages: Language[];
  isInitialized: boolean;
}

interface SetPluginAction {
  type: typeof SettingActionType.SET_LANGUAGES,
  payload: {
    languages: Language[]
  }
}


import BaseAPI from './base.api';
 
export default class LabelsAPI extends BaseAPI {
  private ROUTES = {
    labels: '/kernel/labels'
  }

  getLabels(searchTerm: string='', page: number=0, limit: number=10000): Promise<[]> {
    return this.GET(this.ROUTES.labels + `?limit=${limit}&offset=${page * limit}&sortorder=asc&search=${searchTerm}`);
  }

  putLabels(labels: string[]): Promise<[]> {
    return this.PUT(this.ROUTES.labels, {data: labels});
  }
}


import BaseAPI from './base.api';
import { LanguageResponse } from 'interface/settings/settings.interface';

interface ConfigName {
  configName: string;
}

export interface ObjectForSettingsApi {
  config: string;
  configName: ConfigName;
}

export default class SettingAPI extends BaseAPI {
  private ROUTES = {
    allLanguages: 'sso/settings/configs/languages',
    allCategories: 'sso/settings/configs/point-sol-categories',
    allSidepanels: 'sso/settings/configs/point-sol-sidepanel',
    theme: 'sso/settings/configs/es-2-theme',
    createRoute: 'sso/settings/configs'
  }

  getLanguages(): Promise<LanguageResponse> {
    return this.GET(this.ROUTES.allLanguages);
  }

  getCategories(): Promise<any> {
    return this.GET(this.ROUTES.allCategories);
  }

  getSidePanelSettings(): Promise<any> {
    return this.GET(this.ROUTES.allSidepanels);
  }

  getThemeSettings(): Promise<any> {
    return this.GET(this.ROUTES.theme);
  }

  createSettings(themeSettings: ObjectForSettingsApi): Promise<any> {
    return this.POST(this.ROUTES.createRoute, themeSettings);
  }
}

import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { history } from 'redux/store';

import MultiStepProvider, { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import MultiStepTemplatedExperienceAPI from 'services/api/multistep-templated-experience.api';

import { ReactComponent as ArrowWithline } from 'assets/icons/arrowline2.svg';
import ControlBar from 'components/ControlBar/ControlBar';
import EntryBox from 'components/MultiStepDemo/FlowBox/EntryBox/EntryBox';
import ExitBox from 'components/MultiStepDemo/FlowBox/ExitBox/ExitBox';
import ActionBox from 'components/MultiStepDemo/FlowBox/ActionBox/ActionBox';
import './MultiStepEdit.scss';

interface param {
  [key: string]: string | undefined
}

export function MultiStepEdit() {

  const dispatch = useDispatch();

  const { id }: param = useParams();
  const multiStepTemplatedExperienceAPI = new MultiStepTemplatedExperienceAPI();

  const multiStepContext: any = useContext(MultiStepContext);
  const { template } = multiStepContext;
  const startingEdges = template.entry.edges;

  useEffect(() => {
    multiStepTemplatedExperienceAPI.getInstance(id).then((instance: any) => {
      multiStepContext.setTemplate(instance);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function onSaveHandle(status: 'Draft' | 'Active') {
    template.status = status;
    multiStepTemplatedExperienceAPI.updateInstance(template).then(() => {
      dispatch({type: 'SHOW_SNACKBAR', payload: {content: `Successfully created experience: "${template.name}"`, type: 'success'}});
      history.push('/templated-experiences');
    })
  }

  function onCancelHandle() {
    history.push('/templated-experiences');
  }

  function onNameEditHandle(name: string) {
    multiStepContext.updateTemplateName(name);
  }

  function recursiveRender(ids: string[]) {
    if (ids.length === 0) return <></>;
    return (
      <div className="render-container">
        <div className="column">
          {ids.length > 1 && <div className="vertical-line"></div>}
          {ids.map((id: string, idx: number) =>
            <div className="column-row">
              {idx > 0 && <ArrowWithline style={{position: 'absolute', marginLeft: '-52px', marginTop: '-300px'}}/>}
              <div className="item"> {<ActionBox step={template.nodes[id]} /> } </div>
              {recursiveRender(template.nodes[id].edges)}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <ControlBar
      name={template.name}
      status={template.status}
      onCancel={onCancelHandle}
      onDraft={() => onSaveHandle('Draft')}
      onLaunch={() => onSaveHandle('Active')}
      onNameEdit={onNameEditHandle}
    >
      <div className="multi-create__main__flow">
        <EntryBox/>
        {recursiveRender(startingEdges)}
        <ExitBox/>
      </div>
    </ControlBar>
  )
}


export default () => {
  return (
    <MultiStepProvider>
      <MultiStepEdit/>
    </MultiStepProvider>
  )
}

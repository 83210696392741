
import { AuthState, AuthActionType } from 'redux/auth/auth.type';

let initialState: AuthState = {
  user: undefined,
  token: undefined,
  authenticated: false,
}

export default (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    case AuthActionType.AUTHORIZE_USER:
      return {
        ...state,
        ...action.payload,
        authenticated: true,
      }
    case AuthActionType.UNAUTHORIZE_USER:
      return {
        ...state,
        user: undefined,
        token: undefined,
        authenticated: false,
      }
    default:
      return {
        ...state
      };
  }
};

import {Content} from "../interface/content/content.interface";


export const prepareContentForIframe = (selectedContent: Content|null, showTemplates: boolean) => {
  let langCode = '';
  if (!selectedContent) {
    return;
  }
  if (selectedContent.localizations) {
    const langKeys = Object.keys(selectedContent.localizations);
    // use english lang index type (if it exists, else default to first)
    langKeys.find((lang) => lang === 'en') ? (langCode = 'en') : (langCode = langKeys[0]);
  }
  let title = selectedContent.localizations?.[langCode]?.title || selectedContent.localizations?.[langCode]?.name;

  return {
    ...selectedContent,
    headerImg: selectedContent.localizations?.[langCode]?.image,
    header: selectedContent.localizations?.[langCode]?.header,
    name: title,
    title,
    body: selectedContent.localizations?.[langCode]?.description,
    type: selectedContent.type,
    isContentInstance: !showTemplates ? true : false,
    isStarter: selectedContent?.labels?.includes('starter') ? true : false,
    labels: selectedContent?.labels || [],
    data: selectedContent?.data,
    primaryButtonLabel:
      selectedContent?.type === 'concierge-card-buttons' &&
      selectedContent?.buttons?.length !== 0
        ? selectedContent?.buttons?.[0]?.localizations?.[langCode]?.text
        : '',
    secondaryButtonLabel:
      selectedContent?.type === 'concierge-card-buttons' &&
      selectedContent?.buttons?.length !== 0
        ? selectedContent?.buttons?.[1]?.localizations?.[langCode]?.text
        : '',
    details:
      selectedContent?.type === 'concierge-card-buttons'
        ? selectedContent?.details?.localizations?.[langCode]?.body
        : '',
    linkLabel:
      selectedContent?.type === 'concierge-card-link'
        ? selectedContent?.link?.localizations?.[langCode]?.text
        : '',
    unSupportedTitle: selectedContent?.labels?.includes('starter') ? '' : selectedContent?.localizations?.en?.name,
  }
}


export const mergePayloadToContentTemplate = (content:any, template:any) => {

  return {
    ...template,
    content: {
      data: [content.data]
    },
  }
}

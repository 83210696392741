import PluginAPI from "services/api/plugin.api";
import {setPlugins} from "./plugin.action";

export function fetchPluginsThunk() {
  const pluginAPI = new PluginAPI();
  return async (dispatch: any, getState: any) => {
    const { plugin } = getState();
    return new Promise((resolve, reject) => {
      if (plugin.plugins) {
        resolve(plugin.plugins);
      } else {
        pluginAPI.getPlugins().then(plugins => {
          dispatch(setPlugins({ plugins }));
          resolve(plugins);
        }).catch(() => {
          reject();
        })
      }
    })
  }
}

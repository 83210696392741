import React from 'react';

import './HomeIndex.scss';

export default function HomeIndex() {
  return (
    <div className="home">
    </div>
  );
}

import React, {useEffect, useContext} from 'react';
import { PushFormContext } from 'components/PushMessage/PushFormProvider/PushFormProvider';
import { basicFormSchema, basicFormSchemaDraft } from 'validator/push-message.validator';
import useForm from 'hooks/useForm';
import {FlightTextInput, FlightTextArea} from '@flybits/webapp-design-system-react';

interface IProps {
  lang: string;
  index: number;
  optional: boolean;
}

export default function BasicForm(props: IProps) {

  const context: any = useContext(PushFormContext);
  const {data, onFieldChange, onFieldBlur, onFormSubmit} = useForm({
    formData: {
      title: context.formFields?.title?.[props.lang] || '',
      alert: context.formFields?.alert?.[props.lang] || ''
    },
    formSchema: basicFormSchema,
    formSchemaDraft: basicFormSchemaDraft,
    isDraft: context.isDraft || props.optional,
    validateOnLoad: context.validateOnLoad && !props.optional,
  });

  // Notify parent view to support live preview update
  useEffect(() => {
    context.registerValidator(props.lang, onFormSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function updateField(key: string, value: string) {
    onFieldChange(key, value); // update useForm
    context.updateFormField(key, value); // update context
  }

  return (
    <>
      <FlightTextInput
        label="Title"
        type="text"
        hasError={data.title.hasError}
        errorMessage={data.title.error}
        placeholderText="Title"
        width="100%"
        value={data.title.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField('title', e.target.value)}
        onBlur={() => onFieldBlur('title')}
      />
      <FlightTextArea
        label="Message"
        hasError={data.alert.hasError}
        errorMessage={data.alert.error}
        maxLength={170}
        width="100%"
        value={data.alert.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField('alert', e.target.value)}
        onBlur={() => onFieldBlur('alert')}
      />
    </>
  )
}

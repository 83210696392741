import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as NotificationIcon } from 'assets/icons/notification_icon.svg';
import './Notifications.scss';
import PreferencesApi from 'services/api/preferences.api';
import NotificationDropDown from './NotificationDropDown';
import useClickOutsideDetect from 'hooks/useClickOutsideDetect';
import AOApi from 'services/api/ao.api';
import useAsyncState from 'hooks/useAsyncState';
import { useCallback } from 'react';
import { isEmpty } from 'lodash';

interface PreferenceData {
  journeyId: string;
  read: boolean;
  stagingModelStatus: { status: string };
}

export default function Notifications() {
  const [showDropDown, setShowDropDown] = useState(false);
  const modalRef = useRef<any>();
  const preferencesApi = new PreferencesApi();
  const aoAPI = new AOApi();
  const [notificationData, isNotificationDataLoading] = useAsyncState(() =>
    aoAPI.getMetadata().then((res: any) => res.data),
  );

  const [preferencesData] = useAsyncState(() => preferencesApi.getUserPreferences().then((res: any) => res));
  const handleShowDropDown = () => {
    setShowDropDown((showDropDown) => !showDropDown);
  };
  const handleClick = () => {
    setShowDropDown(false);
  };

  useClickOutsideDetect(modalRef, handleClick);
  const [count, setCount] = useState(0);
  const displayNotificationCount = useCallback(
    (readNotifications?: number) => {
      // when user interacts with the messages, update and override local state of count
      if (typeof readNotifications !== 'undefined') {
        setCount(readNotifications);
        return;
      }
      //when no user preferences exist, all messages are unread
      if ((isEmpty(preferencesData?.data?.notifications) || !preferencesData) && notificationData) {
        const _onlyValidNotifications = notificationData.filter(
          (item: PreferenceData) => item.stagingModelStatus.status === 'Completed',
        );
        setCount(_onlyValidNotifications?.length);
        return _onlyValidNotifications?.length;
      }
      //when user preferences exist we must compare the values from notificationData and userPref to show actual 'unread notifications'
      if (preferencesData && notificationData && count === 0) {
        let count = 0;
        notificationData?.map((prefItem: PreferenceData) => {
          let _prefData;
          if (prefItem.stagingModelStatus.status === 'Completed') {
            _prefData = preferencesData?.data?.notifications?.find(
              (_data: PreferenceData) => _data.journeyId === prefItem.journeyId,
            );

            if (_prefData) {
              if (!_prefData.read) {
                count++;
              }
            } else {
              count++;
            }
            return null;
          }
        });
        setCount(count);
        return count;
      }
    },
    // eslint-disable-next-line
    [notificationData, preferencesData],
  );

  useEffect(() => {
    displayNotificationCount();
  }, [displayNotificationCount, notificationData, preferencesData]);

  return (
    <span className="notifications" ref={modalRef}>
      <span onClick={handleShowDropDown}>
        <NotificationIcon className="notifications__icon" />
      </span>
      {!isNotificationDataLoading && count > 0 && (
        <span className="notifications__circle" onClick={handleShowDropDown}>
          {count}
        </span>
      )}
      <span>
        {showDropDown && (
          <NotificationDropDown
            notificationData={notificationData}
            displayCount={displayNotificationCount}
            count={count}
          />
        )}
      </span>
    </span>
  );
}

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ControlBar from 'components/ControlBar/ControlBar';

import MultiStepTemplatedExperienceAPI from 'services/api/multistep-templated-experience.api';
import './MultiStepSelect.scss';

export default function MultiSelect() {

  const history = useHistory();
  const [MultiStepTemplates, setMultiStepTemplates] = useState<any>([]);
  const multiStepTemplatedExperienceAPI = new MultiStepTemplatedExperienceAPI();

  useEffect(() => {
    // Skip this step for now
    multiStepTemplatedExperienceAPI.getTemplates().then(templates => {
      setMultiStepTemplates(templates);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSelectTemplate = (template: any) => {
    history.push(`/multi/templated-experiences/create/${template.id}`);
  };

  const handleCancel = () => {
    history.push('/templated-experiences');
  };

  return (
    <ControlBar
      onCancel={handleCancel}
    >
      <div className="multi-step-selection">
        <div className="multi-step-selection__container">
          <div className="multi-step-selection__container__block">
            <div className='multi-step-selection__container__block__title'> Select a Customer Workflow </div>
            <div className='multi-step-selection__container__block__list'>
              {MultiStepTemplates && MultiStepTemplates.map((template: any, idx: number) => (
                <button className='multi-step-selection__container__block__template-item' key={`template_${idx}`} onClick={() => _handleSelectTemplate(template)}>
                  <div className='multi-step-selection__container__block__template-item__image-box'>
                    <img src={require(`assets/images/${template.icon ? template.icon : 'image-placeholder.jpg'}`)} className='multi-step-selection__container__block__template-item__image-box__img' alt={template.name}/>
                  </div>
                  <div className='multi-step-selection__container__block__template-item__text-box'>
                    <div className='multi-step-selection__container__block__template-item__text-box__title'>{template.name}</div>
                    <div className='multi-step-selection__container__block__template-item__text-box__description'>{template.description}</div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ControlBar>
  )
}
import React from 'react';
import pluralize from 'pluralize';
import './PushInterval.scss';
import { covertSecondsToPeriod } from 'helpers/templated-experience.helper';

//TODO: this component is extracted from FlowBox to be used
// for multi action container, will need to revisit this
export default function PushInterval(props: any) {
  const isDelivery =
    props?.type === 'Push notification' &&
    props?.trigger?.type !== 'broadcast' && (
    props.actionBody?.delivery?.frequency?.value > 1 ||
    props.actionBody?.delivery?.frequency?.period !== 'day' ||
    props.actionBody?.delivery?.frequency?.total ||
    props.actionBody?.delivery?.delay ||
    props.actionBody?.delivery?.limit ||
    props.actionBody?.delivery?.remain);

  let deliveryBody = <div></div>;
  if (props?.actionType === 'push' && props.actionBody?.delivery?.frequency?.value) {
    let periodValue = props.actionBody?.delivery?.frequency?.value;
    let periodName = props.actionBody?.delivery?.frequency?.period;
    periodName =
      props.actionBody?.delivery?.frequency?.value > 1 ? pluralize(periodName) : pluralize.singular(periodName);
    let frequency = `${periodValue} ${periodName}`;
    let messageNumber = 'Send 1 notification ';
    if (props?.trigger?.type === 'triggered') {
      messageNumber = `Max 1 notification `;
    }
    deliveryBody = (
      <div>
        {messageNumber} every {frequency}
      </div>
    );
    let additionalUser = <div></div>;
    if (props?.trigger?.type === 'reminder') {
      additionalUser = (
        <div>
          Allow each user to enter experience{' '}
          {props.actionBody?.delivery?.limit ? props.actionBody?.delivery?.limit : 'unlimited'} time(s)
        </div>
      );
    }
    if (props?.trigger?.type === 'triggered' && props.actionBody?.delivery?.remain) {
      additionalUser = <div>Include users who are part of the audience</div>;
    }

    let periodTotal = props.actionBody?.delivery?.frequency?.total || props.actionBody?.delivery?.limit;
    if (props?.trigger?.type === 'triggered') periodTotal = props.actionBody?.delivery?.limit;
    deliveryBody = (
      <div className="delivery-box__description">
        {deliveryBody}
        <div>{periodTotal ? `Max ${periodTotal}` : 'Unlimited'} notification(s) in total</div>
        {additionalUser}
      </div>
    );
  }
  let deliveryTitle = '';
  if (props?.trigger?.type === 'triggered') {
    deliveryTitle = 'Notification limits';
  }
  if (props?.trigger?.type === 'reminder') {
    deliveryTitle = 'Notification interval';
  }

  let periodValue = covertSecondsToPeriod(props.actionBody?.delivery?.delay)?.value;
  let periodName = covertSecondsToPeriod(props.actionBody?.delivery?.delay)?.period;
  periodName = Math.round(periodValue) > 1 ? periodName : periodName.slice(0, -1);
  let delay = periodValue !== 0 ? <div>Wait {periodValue} {periodName}</div> : null

  return (
    <>
      {isDelivery && (
        <div className="push-interval">
          <div className="push-interval__title">{deliveryTitle || 'Notification limits'}</div>
          <div className="push-interval__body">{deliveryBody}</div>
          {delay && !props?.showDelayOnCanvas && (
          <>        
          <div className="push-interval__delay">Notification delay</div>
          <div className="push-interval__body">{delay}</div>
          </>)}
        </div>
      )}
    </>
  );
}

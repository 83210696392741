import React, { useState, useEffect, useRef } from 'react';
import { FlightSelectSearchable } from '@flybits/webapp-design-system-react';

import './Labels.scss';
import LabelsAPI from 'services/api/labels.api';
import { debounce, sortBy, isEqual } from 'lodash';
import useClickOutsideDetect from 'hooks/useClickOutsideDetect';

interface Options {
  key: number;
  name: string;
}

interface IProps {
  initialValues: string[];
  returnValues: (data: string[]) => void;
  fieldWidth: string;
}

export default function Labels(props: IProps) {
  const { initialValues, returnValues, fieldWidth } = props;
  const labelsAPI = new LabelsAPI();

  const [options, setOptions] = useState<Options[]>([]);
  const [labels, setLabels] = useState<Array<string>>(initialValues);
  const [labelsFromApi, setLabelsFromApi] = useState<Options[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      setOptions([{ key: 0, name: 'Loading..' }]);
    } else if (labelsFromApi.length !== 0) {
      setOptions(labelsFromApi);
    } else if (searchTerm !== '') {
      setOptions([{ key: 0, name: `Add new label "${searchTerm}"` }]);
    } else {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading && labelsFromApi]);

  useEffect(() => {
    returnValues(labels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  useEffect(() => {
    const sanitizeSearchTerm = searchTerm.replace(/[^A-Z0-9]+/gi, '-');
    const addOptionExists = options.find(x => x.name.includes(`Add new label "${sanitizeSearchTerm}"`))
    const exactValueAlsoExists = options.find(x => (x.name === sanitizeSearchTerm))
    if(addOptionExists && exactValueAlsoExists) {
      const _options = options.filter(item => !item.name.includes(`Add new label "${sanitizeSearchTerm}"`)) //remove add option.
      if(!isEqual(_options, options)) {
        setOptions(_options)
      }
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);
  

  const fetchLabelsFromApi = debounce(
    (searchTerm: string) => {
      const sanitizeSearchTerm = searchTerm.replace(/[^A-Z0-9]+/gi, '-');
      setSearchTerm(sanitizeSearchTerm);
      setIsLoading(true);
      labelsAPI.getLabels(searchTerm).then((res: any) => {
        let labelFromApi: Options[] = [];
        res.data.map((responseData: any) => {
          if (!labels.includes(responseData)) {
            labelFromApi.push({ key: Math.random(), name: responseData });
            if(labelFromApi.find(label => label.name.includes(`Add new label`))) {
              return null;
            } else if(labelFromApi.find(label => label.name.indexOf(sanitizeSearchTerm))) {
              labelFromApi.push({ key: Math.random(), name: `Add new label "${sanitizeSearchTerm}"`})
            }
            return labelFromApi = sortBy(labelFromApi, 'name');
          } else {
            return labelFromApi.push({
              key: Math.random(),
              name: `"${responseData}" has already been added to this item.`,
            });
          }
        });
        setLabelsFromApi([]);
        setLabelsFromApi(labelFromApi);
        setIsLoading(false);
      });
    },
    250,
    { maxWait: 1000 },
  );

  const handleSearch = (searchTerm: string) => {
    if (searchTerm === '') {
      return;
    }
    fetchLabelsFromApi(searchTerm);
  };

  const handleDelete = (labelToDelete: string) => {
    const _labels = labels.filter((label) => label !== labelToDelete);
    setLabels(_labels);
  };

  const handleOptionClick = (e: any) => {
    const sanitizedLabel = e.name.match(/(?:"[^"]*"|^[^"]*$)/)[0].replace(/"/g, '');
    if (labels.includes(sanitizedLabel) || sanitizedLabel === 'Loading..') {
      setOptions([]);
      return;
    }
    setLabels([...labels, sanitizedLabel]);
    setOptions([]);
  };

  function handleClearDropdown() {
    setOptions([]);
  }

  const wrapperRef = useRef(null);
  useClickOutsideDetect(wrapperRef, handleClearDropdown);


  return (
    <span ref={wrapperRef}>
      <FlightSelectSearchable
        label="Labels"
        className="labels"
        options={options}
        handleOptionClick={handleOptionClick}
        handleSearch={handleSearch}
        dropdownMaxHeight="120px"
        width={fieldWidth}
      />
      <div className="labels__container">
        {labels.map((label) => {
          return (
            <div key={Math.random()}>
              <span className="labels__container__tabs">
                {label}
                <span className="labels__container__tabs__close" onClick={() => handleDelete(label)}>
                  x
                </span>
              </span>
            </div>
          );
        })}
      </div>
    </span>
  );
}

import React, {useState} from 'react';
import {FlightButton, FlightCheckbox, FlightModal, FlightTextInput} from "@flybits/webapp-design-system-react";
import InfiniteScroll from "react-infinite-scroller";
import {CircularProgress} from "@material-ui/core";
import {startCase} from "lodash";
import {Attribute} from 'interface/attribute.interface';
import './AttributesModal.scss';

interface IProps {
  attributes?: Array<Attribute>
  isVisible?: boolean
  icon?: string
  title?: string
  content?: object
  statusMessage?: object
  cancelButton?: {
    name?: string
    disabled?: boolean
    onClick: () => void
  }
  primaryButton?: {
    name?: string
    disabled?: boolean
    onClick: (attributes: Attribute[]) => void
  }
}


export default function AttributesModal(props: IProps) {

  const [searchText, setSearchText] = useState<string>('');
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  React.useEffect(() => {
    setAttributes(props?.attributes!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props?.attributes]);

  const handleCheckBoxClick = (attribute: Attribute) => {
    let updatedAttributes:Array<Attribute> = [];
    attributes?.forEach((attr: any) => {
      if (attr.feature === attribute.feature) {
        updatedAttributes.push({...attr, isRegressor: !attr.isRegressor});
      } else {
        updatedAttributes.push(attr);
      }
    });
    props.primaryButton!.disabled = false;
    setAttributes(updatedAttributes);
  }

  function ListItem(attribute: Attribute, idx: number) {
    let attrLength = attribute?.feature?.split('.');
    let categoryName = attrLength?.[2]! || '';
    let attrName = attrLength?.[3]! || '';
    if(attrName === 'query') {
      attrName = attrLength?.[4]! || '';
    }

    return (
      <div className="attributes-modal__item" key={`attr_$${idx}`} onClick={() => handleCheckBoxClick(attribute)}>
        <FlightCheckbox
          checkState={(attribute?.isRegressor) ? 'SELECTED' : 'UNSELECTED'}
          className={'attributes-modal__checkbox'}
          onSelect={() => {}}
        />
        <span className="analytics-performance-optimized-chart__table__attribute">
          {startCase(categoryName)} / <strong>{startCase(attrName)}</strong>
        </span>
      </div>
    )
  }

  function updateSearchText(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
    let filteredAttributes = props.attributes?.filter((attr: Attribute) => {
      let attrLength = attr.feature.split('.');
      let attrName = attrLength[attrLength.length - 1].toLowerCase();
      if(attrName.includes(e.target.value))
        return attr;
    });
    setAttributes(filteredAttributes!);
  }


  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={() => props.cancelButton?.onClick()}
      header={
        <div className="attributes-modal__header">
          {props.icon && (
            <img src={require(`assets/icons/${props.icon}`)} className="confirm-modal__header-icon" alt="modal icon"/>
          )}
          <h3> {props.title} </h3>
        </div>
      }
      content={
        <div className="attributes-modal">
          <div className="attributes-modal__content">
            <FlightTextInput
              width="100%"
              iconInput="search"
              value={searchText}
              hasClearIcon={true}
              onChange={updateSearchText}
            />
            <div className="attributes-modal__rule-list-container">
              {!!attributes?.length && <InfiniteScroll
                className="attributes-modal__rule-list-container__infinite-scroll"
                pageStart={0}
                initialLoad={false}
                loadMore={()=>{}}
                hasMore={attributes?.length > (attributes?.length || 0)}
                threshold={20}
                useWindow={false}
                loader={<div className="audience-searchable-modal__rule-list-container__loader" key={0}>
                  <CircularProgress size={20}/>
                  <span className="audience-searchable-modal__rule-list-container__loader-text">Loading...</span>
                </div>}
              >
                {attributes.map((attribute: Attribute, idx: number) => {
                  return ListItem(attribute, idx);
                })}
              </InfiniteScroll>}
              {!attributes?.length && (
                <div>No attributes found</div>
              )}
            </div>
          </div>
        </div>
      }
      footer={
        <div className="attributes-modal__footer">
          {props.statusMessage! && <div className="attributes-modal__status-message">{props.statusMessage}</div>}
          <div className="attributes-modal__buttons">
            {props.cancelButton! && (
              <FlightButton
                theme="secondary"
                onClick={() => props.cancelButton?.onClick()}
                label={props.cancelButton?.name}
              />
            )}
            {props.primaryButton! && (
              <FlightButton
                onClick={() => props.primaryButton?.onClick(attributes)}
                disabled={props.primaryButton?.disabled}
                label={props.primaryButton?.name}
              />
            )}
          </div>
        </div>
      }
    />
  )
}

import Storage from './storage'
import store from '../redux/store';
import { AuthActionType } from 'redux/auth/auth.type';

class Session {
  private storage: Storage;
  constructor() {
    this.storage = new Storage();
  }

  getToken(tenantIdFromParams?: string): Promise<any> {
    return this.storage.getItem(`${tenantIdFromParams}+token`);
  }

  setToken(tenantId: string, token: string): Promise<any> {
    return this.storage.setItem(`${tenantId}+token`, token);
  }

  async removeToken(): Promise<any> {
    const tenantId = window?.location?.pathname?.split('/')[2]
    await this.storage.removeItem(`${tenantId}+token`);
  }

  async unauthorizeUser() {
    await this.removeToken();
    store.dispatch({ type: AuthActionType.UNAUTHORIZE_USER });
  }
}

export default Session;

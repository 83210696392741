import { stringRepFinal } from 'helpers/rule.helper';
import { debounce, delay, isEmpty, truncate } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './ComplexRulePreview.scss';
import ReactHtmlParser from 'react-html-parser';
import RuleAPI from 'services/api/rule.api';
const ruleAPI = new RuleAPI();
interface IProps {
  ruleName: string;
  showAsModal: boolean;
  journeyRule: any;
  plugins?: any;
}


export default function ComplexPreview(props: IProps) {
  const [isActive, setIsActive] = useState(false);
  const [ruleDataPreview, setRuleDataPreview] = useState<any>(undefined);
  const [_rule, _setRule] = useState<any>(undefined);
  const [_rules, _setRules] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const _idx = useRef();

  const getRule = useCallback(
    debounce((_ruleId: string) => {
      ruleAPI.getRule(_ruleId).then((res: any) => {
        _setRule(res);
        delay(() => {
          setIsLoading(false)
        }, 1000)
      }).catch(() => {
          setIsLoading(false)
      });
    }, 200),
    []
  );

  const getRules = useCallback(
    debounce(() => {
      ruleAPI.getRules(0, 10000).then((res: any) => {
        _setRules(res.data);
        delay(() => {
          setIsLoading(false)
        }, 1000)
      });
    }, 200),
    []
  );


  useEffect(() => {
    if (!isActive) return;
    // rule already exists, use affectBy to grab headname, find rule ID and fetch rule.
    const _indexOfRule = props.journeyRule?.affectedByHeadNames?.indexOf(props?.ruleName?.[0]?.toString());
    if (_idx.current !== _indexOfRule && isEmpty(_rule)) {
      _idx.current = _indexOfRule;
      const _ruleId = props.journeyRule.affectedBy[_indexOfRule];
        if (_ruleId) {
          setIsLoading(true);
          getRule(_ruleId)
      }
    }
    // this rule was just added, so its not a part of the journey rule, we need to filter from list.
    if(((_indexOfRule !== 0  && !_indexOfRule) || _indexOfRule === -1) && props?.ruleName?.[0]?.toString()) {
      setIsLoading(true);
       getRules();
    }

    //eslint-disable-next-line
  }, [props?.ruleName?.[0], isActive]);

  useEffect(() => {
    if (isEmpty(_rules) || !isActive) return;
    const _filteredRule = _rules.find((item: { head: { name: string; }; }) => item?.head?.name === props?.ruleName?.[0]?.toString());
    const strRep = _filteredRule?.stringRepresentation?.split(':-')[1];
    stringRepFinal(strRep, _filteredRule, props.plugins).then((r: any) => {
      setRuleDataPreview(r);
    });
    //eslint-disable-next-line
  }, [_rules, isActive])

  useEffect(() => {
    if (isEmpty(_rule) || !isActive) return;
    const strRep = _rule?.stringRepresentation?.split(':-')[1];
    stringRepFinal(strRep, _rule, props.plugins).then((r: any) => {
      setRuleDataPreview(r);
    });
    //eslint-disable-next-line
  }, [_rule, isActive]);

  return (
    <div
      className="complex-rule-preview"
      onMouseOver={() => (!isActive &&  setIsActive(true))}
      onMouseLeave={() => (isActive && setIsActive(false))}
    >
      <div className={isActive? `complex-rule-preview__trigger--active` : `complex-rule-preview__trigger`}>
        <u> {truncate(props.ruleName, { length: 35, omission: '...' })} </u>
      </div>
      {isActive && (
        <ComplexPreviewBody ruleDataPreview={ruleDataPreview} showAsModal={true} isLoading={isLoading} ruleName={props.ruleName} />
      )}
    </div>
  );
}


const ComplexPreviewBody = ({ ruleName, ruleDataPreview, isLoading }: any) => {
  const ruleNameVerySmall = ruleName?.[0]?.toString()?.length < 30;
  return (
    <div className={`complex-rule-preview__body ${ruleNameVerySmall && 'complex-rule-preview__body--more-left'}`}>
      <div className="complex-rule-preview__body__info">
        {ruleDataPreview?.predicates?.length === 1 && (
          <span>
            {ReactHtmlParser(ruleDataPreview?.predicates?.[0].arguments?.[0])}{' '}
            {ruleDataPreview?.predicates?.[0]?.predicate} &nbsp;{' '}
            <b> {ruleDataPreview?.predicates?.[0]?.arguments?.[1]} </b>{' '}
          </span>
        )}
        {ruleDataPreview?.predicates?.length > 1 && (`Conditions ${ruleDataPreview.toString}`)}
        {ruleDataPreview?.predicates?.length > 1 &&
        ruleDataPreview?.predicates.map(
          (item: any, i: number) => {
            return (
              <div key={i} style={{marginBottom: 5}}>
                <b>{i+1}.</b> {(item.arguments?.[0])} {item?.predicate} <b> {item?.arguments?.[1]} </b>
                {item?.[0]}
              </div>
            );
          },
        )}
        {isEmpty(ruleDataPreview?.predicates) && <span>{isLoading ? 'Loading preview...' : 'Preview currently unavailable'}</span>}
      </div>
    </div>
  );
}

import React from 'react';
import './MiniBox.scss';

interface IProps {
  icon: any;
  title: string;
  body: any;
  color?: {
    title?: string;
    background?: string;
  };
  onClick?: () => void;
}

enum renderMiniBoxLightBg {
  'Trigger',
  'Delay',
  'Audience',
  'Action',
}

export default function MiniBox(props: IProps) {
  const cssClass = props.title in renderMiniBoxLightBg ? 'minibox-content--lightbg' : 'minibox-content--darkbg';
  const colorMePurple = props.title === 'Trigger';
  const colorMePurpleAgain = props.title === 'Delay';
  const colorMeGreen = props.title === 'Audience';
  const colorMeBlue = props.title === 'Action';
  const titleColor = () => {
    if (colorMePurple || colorMePurpleAgain) {
      return '#5123B3';
    } else if (colorMeGreen) {
      return '#00882F';
    } else if (colorMeBlue) {
      return '#185987';
    } else {
      return '';
    }
  };
  return (
    <div className="minibox">
      <div className={cssClass} onClick={props.onClick}>
        <header>
          <img src={props.icon} alt="boxIcon" />
          <div className="title" style={{ color: titleColor() }}>
            {props.title}
          </div>
        </header>
        <section>{props.body}</section>
      </div>
    </div>
  );
}

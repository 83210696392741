import React, { useState, useEffect } from 'react';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
// icons required
import PushIcon from 'assets/icons/push.svg';
import { ReactComponent as ReturnArrow } from 'assets/icons/return_arrow.svg';
import { ReactComponent as DeviceIcon } from 'assets/icons/device.svg';
import { ReactComponent as WebIcon } from 'assets/icons/icon_web.svg';
import { ReactComponent as InApp } from 'assets/icons/in-app.svg';
import { ReactComponent as CustomIcon } from 'assets/icons/custompush.svg';

import PushEditModal from 'components/Modal/EditModal/PushEditModal/PushEditModal';
import MultiActionPushPreview from 'components/PushMessage/PreviewPush/MultiActionPushPreview';

import { getPushType } from 'helpers/templated-experience.helper';
import MultiActionContainer from 'components/Shared/MultiActionContainer/MultiActionContainer';
import MultiActionArrowIcon from 'assets/icons/multiactionarrow.svg';
import MiniCard from 'components/Shared/MiniCard/MiniCard';
import PushInterval from 'components/PushMessage/PushInterval/PushInterval';
import ContentBox from 'components/FlowBox/ContentBox/ContentBox';

import { MiniCardContent } from 'components/Content/MiniCardContent/MiniCardContent';
import { useDispatch } from 'react-redux';

import ContentAPI from 'services/api/content.api';
import { Content } from 'interface/content/content.interface';
import './PushBox.scss';
import DelayBox from 'components/FlowBox/DelayBox/DelayBox';
import { useSelector } from 'react-redux';
import { fetchPushTypesThunk } from 'redux/templated-experience/templated-experience.thunk';
import { isEmpty } from 'lodash';

interface ContentPreview {
  content?: Content;
}

enum StandardPushTypes {
  'basic',
  'weblink',
  'content',
}

export default function PushBox(props: any) {
  const [isPushEditModalVisible, setIsPushEditModalVisible] = useState(false);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const templatedExperience = !isEmpty(reduxTemplatedExperienceState.template)
  ? reduxTemplatedExperienceState.template
  : reduxTemplatedExperienceState.instance;
  const isLocked = templatedExperience.status === 'Active';
  const [openContentModal, setOpenContentModal] = useState(false);
  const [content, setContent] = useState<ContentPreview>();
  const dispatch = useDispatch();
  const [customNotifName, setCustomNotifName] = useState('');
  const contentAPI = new ContentAPI();

  function onCancelHandle() {
    setIsPushEditModalVisible(false);
  }

  function onApplyHandle(form: any, type: any, pushTypeId: string) {
    switch (type) {
      case 'basic':
        reduxTemplatedExperienceAction.setBasicPush(props, form);
        setIsPushEditModalVisible(false);
        break;
      case 'weblink':
        reduxTemplatedExperienceAction.setWebLinkPush(props, form, pushTypeId);
        setIsPushEditModalVisible(false);
        break;
      case 'content':
        reduxTemplatedExperienceAction.setContentPush(props, form, pushTypeId);
        setTimeout(() => setIsPushEditModalVisible(false), 100);
        break;
      default:
        reduxTemplatedExperienceAction.setCustomPush(props, form, pushTypeId);
        setIsPushEditModalVisible(false);
        break;
    }
  }

  const pushType = getPushType(props);
  const fetchTypes = async () => {
    dispatch(fetchPushTypesThunk());
    const customPushType = await reduxTemplatedExperienceState?.pushTypes?.find((x: any) => x.id === pushType);
    setCustomNotifName(customPushType?.name);
    return customPushType?.name;
  };

  const RenderPushType = () => {
    pushType in StandardPushTypes || fetchTypes(); //get pushTypeName in redux only for customPush
    switch (pushType) {
      case 'weblink':
        return (
          <span className="multi-push-preview__push-type">
            <WebIcon className="multi-push-preview__push-type__type-svg" /> Weblink{' '}
          </span>
        );
      case 'content':
        return (
          <span className="multi-push-preview__push-type">
            <InApp className="multi-push-preview__push-type__type-svg" />
            <span className="multi-push-preview__push-type__type-svg__type-content">In-app content</span>
          </span>
        );
      case 'basic':
        return (
          <span className="multi-push-preview__push-type">
            <DeviceIcon className="multi-push-preview__push-type__type-svg" /> App home screen
          </span>
        );
      default:
        return (
          <span className="multi-push-preview__push-type">
            <CustomIcon className="multi-push-preview__push-type__type-svg-link" /> {customNotifName}
          </span>
        );
    }
  };

  function MultiPushPreview() {
    const title = props!!.actionBody?.body[0]?.payload?.title?.en;
    const message = props!!.actionBody?.body[0]?.payload?.alert?.en;
    const keys =
      props!!.actionBody?.body[0]?.payload?.data &&
      Object.keys(props!!.actionBody?.body[0]?.payload?.data).filter((item: string) => item !== 'pushPayloadTypeId');
    const values = keys && props!!.actionBody?.body[0]?.payload?.data;
    const openContentModalDirectly = (e: any) => {
      if (pushType === 'content') {
        e.stopPropagation();
        setOpenContentModal(true);
      }
    };

    const hideCustomPushTypes = () => {
      return keys && values && pushType in StandardPushTypes;
    };

    return (
      <div className={!isLocked ? 'multi-push-preview' : 'multi-push-preview multi-push-preview--locked'}>
        <div className="multi-push-preview__message">{message}</div>
        <MultiActionPushPreview
          title={title}
          message={message}
          placement="box"
          onClick={() => setIsPushEditModalVisible(true)}
        />
        <PushInterval {...props} type="Push notification" showDelayOnCanvas={true} />
        {(content || title) && (
          <div className="multi-push-preview__subject">
            <ReturnArrow style={{ marginRight: 5, marginTop: 12 }} />
            <span className="multi-push-preview__subject__leads">Leads to : {''}</span>
            <span className="multi-push-preview__push-type">{RenderPushType()}</span>
            {pushType === 'weblink' && (
              <div className="multi-push-preview__push-type__link" onClick={openContentModalDirectly}>
                {props!!.actionBody?.body[0]?.payload?.data?.url?.en || 'Add Weblink'}
              </div>
            )}
            {pushType === 'content' && (
              <div className="multi-push-preview__push-type__push-content" onClick={openContentModalDirectly}>
                <MiniCardContent {...content} hideCollapse={true} />
              </div>
            )}
            {!hideCustomPushTypes() && (
              <div className="multi-push-preview__push-type__link" onClick={openContentModalDirectly}>
                <span className="multi-push-preview__push-type">
                  {!isEmpty(keys) &&
                    keys?.map(
                      (item: string) =>
                        values[item] !== '' && (
                          <div key={item}>
                            {item}: {values[item]}
                          </div>
                        ),
                    )}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function showPushModal() {
    if (!props.isNotEditable) {
      setIsPushEditModalVisible(true);
    }
  }

  const RenderMiniCards = () => {
    return (
      <MiniCard title={'Push notification'} icon={PushIcon} type="push" onClick={showPushModal}>
        {MultiPushPreview()}
      </MiniCard>
    );
  };

  const onApply = () => {
    setOpenContentModal(false);
  };

  const fetchContentInstance = () => {
    const id = props!!.actionBody?.body[0]?.payload?.data?.contentId?.value;
    if (id) {
      contentAPI.getInstance(id).then((res: any) => {
        setContent({ content: res });
      });
    }
  };

  const handleRequestUpdatedInstance = () => {
    fetchContentInstance();
  };

  useEffect(() => {
    if (props!!.actionBody?.body[0]?.payload?.data?.contentId?.value !== '') {
      fetchContentInstance();
    }
    // fetch instance only if contentId has been set
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props!!.actionBody?.body[0]?.payload?.data?.contentId?.value]);

  const handleCancel = () => {
    setTimeout(() => setOpenContentModal(false), 100);
    setIsPushEditModalVisible(true);
  };

  const onTriggerContentModal = (form: any, type: any, typeID: string) => {
    onApplyHandle(form, type, typeID);
    setOpenContentModal(true);
  };

  return (
    <React.Fragment>
      {props.actionType === 'push' && !!props.actionBody?.delivery?.delay && (
        <DelayBox {...props} onBoxClick={showPushModal} />
      )}
      <div>
        <MultiActionContainer {...props} title="Actions" icon={MultiActionArrowIcon} hideCollapse>
          <RenderMiniCards />
        </MultiActionContainer>
      </div>
      {isPushEditModalVisible && (
        <PushEditModal
          action={props}
          amplified={false}
          isVisible={isPushEditModalVisible}
          triggerContentModal={onTriggerContentModal}
          onApply={onApplyHandle}
          onCancel={onCancelHandle}
        />
      )}
      <ContentBox
        {...props}
        openModal={openContentModal}
        modalClosed={handleCancel}
        onApply={onApply}
        isCalledFromPush
        contentInstance={{}}
        hideBox
        requestUpdatedInstance={handleRequestUpdatedInstance}
      />
    </React.Fragment>
  );
}

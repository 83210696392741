import React, { useState, useContext } from "react";
import { FlightTextInput, FlightButton, FlightDropdown, FlightOverflowMenu } from "@flybits/webapp-design-system-react";
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import LineIcon from "assets/icons/line.svg";
import ArrowIcon from 'assets/icons/arrow.svg';

import './FlowBoxV2.scss';

interface IProps {
  type: 'entry' | 'exit' | 'action';
  body: any; // content of the box
  step?: any;
  onBoxClick?: () => void;
}

export default function FlowBoxV2(props: IProps) {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const multiStepContext: any = useContext(MultiStepContext);
  const isEntry = props?.type === 'entry'; // entry box (first box)

  function onAddHandle() {
    isEntry
      ? multiStepContext.prependStep()
      : multiStepContext.appendStep(props.step.id);
    setIsDropdownOpen(false);
  }

  function onBranchHandle() {
    isEntry
      ? multiStepContext.branchEntryStep()
      : multiStepContext.branchActionStep(props.step.id);
    setIsDropdownOpen(false);
  }

  const trigger = (
    <FlightButton iconLeft="add" isPropagateUpperActions onClick={() => {}} label=''/>
  )

  const actionDropdown: any = [[{
    key: 1,
    name: 'Remove',
    disabled: false,
    onClick: () => { multiStepContext.removeStep(props.step.id) }
  }]]

  return (
    <div className="flowbox-v2">
      <div
        className={'flowbox-v2__main ' + props.step?.id}
        onClick={props.onBoxClick}
      >
        <header>
          {props.type === 'entry'
            ? "Entry Criteria"
              : props.type === 'exit'
                ? "Complete!"
                : <FlightTextInput value={props.step.name} onChange={(e: any) => multiStepContext.updateStepName(props.step.id, e.target.value)}/>
          }
          {/* Optional Menu */}
          {props.type === 'action' &&
            <FlightOverflowMenu
              direction={'bottom'}
              isOpenRight={true}
              disabled={false}
              optionGroups={actionDropdown}
            />
          }
        </header>
        <section>
          {props.body}
        </section>
      </div>
      {/* Display if and only if this is not the last step*/}
      {props.type !== 'exit' && (
        <div className="flowbox-v2__bridge">
          <img src={LineIcon} alt="LineIcon" />
          <FlightDropdown
            trigger={trigger}
            isActive={isDropdownOpen}
            handleTriggerClick={() => setIsDropdownOpen(true)}
            handleClickOutside={() => {}}
            maxHeight="300px"
            maxWidth="200px"
            direction="bottom"
          >
            <div className="flowbox-v2__bridge-dropdown">
              <p onClick={() => onAddHandle()}> Add Step </p>
              <hr/>
              <p onClick={() => onBranchHandle()}> Branch Step </p>
            </div>
          </FlightDropdown>
          <img src={ArrowIcon} alt="ArrowIcon" />
        </div>
      )}
    </div>
  );
}

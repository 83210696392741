
import React, { useState } from 'react';
import ExperiencePerformanceGraph from 'components/Performance/ExperiencePerformance/ExperiencePerformanceGraph';
import { LineSeries } from 'react-vis';
import { useSelector } from 'react-redux';
import { GraphData, LegendGraphData, LineGraph, LineGraphData } from 'interface/templated-experience/graph-data.interface';
import ErrorState from '../ErrorState/ErrorState';
import LoadingState from '../LoadingState/LoadingState';

const JOURNEY_TYPES_METRICS: Record<string, LineGraph[]> = {
  IN_APP_CONTENT: [{
    id: 'targetAudience',
    data: [],
    label: 'Audience Targeted',
    color: '#7C4DFF',
    className: 'left-color left-color-ta',
  }, {
    id: 'contentViews',
    data: [],
    label: 'Viewed Content',
    color: '#16D892',
    className: 'left-color left-color-op',
  }, {
    id: 'contentClicks',
    data: [],
    label: 'Clicked On Content',
    color: '#EC2758',
    className: 'left-color left-color-coc',
  }],
  IN_APP_MULTI_CONTENT: [{
    id: 'targetAudience',
    data: [],
    label: 'Audience Targeted',
    color: '#7C4DFF',
    className: 'left-color left-color-ta',
  }],
  TRIGGERED_PUSH: [{
    id: 'targetAudience',
    data: [],
    label: 'Audience Targeted',
    color: '#7C4DFF',
    className: 'left-color left-color-ta',
  }, {
    id: 'pushDelivered',
    data: [],
    label: 'Push Delivered',
    color: '#3AC6FF',
    className: 'left-color left-color-pd',
  }, {
    id: 'openedPush',
    data: [],
    label: 'Opened Push',
    color: '#16D892',
    className: 'left-color left-color-op',
  }],
  TRIGGERED_PUSH_CONTENT: [{
    id: 'targetAudience',
    data: [],
    label: 'Audience Targeted',
    color: '#7C4DFF',
    className: 'left-color left-color-ta',
  }, {
    id: 'pushDelivered',
    data: [],
    label: 'Push Delivered',
    color: '#3AC6FF',
    className: 'left-color left-color-pd',
  }, {
    id: 'openedPush',
    data: [],
    label: 'Opened Push',
    color: '#16D892',
    className: 'left-color left-color-op',
  }, {
    id: 'contentClicks',
    data: [],
    label: 'Clicked On Content',
    color: '#EC2758',
    className: 'left-color left-color-coc',
  }],
  IN_APP_AMPLIFIED_CONTENT: [{
    id: 'targetAudience',
    data: [],
    label: 'Audience Targeted',
    color: '#7C4DFF',
    className: 'left-color left-color-ta',
  }, {
    id: 'pushDelivered',
    data: [],
    label: 'Push Delivered',
    color: '#3AC6FF',
    className: 'left-color left-color-pd',
  }, {
    id: 'contentViews',
    data: [],
    label: 'Viewed Content',
    color: '#16D892',
    className: 'left-color left-color-op',
  }, {
    id: 'contentClicks',
    data: [],
    label: 'Clicked On Content',
    color: '#EC2758',
    className: 'left-color left-color-coc',
  }],
  IN_APP_AMPLIFIED_MULTI_CONTENT: [{
    id: 'targetAudience',
    data: [],
    label: 'Audience Targeted',
    color: '#7C4DFF',
    className: 'left-color left-color-ta',
  }, {
    id: 'pushDelivered',
    data: [],
    label: 'Push Delivered',
    color: '#3AC6FF',
    className: 'left-color left-color-pd',
  }, {
    id: 'openedPush',
    data: [],
    label: 'Opened Push',
    color: '#16D892',
    className: 'left-color left-color-op',
  }],
};

export default function ExperiencePerformance(props: { journeyType: string }) {
  const [hoveredNode, setHoveredNode] = useState<GraphData>({x:0,y:0});
  const reduxAnalyticsState = useSelector((state: any) => state.analytics.analyticsPerformance);
  // loading state and error state
  if (!!reduxAnalyticsState.errorMessage) {
    return (
      <ErrorState errorMessage={reduxAnalyticsState.errorMessage} />
    );
  } else if (reduxAnalyticsState.isContentClicksLoading || reduxAnalyticsState.isContentViewsLoading ||
    reduxAnalyticsState.isOpenedPushLoading || reduxAnalyticsState.isPushDeliveredLoading ||
    reduxAnalyticsState.isTargetAudienceLoading) {
      return <LoadingState />;
  }

  let experiencePerformanceData = JOURNEY_TYPES_METRICS[props.journeyType];
  // populate experiencePerformanceData data with redux values
  experiencePerformanceData = experiencePerformanceData.map((metric) => {
    return {
      ...metric,
      data: reduxAnalyticsState[metric.id]?.data || [],
    };
  });

  const legendGraphData: LegendGraphData[] = [];
  const lineGraphData: any[] = [];
  let xAxisLabelData:number[] = [];
  let emptyGraphValue = true;
  experiencePerformanceData.forEach((experiencePerformanceItem: LineGraph, index: number) => {
    const lineGraphDataArray = experiencePerformanceItem.data;
    let lineGraphPlotValue: GraphData[] = [];
    if (lineGraphDataArray) {
      lineGraphPlotValue = lineGraphDataArray.map((lineDataValue: LineGraphData, innerIndex: number) => {
         return ({ x: innerIndex, y: lineDataValue.value });
      });
      const emptyGraphData = lineGraphDataArray.find((d: LineGraphData) => (d.value));
      if(emptyGraphData){
        emptyGraphValue = false;
      }
    } else {
        emptyGraphValue = false;
    }

    legendGraphData.push({ title: experiencePerformanceItem.label,
      color: experiencePerformanceItem.color });
    lineGraphData.push(<LineSeries key={index}
        onNearestXY={(value: GraphData) => setHoveredNode(value)}
        style={{ fill: 'none',strokeWidth: '2px' }}
        data={lineGraphPlotValue}
        color={experiencePerformanceItem.color}
        className={experiencePerformanceItem.className}
      />)
  });

  if (experiencePerformanceData[0] && experiencePerformanceData[0].data) {
    xAxisLabelData = experiencePerformanceData[0].data.map((lineDataValue:LineGraphData, innerIndex:number) => {
      return (lineDataValue.timestamp);
    });
  }

  return (
    <div className="experience-performance-container">
      <ExperiencePerformanceGraph
        legendGraphData={legendGraphData}
        lineGraphData={lineGraphData}
        hoveredNode={hoveredNode}
        xAxisLabelData={xAxisLabelData}
        emptyGraph={emptyGraphValue}
      />
    </div>
  )
}


import React from 'react';
import { useSelector } from 'react-redux';
import { numberWithCommas } from "helpers/templated-experience.helper";
import LoadingState from '../LoadingState/LoadingState';
import ExperienceFlowGraph from 'components/Performance/ExperienceFlow/ExperienceFlowGraph';

import ErrorState from '../ErrorState/ErrorState';

export default function ExperienceFunnel(props: { journeyType: string }) {
  const reduxAnalyticsState = useSelector((state: any) => state.analytics.analyticsFunnel);
  // loading state and error state
  if (!!reduxAnalyticsState.errorMessage) {
    return (
      <ErrorState errorMessage={reduxAnalyticsState.errorMessage} />
    );
  } else if (reduxAnalyticsState.isTargetAudienceLoading || reduxAnalyticsState.isPushSentLoading ||
    reduxAnalyticsState.isOpenedPushLoading || reduxAnalyticsState.isPushDeliveredLoading ||
    reduxAnalyticsState.isClickedOnContentFromPushLoading || reduxAnalyticsState.isViewedContentWithoutPushDeliveredLoading ||
    reduxAnalyticsState.isEngagedContentWithoutPushOpenedLoading || reduxAnalyticsState.isViewedContentWithoutPushOpenedLoading) {
      return <LoadingState size="large" />;
  }

  const viewedContentValue = (reduxAnalyticsState.viewedContentWithoutPushDelivered?.value + reduxAnalyticsState.viewedContentWithoutPushOpened?.value) || 0;
  const experienceFlowTitle = {
    targetAudience: `${numberWithCommas(reduxAnalyticsState.targetAudience?.value || 0)} (${reduxAnalyticsState.targetAudience?.value ? '100%' : '0%'})`,
    pushSent: `${numberWithCommas(reduxAnalyticsState.pushSent?.value || 0)} (${((reduxAnalyticsState.pushSent?.value || 0) / (reduxAnalyticsState.targetAudience?.value || 0) * 100 || 0).toFixed(0)}%)`,
    pushDelivered: `${numberWithCommas(reduxAnalyticsState.pushDelivered?.value || 0)} (${((reduxAnalyticsState.pushDelivered?.value || 0) / (reduxAnalyticsState.targetAudience?.value || 0) * 100 || 0).toFixed(0)}%)`,
    openedPush: `${numberWithCommas(reduxAnalyticsState.openedPush?.value || 0)} (${((reduxAnalyticsState.openedPush?.value || 0) / (reduxAnalyticsState.targetAudience?.value || 0) * 100 || 0).toFixed(0)}%)`,
    clickedOnContent: `${numberWithCommas((reduxAnalyticsState.clickedOnContentFromPush?.value + reduxAnalyticsState.engagedContentWithoutPushOpened?.value) || 0)}
    (${(((reduxAnalyticsState.clickedOnContentFromPush?.value + reduxAnalyticsState.engagedContentWithoutPushOpened?.value) || 0) / (reduxAnalyticsState.targetAudience?.value || 0) * 100 || 0).toFixed(0)}%)`,
    viewedContent: `${numberWithCommas(viewedContentValue)} (${(viewedContentValue / (reduxAnalyticsState.targetAudience?.value || 0) * 100 || 0).toFixed(0)}%)`,
  };
  const experienceFlowNodes = [
    {name: experienceFlowTitle.targetAudience, color: '#A999FF', opacity: 1, hintValue: 'Target Audience'},
    {name: experienceFlowTitle.pushSent, color: '#7CB4FF', opacity: 1, hintValue: 'Push Sent'},
    {name: experienceFlowTitle.pushDelivered, color: '#72DAFF', opacity: 1, hintValue: 'Push Delivered'},
    {name: experienceFlowTitle.openedPush, color: '#50E8BB', opacity: 1, hintValue: 'Opened Push'},
    {name: experienceFlowTitle.viewedContent, color: '#FEE961', opacity: 1, hintValue: 'Viewed Content (from in-app)'},
    {name: experienceFlowTitle.clickedOnContent, color: '#F65D7E', opacity: 1, hintValue: 'Clicked on Content'},
    {name: "", color: '#F65D7E', opacity: 0},
    {name: "", color: '#F65D7E', opacity: 0},
  ];
  const experienceFlowLinks = [
      {source: 0, target: 6, value: (reduxAnalyticsState.targetAudience?.value - reduxAnalyticsState.pushSent?.value - reduxAnalyticsState.viewedContentWithoutPushDelivered?.value) || 0, color: '#FFFFFF', opacity: 0},
      {source: 6, target: 7, value: 0, color: '#FFFFFF', opacity: 0},
      {source: 0, target: 1, value: reduxAnalyticsState.pushSent?.value || 0, color: '#E9E7FF', opacity: 0.75},
      {source: 1, target: 2, value: reduxAnalyticsState.pushDelivered?.value || 0, color: '#DDECFF', opacity: 0.75},
      {source: 2, target: 3, value: reduxAnalyticsState.openedPush?.value || 0, color: '#DAF6FF', opacity: 0.75},
      {source: 2, target: 4, value: reduxAnalyticsState.viewedContentWithoutPushOpened?.value || 0, color: '#DAF6FF', opacity: 0.75},
      {source: 3, target: 5, value: reduxAnalyticsState.clickedOnContentFromPush?.value || 0, color: '#DEFCF5', opacity: 0.75},
      {source: 4, target: 5, value: reduxAnalyticsState.engagedContentWithoutPushOpened?.value || 0, color: '#FDF9D4', opacity: 0.75},
      {source: 0, target: 4, value: reduxAnalyticsState.viewedContentWithoutPushDelivered?.value || 0, color: '#E9E7FF', opacity: 0.75},
    ];

    return (
      <div className="experience-flow-container">
        <ExperienceFlowGraph
          experienceFlowNodes={experienceFlowNodes}
          experienceFlowLinks={experienceFlowLinks}
        />
      </div>
    )

}

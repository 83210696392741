import React, { useEffect, useState } from 'react';
import { Position } from 'react-flow-renderer';
import Handler from 'components/Journey/Handler/Handler';
import { StepTrigger, StepWrapper } from 'interface/journey.interface';
import { getTriggerPosition } from 'helpers/journey.helper';
import { FlightTooltip, getIcon } from '@flybits/webapp-design-system-react';
import { stringRepFinal } from 'helpers/rule.helper';
import { useSelector } from 'react-redux';
import LocationPreview from 'components/AudiencePreview/LocationPreview/LocationPreview';
import ComplexPreview from 'components/AudiencePreview/ComplexRulePreview/ComplexRulePreview';
import RuleAPI from 'services/api/rule.api';
import './Audience.scss';
import LockIcon from 'assets/icons/icons8-lock.svg';
import LockIconOutline from 'assets/icons/lock-outline.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen-no-fill.svg';
import { isEmpty } from 'lodash';


export default function Audience(step:StepWrapper = {}) {

  let className = 'audience';
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const experience = !isEmpty(reduxTemplatedExperienceState.template)
    ? reduxTemplatedExperienceState.template
    : reduxTemplatedExperienceState.instance;
  const audience = experience?.steps?.find((step: any) => step.type === 'audience');
  const plugins = useSelector((state: any) => state.plugin?.plugins);
  const [ruleData, setRuleData] = useState<any>(undefined);
  const [seedRuleData, setSeedRuleData] = useState<any>(undefined);
  const [_journeyRule, _setJourneyRule] = useState<any>();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const ruleAPI = new RuleAPI();
  const ErrorIcon = getIcon('warning', '');
  const isNotEditable = experience?.status === 'Active' || experience?.activation?.manualActivationAt || experience?.activation?.automaticActivationAt;

  const restriction: any = {
    and: 'All of the following restrictions define your audience reach',
    or: 'Any of the following restrictions define your audience reach',
  };
  const preferred: any = {
    and: 'All of the following conditions define your preferred criteria',
    or: 'Any of the following conditions define your preferred criteria',
  };

  if (isNotEditable) {
    className += ' not-clickable';
  }

  if ( audience?.data?.hasError && audience?.data?.showError) {
    className += ' error';
  }

  useEffect(() => {
    stringRepFinal(audience?.data?.ruleStringRepresentation, audience?.data?.ruleBody, plugins).then((r) => setRuleData(r));
    stringRepFinal(audience?.data?.seedRuleStringRepresentation, audience?.data?.seedRuleBody, plugins).then((r) => setSeedRuleData(r));
    //eslint-disable-next-line
  }, [audience?.data, plugins]);


  useEffect(() => {
    if(audience?.data?.ruleID) {
      ruleAPI.getRule(audience?.data?.ruleID).then((res: any) => {
        _setJourneyRule(res);
      })
    }
    return () => _setJourneyRule(null);
    //eslint-disable-next-line
  }, []);


  const AudienceSection = () => {
    if (!ruleData || !seedRuleData) {
      return <></>;
    }
    const audience = ruleData?.predicates?.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    const seedAudience = seedRuleData?.predicates?.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    // Empty Restrictions
    if (!ruleData?.predicates?.length && !seedRuleData?.predicates?.length) {
      return (
        <>
          <div className="flow-box__card-container__card__step-body__descr">
            <div className="anyone">
              <span className="anyone__heading"> Anyone </span>
              <p> With no audience restrictions selected, this Experience may reach every user </p>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {ruleData?.predicates?.length > 0 ? (
            <div className="flow-box__card-container__card__step-body__content">
              <b> Restrictions </b>
              <div className="flow-box__card-container__card__step-body__descr">
                {ruleData?.type === 'custom' ? (
                  <i> Restrictions {ruleData?.toString} defines your audience reach. </i>
                ) : (
                  <p> {restriction?.[ruleData?.type]} </p>
                )}
              </div>
              {audience}
            </div>
          ) : (
            <div className="flow-box__card-container__card__step-body__content">
              <b> Restrictions </b>
              <div className="flow-box__card-container__card__step-body__descr">
                None set
                <div className="empty-audience">
                  <span className="tab-icon"> {getIcon('infoFilled', '')} </span>
                  With no restrictions set, the audience can be anyone*.
                </div>
              </div>
              {audience}
            </div>
          )}
          {seedRuleData?.predicates?.length > 0 && (
            <div className="flow-box__card-container__card__step-body__content">
              <b> Preferred criteria </b>
              <div className="flow-box__card-container__card__step-body__descr">
                {ruleData.type === 'custom' ? (
                  <i> Conditions {seedRuleData?.toString} define the preferred criteria members. </i>
                ) : (
                  <p> {preferred?.[ruleData?.type]} </p>
                )}
              </div>
              {seedAudience}
            </div>
          )}
        </>
      );
    }
  }


  const audienceRow = (stringObj: any, idx: number = 1) => {
    if (!Array.isArray(stringObj)) {
      // check if is not an empty array
      return (
        <div key={`stringRep_${idx}`} className="flow-box__card-container__card__step-body__plugins">
          <div className="flow-box__card-container__card__step-body__plugins__plugin-left">
            <b> {idx + 1}. </b>
          </div>
          <div className="flow-box__card-container__card__step-body__plugins__plugin-right">
            {Array.isArray(stringObj) ? (
              <> {stringObj[0]}</> // rule
            ) : (
              audienceRowType(stringObj)
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div key={`stringRep_${idx}`} className="flow-box__card-container__card__step-body__plugins">
          <div className="flow-box__card-container__card__step-body__plugins__plugin-left">
            <b> {idx + 1}. </b>
          </div>
          <div className="flow-box__card-container__card__step-body__plugins__plugin-right">
            {audienceRowType(stringObj)}
          </div>
        </div>
      );
    }
  }


  const audienceRowType = (audienceObj: any) => {
    let locationText = 'User is in a location';
    if (audienceObj?.arguments?.[0]?.includes('Approximate')) {
      locationText = 'User is in the approximate area of';
    }

    let _audienceObj = audienceObj;
    if(_audienceObj.length === 1) { //complexrule
      _audienceObj.type = 'complexRule'
    }

    switch (_audienceObj.type) {
      case 'location:map':
        return (
          <>
            {' '}
            <span>
              {locationText} &nbsp; <LocationPreview location={audienceObj?.location} args={audienceObj?.arguments} />{' '}
            </span>
          </>
        );
      case 'location:label':
        return <> User is in a location with the label {'"' + audienceObj?.label + '"'}</>;
      case 'general':
      case 'dateTime':
        return (
          <span>
            {' '}
            {audienceObj?.arguments?.[0]} {audienceObj?.predicate} &nbsp; <b> {audienceObj?.arguments?.[1]} </b>{' '}
          </span>
        );
      case 'complexRule':
        return (
          <div>
            {' '}
            <ComplexPreview ruleName={_audienceObj} showAsModal={true} journeyRule={_journeyRule} plugins={plugins} />
          </div>
        );
      default:
        return audienceObj;
    }
  }


  const showAudienceModal = (event:any) => {
    if(event.key === 'Enter' || event.type === "dblclick") {
      if (!isNotEditable) {
        step.data?.setAudienceIframeProps({...step.data?.audienceIframeProps, ...audience?.data, isVisible: true});
      }
    }
  }


  const handleShowEditIcon = (option: boolean) => {
    if (className.indexOf('not-clickable') === -1) {
      setShowEditIcon(option);
      if(className.indexOf('error') !== -1) {
        setShowErrorIcon(!option);
      }
    }
  }


  return (
    <button
      className={className}
      onDoubleClick={showAudienceModal}
      onKeyDown={showAudienceModal}
      onClick={showAudienceModal}
      onMouseEnter={() => handleShowEditIcon(true)}
      onMouseLeave={() => handleShowEditIcon(false)}
    >
      <Handler action="in" type="target" position={Position.Left} />
      <div className="audience__header">
        <div className="audience__header__left">
          <img className="audience__header__icon" src="/static/media/audience.b20e1693.svg" alt="boxIcon" />
          <div className="audience__header__title">Target audience</div>
        </div>
        {!!isNotEditable && (
          <FlightTooltip
            className="flow-box__card-container__card__step-header__tooltip"
            direction="top"
            isEnabled={true}
            description={ !!isNotEditable && "You can't edit this component once the experience becomes Active." }
            delay={0}
          >
            <img
              src={isNotEditable ? LockIcon : LockIconOutline}
              alt="QuestionIcon"
              className="flow-box__card-container__card__step-header__question"
            />
          </FlightTooltip>
        )}
        {showErrorIcon ? (
          <div className="flow-box__card-container__card__step-header__error-icon">{ErrorIcon}</div>
        ) : (
          <EditIcon
            style={{fill: '#4992FD'}}
            className={`flow-box__card-container__card__step-header__${showEditIcon ? 'edit-show' : 'edit'}`}
          />
        )}
      </div>
      <div className="audience__body">
        <AudienceSection />
      </div>
      {step?.data?.stepData?.triggers?.map((trigger: StepTrigger) => {
        return <Handler
          key={trigger.action}
          action={trigger.action}
          triggerLevel={getTriggerPosition(step?.data?.stepData?.triggers, trigger)}
          type="source"
          position={Position.Right}
        />
      })}
    </button>
  );
}

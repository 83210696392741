import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import store from 'redux/store';
import './App.scss';

// Only initialize Sentry for builds
if (process.env.REACT_APP_BUILD_ENVIRONMENT) {
  Sentry.init({
    release: `${process.env.REACT_APP_SENTRY_RELEASE}`,
    environment: `${process.env.REACT_APP_BUILD_ENVIRONMENT}`,
    dsn: "https://96001865d6d4421b8d274e84b3f3014d@o426285.ingest.sentry.io/5367916",
    integrations: [ new Integrations.BrowserTracing() ],
    tracesSampleRate: 1.0,
  });
}

const rootEl = document.getElementById('root');
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootEl,
);

// comment in for PWA with service worker in production mode
// registerServiceWorker();

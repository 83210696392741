import React, { useState, useRef, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MiniMap,
  Position,
  ReactFlowProvider,
  removeElements,
  Connection
} from 'react-flow-renderer';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import './Journey.scss';
// import Sidebar from 'components/Sidebar/Sidebar';
import Audience from 'components/Journey/Audience/Audience';
import PushMessage from 'components/Journey/PushMessage/PushMessage';
import Content from 'components/Journey/Content/Content';
import WebPage from 'components/Journey/WebPage/WebPage';
import Delay from 'components/Journey/Delay/Delay';
import Start from 'components/Journey/Start/Start';
import Line from 'components/Journey/Line/Line';
import Finish from 'components/Journey/Finish/Finish';
import AudienceIframeParent from 'components/Audience/AudienceIframeParent/AudienceIframeParent';
import FullPageOverlay from 'components/FullPageOverlay/FullPageOverlay';
import PushEditModal from 'components/Modal/EditModal/PushEditModal/PushEditModal';
import ContentIframeParent from 'components/Content/ContentIframeParent/ContentIframeParent';
import ContentModal from 'components/Modal/ContentModal/ContentModal';
import {
  AudienceIframeProps,
  ContentIframeProps,
  JourneyLine,
  JourneyStep,
  StepTrigger,
} from 'interface/journey.interface';
import { getId } from 'helpers/templated-experience.helper';
import stepConfig from 'config/step.config';
import useActions from 'hooks/useActions';
import useFeatureFlag from 'hooks/useFeatureFlag';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';


export default function Journey() {

  const experience = useSelector((state: any) => isEmpty(state.templatedExperience.instance) ? state.templatedExperience.template : state.templatedExperience.instance);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const { flags } = useFeatureFlag();
  const audience = experience?.steps?.find((step: JourneyStep) => step.type === 'audience');
  const isNearByTemplate = experience?.steps?.find((step:JourneyStep) => step.type === 'audience' && step?.data?.trigger?.type === "nearby-location");


  const onSubmitHandle = (payload: {
    ruleBody: object;
    seedRuleBody: object;
    ruleStringRepresentation: string;
    seedRuleStringRepresentation: string;
  }) => {
    setAudienceIframeProps({...audienceIframeProps, ...payload, isVisible: false});
    reduxTemplatedExperienceAction.setAudience(payload, flags['tx_journey_builder'] );
  }


  const [audienceIframeProps, setAudienceIframeProps] = useState<AudienceIframeProps>({
    isVisible: false,
    hasError: false,
    ruleBody: audience?.data?.ruleBody,
    seedRuleBody: audience?.data?.seedRuleBody,
    onSubmit: onSubmitHandle
  });


  const [pushModalData, setPushModalData] = useState({
    isVisible: false,
    action: undefined,
    amplified: false,
    triggerContentModal: () => {},
    onApply: ()=>{},
    onCancel: () => setPushModalData({...pushModalData, isVisible: false})
  });


  const handleCreateContent = () => {
    setContentModalData({...contentModalData, isVisible: true, showTemplates: !contentModalData.showTemplates})
  };


  const handleSetContent = (selectedContent: any) => {
    if(isNearByTemplate) {
      let push = experience?.steps?.find((step: any) => step.type === 'push');
      if(push && selectedContent?.[0]?.contentTemplateId) {
        delete(push?.data?.actionBody?.body?.[0]?.payload?.data?.contentId);
        if(push?.data?.actionBody?.body?.[0]?.payload) {
          push.data.actionBody.body[0].payload = {
            ...push.data.actionBody.body[0].payload,
            data: {
              ...push.data.actionBody.body[0].payload?.data,
              contentBlueprintId: selectedContent?.[0]?.contentTemplateId || ''
            }
          }
        }
        reduxTemplatedExperienceAction?.setContent(selectedContent?.[0]?.contentTemplateId, selectedContent, isNearByTemplate, true);
      }
    } else {
      let contentStep = experience?.steps?.find((expStep: any) => expStep.type === 'content');
      reduxTemplatedExperienceAction?.setContent(contentStep.id, [selectedContent], isNearByTemplate, true);
    }
  };


  const onContentApplyHandle = (content: any) => {
    setContentModalData({...contentModalData, isVisible: false, showTemplates: false});
    if (content?.id || content?.templateId || content?.[0]?.templateId) {
      handleSetContent(content);
    } else {
      setContentIframeProps({ ...contentIframeProps, isVisible: true, id: content?.[0]?.id, isEdit: false, payload: content?.[0] });
    }
  };


  const onIframeSuccess = (id: string, content: any) => {
    if(!isNearByTemplate) {
      setContentModalData({...contentModalData, isVisible: true, showTemplates: false})
    }
    if(content && isNearByTemplate) {
      handleSetContent([content]);
    }
    setContentIframeProps({ ...contentIframeProps, isVisible: false });
  };


  const [contentModalData, setContentModalData] = useState({
    isVisible: false,
    showTemplates: false,
    selectedId: '',
    editedInstanceId: '',
    onCancel: () => setContentModalData({...contentModalData, isVisible: false, showTemplates: false}),
    isAfterCreate: false,
    onBack: () => setContentModalData({...contentModalData, showTemplates: false}),
    onApply: onContentApplyHandle,
    onEditInstance: (content: any) => onEditInstance(content),
    onCreateContent: handleCreateContent,
    isNearByTemplate,
    isCalledFromPush: false
  });


  const [contentIframeProps, setContentIframeProps] = useState<ContentIframeProps>({
    isVisible: false,
    hasError: false,
    id: '',
    isEdit: false,
    isNearByTemplate,
    payload: undefined,
    isContentBlueprint: false,
    onSubmit: (id: string, content: any) => onIframeSuccess(id, content),
    onCancel: () => setContentIframeProps({...contentIframeProps, isVisible: false}),
  });


  const onEditInstance = (content: any) => {
    setContentModalData({...contentModalData, isVisible: false, showTemplates: false});
    setContentIframeProps({ ...contentIframeProps, isVisible: true, id: content?.[0]?.id, isEdit: true, payload: content?.[0] });
  };


  const reactFlowWrapper = useRef<any>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
  const [elements, setElements] = useState<any[]>([]);


  useEffect(()=>{
    let _elements: JourneyStep[] = [];
    _elements.push({
      id: getId(),
      type: 'start',
      data: { label: <Start/> },
      position: { x: -(stepConfig.stepDistance+stepConfig.startWidth), y: 0 },
      sourcePosition: Position.Right,
      className: 'journey__start',
      triggers: [{id: getId(), action: 'launched'}]
    });
    experience?.steps?.forEach((step:JourneyStep) => {
      let nodeClass = 'journey__node';
      if(step.type === 'delay') nodeClass = 'journey__helper';
      _elements.push({
        ...step,
        data: getLabelForStep(step),
        className: nodeClass,
        sourcePosition: Position.Right,
        targetPosition: Position.Left
      });
    });
    _elements.push({
      id: getId(),
      type: 'finish',
      data: { label: <Finish/> },
      position: getFinishPosition(experience?.steps),
      targetPosition: Position.Left,
      className: 'journey__finish'
    });
    const lines = generateLines(_elements);
    setElements([..._elements, ...lines]);
    return () => setElements([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[experience]);


  const getTargets = (source:JourneyStep, steps: JourneyStep[]) => {
    if(source.type === 'start') {
      return steps?.filter((step:any) => step.type === 'audience');
    }
    if(source.type === 'audience') {
      if(steps?.filter((step:any) => step.type === 'delay')?.length) {
        return steps?.filter((step:any) => step.type === 'delay')
      } else if (steps?.filter((step:any) => step.type === 'push')?.length) {
        return steps?.filter((step:any) => step.type === 'push');
      } else if (steps?.filter((step:any) => step.type === 'content' || step.type === 'content-blueprint')?.length) {
        return steps?.filter((step:any) => step.type === 'content' || step.type === 'content-blueprint');
      } else if (steps?.filter((step:any) => step.type === 'finish')?.length) {
        return steps?.filter((step:any) => step.type === 'finish')
      }
    }
    if(source.type === 'push') {
      if(steps?.filter((step:any) => step.type === 'delay')?.length) {
        return steps?.filter((step:any) => step.type === 'delay')
      } else if (steps?.filter((step:any) => step.type === 'content')?.length) {
        return steps?.filter((step:any) => step.type === 'content');
      } else if (steps?.filter((step:any) => step.type === 'webpage')?.length) {
        return steps?.filter((step:any) => step.type === 'webpage');
      } else if (steps?.filter((step:any) => step.type === 'finish')?.length) {
        return steps?.filter((step:any) => step.type === 'finish')
      }
    }
    if(source.type === 'content') {
      return steps?.filter((step:any) => step.type === 'finish');
    }
    if(source.type === 'webpage') {
      if (steps?.filter((step:any) => step.type === 'finish')?.length) {
        return steps?.filter((step:any) => step.type === 'finish')
      }
    }
    return steps?.filter((step:any) => step.type === 'finish');
  }


  const generateLines = (steps: JourneyStep[]) => {
    let lines: any[] = [];
    steps?.forEach((step: JourneyStep) => {
      step.triggers?.forEach((trigger: StepTrigger) => {
        const targets = getTargets(step, steps);
        targets.forEach((target:JourneyStep) => {
          lines.push({
            id: getId(),
            source: step.id,
            target: target.id,
            sourceHandle: trigger.action,
            targetHandle: 'in',
            animated: true,
            type: 'line',
            data: { label: getLineLabel(step, trigger), ...trigger }
          });
        });
      });
    });
    return lines;
  }


  const getLineLabel = (step:JourneyStep, trigger: StepTrigger) => {
    switch(step.type) {
      case 'start':
        if(trigger.action === 'launched') return 'Launched';
        break;
      case 'audience':
        if(trigger.action === 'opened') return 'User meets restrictions';
        if(trigger.action === 'not_opened') return 'User not meets restrictions';
        break;
      case 'push':
        if(trigger.action === 'opened') return 'Push opened';
        if(trigger.action === 'not_opened') return 'Push not opened';
        break;
      case 'content':
        if(trigger.action === 'opened') return 'Content viewed';
        if(trigger.action === 'not_opened') return 'Content not viewed';
        break;
      case 'webpage':
        if(trigger.action === 'opened') return 'Visited';
        if(trigger.action === 'not_opened') return 'Not visited';
        break;
      default:
        return 'Conversion';
    }
  }


  const onRemove = (elementsToRemove:JourneyStep) => {
    setElements((elements:any[]) => removeElements([elementsToRemove], elements));
  }


  const getLabelForStep = (step: {type: string}) => {
    let label = <div> </div>
    if(step.type === 'audience') label = <Audience/>
    if(step.type === 'push') label = <PushMessage/>
    if(step.type === 'content') label = <Content/>
    if(step.type === 'webpage') label = <WebPage/>
    if(step.type === 'delay') label = <Delay/>
    return {
      label,
      onRemove,
      stepData: step,
      audienceIframeProps,
      setAudienceIframeProps,
      pushModalData,
      setPushModalData,
      contentModalData,
      setContentModalData,
      contentIframeProps,
      setContentIframeProps
    };
  }


  const getFinishPosition = (steps: JourneyStep[]) => {
    let farStepPositionX = steps?.sort((a:JourneyStep, b:JourneyStep) => b.position?.x - a.position?.x);
    return {x: (farStepPositionX?.[0]?.position?.x || 0) + stepConfig.stepWidth + stepConfig.stepDistance, y: 0}
  }


  const findNodeById = (id:string|null) => {
    return elements?.find(el => el.id === id);
  }


  const onConnect = (params: JourneyLine | Connection) => {
    let label = '';
    const source:JourneyStep = findNodeById(params.source);
    const target:JourneyStep = findNodeById(params.target);

    if(source?.type === 'start') {
      label = 'Journey launched';
    }
    if(source?.type === 'audience') {
      label = 'User meets restrictions';
    }
    if(source?.type === 'push') {
      label = 'Opened';
      if(params?.sourceHandle === 'no') {
        label = 'Not opened';
      }
    }
    if(source?.type === 'content') {
      label = 'Viewed';
      if(params?.sourceHandle === 'no') {
        label = 'Not viewed';
        alert('Please specify waiting period');
        const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
        const centerX = target?.position?.x - ((target?.position?.x - source?.position?.x) / 2) - (stepConfig.stepWidth/2);
        const centerY = source?.position?.y + ((target?.position?.y - source?.position?.y) / 2) + (stepConfig.stepHeight/2);
        const position = reactFlowInstance?.project({
          x: centerX,
          y: centerY - reactFlowBounds.top + 200,
        });

        const newNode = {
          id: getId(),
          type: 'delay',
          position,
          className: 'journey__helper',
          data: { label: <Delay/>, onRemove },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
        };

        const lineToNode = {
          id: getId(),
          source: params.source,
          target: newNode.id,
          sourceHandle: params.sourceHandle,
          targetHandle: 'in',
          animated: true,
          type: 'line',
          data: { label: 'Waiting', events: 0, conversion: 0 },
        }

        const lineFromNode = {
          id: getId(),
          source: newNode.id,
          target: params.target,
          sourceHandle: 'out',
          targetHandle: 'in',
          animated: true,
          type: 'line',
          data: { label: 'Not viewed', events: 0, conversion: 0 },
        }

        setElements((els) => [...els, newNode, lineToNode, lineFromNode]);
        return;
      }
    }
    if(source?.type === 'webpage') {
      label = 'Visited';
      if(params?.sourceHandle === 'no') {
        label = 'Not visited';
      }
    }
    if(source?.type === 'delay') {
      label = 'Delay over';
    }

    params = {
      ...params,
      id: getId(),
      animated: true,
      type: 'line',
      data: { label, events: 0, conversion: 0 },
    }
    setElements((els) => addEdge(params, els));

  }


  const onElementsRemove = (elementsToRemove:any) => {
    setElements((els) => removeElements(elementsToRemove, els));
  }


  const onLoad = (_reactFlowInstance:any) => {
    setReactFlowInstance(_reactFlowInstance);
  }


  useEffect(()=>{
    setTimeout(()=>{
      reactFlowInstance?.fitView();
    }, 0);
  },[reactFlowInstance]);


  const onDragOver = (event:any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };


  const onDrop = (event:any) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
    const nodeType = event.dataTransfer.getData('application/reactflow');
    const position = reactFlowInstance?.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    let nodeClass = 'journey__node';
    if(nodeType === 'delay') nodeClass = 'journey__helper';
    const newNode = {
      id: getId(),
      type: nodeType,
      position,
      className: nodeClass,
      data: getLabelForStep({type: nodeType}),
      triggers: [{action: 'opened', events: 0, conversion: 0}],
    };
    setElements((es) => es.concat(newNode));
  };


  const nodeTypes = {
    audience: Audience,
    content: Content,
    delay: Delay,
    finish: Finish,
    push: PushMessage,
    start: Start,
    webpage: WebPage,
  };


  const edgeTypes = {
    line: Line,
  };


  return (
    <div className="journey">
      {/* TODO: Uncomment after concept approval */}
      {/*<Sidebar/>*/}
      <ReactFlowProvider>
        <div className="journey__wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            elements={elements}
            defaultZoom={1}
            onLoad={onLoad}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onDrop={onDrop}
            onDragOver={onDragOver}
            defaultPosition={[300,200]}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
          >
            <MiniMap
              nodeColor={(node) => {
                switch (node.type) {
                  case 'start':
                    return '#FFE1DEFF';
                  case 'audience':
                    return '#FDF9D4';
                  case 'push':
                    return '#C6B6FFFF';
                  case 'content':
                    return '#B9D9FFFF';
                  case 'webpage':
                    return '#B4EEFFFF';
                  case 'delay':
                    return '#FED726FF';
                  case 'finish':
                    return 'aquamarine';
                  default:
                    return '#eee';
                }
              }}
            />
            <Controls className="journey__controls" />
            <Background />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
      {audienceIframeProps.isVisible && <FullPageOverlay isVisible={audienceIframeProps.isVisible}>
        <AudienceIframeParent
          ruleBody={audienceIframeProps.ruleBody}
          seedRuleBody={audienceIframeProps.seedRuleBody}
          onSubmit={audienceIframeProps.onSubmit}
          onCancel={() => setAudienceIframeProps({...audienceIframeProps, isVisible: false})}
          audienceHasError={audienceIframeProps.hasError}
          experience={experience}
        />
      </FullPageOverlay>}
      {pushModalData.isVisible && (
        <PushEditModal
          action={pushModalData.action}
          amplified={false}
          isVisible={pushModalData.isVisible}
          triggerContentModal={pushModalData.triggerContentModal}
          onApply={pushModalData.onApply}
          onCancel={pushModalData.onCancel}
        />
      )}
      {contentModalData.isVisible && <ContentModal {...contentModalData} />}
      <FullPageOverlay isVisible={contentIframeProps?.isVisible}>
        <ContentIframeParent {...contentIframeProps} />
      </FullPageOverlay>
    </div>
  )
}

import { TEATimeline, TEAContentTimeline } from 'interface/templated-experience/analytics.interface';

export interface AnalyticsContentPerformanceState {
  viewRecords: TEAContentTimeline[],
  clickRecords: TEAContentTimeline[],
  targetAudienceRecords: TEATimeline,
  isViewLoading: boolean,
  isClickLoading: boolean,
  errorMessage: string,
}

export const AnalyticsContentPerformanceActionType = {
  SET_CONTENT_PERFORMANCE_VIEW_LOADING: 'SET_CONTENT_PERFORMANCE_VIEW_LOADING',
  SET_CONTENT_PERFORMANCE_CLICK_LOADING: 'SET_CONTENT_PERFORMANCE_CLICK_LOADING',
  SET_CONTENT_PERFORMANCE_VIEW_DATA: 'SET_CONTENT_PERFORMANCE_VIEW_DATA',
  SET_CONTENT_PERFORMANCE_CLICK_DATA: 'SET_CONTENT_PERFORMANCE_CLICK_DATA',
  SET_CONTENT_TA_PERFORMANCE_DATA: 'SET_CONTENT_TA_PERFORMANCE_DATA',
  SET_CONTENT_PERFORMANCE_ERROR: 'SET_CONTENT_PERFORMANCE_ERROR',
}

export interface SetContentPerformanceDataAction {
  type: string,
  payload:  TEAContentTimeline[] | TEATimeline,
}

export interface SetContentPerformanceLoadingAction {
  type: string,
  payload: boolean,
}

export interface SetContentPerformanceErrorAction {
  type: string,
  payload: string,
}

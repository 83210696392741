import React, {useState, useEffect} from "react";
import FolderIcon from "assets/icons/icons8-folder.svg";
import FolderAPI from "services/api/folder.api";
import {Folder} from "interface/folder.interface";
import { findFolderById } from "helpers/folder.helper";
import {useHistory} from "react-router-dom";
import './FoldersBreadcrumbs.scss';
import { isEmpty } from "lodash";
import queryString from "query-string";
import {useSelector} from "react-redux";

interface iProps {
  clickable: boolean
  redirectToFolder?: (e: React.MouseEvent<HTMLElement>, folderId: string) => void
}

export default function FoldersBreadcrumbs({clickable = false, redirectToFolder}:iProps) {

  const folderAPI = new FolderAPI();
  const history = useHistory();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const pathName = history.location.pathname.replace(/\/$/, "");
  const [folders, setFolders] = useState<Folder[]>([]);
  const queryParams = queryString.parse(history.location?.search);
  const parentFolderID = queryParams.parentFolderID;
  const parentFolder = findFolderById(parentFolderID, folders);
  let pathFolders:Folder[] = [];

  const getPathFolders = (folder: Folder) => {
    const isFolderInArray = pathFolders?.find((pathFolder: Folder) => pathFolder.id === folder.id);
    if(!isEmpty(folder) && !isFolderInArray) {
      pathFolders.push(folder);
    }
    if(folder?.parentFolderID) {
      const parentFolder = findFolderById(folder?.parentFolderID, folders);
      const isParentFolderInArray = pathFolders?.find((pathFolder: Folder) => pathFolder.id === parentFolder?.id);
      if(parentFolder && !isParentFolderInArray) {
        pathFolders.unshift(parentFolder);
        getPathFolders(parentFolder);
      }
    }
    return pathFolders;
  }
  getPathFolders(parentFolder);

  useEffect(() => {
    folderAPI.getFoldersTree().then((res: any) => setFolders(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.parentFolderID, reduxTemplatedExperienceState?.currentFolder?.name]);

  let separator = <></>;
  let folderIcon = <></>;
  let link = <></>;

  return <span className={`breadcrumbs ${!clickable && 'small'}`}>{!isEmpty(pathFolders) ? pathFolders.map((folder:Folder, idx:number) => {
    if(folder) {
      separator = folder.pathName === '/' ? <></> : <span className={`breadcrumbs__separator ${!clickable && 'small'}`}>/</span>;
      folderIcon = folder.pathName === '/' ? <></> : <img src={FolderIcon} alt={folder.name} className="breadcrumbs__folder-icon"/>;
      link = clickable ? <a
        href={pathName}
        onClick={(e) => redirectToFolder!(e, folder.id!)}
      >{folder.pathName === '/' ? 'All experiences' : folder.name}</a> : <span>{folder.pathName === '/' ? 'All experiences' : folder.name}</span>;
    }
    if(idx === pathFolders.length - 1) {
      link = <span>{folder.pathName === '/' ? 'All experiences' : folder.name}</span>;
    }
    return (
      <span key={folder.id}>
        {separator}
        {folderIcon}
        {link}
      </span>
    )
  }) : <span>All folders</span>}</span>
}

import React, { ReactElement, createContext, useState } from 'react';
import { FlightCard } from '@flybits/webapp-design-system-react';
import './MultiActionContainer.scss';
import ArrowIcon from 'assets/icons/arrow__new.svg';

interface MultiActionContainerProps {
  title: string;
  icon: string;
  children: ReactElement;
  hideCollapse: boolean;
  hideTrailingArrow?: boolean;
}

interface ContextProps {
  collapse: boolean;
  handleCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MultiActionContainerContext = createContext({
  collapse: false,
  handleCollapse: () => {},
} as ContextProps);

export default function MultiActionContainer(props: MultiActionContainerProps) {
  const { title, icon, children, hideTrailingArrow, hideCollapse } = props;
  const [collapse, setCollapse] = useState(false);
  const handleCollapse = () => {
    setCollapse((collapse) => !collapse);
  }
  const values = { collapse: collapse, handleCollapse: handleCollapse };

  return (
    <MultiActionContainerContext.Provider value={values}>
      <div className={`multi-action__card-container`}>
        <div className="multi-action__card-container__button">
          <FlightCard
            className="multi-action__card-container__card"
            header={
              <div className="multi-action__card-container__card__step-header">
                <div className="multi-action__card-container__card__step-header__type-box">
                  <img className="multi-action__card-container__card__step-header__icon" src={icon} alt="boxIcon" />
                  <div> {title} </div>
                </div>
                {!hideCollapse ?
                <div
                  className="multi-action__card-container__card__step-header__container-actions"
                  onClick={handleCollapse}
                >
                  {collapse ? 'Collapse all' : 'Expand all' }
                </div> : null}
              </div>
            }
          />
          <div className="multi-action__card-container__card__step-body">{children}</div>
        </div>
        {!hideTrailingArrow ? (
          <img className="multi-action__card-container__arrow" src={ArrowIcon} alt="ArrowIcon" />
        ) : null}
      </div>
    </MultiActionContainerContext.Provider>
  );
}

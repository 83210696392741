import { isEmpty } from 'lodash';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import { isPushValid } from 'validator/push-message.validator';
import { isContentValid } from 'validator/content.validator';

// For Crawl, validate the first step.
// Draft passes through all of these.
// Inactive / Schedule must be validated properly.
export function validateTemplatedExperience(templateExperience: TExperienceInstance) {
  templateExperience?.steps?.map((step: any) => {
    if((step.isRoot && step.trigger.type === 'triggered') ||
      (step.isRoot && step.trigger.type === 'reminder') ||
      (((step.isRoot && step.trigger?.type === '') || (step.isRoot && step.trigger?.type === 'nearby-location')) && isAmplifiedPushAttached(step))||
      (step.isRoot && step.trigger?.type === '' && (step.trigger?.trigger?.key === 'before' || step.trigger?.trigger?.key === 'after')
      )
    ) {
      step.hasError = step.ruleStringRepresentation === "" && step.seedRuleStringRepresentation === "";
    }
    return step?.actions?.forEach((action: any) => {
      action.hasError = !_actionValid(action)
    })
  })
  return templateExperience;
}

export function getIncompleteCount(templateExperience: TExperienceInstance) {
  if (!templateExperience) return 0;
  if (isEmpty(templateExperience)) return 0;
  let count = 0;
  templateExperience?.steps?.map(step => {
    if((step.isRoot && step.trigger?.type === 'triggered') ||
      (step.isRoot && step.trigger?.type === 'reminder') ||
      (((step.isRoot && step.trigger?.type === '') || (step.isRoot && step.trigger?.type === 'nearby-location')) && isAmplifiedPushAttached(step))||
      (step.isRoot && step.trigger?.type === ''
      && (step.trigger?.trigger?.key === 'before' || step.trigger?.trigger?.key === 'after'))
    ) 
    {
      count += step.ruleStringRepresentation === "" && step.seedRuleStringRepresentation === "" ? 1 : 0;
    }
    return step.actions?.forEach((action: any) => {
      count += !_actionValid(action) ? 1 : 0;
    });
  });
  return count;
}

function _actionValid(action: any) {
  switch (action.actionType) {
    case 'experience':
    case 'content-blueprint':
      return isContentValid(action);
    case 'push':
      return isPushValid(action);
    default:
      return;
  }
}

const isAmplifiedPushAttached = (step : any) => {
  const _amplifiedPushStep =  step?.actions?.find((item : any) => item.actionType === "push");
  if(_amplifiedPushStep) {
    if(step?.trigger?.type === 'nearby-location') {
      return !!_amplifiedPushStep?.actionBody?.body?.[0]?.payload?.data?.contentBlueprintId;
    }
    return !!_amplifiedPushStep?.actionBody?.body?.[0]?.payload?.data?.contentId?.value;
  }
  return false;
}

import React from 'react';
import './ScheduleInline.scss';
import moment from 'moment';
import ScheduleIcon from "assets/icons/calendar-with-clock-24.svg";
import { FlightButton } from '@flybits/webapp-design-system-react';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';

interface IProps {
  experience: TExperienceInstance | undefined,
  showScheduleModal: ()=>void
}

export default function ScheduleInline(props:IProps) {

  const dateFormat = "MMM DD, YYYY hh:mmA";
  let scheduleString = <span>Add</span>;
  let start = 'No start date';
  let end = 'No end date';
  let {experience} = props;
  let timezone = '';
  let myZone = moment.tz.guess().replace(/_/g, ' ');
  let myOffset = moment.tz(myZone).format('Z');
  myZone = myZone+' (UTC '+myOffset+')';

  if(
    experience?.schedule?.start ||
    experience?.schedule?.end ||
    experience?.activation?.manualActivationAt ||
    experience?.activation?.manualDeactivationAt
  ) {
    if(experience?.activation?.manualActivationAt) {
      start = moment(experience?.activation?.manualActivationAt*1000).format(dateFormat);
    }

    if(experience?.activation?.manualDeactivationAt && experience?.activation?.manualDeactivationAt > experience.schedule?.start!!) {
      end = moment(experience?.activation?.manualDeactivationAt*1000).format(dateFormat);
    }
    if (experience?.schedule?.start) {
      start = moment(experience.schedule.start*1000).format(dateFormat);
    }
    if (experience?.schedule?.end) {
      end = moment(experience.schedule.end*1000).format(dateFormat);
    }
    if(experience?.schedule?.timezone && myZone !== experience.schedule.timezone!!) {
      timezone = ` (${experience.schedule.timezone!!})`;
    }
    scheduleString = <span>{start} - {end} {timezone}</span>
  }

  function renderRuntimeDescription() {
    const manualActivation = experience?.activation?.manualActivationAt;
    const autoActivation = experience?.activation?.automaticActivationAt;
    const manualDeactivation = experience?.activation?.manualDeactivationAt;
    const autoDeactivation = experience?.activation?.automaticDeactivationAt;
    if((manualActivation || autoActivation) && experience?.status === 'Active') {
      const activationDate = manualActivation!! > autoActivation!! ? manualActivation : autoActivation;
      const activationDescription = manualActivation!! > autoActivation!! ? 'Manually launched ' : 'Automatically launched ';
      if(experience?.schedule?.end && experience?.schedule?.end > moment().unix()) {
        return (
          <div className="schedule-inline__manual-activ">
            Ends in {moment(experience?.schedule?.end!! * 1000).fromNow(true)}
          </div>
        )
      } else {
        return (
          <div className="schedule-inline__manual-activ">
            {activationDescription} on {moment(activationDate!! * 1000).format('MMM D, YYYY')}
          </div>
        )
      }
    }
    if (experience?.schedule?.start && experience?.status === 'Scheduled') {
      return <div className={'schedule-inline__manual-activ'}>
        Starts in {moment(experience?.schedule?.start*1000).fromNow(true)}
      </div>
    }
    if((manualDeactivation || autoDeactivation) && experience?.status === 'Inactive') {
      const deactivationDate = manualDeactivation!! > autoDeactivation!! ? manualDeactivation : autoDeactivation;
      const deactivationDescription = manualDeactivation!! > autoDeactivation!! ? 'Manually deactivated ' : 'Automatically deactivated';
      return (
        <div className="schedule-inline__manual-activ">
          {deactivationDescription} on {moment(deactivationDate!! * 1000).format('MMM D, YYYY')}
        </div>
      )
    }
  }

  return (
    <div className="schedule-inline">
      {renderRuntimeDescription()}
      <img src={ScheduleIcon} alt="ScheduleIcon" />
      <div className={"schedule-inline__name"}>Schedule: </div>
      <FlightButton
        theme="link"
        className="schedule-inline__link"
        onClick={()=>props.showScheduleModal()}
        label={scheduleString}
      />
    </div>
  )
}

import React, { useContext, useEffect, useState } from 'react';
import { PushFormContext } from 'components/PushMessage/PushFormProvider/PushFormProvider';

import BasicForm from 'components/PushMessage/Forms/BasicForm/BasicForm';
import WeblinkForm from 'components/PushMessage/Forms/WebLinkForm/WebLinkForm';
import ContentForm from 'components/PushMessage/Forms/ContentForm/ContentForm';
import CustomForm from 'components/PushMessage/Forms/CustomForm/CustomForm';
import PushDelivery from 'components/PushMessage/PushDelivery/PushDelivery';
import { FlightSelect } from '@flybits/webapp-design-system-react';
import { ReactComponent as AmplificationIcon } from 'assets/icons/megaphone.svg';
import {isEmpty} from "lodash";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './PushEdit.scss';
import { ContentContext } from 'components/FlowBox/ContentBox/ContentBox';
import { useSelector } from 'react-redux';

export default function PushEdit() {
  const context: any = useContext(PushFormContext);
  const contentContext: any = useContext(ContentContext);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);

  function renderForm(props: any) {
    switch (context.selectedNotificationType?.key) {
      case 'basic':
        return <BasicForm {...props} />;
      case 'weblink':
        return <WeblinkForm {...props} />;
      case 'content':
        return <ContentForm {...props} />;
      default:
        return <BasicForm {...props} />;
    }
  }

  const [selectedContent, setSelectedContent] = useState({ key: '', name: '' });
  const contentList: { key: any; name: any }[] = [];
  contentContext?.contents?.map((cont: any) => {
    if(!isEmpty(cont)) {
      return contentList.push({
        key: cont.id || cont.templateId || cont.templateID || '',
        name:
          cont?.content?.data[0]?.localizations?.en?.title?.substring(0, 33) ||
          cont?.content?.data[0]?.localizations?.en?.name?.substring(0, 33) ||
          cont?.localizations?.en?.name?.substring(0, 33) || '',
      });
    }
  });

  useEffect(() => {
    if (context.isAmplified) {
      let _selectedContent = contentContext?.contents?.find(
        (item: { id: any }) => item?.id === contentContext?.selectedContent,
      );
      if (!_selectedContent) {
        _selectedContent = contentContext?.contents?.[0];
      }

      contentContext.setSelectedContent(_selectedContent?.id);
      setSelectedContent({
        key: _selectedContent?.id || _selectedContent?.templateId || _selectedContent?.templateID || '',
        name:
          _selectedContent?.content?.data[0]?.localizations?.en?.title?.substring(0, 33) ||
          _selectedContent?.content?.data[0]?.localizations?.en?.name?.substring(0, 33) ||
          _selectedContent?.localizations?.en?.name?.substring(0, 33) || _selectedContent?.name || ''
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentContext.selectedContent]);

  const handleSelectContent = (value: any) => {
    setSelectedContent({
      key: value.key,
      name: value.name,
    });
    contentContext.setSelectedContent(value.key);
  };

  return (
    context.isInitialized && (
      <div className="push-edit">
        {reduxTemplatedExperienceState?.temp?.viewMode !== 'journey' && (
          !context?.isAmplified ? (
            <>
              <b>Notification leads to</b>
              <div className="notification-type">
                <FlightSelect
                  label=""
                  options={context.notificationOptions}
                  selected={context.selectedNotificationType}
                  hasLabelAnimation
                  handleOptionClick={(e: any) => context.setSelectedNotificationType(e)}
                  dropdownMaxHeight="150px"
                  width="252px"
                />
              </div>
            </>
          ) : (
            <div className="push-edit__amplify">
              <AmplificationIcon />
              <b className="push-edit__amplify__header">Amplify</b>
              <div className="push-edit__amplify__description">
                This Push notification will amplify and lead to the following in-app content
              </div>
              <div className="notification-type">
                <FlightSelect
                  label=""
                  options={contentList}
                  selected={selectedContent}
                  hasLabelAnimation
                  handleOptionClick={(e: any) => handleSelectContent(e)}
                  dropdownMaxHeight="150px"
                  width="252px"
                />
              </div>
            </div>
          )
        )}
        <div className="tab-section">
          <b> Notification details </b>
          <Tabs
            forceRenderTabPanel={true}
            selectedIndex={context.activeTabKey}
            onSelect={(newValue: number) => context.setActiveTabKey(newValue)}
            className="flight-tab"
            selectedTabClassName="flight-tab--selected"
          >
            <TabList className="flight-tab--list">
              {/* Tabs */}
              {context.languages.map((language: any, index: number) => (
                <Tab className="flight-tab--list__item" key={`tab-${index}`}>
                  {language.name}
                  {language.isDefault && <span className="required">*</span>}
                </Tab>
              ))}
            </TabList>
            {/* Tab Content */}
            <div className="tab-section__panels">
              {context.languages.map((language: any, index: number) => {
                const props = { lang: language.id, optional: !language.isDefault };
                return (
                  <TabPanel key={`tab-panel-${index}`}>
                    <div className={index === context.activeTabKey ? 'visible' : 'hidden'}>{renderForm(props)}</div>
                  </TabPanel>
                );
              })}
            </div>
          </Tabs>
        </div>
        <CustomForm />
        <PushDelivery />
      </div>
    )
  );
}

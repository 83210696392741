import React from 'react';
import { Position } from 'react-flow-renderer';
import './Delay.scss';
import Handler from 'components/Journey/Handler/Handler';
import { StepTrigger, StepWrapper } from 'interface/journey.interface';
import { getTriggerPosition } from 'helpers/journey.helper';


export default function Delay(step:StepWrapper = {}) {

  const onElementClick = (event: any) => {
    if(event.key === 'Enter' || event.type === "dblclick") {
      alert(event?.target?.name);
    }
    if(event.key === 'Backspace') {
      step?.data?.onRemove?.(step);
    }
  }

  const onRemove = () => {
    step?.data?.onRemove?.(step);
  }

  return (
    <button
      className="delay"
      onDoubleClick={onElementClick}
      onKeyDown={onElementClick}
      onClick={(e) => e.preventDefault()}
    >
      <Handler action="in" type="target" position={Position.Left} />
      <div className="delay__body">
        <a href={'/'} className="delay__remove" onClick={onRemove}>X</a>
        <div><h3>{step?.data?.stepData?.name || 'Delay'}</h3></div>
        <div>1 day</div>
      </div>
      {step?.data?.stepData?.triggers?.map((trigger: StepTrigger) => {
        return <Handler
          key={trigger.action}
          triggerLevel={getTriggerPosition(step?.data?.stepData?.triggers, trigger)}
          type="source"
          position={Position.Right}
          action={trigger.action}
        />
      })}
    </button>
  );
}


import { SettingActionType, SettingState } from 'redux/settings/settings.type';

let initialState: SettingState = {
  languages: [],
  isInitialized: false,
}

export default (state: SettingState = initialState, action: any) => {
  switch (action.type) {
    case SettingActionType.SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload.languages,
        isInitialized: true
      }
    default:
      return {
        ...state
      };
  }
};

import React, { useState } from 'react';
import { Position } from 'react-flow-renderer';
import './WebPage.scss';
import Handler from 'components/Journey/Handler/Handler';
import { StepTrigger, StepWrapper } from 'interface/journey.interface';
import { getTriggerPosition } from 'helpers/journey.helper';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import WebIcon from 'assets/icons/ellipse.svg';
import { FlightTooltip, getIcon } from '@flybits/webapp-design-system-react';
import LockIcon from 'assets/icons/icons8-lock.svg';
import LockIconOutline from 'assets/icons/lock-outline.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen-no-fill.svg';
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
const ErrorIcon = getIcon('warning', '');


export default function WebPage(step:StepWrapper = {}) {

  let className = 'web-page';
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const experience = !isEmpty(reduxTemplatedExperienceState.template)
    ? reduxTemplatedExperienceState.template
    : reduxTemplatedExperienceState.instance;
  const isNotEditable = experience?.status === 'Active';
  const pushMessage = experience?.steps?.find((expStep: any) => expStep.type === 'push' && expStep.id === step?.id);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);

  if (isNotEditable) {
    className += ' not-clickable';
  }

  if (pushMessage?.data?.hasError && pushMessage?.data?.showError) {
    className += ' error';
  }

  const onApplyHandle = (form: any, type: any, pushTypeId: string) => {
    switch (type) {
      case 'basic':
        reduxTemplatedExperienceAction.setBasicPush(pushMessage?.data, form);
        break;
      case 'weblink':
        reduxTemplatedExperienceAction.setWebLinkPush(pushMessage?.data, form, pushTypeId);
        break;
      case 'content':
        reduxTemplatedExperienceAction.setContentPush(pushMessage?.data, form, pushTypeId);
        break;
      default:
        reduxTemplatedExperienceAction.setCustomPush(pushMessage?.data, form, pushTypeId);
        break;
    }
    step?.data?.setPushModalData({
      ...step.data.pushModalData,
      isVisible: false,
    })
  }


  const onTriggerContentModal = (form: any, type: any, typeID: string) => {
    onApplyHandle(form, type, typeID);
  };


  const showWebPageEditModal = (event:any) => {
    if(event.key === 'Enter' || event.type === "dblclick") {
      if (!isNotEditable) {
        step?.data?.setPushModalData({
          ...step?.data?.pushModalData,
          isVisible: true,
          action: pushMessage?.data,
          amplified: false,
          triggerContentModal: onTriggerContentModal,
          onApply: onApplyHandle
        })
      }
    }
    // TODO: uncomment after concept approval
    // if(event.key === 'Backspace') {
    //   onRemove();
    // }
  }


  const handleShowEditIcon = (option: boolean) => {
    if (className.indexOf('not-clickable') === -1) {
      setShowEditIcon(option);
      if(className.indexOf('error') !== -1) {
        setShowErrorIcon(!option);
      }
    }
  }


  return (
    <button
      className={className}
      onDoubleClick={showWebPageEditModal}
      onKeyDown={showWebPageEditModal}
      onClick={showWebPageEditModal}
      onMouseEnter={() => handleShowEditIcon(true)}
      onMouseLeave={() => handleShowEditIcon(false)}
    >
      <Handler action="in" type="target" position={Position.Left} />
      {/*// TODO: uncomment after concept approval*/}
      {/*<a href={'/'} className="web-page__remove" onClick={onRemove}>X</a>*/}
      <div className="web-page__header">
        <div className="web-page__header__left">
          <img className="web-page__header__icon" src={WebIcon} alt="WebPageIcon" />
          <div className="web-page__header__title">Web-page</div>
        </div>
        {isNotEditable ? (
          <FlightTooltip
            className="flow-box__card-container__card__step-header__tooltip"
            direction="top"
            isEnabled={true}
            description={isNotEditable && "You can't edit experiences that are Active. " +
            "You need to deactivate your experience in order to edit this component." }
            delay={0}
          >
            <img
              src={isNotEditable ? LockIcon : LockIconOutline}
              alt="QuestionIcon"
              className="flow-box__card-container__card__step-header__question"
            />
          </FlightTooltip>
        ) : showErrorIcon ? (
          <div className="flow-box__card-container__card__step-header__error-icon">{ErrorIcon}</div>
        ) : (
          <EditIcon
            style={{fill: '#4992FD'}}
            className={`flow-box__card-container__card__step-header__${showEditIcon ? 'edit-show' : 'edit'}`}
          />
        )}
      </div>
      <div className="web-page__body">
        <a href={step?.data?.stepData?.name || '#'} target="_blank" rel="noopener noreferrer" >
          <h3>{step?.data?.stepData?.name || 'Web Page'}</h3>
        </a>
      </div>
      {step?.data?.stepData?.triggers?.map((trigger: StepTrigger) => {
        return <Handler
          key={trigger.action}
          triggerLevel={getTriggerPosition(step?.data?.stepData?.triggers, trigger)}
          type="source"
          position={Position.Right}
          action={trigger.action}
        />
      })}
    </button>
  )
}

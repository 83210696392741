import React, { useContext } from 'react';
import FlowBoxV2 from 'components/MultiStepDemo/FlowBox/FlowBoxV2';
import FinishIcon from 'assets/icons/end.svg';
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import { FlightButton } from '@flybits/webapp-design-system-react';

export default function ExitBox() {
  const multiStepContext: any = useContext(MultiStepContext);
  const { template } = multiStepContext;

  function renderBody() {
    return (
      <>
      <FlightButton label="Send for Approval" onClick={() => undefined} theme="secondary" />
      <MiniBox
        icon={FinishIcon}
        title="Summary"
        body={
          <>
            <div style={{ paddingBottom: '16px' }}>
              <p> Etimated Audience Engagement: </p>
              <p>
                {' '}
                <b> {template?.exit?.estimateEngagement} </b> Customers{' '}
              </p>
            </div>
            <div style={{ paddingBottom: '16px' }}>
              <p> Dropped Off Customers: </p>
              <p>
                {' '}
                <b> N/A </b>{' '}
              </p>
            </div>
            <div style={{ paddingBottom: '16px' }}>
              <p> Completed Experience: </p>
              <p>
                {' '}
                <b> N/A </b>{' '}
              </p>
            </div>
          </>
        }
      />
      <FlightButton label="Create Audience" onClick={() => undefined} theme="secondary" disabled />
      </>
    );
  }

  return (
    <>
      <div className="entry-box">
        <FlowBoxV2 type="exit" body={renderBody()} />
      </div>
    </>
  );
}

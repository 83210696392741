import {Folder} from "interface/folder.interface";
import { TExperienceInstance } from "interface/templated-experience/templated-experience.interface";
import { PaginationResponse } from "interface/shared/api.interface";
let subFolderIds:Set<string> = new Set([]);

export const findFolderById:any = (folderId: string, folders:Folder[]) => {
  let folder:Folder|undefined = undefined;
  for(let i = 0; i < folders?.length; i++) {
    if(folder?.id) break;
    if(folders[i].id === folderId) {
      folder = folders[i];
      return folder;
    }
    if(folders[i].subFolders?.length) {
      folder = findFolderById(folderId, folders[i].subFolders);
    }
  }
  return folder;
}

export const updateFolderName = (instances:PaginationResponse<TExperienceInstance>|null, folderId:string, folderName: string) => {
  return instances?.data ? instances.data.map((instance: TExperienceInstance) => {
    if (instance.id === folderId) {
      instance.name = folderName;
    }
    return instance;
  }) : [];
}

export const getSubFolderIds:any = (folderId: string, folders:Folder[]) => {
  for(let i = 0; i < folders?.length; i++) {
    if(folders[i].parentFolderID === folderId || subFolderIds.has(folders[i].parentFolderID!)) {
      subFolderIds.add(folders[i].id!);
    }
    if(folders[i].subFolders?.length) {
      return getSubFolderIds(folderId, folders[i].subFolders);
    }
  }
  return subFolderIds;
}

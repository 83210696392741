import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { history } from 'redux/store';
import MultiStepProvider, { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import MultiStepTemplatedExperienceAPI from 'services/api/multistep-templated-experience.api';

import { ReactComponent as ArrowWithline } from 'assets/icons/arrowline2.svg';

import ControlBar from 'components/ControlBar/ControlBar';
import EntryBox from 'components/MultiStepDemo/FlowBox/EntryBox/EntryBox';
import ExitBox from 'components/MultiStepDemo/FlowBox/ExitBox/ExitBox';
import ActionBox from 'components/MultiStepDemo/FlowBox/ActionBox/ActionBox';
import './MultiStepCreate.scss';

function MultiStepCreate() {
  const dispatch = useDispatch();

  const multiStepContext: any = useContext(MultiStepContext);
  const multiStepTemplatedExperienceAPI = new MultiStepTemplatedExperienceAPI();

  const { template } = multiStepContext;
  const startingEdges = template.entry.edges;

  const { id } = useParams();

  useEffect(() => {
    multiStepTemplatedExperienceAPI.getTemplate(id).then((template: any) => {
      multiStepContext.setTemplate(template);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  function onSaveHandle(status: 'Draft' | 'Active') {
    template.status = status;
    multiStepTemplatedExperienceAPI.createInstance(template).then(() => {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: { content: `Successfully created experience: "${template.name}"`, type: 'success' },
      });
      history.push('/templated-experiences');
    });
  }

  function onCancelHandle() {
    history.push('/templated-experiences');
  }

  function onNameEditHandle(name: string) {
    multiStepContext.updateTemplateName(name);
  }

  function recursiveRender(ids: string[]) {
    if (ids.length === 0) return <></>;
    return (
      <div className="render-container">
        <div className="column">
          {/* we could probably draw line and get the height of it based on where the last block is located */}
          {ids.length > 1 && <div className="vertical-line"></div>}
          {ids.map((id: string, idx: number) => (
            <div className="column-row" key={idx}>
              {idx > 0 && <ArrowWithline height={1000} style={{ position: 'absolute', marginLeft: '-52px', marginTop: `-53vh` }} />}
              <div className="item"> {<ActionBox step={template.nodes[id]} />} </div>
              {recursiveRender(template.nodes[id].edges)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <ControlBar
      name={template.name}
      status={template.status}
      onDraft={() => onSaveHandle('Draft')}
      onLaunch={() => onSaveHandle('Active')}
      onCancel={onCancelHandle}
      onNameEdit={onNameEditHandle}
    >
      <div className="multi-create__main__flow">
        <EntryBox />
        {recursiveRender(startingEdges)}
        <ExitBox />
      </div>
    </ControlBar>
  );
}

export default () => {
  return (
    <MultiStepProvider>
      <MultiStepCreate />
    </MultiStepProvider>
  );
};

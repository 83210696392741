import React, { useState, useContext } from 'react';
import FlowBoxV2 from 'components/MultiStepDemo/FlowBox/FlowBoxV2';
import FullPageOverlay from 'components/FullPageOverlay/FullPageOverlay';
import EntryCriteriaEdit from 'components/MultiStepDemo/EntryCriteriaEdit/EntryCriteriaEdit';

import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';

import Audience from 'components/MultiStepDemo/MiniBox/EntryCriteria/Audience/Audience';
import Schedule from 'components/MultiStepDemo/MiniBox/EntryCriteria/Schedule/Schedule';
import SendSettings from 'components/MultiStepDemo/MiniBox/EntryCriteria/SendSettings/SendSettings';

import './EntryBox.scss';

function EntryBox() {
  const multiStepContext: any = useContext(MultiStepContext);
  type tabType = 'audience_restriction' | 'audience_preferred' | 'schedule' | 'setting';

  interface overlayProps {
    isVisible: boolean;
    selectedTab: tabType;
  }

  const [overlayProps, _setOverlayProps] = useState<overlayProps>({
    isVisible: false,
    selectedTab: 'audience_restriction',
  });

  function openEditWindow(tabType: tabType) {
    _setOverlayProps({
      isVisible: true,
      selectedTab: tabType,
    });
  }

  function closeEditWindow() {
    _setOverlayProps({
      ...overlayProps,
      isVisible: false,
    });
  }

  function onSubmitHandle(payload: {
    ruleBody: object;
    seedRuleBody: object;
    ruleStringRepresentation: string;
    seedRuleStringRepresentation: string;
  }) {
    multiStepContext.updateEntry(payload);
    closeEditWindow();
  }

  function renderBody() {
    return (
      <>
        <Audience type="restriction" onClick={() => openEditWindow('audience_restriction')} />
        <Audience type="preferred" onClick={() => openEditWindow('audience_preferred')} />
        <Schedule onClick={() => openEditWindow('schedule')} />
        <SendSettings onClick={() => openEditWindow('setting')} />
      </>
    );
  }

  return (
    <>
      <div className="entry-box">
        <FlowBoxV2 type="entry" body={renderBody()} />
      </div>
      <FullPageOverlay isVisible={overlayProps.isVisible}>
        <EntryCriteriaEdit initialTab={overlayProps.selectedTab} onSubmit={onSubmitHandle} onCancel={closeEditWindow} />
      </FullPageOverlay>
    </>
  );
}

export default EntryBox;

import React, { useState } from 'react';
import {FlightButton, FlightCheckbox, FlightModal} from "@flybits/webapp-design-system-react";
import '../ConfirmModal.scss';

interface IProps {
  isVisible: boolean
  isDeleting: boolean
  onCancel: () => void
  onDelete: (isCheckboxSelected:string) => void
}

export default function ConfirmDeleteFolder(props:IProps) {
  enum CheckboxState {
    SELECTED = 'SELECTED',
    UNSELECTED = 'UNSELECTED'
  }
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<CheckboxState>(CheckboxState['UNSELECTED']);

  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={() => props.onCancel()}
      header={
        <div className="confirm-modal__header">
          <h3> Delete folder </h3>
        </div>
      }
      content={
        <div className="confirm-modal">
          <div className="confirm-modal__content">
            <FlightCheckbox
              label="Delete folder and its content"
              checkState={isCheckboxSelected}
              onSelect={() => setIsCheckboxSelected(isCheckboxSelected === CheckboxState['SELECTED'] ? CheckboxState['UNSELECTED'] : CheckboxState['SELECTED'])}
            />
            {isCheckboxSelected !== CheckboxState['SELECTED'] ?
              <div>
                <p>Only this folder will be deleted. All content inside will be moved to All Experiences.</p>
                <p>This cannot be undone. Do you still wish to continue?</p>
              </div> :
              <div>
                <p>All content inside this folder will be permanently deleted.</p>
                <p>This cannot be undone. Do you still wish to continue?</p>
              </div>
            }
          </div>
        </div>
      }
      footer={
        <div className="confirm-modal__footer">
          <FlightButton
            theme="secondary"
            onClick={() => props.onCancel()}
            label="Cancel"
          />
          <FlightButton
            onClick={() => props.onDelete(isCheckboxSelected)}
            label={isCheckboxSelected === CheckboxState['SELECTED'] ? 'Delete folder and content inside' : 'Delete folder only'}
            loading={props.isDeleting}
            disabled={props.isDeleting}
          />
        </div>
      }
    />
  )
}

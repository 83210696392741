
import React, { useState, useEffect, useContext, useRef } from 'react'
import { FlightButton, FlightCheckbox } from '@flybits/webapp-design-system-react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { parse, stringify } from 'flatted';
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import AudienceIframe from 'components/Audience/AudienceIframe/AudienceIframe';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';

import ScheduleEdit from 'components/MultiStepDemo/EntryCriteriaEdit/ScheduleEdit/ScheduleEdit';
import SettingEdit from 'components/MultiStepDemo/EntryCriteriaEdit/SettingEdit/SettingEdit';

import './EntryCriteriaEdit.scss';

type tabType = 'audience_restriction' | 'audience_preferred' | 'schedule' | 'setting';

const filterStringRep = (rep: string) => {
  return rep === '()' ? '' : rep;
}

interface IProps {
  initialTab: tabType;
  onSubmit: any;
  onCancel: any;
}

interface IFrame {
  iframe: any;
  stringRepresentation: string;
  ruleBody: object;
  hasReceived: boolean;
}

export default function EntryCriteriaEdit(props: IProps) {
  const { flags, traits, setTrait } = useFeatureFlag();
  const multiStepContext: any = useContext(MultiStepContext);
  const { entry, message } = multiStepContext.template.entry.setting;
  const [selectedTab, setSelectedTab] = useState(props.initialTab);
  const [hasRestrictionError, setHasRestrictionError] = useState(false);
  const [hasPreferrredError, setHasPreferredError] = useState(false);
  const [dontShowPreferredAudienceFirstTime, setDontShowPreferredAudienceFirstTime] = useState('UNSELECTED');
  const [preferredAudienceFirstTimeModalProps, setPreferredAudienceFirstTimeModalProps] = useState({
    isVisible: false,
    cb: () => {}
  });

  const [scheduleData, setScheduleData] = useState({
    start: null,
    end: null,
    timezone: {
      key: 0,
      name: '',
      offset: 0,
    },
  });

  const [settingData, setSettingData] = useState({
    entry: {
      isUnlimited: entry.isUnlimited,
      max: entry.max
    },
    message: {
      isUnlimited: message.isUnlimited,
      frequency: message.frequency,
      max: message.max,
      repeat: {
        key: message.repeat.key,
        name: message.repeat.name
      }
    }
  });

  const { ruleBody, seedRuleBody } = multiStepContext.template.entry.audience;

  useEffect(() => {
    window.addEventListener("message", messageListener, false);
    return () => window.removeEventListener("message", messageListener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const messageListener = (e: any) => {
    switch (e.data.command) {
      case 'setRule:restriction':
        setRestrictionData({...restrictionDataRef.current, ruleBody: parse(unescape(e.data.rule)), stringRepresentation: filterStringRep(e.data.stringRepresentation), hasReceived: true});
        setHasRestrictionError(false);
        break;
      case 'setRule:preferred':
        setPreferredData({...preferredDataRef.current, ruleBody: parse(unescape(e.data.rule)), stringRepresentation: filterStringRep(e.data.stringRepresentation), hasReceived: true});
        setHasPreferredError(false);
        break;
      case 'setError:restriction':
        setHasRestrictionError(true);
        setSelectedTab("audience_restriction");
        break;
      case 'setError:preferred':
        setHasPreferredError(true);
        setSelectedTab("audience_preferred");
        break;
      default:
        break;
    }
  }

  const [restrictionData, _setRestrictionData] = useState<IFrame>({
    iframe: undefined,
    stringRepresentation: '',
    ruleBody: {},
    hasReceived: false
  });

  const [preferredData, _setPreferredData] = useState<IFrame>({
    iframe: undefined,
    stringRepresentation: '',
    ruleBody: {},
    hasReceived: false
  });

  const restrictionDataRef = useRef(restrictionData);
  const setRestrictionData = (data: any) => {
    restrictionDataRef.current = data;
    _setRestrictionData(data);
  }

  const preferredDataRef = useRef(preferredData);
  const setPreferredData = (data: any) => {
    preferredDataRef.current = data;
    _setPreferredData(data);
  }

  // Schedule
  function onScheduleUpdate(schedule: any) {
    setScheduleData(schedule);
  }

  function onSettingUpdate(setting: any) {
    setSettingData(setting);
  }

  useEffect(() => {
    if (!restrictionData.iframe || (flags['tx_ao_enabled'] && !preferredData.iframe)) return;
    if (!restrictionData.hasReceived || (flags['tx_ao_enabled'] && !preferredData.hasReceived)) return;
    if (hasRestrictionError || hasPreferrredError) return;
    const cb = () => {
      props.onSubmit({
        audience: {
          ruleStringRepresentation: restrictionData.stringRepresentation,
          ruleBody: stringify(restrictionData.ruleBody) || '',
          seedRuleStringRepresentation: preferredData.stringRepresentation,
          seedRuleBody: stringify(preferredData.ruleBody) || '',
        },
        schedule: scheduleData,
        setting: settingData,
      });
    }
    if (!traits['tx_never_show_preferred_save_modal'] && preferredData.stringRepresentation) {
      setPreferredAudienceFirstTimeModalProps({
        isVisible: true,
        cb,
      });
    } else {
      cb();
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    restrictionData,
    preferredData,
    hasRestrictionError,
    hasPreferrredError
  ]);

  // Invoke apply for each child
  function onApply() {
    setRestrictionData({...restrictionData, hasReceived: false});
    setPreferredData({...preferredData, hasReceived: false});
    setTimeout(() => {
      if (restrictionData.iframe) {
        restrictionData.iframe.sendApply();
      }
      if (preferredData.iframe) {
        preferredData.iframe.sendApply();
      }
    });
  }

  return (
    <>
      <div className="entry-criteria-edit">
        <div className="entry-criteria-edit-main">
          <div className="entry-criteria-edit-main__nav">
            <a
              href="# "
              className={selectedTab === 'audience_restriction' ? 'selected' : ''}
              onClick={() => setSelectedTab('audience_restriction')}
            >
              Entry Audience
            </a>
            <a
              href="# "
              className={selectedTab === 'audience_preferred' ? 'selected' : ''}
              onClick={() => setSelectedTab('audience_preferred')}
            >
              Participation Audience
            </a>
            <a
              href="# "
              className={selectedTab === 'schedule' ? 'selected' : ''}
              onClick={() => setSelectedTab('schedule')}
            >
              Schedule
            </a>
            <a
              href="# "
              className={selectedTab === 'setting' ? 'selected' : ''}
              onClick={() => setSelectedTab('setting')}
            >
              Send Settings
            </a>
          </div>
          <div className="entry-criteria-edit-main__content">
            {/* Audience Restriction */}
            <AudienceIframe
              type="restriction"
              rule={ruleBody}
              isVisible={selectedTab === 'audience_restriction'}
              onInit={(iframe: any) => {
                setRestrictionData({...restrictionData, iframe})
              }}
              hideOptions={true}
            />
            {/* Audience Preferred */}
            <AudienceIframe
              type="preferred"
              rule={seedRuleBody}
              isVisible={selectedTab === 'audience_preferred'}
              onInit={(iframe: any) => {
                setPreferredData({...preferredData, iframe})
              }}
              hideOptions={true}
            />
            {/* Schedule */}
            <div className={selectedTab !== 'schedule' ? 'hidden' : 'show'}>
              <ScheduleEdit onChange={onScheduleUpdate}/>
            </div>
            {/* Setting */}
            <div className={selectedTab !== 'setting' ? 'hidden' : 'show'}>
              <SettingEdit onChange={onSettingUpdate}/>
            </div>
          </div>
        </div>
        <div className="entry-criteria-edit-footer">
          <FlightButton
            theme="secondary"
            label="Cancel"
            onClick={() => props.onCancel()}
          />
          <FlightButton
            theme="primary"
            label="Save"
            onClick={() => onApply()}
          />
        </div>
      </div>
      <ConfirmModal
        isVisible={preferredAudienceFirstTimeModalProps.isVisible}
        title="You set a preferred criteria"
        content={
          <div className="preferred-audience-notify-modal">
            <p> To generate accurate audience optimization suggestions, we will target up to 1,000 users outside of your preferred criteria. Once the suggestions are ready, you will be notified. </p>
            <FlightCheckbox
              label="Don't show this again"
              checkState={dontShowPreferredAudienceFirstTime}
              onSelect={() => setDontShowPreferredAudienceFirstTime(dontShowPreferredAudienceFirstTime === 'SELECTED' ? 'UNSELECTED' : 'SELECTED')}
            />
          </div>
        }
        cancelButton={{
          name: 'Cancel',
          onClick: () => { setPreferredAudienceFirstTimeModalProps({...preferredAudienceFirstTimeModalProps, isVisible: false}) }
        }}
        primaryButton={{
          name: 'Continue',
          onClick: () => {
            if (dontShowPreferredAudienceFirstTime === 'SELECTED') {
              setTrait('tx_never_show_preferred_save_modal', true);
            }
            preferredAudienceFirstTimeModalProps.cb() }
        }}
      />
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { Position } from 'react-flow-renderer';
import './Content.scss';
import Handler from 'components/Journey/Handler/Handler';
import { StepTrigger, StepWrapper } from 'interface/journey.interface';
import { getTriggerPosition } from 'helpers/journey.helper';
import { MiniCardContent } from 'components/Content/MiniCardContent/MiniCardContent';
import MiniCard from 'components/Shared/MiniCard/MiniCard';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ContentAPI from 'services/api/content.api';
import InAppContentIcon from 'assets/icons/in-app.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen-no-fill.svg';
import { FlightTooltip, getIcon } from '@flybits/webapp-design-system-react';
import LockIcon from 'assets/icons/icons8-lock.svg';
import LockIconOutline from 'assets/icons/lock-outline.svg';
import { prepareContentForIframe } from 'helpers/content.helper';
const ErrorIcon = getIcon('warning', '');



export default function Content(step:StepWrapper = {}) {

  let className = 'content';
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const experience = !isEmpty(reduxTemplatedExperienceState.template)
    ? reduxTemplatedExperienceState.template
    : reduxTemplatedExperienceState.instance;
  let [content, setContent] = useState(experience?.steps?.find((expStep: any) => expStep.type === 'content' && expStep.id === step?.id));
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const contentAPI = new ContentAPI();
  const isNotEditable = experience?.status === 'Active';
  const isNearByTemplate = experience?.steps?.find((step:any) => step.type === 'audience' && step?.data?.trigger?.type === "nearby-location");

  if (isNotEditable) {
    className += ' not-clickable';
  }

  if ( content?.data?.hasError && content?.data?.showError) {
    className += ' error';
  }

  useEffect(() => {
    const getContentInstance = async () => {
      if(content?.id) {
        const contentInstance = await contentAPI.getInstance(content?.id);
        setContent({...content, data: contentInstance})
      }
    }
    getContentInstance();
    // eslint-disable-next-line
  }, []);


  // TODO: uncomment after concept approval
  // const onRemove = () => {
  //   step?.data?.onRemove?.(step);
  // }


  const showContentEditModal = (event:any) => {
    if(event.key === 'Enter' || event.type === "dblclick") {
      if (!isNotEditable) {
        openContentModal(content?.data);
      }
    }
    // TODO: uncomment after concept approval
    // if(event.key === 'Backspace') {
    //   onRemove();
    // }
  }

  const openContentModal = (content: any) => {
      if(isNearByTemplate) {
        if(content.id || content.templateId) {
          let preparedContent = prepareContentForIframe(content?.actionBody, false);
          step?.data?.setContentIframeProps({
            ...step.data.contentIframeProps,
            isVisible: true,
            id: content?.actionBody?.templateId || content.id,
            isEdit: true,
            payload: preparedContent,
            isContentBlueprint: content.actionType === 'content-blueprint'
          });
        } else {
          step?.data?.setContentModalData({
            ...step.data.contentModalData,
            isVisible: true,
            showTemplates: true,
            selectedId: content?.id || ''
          });
        }
      } else {
        step?.data?.setContentModalData({
          ...step.data.contentModalData,
          isVisible: true,
          selectedId: content?.id
        });
      }
  };


  const handleShowEditIcon = (option: boolean) => {
    if (className.indexOf('not-clickable') === -1) {
      setShowEditIcon(option);
      if(className.indexOf('error') !== -1) {
        setShowErrorIcon(!option);
      }
    }
  }

  return (
    <button
      className={className}
      onDoubleClick={showContentEditModal}
      onKeyDown={showContentEditModal}
      onClick={showContentEditModal}
      onMouseEnter={() => handleShowEditIcon(true)}
      onMouseLeave={() => handleShowEditIcon(false)}
    >
      <Handler action="in" type="target" position={Position.Left} />
      {/*// TODO: uncomment after concept approval*/}
      {/*<a href={'/'} className={`${className}__remove`} onClick={onRemove}>X</a>*/}
      <div className="content__header">
        <div className="content__header__left">
          <img className="content__header__icon" src={InAppContentIcon} alt="In-App Content Icon" />
          <div className="content__header__title">In-App Content</div>
        </div>
        {isNotEditable ? (
          <FlightTooltip
            className="flow-box__card-container__card__step-header__tooltip"
            direction="top"
            isEnabled={true}
            description={isNotEditable && "You can't edit experiences that are Active. " +
            "You need to deactivate your experience in order to edit this component." }
            delay={0}
          >
            <img
              src={isNotEditable ? LockIcon : LockIconOutline}
              alt="QuestionIcon"
              className="flow-box__card-container__card__step-header__question"
            />
          </FlightTooltip>
        ) : showErrorIcon ? (
          <div className="flow-box__card-container__card__step-header__error-icon">{ErrorIcon}</div>
        ) : (
          <EditIcon
            style={{fill: '#4992FD'}}
            className={`flow-box__card-container__card__step-header__${showEditIcon ? 'edit-show' : 'edit'}`}
          />
        )}
      </div>
      <div className="content__body">
        <MiniCard title={''} icon={''} type="content" id={content?.id}>
          <MiniCardContent content={content?.data} hideCollapse={true} isNearByTemplate={isNearByTemplate} />
        </MiniCard>
      </div>
      {step?.data?.stepData?.triggers?.map((trigger: StepTrigger) => {
        return <Handler
          key={trigger.action}
          triggerLevel={getTriggerPosition(step?.data?.stepData?.triggers, trigger)}
          type="source"
          position={Position.Right}
          action={trigger.action}
        />
      })}
    </button>
  );
}

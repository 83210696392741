import { StepTrigger } from 'interface/journey.interface';


/*
  This function defines the position of the step in/out joiners,
  based on the number ob triggers in the step
 */
export const getTriggerPosition = (triggers: StepTrigger[] = [], trigger: StepTrigger) => {
  let triggerPosition = 3; // middle from maximum 5
  if(triggers.length === 1) {
    triggerPosition = 3;
  }
  if(triggers.length === 2) {
    if(triggers.indexOf(trigger) === 0) triggerPosition = 1;
    if(triggers.indexOf(trigger) === 1) triggerPosition = 5;
  }
  if(triggers.length === 3) {
    if(triggers.indexOf(trigger) === 0) triggerPosition = 1;
    if(triggers.indexOf(trigger) === 1) triggerPosition = 3;
    if(triggers.indexOf(trigger) === 2) triggerPosition = 5;
  }
  if(triggers.length === 4) {
    if(triggers.indexOf(trigger) === 0) triggerPosition = 1;
    if(triggers.indexOf(trigger) === 1) triggerPosition = 2;
    if(triggers.indexOf(trigger) === 2) triggerPosition = 4;
    if(triggers.indexOf(trigger) === 3) triggerPosition = 5;
  }
  if(triggers.length === 5) {
    if(triggers.indexOf(trigger) === 0) triggerPosition = 1;
    if(triggers.indexOf(trigger) === 1) triggerPosition = 2;
    if(triggers.indexOf(trigger) === 2) triggerPosition = 3;
    if(triggers.indexOf(trigger) === 3) triggerPosition = 4;
    if(triggers.indexOf(trigger) === 4) triggerPosition = 5;
  }
  return triggerPosition;
}

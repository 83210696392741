
import React, { useState, useEffect } from 'react';

import { times, toPairs, flatten } from 'lodash';
import { ruleToArr } from 'helpers/rule.helper';

import {
  conditionOptions,
  booleanOptions,
  getOperatorOptions,
  getOperatorByKey,
  toString
} from 'helpers/rule-builder.helper';

import Skeleton from 'react-loading-skeleton';
import { FlightSelectSearchable, FlightSelect, FlightButton, FlightTextInput } from '@flybits/webapp-design-system-react';
import { getIcon } from '@flybits/webapp-design-system-react';
import './AudienceEdit.scss';

interface IProps {
  type: string;
  isVisible: boolean;
  ruleStringRepresentation: string;
  plugins: any;
  onChange: (payload: {type: string, condition: any, audiences: any}) => void,
}

export default function AudienceEdit(props: IProps) {

  const [pluginOptionsImmutable, setPluginOptionsImmutable] = useState([]);
  const [pluginOptions, setPluginOptions] = useState([]);

  const [audiences, setAudiences] = useState<any>([]);
  const [condition, setCondition] = useState(conditionOptions[0]);
  const [isEditing, setIsEditing] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const audienceArr = ruleToArr(props.ruleStringRepresentation!!);
    if (audienceArr.data.length > 0) setIsNew(false);
  }, [props.ruleStringRepresentation]);

  // Plugin info received from parent
  useEffect(() => {
    if (!props.plugins) return;
    let _pluginOptions = props.plugins.map((plugin: any, idx: number) => {
      return toPairs(plugin.values).map(([key, value]: [any, any]) => {
        return {
          key: key + idx,
          name: plugin.name + ' / ' + value.name,
          stringRep: value.uid,
          valueType: value.valueType,
          icon: value.iconUrl,
        }
      });
    });
    setPluginOptions(flatten(_pluginOptions));
    setPluginOptionsImmutable(flatten(_pluginOptions));
    setIsLoaded(true);
  }, [props.plugins]);

  // initialize form from given stringRepresentation if it exists.
  useEffect(() => {
    if (pluginOptions.length === 0) return;
    const audienceArr = ruleToArr(props.ruleStringRepresentation!!);
    let _audiences = audienceArr.data.map((audience: any) => {
      const [operator, stringRep, attribute] = audience.delimittedRuleStringRepresentation;
      return {
        plugin: pluginOptions.find((option: any) => option.stringRep === stringRep),
        operator: getOperatorByKey(operator),
        attribute: ["true", "false"].includes(attribute) ? {key: attribute, name: attribute} : attribute,
        stringRep: audience.ruleStringRepresentation,
       };
    })
    setAudiences(_audiences);
    setCondition(props.ruleStringRepresentation.indexOf('Or') > -1 ? conditionOptions[1] : conditionOptions[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    props.onChange({
      type: props.type,
      condition,
      audiences
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audiences, condition]);

  function addAudienceRow(payload?: {plugin?: any, operator?: any, attribute?: any, stringRep?: string}) {
    const empty = {
      plugin: payload?.plugin,
      operator: payload?.operator,
      attribute: payload?.attribute,
      stringRep: payload?.stringRep,
    }
    setAudiences([...audiences, empty]);
    setIsEditing(true);
  }

  function removeAudienceRow(idx: number) {
    audiences.splice(idx, 1);
    setAudiences([...audiences]);
    if (audiences.length === 0 || (audiences.length - 1) < idx) {
      setIsEditing(false);
    }
  }

  function filterPluginOptions(keyword: string) {
    const _pluginOptions = pluginOptionsImmutable.filter((filter: any) => {
      const name = filter.name.toLowerCase();
      return name.indexOf(keyword.toLowerCase()) > -1;
    });
    setPluginOptions(_pluginOptions);
  }

  function selectPluginOption(idx: number, plugin: any) {
    audiences[idx].plugin = plugin;
    audiences[idx].operator = undefined;
    audiences[idx].attribute = undefined;
    setAudiences([...audiences]);
    resetPluginOptions();
    setIsEditing(false);
  }

  function selectOperatorOption(idx: number, operator: any) {
    audiences[idx].operator = operator;
    audiences[idx].stringRep = toString(audiences[idx]);
    setAudiences([...audiences]);
  }

  function selectAttributeOption(idx: number, attribute: any) {
    audiences[idx].attribute = attribute;
    audiences[idx].stringRep = toString(audiences[idx]);
    setAudiences([...audiences]);
  }

  function resetPluginOptions() {
    setPluginOptions([...pluginOptionsImmutable]);
  }

  function renderAttributeInput(idx: number) {
    const valueType = audiences[idx].plugin.valueType;
    switch (valueType) {
      case 'bool':
        return (
          <FlightSelect
            label=""
            options={booleanOptions}
            selected={audiences[idx].attribute}
            handleOptionClick={(attribute: any) => selectAttributeOption(idx, attribute)}
            dropdownMaxHeight="170px"
            width="270px"
          />
        )
      case 'float':
      case 'int':
      case 'string':
        return (
          <FlightTextInput
            label=""
            type="text"
            value={audiences[idx].attribute}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectAttributeOption(idx, e.target.value)}
            width="270px"
          />
        )
    }
  }

  function audienceRow(audience: any, idx: number) {
    return (
      <div className="audience-edit__main-row card" key={`condition_${idx}`}>
        <div className="left">
          <div className="number"> {idx + 1} </div>
        </div>
        <div className="right">
          <div className="top">
            <FlightSelectSearchable
              label=""
              className="plugin-searchbar"
              options={pluginOptions}
              selected={audiences[idx].plugin}
              handleSearch={(keyword: string) => filterPluginOptions(keyword)}
              handleOptionClick={(plugin: any) => selectPluginOption(idx, plugin)}
              dropdownMaxHeight="250px"
              width="545px"
            />
          </div>
          <div className="right-bottom">
            {audiences[idx].plugin && (
              <div>
                <div className="row">
                  <FlightSelect
                    label=""
                    options={getOperatorOptions(audiences[idx].plugin.valueType)}
                    selected={audiences[idx].operator}
                    handleOptionClick={(operator: any) => selectOperatorOption(idx, operator)}
                    dropdownMaxHeight="170px"
                    width="270px"
                  />
                  {renderAttributeInput(idx)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="absolute">
          <a href="# " className="remove" onClick={() => removeAudienceRow(idx)}>
            {getIcon('remove', null)}
          </a>
        </div>
      </div>
    )
  }

  function guideView(type: string) {
    return (
      <div className="audience-guide">
        <div className="audience-guide__image">
          {type === 'restriction'
            ? <img src={require('assets/images/audience-restriction.svg')} alt="audience restriction guide"/>
            : <img src={require('assets/images/audience-preferred.svg')} alt="audience preferred guide"/>
          }
        </div>
        <div className="audience-guide__detail">
          <p>
            {type === 'restriction'
              ? 'This will restrict the experience reach to only people within those restricitons. With no restrictions set, this experience will reach every user.'
              : 'Add conditions to describe the types of people who you think are most likely to enjoy your experience. People in this group are more likely to see the experience.'
            }
          </p>
          <FlightButton
            type="secondary"
            iconLeft="add"
            label="Add Condition"
            onClick={() => setIsNew(false)}
           />
        </div>
      </div>
    )
  }

  function editView() {
    return (
      <div className="audience-edit">
        <div className="audience-edit__header">
          <FlightSelect
            label=""
            hasLabelAnimation
            selected={condition}
            options={conditionOptions}
            handleOptionClick={(e) => setCondition(e)}
            width="50px"
          />
          <p> {condition.detail} </p>
        </div>
        <div className="audience-edit__main">
          {audiences.map((audience: any, idx: number) =>
            audienceRow(audience, idx)
          )}
          {!isEditing && (
            <div className="audience-edit__main-row">
              <div className="left">
                <FlightButton
                  iconLeft="add"
                  size="medium"
                  type="primary"
                  onClick={() => addAudienceRow()}
                />
              </div>
              <div className="right" onClick={() => addAudienceRow()}>
                <p className="primary"> Add Restriction </p>
              </div>
            </div>
          )}
          <div className="filler"></div>
        </div>
      </div>
    )
  }

  return (
    <>
      {props.isVisible && (
        <div className="audience">
        {isNew
          ? guideView(props.type)
          : !isLoaded
            ? (
              <div className="loading">
                {times(3, (i) => ( <Skeleton key={i} width="100%" height={110} />))}
              </div>
            )
            : editView()}
        </div>
      )}
    </>
  )
}

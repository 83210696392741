
import React, { useState, useEffect } from 'react'
import { FlightCheckbox, FlightNumberInput, FlightSelect } from '@flybits/webapp-design-system-react';

interface IProps {
  step: any;
  onChange: (e: any) => void;
}

export default function DelayEdit(props: IProps) {

  const periods = [
    {key: 'seconds', name: 'Seconds'},
    {key: 'minutes', name: 'Minutes'},
    {key: 'hours', name: 'Hours'},
    {key: 'days', name: 'Days'},
    {key: 'weeks', name: 'Weeks'},
    {key: 'months', name: 'Months'},
    {key: 'years', name: 'Years'},
  ];

  const [delayData, setDelayData] = useState({
    isDelay: false,
    time: {
      value: 0,
      unit: {
        key: 'seconds',
        name: 'Seconds'
      }
    }
  });

  // initialize
  useEffect(() => {
    if (props.step?.delay) {
      setDelayData(props.step?.delay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.onChange(delayData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayData]);

  return (
    <div className="setting-edit">
      <h2> Delay </h2>
      <div className="block">
        <div className="row">
          <FlightCheckbox
            label=""
            checkState={ delayData.isDelay ? 'SELECTED' : 'UNSELECTED'}
            onSelect={() => setDelayData({...delayData, isDelay: !delayData.isDelay})}
          />
          <p> Delay each notification by </p>
          <FlightNumberInput
            width={'70px'}
            disabled={!delayData.isDelay}
            value={delayData.time.value.toString()}
            onChange={(e) => {
              delayData.time.value = e.target.value;
              setDelayData({...delayData});
            }}
            className="number-input"
          />
          <FlightSelect
            label=""
            options={periods}
            selected={delayData.time.unit}
            hasLabelAnimation={true}
            handleOptionClick={(option)=> {
              delayData.time.unit = option;
              setDelayData({...delayData});
            }}
            dropdownMaxHeight={'200px'}
            disabled={!delayData.isDelay}
            dropdownDirection={'bottom'}
          />
        </div>
      </div>
    </div>
  )
}

import { AnalyticsPerformanceState } from 'redux/analytics/experience-performance/experience-performance.type';
import {
  AnalyticsPerformanceActionType
} from 'redux/analytics/experience-performance/experience-performance.type';
import { TEATimeline } from 'interface/templated-experience/analytics.interface';

type ActionType = {
  type: string,
  payload: TEATimeline,
};

let initialState: AnalyticsPerformanceState = {
  targetAudience: { data: [] },
  contentViews: { data: [] },
  pushDelivered: { data: [] },
  openedPush: { data: [] },
  contentClicks: { data: [] },
  isTargetAudienceLoading: false,
  isContentViewsLoading: false,
  isContentClicksLoading: false,
  isOpenedPushLoading: false,
  isPushDeliveredLoading: false,
  errorMessage: '',
};

export default (state: AnalyticsPerformanceState = initialState, action: ActionType) => {
  switch (action.type) {
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_TA_DATA:
      return {
        ...state,
        targetAudience: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_CV_DATA:
      return {
        ...state,
        contentViews: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_PD_DATA:
      return {
        ...state,
        pushDelivered: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_OP_DATA:
      return {
        ...state,
        openedPush: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_CC_DATA:
      return {
        ...state,
        contentClicks: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_TA_LOADING:
      return {
        ...state,
        isTargetAudienceLoading: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_OP_LOADING:
      return {
        ...state,
        isOpenedPushLoading: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_CV_LOADING:
      return {
        ...state,
        isContentViewsLoading: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_PD_LOADING:
      return {
        ...state,
        isPushDeliveredLoading: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_CC_LOADING:
      return {
        ...state,
        isContentClicksLoading: action.payload,
      }
    case AnalyticsPerformanceActionType.SET_PERFORMANCE_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return {
        ...state
      };
  }
};

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { history } from 'redux/store';

import AOTemplatedExperienceAPI from 'services/api/ao-templated-experience.api';

// Redux Actions
import { useSelector, useDispatch } from 'react-redux';
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import FinishIcon from 'assets/icons/end.svg';
import ControlBar from 'components/ControlBar/ControlBar';
import AODemoAudienceBox from 'components/AODemo/FlowBox/AODemoAudienceBox/AODemoAudienceBox';
import './AOCreate.scss';

export default function AOCreate() {

  const { id } = useParams();
  const aoTemplatedExperienceAPI = new AOTemplatedExperienceAPI();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);

  const dispatch = useDispatch();

  useEffect(() => {
    aoTemplatedExperienceAPI.getTemplate(id).then((template: any) => {
      reduxTemplatedExperienceAction.setTemplate({template});
    });
    return () => {
      reduxTemplatedExperienceAction.clearTemplate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Add snackbar support here
  function onLaunchHandle() {
    aoTemplatedExperienceAPI.createInstance(reduxTemplatedExperienceState.template).then(() => {
      dispatch({type: 'SHOW_SNACKBAR', payload: {content: `Successfully created experience: "${reduxTemplatedExperienceState.template.name}"`, type: 'success'}});
      history.push('/ao/templated-experiences');
    })
  }

  function onCancelHandle() {
    history.push('/ao/templated-experiences');
  }

  function onNameEditHandle(name: string) {
    reduxTemplatedExperienceAction.setName(name);
  }

  function renderStep(template: any, idx: number) {
    const props = { ...template, key: `step_${idx}` };
    if (template.isRoot) {
      return <AODemoAudienceBox {...props}/>
    }
  }

  return (
    <ControlBar
      name={reduxTemplatedExperienceState.template?.name || ''}
      onCancel={onCancelHandle}
      onLaunch={onLaunchHandle}
      onNameEdit={onNameEditHandle}
    >
      {reduxTemplatedExperienceState.template && (
        <div className="templated-experiences-create__main__flow">
          {reduxTemplatedExperienceState.template?.steps?.length && (
            reduxTemplatedExperienceState.template?.steps.map((_template: any, idx: number) => renderStep(_template, idx))
          )}
          <div>
            <img className='templated-experiences-create__finish-icon' src={FinishIcon} alt="FinishIcon"/>
          </div>
        </div>
      )}
    </ControlBar>
  )
}


import React, { useContext } from 'react'
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';
import ScheduleIcon from "assets/icons/calendar-with-clock-24.svg";

import './SendSettings.scss';

interface IProps {
  onClick: () => void
}

export default function SendSettings(props: IProps) {
  const multiStepContext: any = useContext(MultiStepContext);
  const { entry, message } = multiStepContext.template.entry.setting;

  function renderBody() {
    return (
      <div className="send-setting">
        <div className="send-setting-row">
          <b> Entry </b>
          <p> {entry.isUnlimited ? 'Unlimited' : entry.max} entries</p>
        </div>
        <div className="send-setting-row">
          <b> Message </b>
          <p> {message.isUnlimited ? 'Unlimited' : message.max} messages </p>
          {!message.isUnlimited && (
            <p> Repeat every {message.frequency} {message.repeat.key} </p>
          )}
        </div>
      </div>
    )
  }

  return (
    <MiniBox
      {...props}
      icon={ScheduleIcon}
      title="Send Settings"
      body={renderBody()}
    />
  )
}

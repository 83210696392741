import { isEmpty } from 'lodash';

// this function checks both for push (with content) & validates exp templates, please don't change this
  // action?.actionBody?.contentIds?.length => checks for in app- templates
  // action?.actionBody?.contentIds?.length => checks for push with attached content
export function isContentValid(action: any) {
  if(action.actionType === 'content-blueprint') {
    return !isEmpty(action.actionBody);
  }
  return action?.actionBody?.contentIds?.length && action?.actionBody?.body?.[0]?.payload?.data?.contentId?.value !== '';	
}



import React, { useState, useEffect } from 'react'
import { transformScheduleData, cleanupTimezone  } from "helpers/templated-experience.helper";
import ContentAPI from 'services/api/content.api';
import { v4 as uuidv4 } from 'uuid';

export const MultiStepContext = React.createContext({});

export default function MultiStepProvider(props: any) {

  const contentAPI = new ContentAPI();
  const [contents, setContents] = useState([]);
  const [template, setTemplate] = useState<any>({
    name: 'Untitled',
    status: 'Draft',
    entry: {
      audience: {
        ruleStringRepresentation: '',
        ruleBody: {},
        seedRuleStringRepresentation: '',
        seedRuleBody: {},
      },
      schedule: {
        start: 0,
        end: 0,
        timezone: ''
      },
      setting: {
        entry: {
          isUnlimited: true,
          max: 0
        },
        message: {
          isUnlimited: true,
          frequency: 0,
          max: 0,
          repeat: {
            key: 'days',
            name: 'Days'
          }
        }
      },
      edges: []
    },
    nodes: {
    }
  });

  // fetch content and expose it as value of provider
  useEffect(() => {
    contentAPI.getContentInstances().then((r: any) => setContents(r.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _createEmptyStep(): any {
    const id = uuidv4().toUpperCase();
    return {
      id,
      name: `Untitled #${id.substring(0, 5)}`,
      trigger: null,
      delay: null,
      audience: null,
      actions: [],
      edges: [],
    }
  }

  function generateEdgeData() {
    const edges: any = [];
    const startingEdges = template.entry.edges;
    const childNodes = Object.keys(template.nodes);
    startingEdges.forEach((vertex: any) => edges.push(['id0', vertex]));
    childNodes.map((id: any) => template.nodes[id].edges.forEach((vertex: any) => edges.push([id, vertex])))
    return edges;
  }

  // perform bulk update to migrate children to a different parent node.
  function bulkUpdateTrigger(nodes: string[], parentTrigger: object) {
    if (nodes?.length === 0) return;
    nodes.forEach((id: string) => {
      template.nodes[id].trigger.shift();
      template.nodes[id].trigger.unshift(parentTrigger);
    })
  }

  // This is when user clicks on "+" attached to the entry criteria
  function prependStep(): any {
    const emptyStep = _createEmptyStep();

    // override previous immediate children of entry criteria to the newly created parent aka 'emptyStep'
    bulkUpdateTrigger(template.entry.edges, {
      step: {key: emptyStep.id, name: emptyStep.id},
      action: undefined,
      content: undefined,
    });

    // set the default trigger for the immediate step of the entry criteria.
    emptyStep.trigger = [{
      step: {key: "entry-criteria", name: "Entry Criteria"},
      action: undefined,
      content: undefined,
    }]

    emptyStep.edges = template.entry.edges;
    template.entry.edges = [emptyStep.id];
    template.nodes[emptyStep.id] = emptyStep;

    setTemplate({...template});
  }

  function appendStep(stepID: string): any {
    const emptyStep = _createEmptyStep();
    bulkUpdateTrigger(template.nodes[stepID].edges, {
      step: {key: emptyStep.id, name: emptyStep.id},
      action: undefined,
      content: undefined,
    });
    emptyStep.trigger = [{...emptyStep.trigger, step: {key: stepID, name: stepID}}];
    emptyStep.edges = template.nodes[stepID].edges;
    template.nodes[emptyStep.id] = emptyStep;
    template.nodes[emptyStep.id].edges = template.nodes[stepID].edges;
    template.nodes[stepID].edges = [emptyStep.id];
    setTemplate({...template});
  }

  function branchEntryStep() {
    const emptyStep = _createEmptyStep();
    emptyStep.trigger = [{...emptyStep.trigger, step: {key: "entry-criteria", name: "Entry Criteria"}}];
    template.entry.edges.push(emptyStep.id);
    template.nodes[emptyStep.id] = emptyStep;
    setTemplate({...template});
  }

  function branchActionStep(stepID: string) {
    const emptyStep = _createEmptyStep();
    emptyStep.trigger = [{...emptyStep.trigger, step: {key: stepID, name: stepID}}];
    template.nodes[stepID].edges.push(emptyStep.id);
    template.nodes[emptyStep.id] = emptyStep;
    setTemplate({...template});
  }

  // This is invoked after apply is clicked in the EntryCriteriaEdit.tsx
  function updateEntry(payload: any) {
    let _template = Object.assign({}, template);
    _template.entry.audience = payload.audience;
    _template.entry.schedule = transformScheduleData(payload.schedule);
    _template.entry.schedule.timezone = cleanupTimezone(_template.entry.schedule.timezone);
    _template.entry.setting = payload.setting;
    setTemplate({..._template});
  }

  // This is invoked after apply is clicked in the ActionEdit.tsx
  function updateStep(payload: {id: string, body: object}) {
    let _template = Object.assign({}, template);
    _template.nodes[payload.id] = Object.assign(_template.nodes[payload.id] || {}, payload.body);
    setTemplate({..._template});
  }

  // remove the step and update parent / child
  function removeStep(id: string) {
    // Base case: only 1 node in multi step builder
    // clear all parent/child in this case
    if (Object.keys(template.nodes).length === 1) {
      template.entry.edges = [];
      template.nodes = {};
    } else {
      const children = template.nodes[id].edges;
      // Case: selected node is child of entry criteria
      if (template.entry.edges.includes(id)) {
        const removeIdx = template.entry.edges.indexOf(id); // the location of id in entry edges array
        template.entry.edges.splice(removeIdx, 1); // remove id from the array
        template.entry.edges.push(...children); // transfer over the children to the parent
        delete template.nodes[id]; // delete the selected node
      } else { // Case: selected node is not a child of entry criteria
        const parentID: any = Object.keys(template.nodes).find((nodeID: any) => template.nodes[nodeID].edges.includes(id)); // the parent of step with id
        const removeIdx = template.nodes[parentID].edges.indexOf(id); // the location of id in entry edges array
        template.nodes[parentID].edges.splice(removeIdx, 1); // remove id from the array
        template.nodes[parentID].edges.push(...children); // transfer the child to the new parent
        delete template.nodes[id]; // delete the selected node
      }
    }
    setTemplate({...template});
  }

  function updateStepName(stepID: string, name: string) {
    template.nodes[stepID].name = name;
    setTemplate({...template});
  }

  function updateTemplateName(name: string) {
    template.name = name;
    setTemplate({...template});
  }

  function isEntryChild(stepID: string) {
    return !!template.entry.edges.includes(stepID);
  }

  return (
    <MultiStepContext.Provider value={{
      contents,
      template,
      setTemplate,
      updateTemplateName,
      isEntryChild,
      generateEdgeData,
      updateEntry,
      updateStep,
      updateStepName,
      prependStep,
      appendStep,
      removeStep,
      branchEntryStep,
      branchActionStep,
    }}>
      {props.children}
    </MultiStepContext.Provider>
  )
}

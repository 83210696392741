import React, { useEffect, useContext } from 'react';

import { PushFormContext } from 'components/PushMessage/PushFormProvider/PushFormProvider';
import { weblinkFormSchema, weblinkFormSchemaDraft } from 'validator/push-message.validator';
import useForm from 'hooks/useForm';

import {
  FlightTextInput,
  FlightTextArea,
} from '@flybits/webapp-design-system-react';

interface IProps {
  lang: string;
  index: number;
  optional: boolean;
}

export default function WebLinkForm(props: IProps) {

  const context: any = useContext(PushFormContext);

  const {data, onFieldChange, onFieldBlur, onFormSubmit} = useForm({
    formData: {
      title: context.formFields?.title?.[props.lang] || '',
      alert: context.formFields?.alert?.[props.lang] || '',
      url: context.formFields?.url?.[props.lang] || ''
    },
    formSchema: weblinkFormSchema,
    formSchemaDraft: weblinkFormSchemaDraft,
    isDraft: context.isDraft || props.optional,
    validateOnLoad: context.validateOnLoad && !props.optional,
  });

  // Notify parent view to support live preview update
  useEffect(() => {
    context.registerValidator(props.lang, onFormSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function updateField(key: string, value: string) {
    onFieldChange(key, value); // update useForm
    context.updateFormField(key, value); // update context
  }

  return (
    <>
      <FlightTextInput
        className="push-edit__input"
        label="Title"
        type="text"
        iconInput=""
        hasError={data.title.hasError}
        errorMessage={data.title.error}
        placeholderText="Title"
        hasClearIcon={false}
        width="100%"
        value={data.title.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField('title', e.target.value)}
        onBlur={() => onFieldBlur('title')}
      />
      <FlightTextArea
        className="push-edit__input"
        label="Message"
        hasError={data.alert.hasError}
        errorMessage={data.alert.error}
        disabled={false}
        maxLength={170}
        width="100%"
        value={data.alert.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField('alert', e.target.value)}
        onBlur={() => onFieldBlur('alert')}
      />
      <FlightTextInput
        className="push-edit__input"
        label="Weblink URL"
        type="text"
        iconInput=""
        hasError={data.url.hasError}
        errorMessage={data.url.error}
        placeholderText=""
        hasClearIcon={false}
        width="100%"
        value={data.url.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateField('url', e.target.value)}
        onBlur={() => onFieldBlur('url')}
      />
    </>
  )
}


import { useReducer, useEffect, useRef } from 'react';
import { forEach } from 'lodash';

export default function useForm({formData, formSchema, formSchemaDraft, isDraft, validateOnLoad}: {
  formData: object,
  formSchema: any,
  formSchemaDraft: any,
  isDraft: boolean,
  validateOnLoad: boolean
}) {

  // or language is not primary, then it is draft all the time
  const schema = isDraft ? formSchemaDraft : formSchema;
  const updatedStateRef = useRef<any>({});

  function validationReducer(state: any, action: any) {
    switch(action.type) {
      case 'changeField': {
        const key = action.payload.key;
        return {
          ...state,
          [key]: {
            ...state[key],
            value: action.payload.value,
          }
        }
      }
      case 'changeCustomField': {
        const key = action.payload.key;
        const value = action.payload.value.key;
        return {
          ...state,
          [key]: {
            ...state[key],
            value: value,
          }
        }
      }
      case 'validateField': {
        const key = action.payload.key;
        if (!action.payload.force) {
          if (!state[key].isDirty) {
            return {
              ...state,
            }
          }
        }
        const errors = schema(state)[key]().filter((res: any) => res[0] === false);
        const hasError = errors.length > 0;
        return {
          ...state,
          [key]: {
            ...state[key],
            isDirty: true,
            hasError,
            error: hasError ? errors[0][1] : '',
          }
        }
      }
      case 'validateForm': {
        forEach(state, (value: any, key: string) => {
          const errors = action.payload.schema(state)[key]().filter((res: any) => res[0] === false);
          const hasError = errors.length > 0;
          state = {
            ...state,
            [key]: {
              ...state[key],
              isDirty: true,
              hasError,
              error: hasError ? errors[0][1] : '',
            }
          }
        });
        updatedStateRef.current = state;
        return state;
      }
      default:
        break;
    }
  }

  function _convertToFormObject(data: any) {
    let formData: {[key: string]: any} = {};
    forEach(data, (value: any, key: string) => {
      formData[key] = {
        value,
        isDirty: false,
        hasError: false,
        error: '',
      }
    });
    return formData;
  }

  let [state, dispatch] = useReducer(validationReducer, _convertToFormObject(formData));

  useEffect(() => {
    if (validateOnLoad) {
      dispatch({
        type: 'validateForm',
        payload: {
          schema: formSchema,
        }
      });
    }
 }, [validateOnLoad, formSchema]);

  function onFieldChange (key: string, value: any) {
    dispatch({
      type: 'changeField',
      payload: {
        key,
        value
      }
    });
    dispatch({
      type: 'validateField',
      payload: {
        key
      }
    })
  }

  function onChangeCustomField (key: string, value: any) {
    dispatch({
      type: 'changeCustomField',
      payload: {
        key,
        value
      }
    });
    dispatch({
      type: 'validateField',
      payload: {
        key
      }
    })
  }

  function onFieldBlur (key: string) {
    dispatch({
      type: 'validateField',
      payload: {
        key,
        force: true,
      }
    })
  }

  function onFormSubmit () {
    dispatch({
      type: 'validateForm',
      payload: {
        schema,
      }
    });
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const keys = Object.keys(updatedStateRef.current);
        const isValid = keys.map((key: string) => updatedStateRef.current[key].hasError).every((bool: boolean) => !bool);
        resolve(isValid);
      });
    })
  }

  return {
    data: state,
    onFieldChange,
    onFieldBlur,
    onFormSubmit,
    onChangeCustomField
  }
}

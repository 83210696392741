import React from 'react';
import { ReactComponent as EmptyState } from 'assets/images/empty-state.svg';

import './EmptyState.scss';

const EmptyDraftState = () => {
  return (
    <div className="performance-empty-state">
      <EmptyState className="performance-empty-state__image" />
      <div className="performance-empty-state__title">No Data to show</div>
      <div className="performance-empty-state__description">Nothing to show yet, check back after users have been targeted.</div>
    </div>
  )
};

export default EmptyDraftState;

import React from 'react';
import { ReactComponent as LoadIcon } from 'assets/icons/loading.svg';
import './LoadingIcon.scss';

interface LoadingIconProps {
  className?: string;
}

export const LoadingIcon = (props: LoadingIconProps) => {
  const { className } = props;
  const MAIN_CLASS = 'loading-icon';

  return <LoadIcon className={`${MAIN_CLASS} ${className}`} />;
};

import React, { ReactElement, useState } from 'react';
import './MiniCard.scss';
import { useSelector } from 'react-redux';
import { ReactComponent as EditIcon } from 'assets/icons/edit-pen-no-fill.svg';
import { ReactComponent as LockIconOtline } from 'assets/icons/lock-outline.svg';
import { isEmpty } from 'lodash';
import { getIcon, FlightTooltip } from '@flybits/webapp-design-system-react';
import useFeatureFlag from 'hooks/useFeatureFlag';

interface MiniCardProps {
  id?: string;
  icon: string;
  title: string;
  type: 'content' | 'push' | string;
  children: ReactElement;
  onClick?: (e: any) => void;
}

export default function MiniCard(props: MiniCardProps) {
  const { icon, title, children, type, onClick } = props;
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const { flags } = useFeatureFlag();
  const templatedExperience = !isEmpty(reduxTemplatedExperienceState.template)
    ? reduxTemplatedExperienceState.template
    : reduxTemplatedExperienceState.instance;

  const getHasError = () => {
    if (
      templatedExperience?.showError &&
      (templatedExperience?.steps[0]?.actions?.find((item: any) => item?.actionType === 'experience')?.hasError ||
        (templatedExperience?.steps[0]?.actions?.find((item: any) => item?.actionType === 'push')?.hasError &&
          props.id ===
            templatedExperience?.steps?.[0]?.actions?.find((item: any) => item?.actionType === 'push')?.actionBody
              ?.body?.[0]?.payload?.data?.contentId?.value) ||
        templatedExperience?.steps[0]?.actions?.[0]?.hasError)
    )
      return true;
    return false;
  };

  const isLocked = !flags['tx_journey_builder'] && reduxTemplatedExperienceState?.temp?.viewMode !== 'journey' && templatedExperience.status === 'Active';
  const hasError = getHasError();
  const ErrorIcon = getIcon('warning', '');
  const getClassName = () => {
    if (isLocked) {
      return 'minicard__content minicard__content--not-clickable';
    }
    if (hasError) {
      return 'minicard__content minicard__content--error';
    }
    return 'minicard__content';
  };

  const handleShowEditIcon = (option: boolean) => {
    if (!flags['tx_journey_builder'] && reduxTemplatedExperienceState?.temp?.viewMode !== 'journey' && !hasError && !isLocked) {
      setShowEditIcon(option);
    }
  };

  const onKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      onClick && onClick(event);
    }
  };

  return (
    <div
      className={'minicard'}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={() => handleShowEditIcon(true)}
      onMouseLeave={() => handleShowEditIcon(false)}
    >
      {showEditIcon ? <EditIcon className="minicard__icon" /> : null}
      {hasError ? <div className="minicard__icon minicard__icon--error">{ErrorIcon}</div> : null}
      {isLocked ? (
        <FlightTooltip
          direction="right"
          isEnabled={true}
          description={
            "You can't edit experiences that are Active. You need to deactive your experience before you can edit this component"
          }
          delay={0}
        >
          <>
            <LockIconOtline className="minicard__locked-icon" />
          </>
        </FlightTooltip>
      ) : (
        ''
      )}
      <div className={getClassName()}>
        <header>
          {!!icon && <img src={icon} alt="boxIcon" />}
          {!!title && <div className={`title${type === 'content' ? '--content' : '--push'}`}>{title}</div>}
        </header>
        {children}
      </div>
    </div>
  );
}

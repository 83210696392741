
import React, { useState } from 'react'
import { XYPlot,
  XAxis,
  YAxis,
  ChartLabel,
  DiscreteColorLegend,
  HorizontalGridLines,
  MarkSeries,
  LabelSeries,
  Crosshair,
  makeWidthFlexible } from 'react-vis';
import moment from 'moment';
import './MultiInstance.scss';
import { GraphData } from 'interface/templated-experience/graph-data.interface';
import { numberWithCommas } from "helpers/templated-experience.helper";

interface MultiInstData {
  legendGraphData: {title: string; color: string}[];
  lineGraphData: any[];
  tabselected: string;
  xAxisLabels: string[];
  emptyGraph: boolean;
  hoveredNode: GraphData;
  timestampData: number[];
  reduxAnalyticsState: any;
}

const MAX_X_AXIS_TICK = 7;

export default function MultiInstanceGraph(props: MultiInstData) {
  const [tooltipData, setTooltipData] = useState<boolean>(false);
  if (props.legendGraphData.length === 0) return null;
  const FlexibleXYPlot = makeWidthFlexible(XYPlot);

  function formatNumber(yAxisLabel:any) {
    if(yAxisLabel.toString().length > 6) {
      return yAxisLabel/1000000 + 'M'
    } else if(yAxisLabel.toString().length > 3) {
      return yAxisLabel/1000 + 'K'
    } else {
      return yAxisLabel;
    }
  }

  const tooltipTitleDate = () => {
    return moment(props.timestampData[props.hoveredNode.x] * 1000).utc().format('dddd, MMM D, YYYY');
  }

  const tooltipPercentValue = (lineGraphDataProp: any, index: number) => {
    let denominatorValue = 0;
    if (props.tabselected === 'views') {
      denominatorValue = props.reduxAnalyticsState.targetAudienceRecords.data[props.hoveredNode.x].value;
    } else {
      denominatorValue = props.reduxAnalyticsState.viewRecords[index].data[props.hoveredNode.x].value;
    }
    const numeratorValue = lineGraphDataProp.data.find((d:GraphData) => (d.x === props.hoveredNode.x));
    return denominatorValue ? numeratorValue.y/denominatorValue * 100 : 0;
  }

  const crosshairData = (lineGraphData: any) => {
    if(props.hoveredNode) {
      return lineGraphData.map((lGData:any, index:number) => {
        const labelData = props.legendGraphData[index].title;
        const lineGraphDataProp = lGData.props;
        const tooltipValue = lineGraphDataProp.data.find((d:GraphData) => (d.x === props.hoveredNode.x));
        const keyValue = 'crossHair_' + index.toString();
        if(tooltipValue) {
          return (
            <div className="crossHair_col" key={keyValue}>
              <div className={lineGraphDataProp.className}></div>
              <div className="crossHair_col__name">{labelData}</div>
              <div className="crossHair_col__value">
                {numberWithCommas(tooltipValue.y)}
                <b> ({tooltipPercentValue(lineGraphDataProp, index)}%)</b>
              </div>
            </div>)
        } else {
          return(null);
        }
      });
    }
  }

  return (
    <div className="multi-instance-graph-container">
      <div className="multi-instance-legend-container">
        <DiscreteColorLegend items={props.legendGraphData} />
      </div>
      <FlexibleXYPlot height={300} margin={{left: 70, right:70}}
        onMouseLeave={() => setTooltipData(false)}
        onMouseEnter={() => setTooltipData(true)} >
        <XAxis
          style={{ strokeWidth: '1px', fontSize: '14px' }}
          tickTotal={Math.min(props.xAxisLabels?.length || 0, MAX_X_AXIS_TICK)}
          tickFormat={v => props.xAxisLabels[v] || ''}
        />
        <YAxis style={{ strokeWidth: '0px', fontSize: '14px' }}
          left={-80} width={150} tickTotal={4}
          tickFormat={yAxisLabel => formatNumber(yAxisLabel)} />
        <ChartLabel text={`${props.tabselected==='views' ? 'Unique Views' : 'Unique Clicks'}`} className="alt-y-label"
          includeMargin={true} xPercent={0.01} yPercent={0.03}
          style={{ transform: 'rotate(-90)', textAnchor: 'end' }} />
        <MarkSeries data={[{ x: 0, y: 0 }]} style={{ display: 'none' }} />
        <HorizontalGridLines />
        {props.lineGraphData}
        {(props.emptyGraph) ? (
          //set an empty graph with y axis showing value 4
          <LabelSeries data={[{ x: 0, y: 4, label: '' }]} />
        ) : null }
        {(tooltipData) ? (
          <Crosshair values={[props.hoveredNode]} className='crossHair_container'>
            <b>{tooltipTitleDate()}</b>
            {crosshairData(props.lineGraphData)}
          </Crosshair>
        ) : null }
      </FlexibleXYPlot>
    </div>
  )
}

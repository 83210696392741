import React, { useState, useEffect } from 'react';
import {FlightCard} from "@flybits/webapp-design-system-react";
import "./DelayBox.scss";
import ArrowIcon from "assets/icons/arrow__new.svg";
import ClockIcon from "assets/icons/clock-fill.svg";
import {covertSecondsToPeriod} from "helpers/templated-experience.helper";

export default function DelayBox(props:any) {

  const [delayData, setDelayData] = useState({
    title: 'Delay',
    icon: ClockIcon,
    body: <div></div>,
    placeholder: '',
    onBoxClick: ()=>{},
  });

  useEffect(() => {
    let newBody = <div></div>;
    if(props.actionType === "push" && props.actionBody?.delivery?.delay) {
      let periodValue = covertSecondsToPeriod(props.actionBody?.delivery?.delay)?.value;
      let periodName = covertSecondsToPeriod(props.actionBody?.delivery?.delay)?.period;
      periodName = Math.round(periodValue) > 1 ? periodName : periodName.slice(0, -1);
      let delay = `${periodValue} ${periodName}`;
      newBody = <div className="delay-box__description">Wait {delay}</div>;
    }

    setDelayData({
      ...delayData,
      body: newBody
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props]);


  return <div className="delay-box">
    <button
      className="delay-box__button"
      onClick={()=>props.onBoxClick()}
    >
      <FlightCard
        className="delay-box__card"
        header={''}
        body={
          <div className="delay-box__body">
            <img className="delay-box__icon" src={ClockIcon} alt="boxIcon" />
            <div className="delay-box__title">
              {delayData.title}
            </div>
            {delayData.body}
          </div>
        }
      />
    </button>
    {!props.hideArrow ? <img className="delay-box__arrow" src={ArrowIcon} alt="ArrowIcon" /> : null}
  </div>
}

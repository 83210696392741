
import DeveloperHubIndex from './DeveloperHub/DeveloperHubIndex';
import ThemeHub from './ThemeHub/ThemeHub';

import HomeIndex from './Home/HomeIndex';
import RedirectIndex from './Redirect/RedirectIndex';

import TemplatedExperienceIndex from './TemplatedExperience/Index/TemplatedExperienceIndex';
import TemplatedExperienceCreate from './TemplatedExperience/Create/TemplatedExperienceCreate';
import TemplatedExperienceEdit from './TemplatedExperience/Edit/TemplatedExperienceEdit';
import TemplatedExperienceView from './TemplatedExperience/View/TemplatedExperienceView';
import TemplateSelection from './TemplatedExperience/TemplateSelection/TemplateSelection';
import LibrarySelection from './TemplatedExperience/LibrarySelection/LibrarySelection';

// Audience Optimization Demo
import AOIndex from './AODemo/Index/AOIndex';
import AOSelect from './AODemo/Select/AOSelect';
import AOCreate from './AODemo/Create/AOCreate';
import AOEdit from './AODemo/Edit/AOEdit';

// Multi-Step FIS Demo
import MultiIndex from './MultiStepDemo/Index/MultiStepIndex';
import MultiSelect from './MultiStepDemo/Select/MultiStepSelect';
import MultiCreate from './MultiStepDemo/Create/MultiStepCreate';
import MultiEdit from './MultiStepDemo/Edit/MultiStepEdit';

import NotFoundIndex from './NotFound/NotFoundIndex';
import NoAccess from './NoAccess/NoAccess';

export default {
  DeveloperHubIndex,
  ThemeHub,

  HomeIndex,
  RedirectIndex,
  TemplatedExperienceIndex,
  TemplatedExperienceCreate,
  TemplatedExperienceEdit,
  TemplatedExperienceView,
  TemplateSelection,
  LibrarySelection,

  AOIndex,
  AOSelect,
  AOCreate,
  AOEdit,

  MultiIndex,
  MultiSelect,
  MultiCreate,
  MultiEdit,

  NoAccess,
  NotFoundIndex
}


import React from 'react'
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';
import ScheduleIcon from "assets/icons/calendar-with-clock-24.svg";

import './Trigger.scss';

interface IProps {
  node: any;
  onClick: () => void;
}

export default function Trigger(props: IProps) {
  const trigger = props.node.trigger || [];

  function isEntryCriteriaTrigger() {
    return trigger.some((trig: any) => trig.step?.key === 'entry-criteria');
  }

  function renderBody() {
    if (isEntryCriteriaTrigger()) {
      return <p> Entry Criteria </p>
    } else {
      return (
        trigger.map((trig: any, idx: number) =>
          <div className="trigger-container" key={idx}>
            <p> <b>Step:</b> {trig.step?.name ? trig.step?.name : 'N/A'} </p>
            <p> <b>Content:</b> {trig.content?.name ? trig.content?.name : 'N/A'} </p>
            <p> <b>Action:</b> {trig.action?.name ? trig.action?.name : 'N/A' } </p>
          </div>
        )
      )
    }
  }

  return (
    <MiniBox
      {...props}
      icon={ScheduleIcon}
      title="Trigger"
      body={renderBody()}
    />
  )
}

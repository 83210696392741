import React from 'react';
import { Position } from 'react-flow-renderer';
import Handler from 'components/Journey/Handler/Handler';
import './Finish.scss';


export default function Finish() {
  return (
    <button
      className="finish"
      onClick={(e) => e.preventDefault()}
    >
      <Handler action="in" type="target" position={Position.Left} />
      <div className="finish__body">
        <div>FINISH</div>
      </div>
    </button>
  );
}

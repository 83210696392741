import localforage from 'localforage';

/**
 * Offline Browser storage utility using localforage.
 * https://github.com/localForage/localForage
 *
 * Localforage uses async storage. The call should be made using async/await or then/catch.
 */
class Storage {
  private store: LocalForage;
  constructor() {
    localforage.config({
      driver      : localforage.INDEXEDDB,
      name        : 'experience-studio',
      version     : 1.0,
      size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName   : 'templated_experience',
    });
    this.store = localforage;
  }

  clear(): Promise<void> {
    return this.store.clear();
  }

  getItem(key: string): Promise<any> {
    return this.store.getItem(key);
  }

  removeItem(key: string): Promise<void> {
    return this.store.removeItem(key);
  }

  setItem(key: string, value: any): Promise<any> {
    return this.store.setItem(key, value);
  }

  setItems(objects: {[key: string]: any}): Promise<any[]> {
    let promises: Promise<any>[] = [];
    Object.keys(objects).forEach((key: any) => {
      promises.push(this.setItem(key, objects[key]));
    });
    return Promise.all(promises);
  }
}

export default Storage;

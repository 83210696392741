
import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import {
  SetFunnelErrorAction,
  AnalyticsFunnelActionType,
  SetFunnelLoadingAction,
  SetFunnelDataAction,
} from 'redux/analytics/experience-funnel/experience-funnel.type';
import { TEASingleValue } from 'interface/templated-experience/analytics.interface';

const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();

// fetch Target Audience for Experience Funnel Graph
export function fetchFunnelTAData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FUNNEL_TA_LOADING));
      dispatch(setFunnelError(''));
      const getTargetAudienceCount = opts.ruleID ?
      templatedExperienceAnalyticsAPI.getTargetAudienceCount(opts.ruleID, opts.params) :
      templatedExperienceAnalyticsAPI.getTotalUsersCount();
      getTargetAudienceCount.then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FUNNEL_TA_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FUNNEL_TA_LOADING));
      });
    })
  }
}

// fetch Opened Push for Experience Funnel Graph
export function fetchFunnelOPData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FUNNEL_OP_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getOpenedPushCount(opts.pushID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FUNNEL_OP_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FUNNEL_OP_LOADING));
      });
    })
  }
}

// fetch Sent Push for Experience Funnel Graph
export function fetchFunnelSPData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FUNNEL_SP_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getSentPushCount(opts.pushID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FUNNEL_SP_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FUNNEL_SP_LOADING));
      });
    })
  }
}

// fetch Push Delivered for Experience Funnel Graph
export function fetchFunnelPDData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FUNNEL_PD_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getDeliveredPushCount(opts.pushID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FUNNEL_PD_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FUNNEL_PD_LOADING));
      });
    })
  }
}

// fetch Clicked on content from push for Experience Funnel Graph
export function fetchFunnelCOCFromPushData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FUNNEL_COCP_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getClickedOnContentFromPush(opts.pushID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FUNNEL_COCP_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FUNNEL_COCP_LOADING));
      });
    })
  }
}

// fetch viewed content count for Experience Funnel Graph
export function fetchFunnelVCData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FUNNEL_VC_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getViewedContentInAppCount(opts.experienceID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FUNNEL_VC_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FUNNEL_VC_LOADING));
      });
    })
  }
}

// fetch Clicked on content from experience in-app for Experience Funnel Graph
export function fetchFunnelCOCInAppData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FUNNEL_COCA_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getClickedOnContentInApp(opts.experienceID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FUNNEL_COCA_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FUNNEL_COCA_LOADING));
      });
    })
  }
}

// Fetch unique user viewed content (in-app) count without push delivered for Experience Flow Graph
export function fetchFlowPNDData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FLOW_PND_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getContentViewedCountWithoutPushDelivered(opts.experienceID, opts.pushID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FLOW_PND_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FLOW_PND_LOADING));
      });
    })
  }
}

// Fetch unique user viewed content (in-app) count without opening push for Experience Flow Graph
export function fetchFlowPNOVData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FLOW_PNOV_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getContentViewedCountWithoutPushOpened(opts.experienceID, opts.pushID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FLOW_PNOV_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FLOW_PNOV_LOADING));
      });
    })
  }
}

// Fetch unique user engaged content (in-app) count without opening push for Experience Flow Graph
export function fetchFlowPNOEData(opts:any) {
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setFunnelLoading(true, AnalyticsFunnelActionType.SET_FLOW_PNOE_LOADING));
      dispatch(setFunnelError(''));
      templatedExperienceAnalyticsAPI.getContentEngagedCountWithoutPushOpened(opts.experienceID, opts.pushID, opts.contentID, opts.params).then((res: any) => {
        dispatch(setFunnelData({value: res.data.value}, AnalyticsFunnelActionType.SET_FLOW_PNOE_DATA));
      }).catch((err: any) => {
        dispatch(setFunnelError(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject();
      }).finally(() => {
        dispatch(setFunnelLoading(false, AnalyticsFunnelActionType.SET_FLOW_PNOE_LOADING));
      });
    })
  }
}

// set Target Audience loading state for Experience Funnel Graph
function setFunnelLoading(payload: boolean, funnelType: string): SetFunnelLoadingAction {
  return {
    type: funnelType,
    payload,
  }
}

// set Target Audience data for Experience Funnel Graph
function setFunnelData(payload: TEASingleValue, funnelType: string): SetFunnelDataAction {
  return {
    type: funnelType,
    payload,
  }
}

// set Error for Experience Funnel Graph
function setFunnelError(payload: string): SetFunnelErrorAction {
  return {
    type: AnalyticsFunnelActionType.SET_FUNNEL_ERROR,
    payload,
  }
}

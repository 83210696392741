import TemplatedExperienceAnalyticsAPI from 'services/api/analytics.api';
import ContentAPI from 'services/api/content.api';
import { Content } from 'interface/content/content.interface';
import { TEATimeline, TEAContentTimeline } from 'interface/templated-experience/analytics.interface';
import {
  AnalyticsContentPerformanceActionType,
  SetContentPerformanceDataAction,
  SetContentPerformanceLoadingAction,
  SetContentPerformanceErrorAction,
} from './content-performance.type';

const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
const contentAPI = new ContentAPI();

// fetch multi content views for multi content Performance Graph
export const fetchMultiContentViewPerformanceData = (contentIDs: string[], parentIDs: string[], params: any, ruleID: string, defaultLanguage: string) => (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const contentPromises: Promise<Content>[] = [];
    const contentViewPromises: Promise<TEATimeline>[] = [];
    dispatch(setContentPerformanceLoading(true, AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_VIEW_LOADING));
    dispatch(setContentPerformanceErrorMessage(''));
    contentIDs.forEach((contentID) => {
      // to fetch content instance (to get content name)
      contentPromises.push(new Promise((resolve, reject) => {
        contentAPI.getInstanceWithoutData(contentID).then((content: Content) => {
          resolve(content);
        }).catch((res) => reject(res));
      }));
      // to fetch content view performance data
      contentViewPromises.push(new Promise((resolve, reject) => {
        templatedExperienceAnalyticsAPI.getContentViewsTimeline(contentID, {
          ...params,
          parentIDs: parentIDs.join(),
        }).then((res) => {
          resolve(res);
        }).catch((res) => reject(res));//err?.response?.data || err?.message || 'Unknown error');
      }));
    });

    // need to fetch for target audience to calculate content view percentage with the correct aggregate value (day, week, month)
    const targetAudienceData: TEATimeline = await new Promise((resolve, reject) => {
      const getTargetAudienceTimeline = ruleID ?
      templatedExperienceAnalyticsAPI.getTargetAudienceTimeline(ruleID, params) :
      templatedExperienceAnalyticsAPI.getTotalUsersTimeline({
        startTime: params.startTime,
        endTime: params.endTime,
        interval: params.aggregate,
      });
      getTargetAudienceTimeline.then((res: TEATimeline) => {
        resolve(res);
      }).catch((err) => reject(err));
    });
    dispatch(setContentPerformanceData(targetAudienceData, AnalyticsContentPerformanceActionType.SET_CONTENT_TA_PERFORMANCE_DATA))

    Promise.all(contentPromises).then((contents: Content[]) => {
      const contentNames: Array<string> = [];
      contents.forEach((content) => {
        if (content.localizations[defaultLanguage]) {
          contentNames.push(content.localizations[defaultLanguage].name);
        } else {
          contentNames.push(content?.localizations[Object.keys(content?.localizations || {})[0]]?.name || content.id);
        }
      });
      Promise.all(contentViewPromises).then((viewTimelines: TEATimeline[]) => {
        const contentViewTimelines: TEAContentTimeline[] = [];
        viewTimelines.forEach((timeline, idx) => {
          contentViewTimelines.push({
            contentName: contentNames[idx],
            data: timeline.data,
          });
        });
        dispatch(setContentPerformanceData(contentViewTimelines,
          AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_VIEW_DATA));
        resolve(contentViewTimelines);
      }).catch((err) => {
        dispatch(setContentPerformanceErrorMessage(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject(err);
      }).finally(() => {
        dispatch(setContentPerformanceLoading(false, AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_VIEW_LOADING));
      });
    }).catch((err) => {
      dispatch(setContentPerformanceErrorMessage(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
      dispatch(setContentPerformanceLoading(false, AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_VIEW_LOADING));
      reject(err);
    });
  });
};

// fetch multi content clicks for multi content Performance Graph
export const fetchMultiContentClickPerformanceData = (contentIDs: string[], parentIDs: string[], params: any, defaultLanguage: string) => (dispatch: any) => {
  return new Promise((resolve, reject) => {
    const contentPromises: Promise<Content>[] = [];
    const contentClicksPromises: Promise<TEATimeline>[] = [];
    dispatch(setContentPerformanceLoading(true, AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_CLICK_LOADING));
    dispatch(setContentPerformanceErrorMessage(''));
    contentIDs.forEach((contentID) => {
      // to fetch content instance (to get content name)
      contentPromises.push(new Promise((resolve, reject) => {
        contentAPI.getInstanceWithoutData(contentID).then((content: Content) => {
          resolve(content);
        }).catch((res) => reject(res));
      }));
      // to fetch content click performance data
      contentClicksPromises.push(new Promise((resolve, reject) => {
        templatedExperienceAnalyticsAPI.getContentClicksTimeline(contentID, {
          ...params,
          parentIDs: parentIDs.join(),
        }).then((res) => {
          resolve(res);
        }).catch((res) => reject(res));
      }));
    });
    Promise.all(contentPromises).then((contents: Content[]) => {
      const contentNames: Array<string> = [];
      contents.forEach((content) => {
        if (content.localizations[defaultLanguage]) {
          contentNames.push(content.localizations[defaultLanguage].name);
        } else {
          contentNames.push(content?.localizations[Object.keys(content?.localizations || {})[0]]?.name || content.id);
        }
      });
      Promise.all(contentClicksPromises).then((clickTimelines: TEATimeline[]) => {
        const contentClickTimelines: TEAContentTimeline[] = [];
        clickTimelines.forEach((timeline, idx) => {
          contentClickTimelines.push({
            contentName: contentNames[idx],
            data: timeline.data,
          });
        });
        dispatch(setContentPerformanceData(contentClickTimelines,
          AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_CLICK_DATA));
        resolve(contentClickTimelines);
      }).catch((err: any) => {
        dispatch(setContentPerformanceErrorMessage(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
        reject(err);
      }).finally(() => {
        dispatch(setContentPerformanceLoading(false, AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_CLICK_LOADING));
      });
    }).catch((err: any) => {
      dispatch(setContentPerformanceErrorMessage(err?.response?.data?.errors?.error || 'We’re sorry, there was a problem loading the data.'));
      dispatch(setContentPerformanceLoading(false, AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_CLICK_LOADING));
      reject(err);
    });
  });
};


function setContentPerformanceLoading(payload: boolean, type: string): SetContentPerformanceLoadingAction {
  return {
    type,
    payload,
  }
}

function setContentPerformanceData(payload: TEAContentTimeline[] | TEATimeline, type: string): SetContentPerformanceDataAction {
  return {
    type,
    payload,
  }
}

function setContentPerformanceErrorMessage(payload: string): SetContentPerformanceErrorAction {
  return {
    type: AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_ERROR,
    payload,
  }
}

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import useSetting from 'hooks/useSetting';
import { FlightDateRangePicker, FlightOverflowMenu } from '@flybits/webapp-design-system-react';
import useFeatureFlag from 'hooks/useFeatureFlag';
import AnalyticsPerformanceOptimizedChart from 'components/Analytics/AnalyticsPerformanceOptimizedChart/AnalyticsPeformanceOptimizedChart';
import ExperienceFunnel from 'components/Performance/ExperienceFunnel/ExperienceFunnel';
import ExperienceFlow from 'components/Performance/ExperienceFlow/ExperienceFlow';
import ContentEngagementBySource from 'components/Performance/ContentEngagementBySource/ContentEngagementBySource';
import ContentEngagementTable from 'components/Performance/ContentEngagementTable/ContentEngagementTable';
import ExperiencePerformance from 'components/Performance/ExperiencePerformance/ExperiencePerformance';
import MultiInstance from 'components/Performance/MultiInstance/MultiInstance';
import * as AnalyticsContentEngagementTableActions from 'redux/analytics/content-engagement/content-engagement.action';
import * as AnalyticsFunnelActions from 'redux/analytics/experience-funnel/experience-funnel.action';
import * as AnalyticsContentEngagementBySourceActions from 'redux/analytics/content-engagement-by-source/content-engagement-by-source.actions';
import * as AnalyticsPerformanceActions from 'redux/analytics/experience-performance/experience-performance.action';
import * as AnalyticsContentPerformanceActions from 'redux/analytics/content-performance/content-performance.action';

import './Performance.scss';

interface PerformanceProps {
  dateRange: Array<Date | null>;
  setDateRange: (dateRange: Array<Date | null>) => void;
}

interface GraphDisplayInfo {
  type: string | null;
  experienceID?: string | null;
  pushID?: string | null;
  experienceContentIDs?: string[] | null;
  pushContentID?: string | null;
  ruleID?: string | null;
}

interface AggregateIngore {
  isIgnorePerformanceAggregate?: boolean;
  isIgnoreMultiInstanceAggregate?: boolean;
}

const GRAPHS_DISPLAY_TYPES = {
  IN_APP_CONTENT: 'IN_APP_CONTENT',
  IN_APP_MULTI_CONTENT: 'IN_APP_MULTI_CONTENT',
  IN_APP_AMPLIFIED_CONTENT: 'IN_APP_AMPLIFIED_CONTENT',
  IN_APP_AMPLIFIED_MULTI_CONTENT: 'IN_APP_AMPLIFIED_MULTI_CONTENT',
  PUSH_BROADCAST: 'PUSH_BROADCAST',
  PUSH_BROADCAST_CONTENT: 'PUSH_BROADCAST_CONTENT',
  TRIGGERED_PUSH: 'TRIGGERED_PUSH',
  TRIGGERED_PUSH_CONTENT: 'TRIGGERED_PUSH_CONTENT',
};

const JOURNEY_TEMPLATE_TYPES = {
  CONTENT: 'content',
  TRIGGERED: 'triggered',
  REMINDER: 'reminder',
  BROADCAST: 'broadcast',
  LIST_BROADCAST: 'list-broadcast',
};

const UNRELATED_JOURNEY_STATUS = ['Draft', 'Scheduled'];

const AGGREGATE_DROPDOWN_MAP: Record<string, string> = {
  Daily: 'day',
  Weekly: 'week',
  Monthly: 'month',
};

// normalizeDate converts start and end Date to number in epoch and
//  normalizes the end Date to the beginning of date
const normalizeDate = (start: Date, end: Date): { startTime: number; endTime: number } => {
  const endDate = new Date(end);
  // set endDate to beginning of the next day (current timezone)
  endDate.setHours(24, 0, 0, 0);
  return {
    startTime: start.getTime() / 1000,
    endTime: endDate.getTime() / 1000,
  };
};

const getGraphDisplayInfo = (instance: any): GraphDisplayInfo => {
  const graphDisplayInfo: GraphDisplayInfo = { type: null };
  const journeyStep = instance?.steps[0];
  if (journeyStep) {
    const pushAction = journeyStep.actions?.find((action: any) => action.actionType === 'push');
    const experienceAction = journeyStep.actions?.find((action: any) => action.actionType === 'experience');
    graphDisplayInfo.ruleID = journeyStep.ruleID;
    graphDisplayInfo.pushID = pushAction?.actionID;
    graphDisplayInfo.pushContentID = pushAction?.actionBody?.body[0]?.payload?.data?.contentId?.value;
    switch (journeyStep?.trigger?.type) {
      case JOURNEY_TEMPLATE_TYPES.BROADCAST:
      case JOURNEY_TEMPLATE_TYPES.LIST_BROADCAST:
        if (graphDisplayInfo.pushID && graphDisplayInfo.pushContentID) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST_CONTENT;
        } else if (graphDisplayInfo.pushID) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST;
        }
        break;
      case JOURNEY_TEMPLATE_TYPES.TRIGGERED:
      case JOURNEY_TEMPLATE_TYPES.REMINDER:
        if (graphDisplayInfo.pushID && graphDisplayInfo.pushContentID) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH_CONTENT;
        } else if (graphDisplayInfo.pushID) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH;
        }
        break;
      case JOURNEY_TEMPLATE_TYPES.CONTENT:
      default:
        graphDisplayInfo.experienceID = experienceAction?.actionID;
        graphDisplayInfo.experienceContentIDs = experienceAction?.actionBody?.contentIds;
        if (
          graphDisplayInfo.experienceID &&
          graphDisplayInfo.pushID &&
          graphDisplayInfo.experienceContentIDs?.length &&
          graphDisplayInfo.experienceContentIDs?.length > 1
        ) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT;
        } else if (
          graphDisplayInfo.experienceID &&
          graphDisplayInfo.pushID &&
          graphDisplayInfo.experienceContentIDs?.length
        ) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT;
        } else if (
          graphDisplayInfo.experienceID &&
          graphDisplayInfo.experienceContentIDs?.length &&
          graphDisplayInfo.experienceContentIDs?.length > 1
        ) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT;
        } else if (graphDisplayInfo.experienceID && graphDisplayInfo.experienceContentIDs?.length) {
          graphDisplayInfo.type = GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT;
        }
        break;
    }
  }
  return graphDisplayInfo;
};

export default function Performance(props: PerformanceProps) {
  const { languages } = useSetting();
  const { flags } = useFeatureFlag();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const [journeyAnalyticsInfo, setJourneyAnalyticsInfo] = useState<GraphDisplayInfo>();
  const [dropdownValue, setDropdownValue] = useState('Daily');
  const [multiInstanceDropdownValue, setMultiInstanceDropdownValue] = useState<string>('Daily');
  const [tabSelected, setTabSelected] = useState('views');
  const reduxAnalyticsFunnelActions = useActions(AnalyticsFunnelActions);
  const reduxAnalyticsPerformanceActions = useActions(AnalyticsPerformanceActions);
  const reduxAnalyticsContentPerformanceActions = useActions(AnalyticsContentPerformanceActions);
  const reduxContentEngagementTableActions = useActions(AnalyticsContentEngagementTableActions);
  const reduxAnalyticsContentEngagementBySourceActions = useActions(AnalyticsContentEngagementBySourceActions);

  useEffect(() => {
    // Do not show Performance if journey is in Draft or Scheduled
    if (
      !UNRELATED_JOURNEY_STATUS.includes(reduxTemplatedExperienceState?.instance?.status) &&
      reduxTemplatedExperienceState?.instance?.status &&
      reduxTemplatedExperienceState?.instance?.id
    ) {
      setJourneyAnalyticsInfo(getGraphDisplayInfo(reduxTemplatedExperienceState.instance));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxTemplatedExperienceState.instance?.id]);

  useEffect(() => {
    if (props.dateRange[0] !== null && props.dateRange[1] !== null && journeyAnalyticsInfo) {
      const dateRange = normalizeDate(props.dateRange[0], props.dateRange[1]);
      fetchExperienceFunnelFlowData(dateRange.startTime, dateRange.endTime);
      fetchExperiencePerformanceData(dateRange.startTime, dateRange.endTime, {});
      fetchContentEngagementTableData(dateRange.startTime, dateRange.endTime);
      fetchContentEngagementBySourceData(dateRange.startTime, dateRange.endTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange, journeyAnalyticsInfo]);

  // fetch only content performance data when performance dropdown value changes
  useEffect(() => {
    if (props.dateRange[0] !== null && props.dateRange[1] !== null) {
      const dateRange = normalizeDate(props.dateRange[0], props.dateRange[1]);
      fetchExperiencePerformanceData(dateRange.startTime, dateRange.endTime, {
        isIgnoreMultiInstanceAggregate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownValue]);

  // fetch only multi instance content performance data when multi instance dropdown value changes
  useEffect(() => {
    if (props.dateRange[0] !== null && props.dateRange[1] !== null) {
      const dateRange = normalizeDate(props.dateRange[0], props.dateRange[1]);
      fetchExperiencePerformanceData(dateRange.startTime, dateRange.endTime, {
        isIgnorePerformanceAggregate: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiInstanceDropdownValue]);

  const fetchExperienceFunnelFlowData = (startTime: number, endTime: number) => {
    if (!journeyAnalyticsInfo?.type) return;
    const funnelParams = { startTime, endTime };
    // will not fetch funnel data for an in-app multi content journey
    if (journeyAnalyticsInfo.type !== GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT) {
      // fetch target audience count for all types but in-app multi content journey
      reduxAnalyticsFunnelActions.fetchFunnelTAData({
        ruleID: journeyAnalyticsInfo.ruleID,
        params: funnelParams,
      });
      // for valid in-app content journey with only one content instance
      if (
        journeyAnalyticsInfo.type === GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT &&
        journeyAnalyticsInfo.experienceID &&
        journeyAnalyticsInfo.experienceContentIDs?.length
      ) {
        // fetch viewed content count
        reduxAnalyticsFunnelActions.fetchFunnelVCData({
          experienceID: journeyAnalyticsInfo.experienceID,
          contentID: journeyAnalyticsInfo.experienceContentIDs[0],
          params: funnelParams,
        });
        // fetch clicked on content in-app fetchFunnelCOCInAppData
        reduxAnalyticsFunnelActions.fetchFunnelCOCInAppData({
          experienceID: journeyAnalyticsInfo.experienceID,
          contentID: journeyAnalyticsInfo.experienceContentIDs[0],
          params: funnelParams,
        });
      } else if (journeyAnalyticsInfo.type !== GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT) {
        // fetch sent push, push delivered, opened push counts
        reduxAnalyticsFunnelActions.fetchFunnelSPData({ pushID: journeyAnalyticsInfo.pushID, params: funnelParams });
        reduxAnalyticsFunnelActions.fetchFunnelPDData({ pushID: journeyAnalyticsInfo.pushID, params: funnelParams });
        reduxAnalyticsFunnelActions.fetchFunnelOPData({ pushID: journeyAnalyticsInfo.pushID, params: funnelParams });
        // fetch clicked on content from push if the push contains a content
        if (
          ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH].includes(
            journeyAnalyticsInfo.type,
          )
        ) {
          reduxAnalyticsFunnelActions.fetchFunnelCOCFromPushData({
            pushID: journeyAnalyticsInfo.pushID,
            contentID: journeyAnalyticsInfo.pushContentID,
            params: funnelParams,
          });
        }
        // fetch experience flow data for in-app amplified content
        if (
          [GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT, GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT].includes(
            journeyAnalyticsInfo.type,
          )
        ) {
          reduxAnalyticsFunnelActions.fetchFlowPNDData({
            pushID: journeyAnalyticsInfo.pushID,
            contentID: journeyAnalyticsInfo.pushContentID,
            experienceID: journeyAnalyticsInfo.experienceID,
            params: funnelParams,
          });
          reduxAnalyticsFunnelActions.fetchFlowPNOVData({
            pushID: journeyAnalyticsInfo.pushID,
            contentID: journeyAnalyticsInfo.pushContentID,
            experienceID: journeyAnalyticsInfo.experienceID,
            params: funnelParams,
          });
          reduxAnalyticsFunnelActions.fetchFlowPNOEData({
            pushID: journeyAnalyticsInfo.pushID,
            contentID: journeyAnalyticsInfo.pushContentID,
            experienceID: journeyAnalyticsInfo.experienceID,
            params: funnelParams,
          });
        }
      }
    }
  };

  const fetchExperiencePerformanceData = (startTime: number, endTime: number, agIgnore: AggregateIngore) => {
    if (!journeyAnalyticsInfo?.type) return;
    const performanceParams = { startTime, endTime, aggregate: AGGREGATE_DROPDOWN_MAP[dropdownValue] };
    // broadcast type journey will not need experience performance graph
    if (
      !agIgnore.isIgnorePerformanceAggregate &&
      ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST_CONTENT].includes(
        journeyAnalyticsInfo.type,
      )
    ) {
      reduxAnalyticsPerformanceActions.fetchPerformanceTAData({
        ruleID: journeyAnalyticsInfo.ruleID,
        params: performanceParams,
      });
    }
    // fetch content view/click performance data
    if (
      !agIgnore.isIgnorePerformanceAggregate &&
      [GRAPHS_DISPLAY_TYPES.IN_APP_CONTENT, GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT].includes(
        journeyAnalyticsInfo.type,
      ) &&
      journeyAnalyticsInfo.experienceContentIDs &&
      journeyAnalyticsInfo.experienceContentIDs[0]
    ) {
      const parentIDs = journeyAnalyticsInfo.pushID
        ? [journeyAnalyticsInfo.experienceID, journeyAnalyticsInfo.pushID]
        : [journeyAnalyticsInfo.experienceID];
      // fetch content viewed performance only once for in-app type journey
      reduxAnalyticsPerformanceActions.fetchPerformanceCVData({
        contentID: journeyAnalyticsInfo.experienceContentIDs[0],
        parentIDs,
        params: performanceParams,
      });
      // fetch content clicked performance only once for in-app type journey
      reduxAnalyticsPerformanceActions.fetchPerformanceCCData({
        contentID: journeyAnalyticsInfo.experienceContentIDs[0],
        parentIDs,
        params: performanceParams,
      });
    } else if (
      !agIgnore.isIgnoreMultiInstanceAggregate &&
      [GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT, GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT].includes(
        journeyAnalyticsInfo.type,
      ) &&
      journeyAnalyticsInfo.experienceContentIDs &&
      journeyAnalyticsInfo.experienceContentIDs[0]
    ) {
      const contentIDs = journeyAnalyticsInfo.pushContentID
        ? journeyAnalyticsInfo.experienceContentIDs.concat([journeyAnalyticsInfo.pushContentID])
        : journeyAnalyticsInfo.experienceContentIDs;
      const parentIDs = journeyAnalyticsInfo.pushID
        ? [journeyAnalyticsInfo.experienceID, journeyAnalyticsInfo.pushID]
        : [journeyAnalyticsInfo.experienceID];
      // fetch content viewed performance for in-app type multi contents journey
      reduxAnalyticsContentPerformanceActions.fetchMultiContentViewPerformanceData(
        contentIDs,
        parentIDs,
        {
          ...performanceParams,
          aggregate: AGGREGATE_DROPDOWN_MAP[multiInstanceDropdownValue],
        },
        journeyAnalyticsInfo.ruleID,
        languages[0] || 'en',
      );
      // fetch content clicked performance for in-app type multi contents journey
      reduxAnalyticsContentPerformanceActions.fetchMultiContentClickPerformanceData(
        contentIDs,
        parentIDs,
        {
          ...performanceParams,
          aggregate: AGGREGATE_DROPDOWN_MAP[multiInstanceDropdownValue],
        },
        languages[0] || 'en',
      );
    } else if (
      !agIgnore.isIgnorePerformanceAggregate &&
      journeyAnalyticsInfo.type === GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH_CONTENT
    ) {
      // fetch content viewed performance only once for triggered push type journey with content
      reduxAnalyticsPerformanceActions.fetchPerformanceCCData({
        contentID: journeyAnalyticsInfo.pushContentID,
        parentIDs: [journeyAnalyticsInfo.pushID],
        params: performanceParams,
      });
    }
    // fetch push delivered performance data
    if (
      !agIgnore.isIgnorePerformanceAggregate &&
      [
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT,
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT,
        GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH,
        GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH_CONTENT,
      ].includes(journeyAnalyticsInfo.type)
    ) {
      reduxAnalyticsPerformanceActions.fetchPerformancePDData({
        pushID: journeyAnalyticsInfo.pushID,
        params: performanceParams,
      });
      // fetch push opened performance data
      if (journeyAnalyticsInfo.type !== GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT) {
        reduxAnalyticsPerformanceActions.fetchPerformanceOPData({
          pushID: journeyAnalyticsInfo.pushID,
          params: performanceParams,
        });
      }
    }
  };

  const fetchContentEngagementTableData = (startTime: number, endTime: number) => {
    if (!journeyAnalyticsInfo?.type) return;
    if (
      ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH].includes(journeyAnalyticsInfo.type)
    ) {
      const contentIDs: string[] = journeyAnalyticsInfo.experienceContentIDs || [];
      journeyAnalyticsInfo.pushContentID && contentIDs.push(journeyAnalyticsInfo.pushContentID);
      reduxContentEngagementTableActions.fetchContentEngagementTableData({
        experienceID: journeyAnalyticsInfo.experienceID,
        pushID: journeyAnalyticsInfo.pushID,
        contentIDs,
        params: { startTime, endTime },
        isReset: true,
        defaultLanguage: languages[0] || 'en',
      });
    }
  };

  const fetchContentEngagementBySourceData = (startTime: number, endTime: number) => {
    if (!journeyAnalyticsInfo?.type) return;
    const params = { startTime, endTime };
    if (journeyAnalyticsInfo.type === GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT) {
      // fetch 4 total content engagament values for views/clicks from push/experience
      journeyAnalyticsInfo.pushContentID &&
        journeyAnalyticsInfo.pushID &&
        journeyAnalyticsInfo.experienceID &&
        reduxAnalyticsContentEngagementBySourceActions.fetchTotalContentViewsCountFromPush({
          pushID: journeyAnalyticsInfo.pushID,
          contentID: journeyAnalyticsInfo.pushContentID,
          params,
        });
      reduxAnalyticsContentEngagementBySourceActions.fetchTotalContentClicksCountFromPush({
        pushID: journeyAnalyticsInfo.pushID,
        contentID: journeyAnalyticsInfo.pushContentID,
        params,
      });
      reduxAnalyticsContentEngagementBySourceActions.fetchTotalContentViewsCountFromExperience({
        experienceID: journeyAnalyticsInfo.experienceID,
        contentID: journeyAnalyticsInfo.pushContentID,
        params,
      });
      reduxAnalyticsContentEngagementBySourceActions.fetchTotalContentClicksCountFromExperience({
        experienceID: journeyAnalyticsInfo.experienceID,
        contentID: journeyAnalyticsInfo.pushContentID,
        params,
      });
    }
  };

  function setGraphDropdownValue(dropdownId: string, dropdownValue: string) {
    if (dropdownId === 'experiencePerformance') {
      setDropdownValue(dropdownValue);
    } else {
      setMultiInstanceDropdownValue(dropdownValue);
    }
  }

  function setDateValue(dateRange: Array<Date | null>) {
    if (
      dateRange[0]?.getTime() === props.dateRange[0]?.getTime() &&
      dateRange[1]?.getTime() === props.dateRange[1]?.getTime()
    )
      return;
    else if (dateRange[1]?.getTime() === props.dateRange[1]?.getTime()) props.setDateRange([dateRange[0], null]);
    else props.setDateRange(dateRange);
  }

  let showExperienceFunnel = false;
  let showExperienceFlow = false;
  let showExperiencePerformance = false;
  let showContentEngagements = false;
  let showContentEngagementBySource = false;
  let showContentEngagementTable = false;
  if (flags['tx_performance_tab_tea_graph']) {
    if (
      journeyAnalyticsInfo?.type &&
      ![
        GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT,
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT,
        GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT,
      ].includes(journeyAnalyticsInfo.type)
    ) {
      showExperienceFunnel = true;
    }
    if (
      journeyAnalyticsInfo?.type &&
      [GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT, GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT].includes(
        journeyAnalyticsInfo.type,
      )
    ) {
      showExperienceFlow = true;
    }
    if (
      journeyAnalyticsInfo?.type &&
      ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST_CONTENT].includes(
        journeyAnalyticsInfo.type,
      )
    ) {
      showExperiencePerformance = true;
    }
    if (
      journeyAnalyticsInfo?.type &&
      [GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT, GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_MULTI_CONTENT].includes(
        journeyAnalyticsInfo.type,
      )
    ) {
      showContentEngagements = true;
    }
    if (journeyAnalyticsInfo?.type && journeyAnalyticsInfo.type === GRAPHS_DISPLAY_TYPES.IN_APP_AMPLIFIED_CONTENT) {
      showContentEngagementBySource = true;
    }
    if (
      journeyAnalyticsInfo?.type &&
      ![GRAPHS_DISPLAY_TYPES.PUSH_BROADCAST, GRAPHS_DISPLAY_TYPES.TRIGGERED_PUSH].includes(journeyAnalyticsInfo.type)
    ) {
      showContentEngagementTable = true;
    }
  }

  return (
    <div className="templated-experiences-performance">
      <div className="templated-experiences-performance__date-range-picker">
        <FlightDateRangePicker value={props.dateRange} onChange={(value) => setDateValue(value)} />
      </div>

      {showExperienceFunnel && (
        <div className="templated-experiences-performance__experience-funnel">
          <div className="templated-experiences-performance__experience-funnel__title">Experience funnel</div>
          <div className="analytics-chart-container">
            <ExperienceFunnel journeyType={journeyAnalyticsInfo?.type || ''} />
          </div>
        </div>
      )}

      {showExperienceFlow && (
        <div className="templated-experiences-performance__experience-flow">
          <div className="templated-experiences-performance__experience-flow__title">Experience flow</div>
          <div className="analytics-chart-container">
            <ExperienceFlow journeyType={journeyAnalyticsInfo?.type || ''} />
          </div>
        </div>
      )}

      {showExperiencePerformance && (
        <div className="templated-experiences-performance__experience-performance">
          <div className="templated-experiences-performance__experience-performance__title">
            {journeyAnalyticsInfo?.type === GRAPHS_DISPLAY_TYPES.IN_APP_MULTI_CONTENT
              ? 'Audience Targeted'
              : 'Experience Performance'}
          </div>
          <FlightOverflowMenu
            label={dropdownValue}
            iconRight="baselineKeyboardArrowDown"
            optionGroups={[
              [
                {
                  key: '0',
                  name: 'Daily',
                  disabled: false,
                  onClick: () => {
                    setGraphDropdownValue('experiencePerformance', 'Daily');
                  },
                },
                {
                  key: '1',
                  name: 'Weekly',
                  disabled: false,
                  onClick: () => {
                    setGraphDropdownValue('experiencePerformance', 'Weekly');
                  },
                },
                {
                  key: '2',
                  name: 'Monthly',
                  disabled: false,
                  onClick: () => {
                    setGraphDropdownValue('experiencePerformance', 'Monthly');
                  },
                },
              ],
            ]}
          />
          <div className="analytics-chart-container">
            <ExperiencePerformance journeyType={journeyAnalyticsInfo?.type || ''} />
          </div>
        </div>
      )}

      {showContentEngagements && (
        <div className="templated-experiences-performance__content-engagements-over-time">
          <div className="templated-experiences-performance__content-engagements-over-time__title">
            Content engagements over time
          </div>
          <FlightOverflowMenu
            label={multiInstanceDropdownValue}
            iconRight="baselineKeyboardArrowDown"
            optionGroups={[
              [
                {
                  key: '0',
                  name: 'Daily',
                  disabled: false,
                  onClick: () => {
                    setGraphDropdownValue('multiInstance', 'Daily');
                  },
                },
                {
                  key: '1',
                  name: 'Weekly',
                  disabled: false,
                  onClick: () => {
                    setGraphDropdownValue('multiInstance', 'Weekly');
                  },
                },
                {
                  key: '2',
                  name: 'Monthly',
                  disabled: false,
                  onClick: () => {
                    setGraphDropdownValue('multiInstance', 'Monthly');
                  },
                },
              ],
            ]}
          />
          <div className="templated-experiences-performance__content-engagements-over-time__tab">
            <div
              onClick={() => setTabSelected('views')}
              className={`templated-experiences-performance__content-engagements-over-time__tab__views
              ${tabSelected === 'views' ? 'highlight-tab' : ''}`}
            >
              Views
            </div>
            <div
              onClick={() => setTabSelected('clicks')}
              className={`templated-experiences-performance__content-engagements-over-time__tab__clicks
              ${tabSelected === 'clicks' ? 'highlight-tab' : ''}`}
            >
              Clicks
            </div>
          </div>
          <div className="analytics-chart-container">
            <MultiInstance selectedTab={tabSelected} />
          </div>
        </div>
      )}

      {showContentEngagementBySource && (
        <div className="templated-experiences-performance__content-engagement-by-source">
          <div className="templated-experiences-performance__content-engagement-by-source__title">
            Content engagement by source
          </div>
          <div className="analytics-chart-container">
            <ContentEngagementBySource />
          </div>
        </div>
      )}

      {showContentEngagementTable && (
        <div className="templated-experiences-performance__content-engagements">
          <div className="templated-experiences-performance__content-engagements__title">Content engagements</div>
          <div className="analytics-chart-container">
            <ContentEngagementTable />
          </div>
        </div>
      )}

      {flags['tx_ao_enabled'] && reduxTemplatedExperienceState.instance?.audienceOptimizationEligible && (
        <div className="templated-experiences-performance__optimized-audience">
          <div className="templated-experiences-performance__content-engagements__title">
            Projected smart targeting performance
          </div>
          <AnalyticsPerformanceOptimizedChart />
        </div>
      )}
    </div>
  );
}


export class IFrameHelper {
  private parent: any;
  private url: any;
  private id: any;
  constructor(id: string, parent: any, url: any) {
    this.id = id;
    this.parent = parent;
    this.url = url;
  }

  loadIframe() {
    return new Promise((resolve, reject) => {
      let iframe = document.getElementById(this.id); // make this unique
      if (!iframe) {
        iframe = document.createElement('iframe');
        iframe.setAttribute('id', this.id);
        iframe.setAttribute('src', this.url);
        iframe.setAttribute('style', 'height: 100%; width: 100%; frameborder=0');
        this.parent.appendChild(iframe);
      } else {
        iframe.setAttribute('src', 'about:blank');
        setTimeout(() => {
          iframe!!.setAttribute('src', this.url);
        }, 150);
      }
      // If frame loads. return success
      document.getElementById(this.id)!!.onload = () => {
        resolve(true);
      };
    });
  }

  sendApply() {
    const payload = { command: 'apply' };
    const iframe: any = document.getElementById(this.id);
    const iframeWindow = iframe.contentWindow;
    iframeWindow.postMessage(payload, this.url);
  }

  sendOptionalPayload(_payload: any) {
    return new Promise((resolve, reject) => {
      const payload = { command: 'sendProps', payload: _payload};
      const iframe: any = document.getElementById(this.id);
      const iframeWindow = iframe.contentWindow;
      iframeWindow.postMessage(payload, this.url);
      resolve(true)
    });
  }

  setURL(url: string) {
    this.url = url;
  }
}

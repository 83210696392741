import React from 'react';
import {FlightSnackbar} from "@flybits/webapp-design-system-react";
import {useSelector, useDispatch} from "react-redux";
import { dismissSnackbar } from 'redux/snackbar/snackbar.action';
import './SnackBar.scss';

export default function SnackBar() {

  const state = useSelector((state: any) => state.snackbar);
  const dispatch = useDispatch();

  return (
    <div className="snackbar">
      <FlightSnackbar
        isVisible={state.isVisible}
        isFloating={state.isFloating}
        animation={state.animation}
        icon={state.icon}
        title={state.title}
        content={state.content}
        type={state.type}
        isAutoDismiss={state.isAutoDismiss}
        dismissAfter={state.dismissAfter}
        action={state.action ? state.action : () => dispatch(dismissSnackbar())}
        actionName={state.actionName}
        handleClose={state.toggleVisible ? state.toggleVisible : () => dispatch(dismissSnackbar())}
      />
    </div>
  )
}


import React, { useEffect } from 'react';
import '../../Audience/AudienceIframeParent/AudienceIframeParent.scss';
import ContentIframe from '../ContentIframe/ContentIframe';

interface IProps {
  onSubmit: (id: string, content: any) => void;
  onCancel: () => void;
  id: string;
  isEdit?: boolean;
  payload?: any;
  isNearByTemplate: boolean;
}

export default function ContentIframeParent(props: IProps) {  
  const messageListener = (e: any) => {
    if (e.data.command === 'cancelled') {
      props.onCancel();
    } else if (e.data.command === 'success') {
      props.onSubmit(e.data?.id, e.data?.content);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageListener, false);
    return () => window.removeEventListener('message', messageListener, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="audience-builder">
        <div className="main">
          <div className="iframe-container">
            <ContentIframe isVisible={true} {...props} />
          </div>
        </div>
      </div>
    </>
  );
}

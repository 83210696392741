import { TEAContentEngagementAnalytics, ContentEngagement } from 'interface/templated-experience/analytics.interface';
import { Content } from 'interface/content/content.interface';
import TemplatedExperienceAnalyticsAPI from "services/api/analytics.api";
import ContentAPI from 'services/api/content.api';
import {
  AnalyticsContentEngagementActionType,
  SetContentEngagementLoadingAction,
  SetContentEngagementDataAction,
  SetContentEngagementErrorAction,
} from "./content-engagement.type";

const templatedExperienceAnalyticsAPI = new TemplatedExperienceAnalyticsAPI();
const contentAPI = new ContentAPI();

function setContentEngagementLoading(payload: boolean): SetContentEngagementLoadingAction {
  return {
    type: AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_LOADING,
    payload,
  }
}

function resetContentEngagementData(): SetContentEngagementDataAction {
  return {
    type: AnalyticsContentEngagementActionType.RESET_CONTENT_ENGAGEMENT_DATA,
    payload: { data: [] },
  }
}

function setContentEngagementData(payload: TEAContentEngagementAnalytics): SetContentEngagementDataAction {
  return {
    type: AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_DATA,
    payload,
  }
}

function setContentEngagementErrorMessage(payload: string): SetContentEngagementErrorAction {
  return {
    type: AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_ERROR,
    payload,
  }
}

export const fetchContentEngagementTableData = (obj: {
  experienceID: string,
  pushID: string,
  params: { startTime: number, endTime: number },
  contentIDs: string[],
  isReset: boolean,
  defaultLangauge: string,
}) => (dispatch: any) => {
  return new Promise((resolve, reject) =>  {
    const contentEngagementMap = new Map<string, ContentEngagement>();
    const contentPromises: Promise<Content>[] = [];
    const journeyActionPromises: Promise<TEAContentEngagementAnalytics>[] = [];
    dispatch(setContentEngagementLoading(true));
    if (obj.isReset) {
      dispatch(resetContentEngagementData());
      dispatch(setContentEngagementErrorMessage(''));
    }

    obj.contentIDs.forEach((contentID) => {
      contentPromises.push(new Promise((resolve, reject) => {
        contentAPI.getInstanceWithoutData(contentID).then((content: Content) => {
          if (content.id) {
            const contentEngagement: ContentEngagement = {
              key: 0,
              contentId: content.id,
              contentName: (content.localizations[obj.defaultLangauge] ?
                content.localizations[obj.defaultLangauge].name :
                content.localizations[Object.keys(content.localizations)[0]].name),
              contentTemplateType: content.templateType,
              components: [],
              totalClickRate: 0,
              totalClicks: 0,
              totalViews: 0,
              uniqueClicks: 0,
              uniqueViews: 0,
              uniqueClickRate: 0,
            };
            contentEngagementMap.set(content.id, contentEngagement)
          }
          resolve();
        }).catch(() => {
          reject();
        });
      }));
    });

    const normalizeContentEngagementData = (data: ContentEngagement[]): ContentEngagement[] => {
      const normalizedContentEngagementData: ContentEngagement[] = [];
      data.forEach((record) => {
        if (contentEngagementMap.get(record.contentId)) {
          record.contentName = contentEngagementMap.get(record.contentId)!.contentName;
          record.contentTemplateType = contentEngagementMap.get(record.contentId)!.contentTemplateType;
        } else {
          record.contentName = '[Deleted]';
          record.contentTemplateType = '-';
        }
        contentEngagementMap.set(record.contentId, record);
      });
      contentEngagementMap.forEach((contentEngagement) => {
        normalizedContentEngagementData.push(contentEngagement);
      });
      return normalizedContentEngagementData;
    };

    Promise.allSettled(contentPromises).then(() => {
      obj.experienceID && journeyActionPromises.push(new Promise((resolve, reject) => {
        templatedExperienceAnalyticsAPI.getContentEngagementRecordsByExperience(obj.experienceID, obj.params)
        .then((res: TEAContentEngagementAnalytics) => {
          res.data = normalizeContentEngagementData(res.data);
          resolve(res);
        }).catch((err: any) => {
          reject(err);
        });
      }));
      obj.pushID && journeyActionPromises.push(new Promise((resolve, reject) => {
        templatedExperienceAnalyticsAPI.getContentEngagementRecordsByPush(obj.pushID, obj.params)
        .then((res: TEAContentEngagementAnalytics) => {
          res.data = normalizeContentEngagementData(res.data);
          resolve(res);
        }).catch((err: any) => {
          reject(err);
        });
      }));
      Promise.allSettled(journeyActionPromises).then((results) => {
        if (results.find((res) => res && res.status === 'rejected')) {
          throw new Error('Error fetching content engagement data');
        }
        const contentEngagementRecords: ContentEngagement[] = (results[0].status === 'fulfilled' && results[0].value.data) || [];
        if (results[1] && results[1].status === 'fulfilled') {
          // if results[1] exists it will be push content engagement record and the content will exist in 
          const pushContent = results[1].value.data[0];
          const pushContentInExperience = contentEngagementRecords.find((record) => record.contentId === pushContent.contentId);
          pushContentInExperience && Object.assign(pushContentInExperience, {
            totalClicks: pushContent.totalClicks + pushContentInExperience.totalClicks,
            totalViews: pushContent.totalClicks + pushContentInExperience.totalViews,
            uniqueClicks: pushContent.uniqueClicks + pushContentInExperience.uniqueClicks,
            uniqueViews: pushContent.uniqueViews + pushContentInExperience.uniqueViews,
            totalClickRate: ((pushContent.totalClicks + pushContentInExperience.totalClicks) /
              (pushContent.totalClicks + pushContentInExperience.totalViews)) || 0,
            uniqueClickRate: ((pushContent.uniqueClicks + pushContentInExperience.uniqueClicks) /
              (pushContent.uniqueViews + pushContentInExperience.uniqueViews)) || 0,
          });
          pushContent.components.forEach((pushComp) => {
            const matchedComponent = pushContentInExperience?.components.find(
              (expComp) => expComp.componentId === pushComp.componentId &&
              expComp.elementName === pushComp.elementName &&
              expComp.elementType === pushComp.elementType);
            matchedComponent && Object.assign(matchedComponent, {
              totalClicks: matchedComponent.totalClicks + pushComp.totalClicks,
              uniqueClicks: matchedComponent.uniqueClicks + pushComp.uniqueClicks,
            });
          });
        }
        const contentEngagementAnalytics: TEAContentEngagementAnalytics = {
          data: contentEngagementRecords,
        };
        dispatch(setContentEngagementData(contentEngagementAnalytics));
        resolve(contentEngagementAnalytics);
      }).catch((err: Error) => {
        dispatch(setContentEngagementErrorMessage(err.message));
        reject(err.message);
      }).finally(() => {
        dispatch(setContentEngagementLoading(false));
      });
    }).catch(() => {
      dispatch(setContentEngagementErrorMessage('Error requesting content data'));
      dispatch(setContentEngagementLoading(false));
    });
  });
};

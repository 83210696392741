import React from 'react';
import { times, isEmpty } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import FinishIcon from 'assets/icons/end.svg';
import AudienceBox from 'components/FlowBox/AudienceBox/AudienceBox';
import { useSelector } from 'react-redux';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import useFeatureFlag from 'hooks/useFeatureFlag';
import Journey from 'components/Journey/Journey';


export default function ActionTab () {

  const { flags } = useFeatureFlag();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const experience = !isEmpty(reduxTemplatedExperienceState.instance) ? reduxTemplatedExperienceState.instance : reduxTemplatedExperienceState.template;
  const isNotEditable = experience?.status === 'Active';

  function renderStep(step: any, idx: number, experience: TExperienceInstance) {
    const props = {
      ...step,
      key: `step_${idx}`,
      isNotEditable,
      tooltip: "You can't edit experiences that are Active. " +
        "You need to deactivate your experience in order to edit this component.",
    };
    if (step.isRoot) {
      return <AudienceBox {...props} experience={experience} />
    }
  }


  return (
    <div className="templated-experiences-create">
      <div className="templated-experiences-create__main">
        {reduxTemplatedExperienceState.isLoading ? (
            <div className="templated-experiences-create__main__progress">
              {times(3, (i) => (
                <div key={i} style={{ marginRight: 100}}>
                  <Skeleton width={300} height={300} />
                </div>
              ))}
            </div>
          ) : flags['tx_journey_builder'] && reduxTemplatedExperienceState?.temp?.viewMode === 'journey' ? (
            <Journey/>
          ) : experience ? (
            <div className="templated-experiences-create__main__flow">
              {experience?.steps?.map((step: any, idx: number) => renderStep(step, idx, experience))}
              <div>
                <img className='templated-experiences-create__finish-icon' src={FinishIcon} alt="FinishIcon"/>
              </div>
            </div>
          ) : reduxTemplatedExperienceState.errorMessage ? (
            <div className="templated-experiences-create__main__error">Error: {reduxTemplatedExperienceState.errorMsg}</div>
          ) : (
            <div className="templated-experiences-create__main__empty"> There are no data </div>
          )
        }
      </div>
    </div>
  )

}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import * as AuthActions from 'redux/auth/auth.action';
import useActions from 'hooks/useActions';
import useFeatureFlag from 'hooks/useFeatureFlag';

export default function PrivateRoute(props: any) {
  const history = useHistory();
  const [hasRequiredFlags, setHasRequiredFlags] = useState(false);
  const authActions = useActions(AuthActions);
  const auth = useSelector((state: any) => state.auth);
  const featureFlag = useSelector((state: any) => state.featureFlag);
  const { flags } = useFeatureFlag();
  const { requiredFlag } = props;

  // authenticate user
  useEffect(() => {
    if (!auth.authenticated) {
      authActions.authenticateUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check if feature flag matches
  useEffect(() => {
    if (!featureFlag.isInitialized) return;
    if (!requiredFlag) {
      setHasRequiredFlags(true);
    } else {
      if (flags[requiredFlag]) {
        setHasRequiredFlags(true);
      } else {
        history.push('/no-access');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    flags,
    requiredFlag,
    featureFlag.isInitialized
  ]);

  return (
  (
    auth.authenticated
    && featureFlag.isInitialized
    && hasRequiredFlags)
      ? <Route {...props} />
      : <> </>
  )
}

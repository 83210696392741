
const templates = [
   {
      "id":"F640FE77-E8E7-4D45-AAA6-878855EB87BE",
      "tenantID":"A22C0603-982A-433C-9B4A-64C136775BF2",
      "userID":"",
      "deviceID":"",
      "name":"Configurable Audience with In-app Content",
      "desc":"",
      "icon":"targeted.svg",
      "steps":[
         {
            "id":"21462E42-C77B-4198-BC26-E2705ADC8912",
            "name":"step 1",
            "desc":"",
            "ruleName":"At The Gallery",
            "ruleStringRepresentation":"",
            "ruleBody": "",
            "seedRuleBody": "",
            "seedRuleStringRepresentation":"",
            "actions":[
               {
                  "id":"35DCEE7C-7D30-48E9-899F-9EC098DA83A2",
                  "name":"Show Experience",
                  "desc":"",
                  "actionType":"experience",
                  "actionBody":{
                     "labels":[
                     ],
                     "groupIds":[
                     ],
                     "contentIds":[
                     ],
                     "localizations":{
                        "en":{
                           "name":"",
                           "description":""
                        }
                     }
                  },
                  "isConfigurable":true,
                  "isCreatable":false,
                  "isHidden":false
               }
            ],
            "stepChildren":[

            ],
            "isRoot":true,
            "isRuleHidden":false,
            "isRuleCreatable":false,
            "isRuleConfigurable":true
         }
      ],
      "rootStepID":"21462E42-C77B-4198-BC26-E2705ADC8912",
      "populationPercentage":0,
      "journeyMode":"",
      "createdAt":"2020-04-03 17:54:32.841484 +0000 +0000",
      "updatedAt":"2020-04-03 17:54:32.841484 +0000 +0000"
   },
   {
      "id":"BC26C06F-263C-48E0-ACC3-51A2F9442642",
      "tenantID":"A22C0603-982A-433C-9B4A-64C136775BF2",
      "userID":"",
      "deviceID":"",
      "name":"Business Services Benefits",
      "desc":"",
      "icon":"push.svg",
      "steps":[
         {
            "id":"AD177B16-8070-4876-BB11-B125597C43F2",
            "name":"step 1",
            "desc":"",
            "ruleName":"BatteryAndFitness",
            "ruleStringRepresentation":"",
            "seedRuleStringRepresentation":"",
            "actions":[
               {
                  "id":"008BBA85-6B06-43B4-B4BD-2C71E144603C",
                  "name":"Push Experience",
                  "desc":"",
                  "actionType":"push",
                  "actionBody":{
                     "body":[
                        {
                           "weight":100,
                           "payload":{
                              "data":{
                                 "contentId":{
                                    "value":"0497B80E-D68E-43A1-BA9F-F3F5818D4017",
                                    "entity":"content",
                                    "metadata":{

                                    }
                                 },
                                 "pushPayloadTypeId":"4D7484BF-62A3-4D0F-9EF9-C8CF838E2EBA"
                              },
                              "alert":{
                                 "en":"Business Benefits Message"
                              },
                              "title":{
                                 "en":"Business Benefits Title"
                              },
                              "action":"custom",
                              "entity":"",
                              "category":""
                           }
                        }
                     ],
                     "name":"Battery Ruleaaaa",
                     "tags":[
                     ],
                     "labels":[
                     ],
                     "delivery":{
                     },
                     "keywords":[
                     ],
                     "userScope":false
                  },
                  "isConfigurable":true,
                  "isCreatable":false,
                  "isHidden":false
               }
            ],
            "stepChildren":[
            ],
            "isRoot":true,
            "isRuleHidden":true,
            "isRuleCreatable":false,
            "isRuleConfigurable":true
         }
      ],
      "rootStepID":"AD177B16-8070-4876-BB11-B125597C43F2",
      "populationPercentage":0,
      "journeyMode":"",
      "createdAt":"2020-04-07 19:17:06.353537 +0000 +0000",
      "updatedAt":"2020-04-07 19:17:06.353537 +0000 +0000"
   }
]

const analytics = [
  {
    totalUsers: 240435,
    engagements: 12034,
    engagementRate: 5,
    audienceTarget: [40, 15],
    audienceGraph: [
      [0, 0],
      [15027, 0],
      [21038, 601],
      [30054, 1805],
      [54097, 3008],
      [69125, 3610],
      [84152, 4813],
      [105190, 7220],
      [120217, 9025],
      [144261, 9627],
      [180326, 10228],
      [210380, 11432],
      [225407, 11432],
      [231418, 12034],
      [240435, 12034],
    ],
    optimizationGraph: [
      100, 97, 93, 90, 87, 84, 81, 79, 78, 76,
      74, 72, 71, 70, 68, 65, 64, 63, 62, 60,
      59, 58, 54, 53, 52, 50, 49, 48, 45, 43,
      42, 40, 38, 37, 37, 36, 36, 35, 34, 33,
      32, 32, 31, 30, 30, 30, 29, 29, 28, 26,
      26, 25, 25, 25, 24, 24, 24, 24, 24, 24,
      23, 23, 23, 23, 22, 22, 22, 21, 21, 21,
      20, 20, 19, 19, 18, 18, 17, 17, 16, 16,
      16, 15, 15, 15, 15, 14, 14, 13, 12, 11,
      11, 11, 10, 10, 10, 10, 9, 8, 8, 8,
    ],
  },
  {
    totalUsers: 12802,
    engagements: 1204,
    engagementRate: 9,
    audienceTarget: [12, 16],
    audienceGraph: [
      [0, 0],
      [800, 0],
      [1120, 60],
      [1600, 180],
      [2880, 301],
      [3680, 361],
      [4480, 481],
      [5600, 722],
      [6401, 903],
      [7681, 963],
      [9601, 1023],
      [11201, 1143],
      [12001, 1143],
      [12321, 1204],
      [12802, 1204],
    ],
    optimizationGraph: [
      100, 97, 40, 27, 22, 20, 19, 18, 18, 17,
      16, 16, 16, 16, 16, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
      15, 15, 15, 15, 15, 15, 15, 15, 15, 15,
    ],
  },
  {
    totalUsers: 480203,
    engagements: 48104,
    engagementRate: 10,
    audienceTarget: [14, 75],
    audienceGraph: [
      [0, 0],
      [30012, 0],
      [42017, 2405],
      [60025, 7215],
      [108045, 12026],
      [138058, 14431],
      [168071, 19241],
      [210088, 28862],
      [240101, 36078],
      [288121, 38483],
      [360152, 40888],
      [420177, 45698],
      [450190, 45698],
      [462195, 48104],
      [480203, 48104],
    ],
    optimizationGraph: [
      100, 99, 98, 97, 97, 96, 95, 95, 94, 94,
      94, 93, 93, 92, 92, 91, 90, 89, 87, 85,
      84, 82, 80, 75, 70, 60, 40, 35, 31, 30,
      28, 28, 28, 27, 26, 26, 26, 26, 26, 26,
      26, 26, 26, 26, 26, 26, 26, 26, 26, 26,
      26, 26, 26, 26, 26, 26, 26, 26, 26, 26,
      26, 26, 26, 26, 26, 26, 26, 26, 26, 26,
      26, 26, 26, 26, 26, 26, 26, 26, 26, 26,
      26, 26, 26, 26, 26, 26, 26, 26, 26, 26,
      26, 26, 26, 25, 25, 25, 25, 25, 24, 24,
    ]
  },
]

export {
  templates,
  analytics
}

import React, { useState, useEffect } from 'react';
import './FilterBar.scss';

interface FilterBarProps {
  filters: string[];
  className?: string;
  handleFilter: (filters: string[]) => void;
}

export const FilterBar = (props: FilterBarProps) => {
  const { filters, className, handleFilter } = props;
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const MAIN_CLASS = 'filter-bar';

  const isSelected = (filter: string) => {
    return selectedFilters.includes(filter);
  };

  const onSelectFilter = (filter: string) => {
    let updatedFilters;
    if (isSelected(filter)) {
      updatedFilters = selectedFilters.filter((item) => item !== filter);
    } else {
      updatedFilters = selectedFilters.concat(filter);
    }
    setSelectedFilters(updatedFilters);
  };

  useEffect(() => {
    handleFilter(selectedFilters);
    //eslint-disable-next-line
  }, [selectedFilters]);

  return (
    <div className={`${MAIN_CLASS} ${className}`}>
      <span className={`${MAIN_CLASS}__text`}>Filter</span>
      {filters.map((filter) => (
        <button
          key={filter}
          className={`${MAIN_CLASS}__filter` + (isSelected(filter) ? ' selected' : '')}
          onClick={() => onSelectFilter(filter)}
        >
          {filter}
        </button>
      ))}
    </div>
  );
};

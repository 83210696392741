import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ControlBar from 'components/ControlBar/ControlBar';
import PushIcon from "assets/icons/push.svg";
import InAppIcon from "assets/icons/in-app.svg";

import AOTemplatedExperienceAPI from 'services/api/ao-templated-experience.api';
import './AOSelect.scss';

export default function AOSelect() {

  const history = useHistory();
  const [AOTemplates, setAOTemplates] = useState<any>([]);
  const aoTemplatedExperienceAPI = new AOTemplatedExperienceAPI();

  useEffect(() => {
    aoTemplatedExperienceAPI.getTemplates().then(templates => {
      setAOTemplates(templates);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _handleSelectTemplate = (template: any) => {
    history.push(`/ao/templated-experiences/create/${template.id}`);
  };

  const handleCancel = () => {
    history.push('/ao/templated-experiences');
  };

  function actionIcons(template: any) {
    return template.steps[0].actions.map((action: any, idx: number) => {
      if (action.actionType === 'push') {
        return (
          <div className='template-selection__container__block__template-item__text-box__elements__element' key={`icon_${idx}`}>
            <img src={PushIcon} alt="PushIcon" className="flow-box__card-container__card__step-header__question"/>
            <div>Notification</div>
          </div>
        );
      } else if (action.actionType === 'experience') {
        return (
          <div className='template-selection__container__block__template-item__text-box__elements__element' key={`icon_${idx}`}>
            <img src={InAppIcon} alt="InAppIcon" className="flow-box__card-container__card__step-header__question"/>
            <div>In-App Content</div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  return (
    <ControlBar
      onCancel={handleCancel}
    >
      <div className="template-selection">
        <div className="template-selection__container">
          <div className="template-selection__container__block">
            <div className='template-selection__container__block__title'> Select a Template </div>
            <div className='template-selection__container__block__list'>
              {AOTemplates && AOTemplates.map((template: any, idx: number) => (
                <button className='template-selection__container__block__template-item' key={`template_${idx}`} onClick={() => _handleSelectTemplate(template)}>
                  <div className='template-selection__container__block__template-item__image-box'>
                    <img src={require(`assets/images/${template.icon ? template.icon : 'image-placeholder.jpg'}`)} className='template-selection__container__block__template-item__image-box__img' alt={template.name}/>
                  </div>
                  <div className='template-selection__container__block__template-item__text-box'>
                    <div className='template-selection__container__block__template-item__text-box__title'>{template.name}</div>
                    <div className='template-selection__container__block__template-item__text-box__description'>{template.desc}</div>
                    <div className='template-selection__container__block__template-item__text-box__content'>Eligible Audience will be sent:</div>
                    <div className='template-selection__container__block__template-item__text-box__elements'>
                      {actionIcons(template)}
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ControlBar>
  )
}

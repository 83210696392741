import React, {useEffect, useState} from "react";
import {FlightSelectSearchable} from "@flybits/webapp-design-system-react";
import {setInstance, setTemplate} from "redux/templated-experience/templated-experience.action";
import LabelsAPI from "services/api/labels.api";
import {useDispatch, useSelector} from "react-redux";
import { Label } from "interface/experience/experience.interface";
import { TExperienceInstance } from "interface/templated-experience/templated-experience.interface";
import './LabelInput.scss';
import { isEmpty } from "lodash";
interface IProps {
  experience?: TExperienceInstance | undefined
  entities?: Set<string> | undefined
  labels?: string[]
  setLabels?: (labels: string[]) => void
}

export default function LabelInput(props:IProps) {
  const labelsAPI = new LabelsAPI();
  const dispatch = useDispatch();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const [experienceLabels, setExperienceLabels] = useState<string[]>(props.experience?.labels || []);
  const [allLabels, setAllLabels] = useState<Label[]>([]);
  const [filteredLabels, setFilteredLabels] = useState<Label[]>([]);

  useEffect(() => {
    labelsAPI.getLabels('').then((res: any) => {
      const serializedLabels = sortLabels(res.data?.map((label: string) => ({
        key: label,
        name: label
      })));
      setAllLabels(serializedLabels);
      const labels = serializedLabels?.filter((label:any) => !experienceLabels?.includes(label.name));
      setFilteredLabels(labels);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setAllLabels([]);
      setFilteredLabels([]);
      setExperienceLabels([]);
    }
  },[]);

  const sortLabels = (labels: Label[]) => {
    return labels.sort((a:Label, b:Label) => {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
  }

  const updateExperienceLabels = (labels:string[]) => {
    if(!isEmpty(props.experience)) {
      if(reduxTemplatedExperienceState.instance) {
        dispatch(
          setInstance({
            instance: {
              ...reduxTemplatedExperienceState.instance,
              labels,
            },
          }),
        );
      }
      if(reduxTemplatedExperienceState.template) {
        dispatch(
          setTemplate({
            template: {
              ...reduxTemplatedExperienceState.template,
              labels,
            },
          }),
        );
      }
    }
    if(!isEmpty(props.entities) && props.setLabels) {
      props.setLabels(labels);
    }
  }

  const handleSearchLabel = (labelName: string) => {
    const filteredLabels = allLabels.filter((label:any) => label.name?.includes(labelName));
    setFilteredLabels(filteredLabels);
  }

  const handleSelectLabel = (label:{key:string, name:string}) => {
    const labelExists = experienceLabels.find((expLabel:string) => expLabel === label?.name);
    if(label && !labelExists) {
      setExperienceLabels([...experienceLabels, label?.name]);
      updateExperienceLabels([...experienceLabels, label?.name]);
      if(props.setLabels) {
        props.setLabels!([...props.labels!, label?.name]);
      }
    }
    setTimeout(() => {
      const updatedLabels = filteredLabels.filter((filteredLabel:any) => filteredLabel.name !== label.name);
      setFilteredLabels(updatedLabels);
    }, 500);
  }

  const handleRemoveLabel = (label:string) => {
    const updatedLabels = experienceLabels.filter((expLabel:string) => expLabel !== label);
    setExperienceLabels(updatedLabels);
    updateExperienceLabels(updatedLabels);
    if(props.setLabels) {
      props.setLabels!([...updatedLabels]);
    }
    const sortedFilteredLabels = sortLabels([...filteredLabels, {key: label, name: label}]);
    setFilteredLabels(sortedFilteredLabels);
  }

  return (
    <div className="label-input">
      <div className="label-input__text">
        <FlightSelectSearchable
          label="Search labels or create new"
          options={filteredLabels}
          handleOptionClick={handleSelectLabel}
          handleSearch={handleSearchLabel}
          dropdownMaxHeight="200px"
          className="label-input__search"
        />
      </div>
      <div className="label-input__description">You can add one or more labels</div>
      <div className="label-input__text">
        {experienceLabels?.map((label:string, idx:number) =>
          <div key={idx} className="label-input__label">
            {label} <button onClick={()=>handleRemoveLabel(label)}>X</button>
          </div>
        )}
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import {
  useSelector,
  useDispatch
} from 'react-redux';

import Storage from "services/storage";
import moment from "moment";
import queryString from 'query-string';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { DropdownOptions } from 'interface/shared/dropdown.interface';
import {
  FlightButton,
  FlightTable,
  FlightOverflowMenu,
  FlightTag,
  FlightTooltip,
  FlightTextInput,
  FlightSelectMulti,
  FlightDateRangePicker,
} from '@flybits/webapp-design-system-react';
import './TemplatedExperienceIndex.scss';
import {TExperienceInstance} from 'interface/templated-experience/templated-experience.interface';
import {defineStatusStyle, convertTimezoneToDate} from "helpers/templated-experience.helper";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from 'helpers/analytics.helper';
import GroupsModal from 'components/Modal/GroupsModal/GroupsModal';
import {setInstance, setCurrentFolder} from 'redux/templated-experience/templated-experience.action';
import FolderIcon from "assets/icons/icons8-folder.svg";
import FolderIconGray from "assets/icons/icons8-folder-gray.svg";
import GroupIcon from "assets/icons/four-squares.svg";
import MultiStepTemplatedExperienceAPI from 'services/api/multistep-templated-experience.api';
import useFeatureFlag from 'hooks/useFeatureFlag';
import GroupAPI from "services/api/group.api";
import LabelsAPI from "services/api/labels.api";
import TemplatedExperienceAPI from "services/api/templated-experience.api";
import {Group} from "interface/group.interface";
import useDebounce from "hooks/useDebounce";
import { isEmpty, isEqual, union, reduce, keys, cloneDeep } from 'lodash';
import { Status, Label, Type } from 'interface/experience/experience.interface';
import FolderModal, { FolderModalProps } from 'components/Modal/FolderModal/FolderModal';
import FoldersModal from 'components/Modal/FoldersModal/FoldersModal';
import FoldersBreadcrumbs from 'components/FoldersBreadcrumbs/FoldersBreadcrumbs';
import { Folder } from 'interface/folder.interface';
import FolderAPI from "services/api/folder.api";
import { findFolderById } from 'helpers/folder.helper';
import { PaginationResponse } from 'interface/shared/api.interface';
import ConfirmDeleteFolder from 'components/Modal/ConfirmModal/ConfirmDeleteFolder/ConfirmDeleteFolder';
import LabelsModal from 'components/Modal/LabelsModal/LabelsModal';

export default function TemplatedExperienceIndex() {
  
  const { flags } = useFeatureFlag();
  const history = useHistory();
  const dispatch = useDispatch();
  const storage = new Storage();
  const groupAPI = new GroupAPI();
  const labelsAPI = new LabelsAPI();
  const folderAPI = new FolderAPI();
  const experienceAPI = new TemplatedExperienceAPI();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [groupsState, setGroupsState] = useState<Group[]>([]);
  const [selectedExperiences, setSelectedExperiences] = useState<any>(new Set([]));
  const [maxPage, setMaxPage] = useState<number>(0);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const queryParams = queryString.parse(history.location?.search)
  const [filters, setFilters] = useState<any>({
    search: queryParams.search! ? String(queryParams.search) : '',
    searchExact: queryParams.searchExact! === 'true' ? Boolean(queryParams.searchExact) : false,
    sortby: queryParams.sortby! ? String(queryParams.sortby) : 'created_at',
    sortorder: queryParams.sortorder! ? String(queryParams.sortorder) : 'desc',
    limit: queryParams.limit! ? Number(queryParams.limit) : 10,
    page: queryParams.page! ? Number(queryParams.page) : 1,
    status: !isEmpty(queryParams.status) ? queryParams.status : [],
    type: !isEmpty(queryParams.type) ? queryParams.type : [],
    label: !isEmpty(queryParams.label) ? queryParams.label : [],
    startdate: queryParams.startdate! ? Number(queryParams.startdate) : 0,
    enddate: queryParams.enddate! ? Number(queryParams.enddate) : 0,
    parentFolderID: queryParams.parentFolderID! ? String(queryParams.parentFolderID) : '00000000-0000-0000-0000-000000000000',
  });
  const initialFilters:any = {
    search: '',
    searchExact: false,
    sortby: 'created_at',
    sortorder: 'desc',
    limit: 10,
    page: 1,
    status: [],
    type: [],
    label: [],
    startdate: 0,
    enddate: 0,
    parentFolderID: ''
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeFilters, setActiveFilters] = useState(0);
  const debouncedFilters = useDebounce(filters, 300);
  const [showFilters,setShowFilters] = useState(false);
  const confirmModalProps = {
    isVisible: false,
    icon: 'warning.svg',
    title: 'Your Experience will  be Deactivated',
    content: <div>You can&apos;t edit experiences that are active.
      Proceeding will deactivate your experience immediately.</div>,
    errorMessage: '',
    primaryButton: {
      name: 'Deactivate and Edit',
      onClick: () => {},
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    }
  }
  const [modalProps, setModalProps] = useState(confirmModalProps);
  const [folderModalProps, setFolderModalProps] = useState<FolderModalProps>({
    isVisible: false,
    editFolder: false,
    parentFolderID: '',
    newFolder: '',
    folder: undefined,
    onCancel: () => setFolderModalProps({...folderModalProps, isVisible: false}),
    onCreate: (folder:Folder) => {setNewFolder(folder)},
  });
  const [foldersModalProps, setFoldersModalProps] = useState({
    isVisible: false,
    entities: new Set([]),
    moved: false,
    onCancel: () => setFoldersModalProps({...foldersModalProps, isVisible: false}),
    onMove: () => {
      setFoldersModalProps({...foldersModalProps, moved: !foldersModalProps.moved});
      setSelectedExperiences(new Set([]));
    }
  });
  const [deleteFolderModalProps, setDeleteFolderModalProps] = useState({
    isVisible: false,
    isDeleting: false,
    onCancel: () => setDeleteFolderModalProps({...deleteFolderModalProps, isVisible: false, isDeleting: false}),
    onDelete: (isCheckboxSelected:string) => handleDeleteFolder(isCheckboxSelected, undefined)
  });
  const [newFolder, setNewFolder] = useState<Folder|undefined>(undefined);
  const [groupsModalProps, setGroupsModalProps] = useState({});
  const [labelsModalProps, setLabelsModalProps] = useState({});
  const [tableData, setTableData] = useState<any[]>();
  const tableHeader = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
      isSortable: true
    },{
      name: 'Status',
      key: 'status',
      isVisible: true,
      isSortable: false
    },{
      name: 'Schedule',
      key: 'schedule',
      isVisible: true,
      isSortable: false
    },{
      name: 'Last modified',
      key: 'updated_at',
      isVisible: true,
      isSortable: true
    },{
      name: 'Date created',
      key: 'created_at',
      isVisible: true,
      isSortable: true
    },{
      name: '',
      key: 'edit',
      isVisible: true,
      isSortable: false
    }, {
      name: '',
      key: 'item',
      isVisible: false,
      isSortable: false
    },
  ];
  if(flags['tx_labels']) {
    tableHeader.splice(2, 0, {
      name: 'Labels',
      key: 'labels',
      isVisible: true,
      isSortable: false
    });
  }

  const initialStatuses = [
    {
      key: 'active',
      name: 'Active',
      isSelected: false
    },
    {
      key: 'draft',
      name: 'Draft',
      isSelected: false
    },
    {
      key: 'inactive',
      name: 'Inactive',
      isSelected: false
    },
    {
      key: 'scheduled',
      name: 'Scheduled',
      isSelected: false
    }
  ];
  const [statuses, setStatuses] = useState<Status[]>(cloneDeep(initialStatuses));
  const [labels, setLabels] = useState<Label[]>([]);
  const [allLabels, setAllLabels] = useState<Label[]>([]);
  const [templateTypes, setTemplateTypes] = useState<Type[]>([]);

  const pathName = history.location.pathname.replace(/\/$/, "");

  const openGroupedExperiences = async(group: any = null) => {
    let origin = await storage.getItem('origin');
    origin = origin || 'http://localhost:4200/#';
    let URL = '';
    if (group.id) {
      URL = `${origin.split('/#')[0]}/#/experiences?groupId=${group.id}`;
    } else {
      URL = `${origin.split('/#')[0]}/#/experiences/`;
    }
    window.open(URL, '_blank');
  }


  useEffect(() => {
    groupAPI.getGroups(0, 10000, '').then((res: any) => {
      setGroupsState(res.data);
    });
    labelsAPI.getLabels('').then((res: any) => {
      const serializedLabels = res.data.sort()?.map((label: string) => ({
        key: label,
        name: label,
        isSelected: filters?.label?.includes(label) || false
      }));
      setLabels(serializedLabels);
      setAllLabels(serializedLabels);
    });
    experienceAPI.getTemplateTypes().then((res:string[]) => {
      const serializedTypes = res?.map((type: string) => ({
        key: type,
        name: type,
        isSelected: filters?.type?.includes(type) || false
      }));
      setTemplateTypes(serializedTypes);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // Initial Load
  useEffect(() => {
    if(flags['tx_folders']) {
      if(filters.parentFolderID) {
        reduxTemplatedExperienceAction.fetchFolderChildren(filters.parentFolderID, filters);
      } else {
        reduxTemplatedExperienceAction.fetchFoldersAndInstances(filters);
      }
    } else {
      reduxTemplatedExperienceAction.fetchInstances(filters);
    }
    history.push(`${pathName}?${queryString.stringify({...filters},{arrayFormat:'none'})}`);
    if(!isEmpty(filters.status)) {
      const selectedStatuses = statuses?.map(status => {
        if(filters?.status?.includes(status.key)) {
          status.isSelected = true;
        } else {
          status.isSelected = false;
        }
        return status;
      });
      setStatuses(selectedStatuses);
      setShowFilters(true);
    }
    if(!isEmpty(filters.type)) {
      const selectedTypes = templateTypes?.map(type => {
        if(filters?.type?.includes(type.key)) {
          type.isSelected! = true;
        } else {
          type.isSelected = false;
        }
        return type;
      });
      setTemplateTypes(selectedTypes);
      setShowFilters(true);
    }
    if(!isEmpty(filters.label)) {
      const selectedLabels = labels?.map(label => {
        if(filters?.label?.includes(label.key)) {
          label.isSelected! = true;
        } else {
          label.isSelected! = false;
        }
        return label;
      });
      setLabels(selectedLabels);
      setShowFilters(true);
    }
    if(filters.startdate || filters.enddate) {
      setShowFilters(true);
    }
    setActiveFilters(countActiveFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilters, debouncedFilters?.parentFolderID, newFolder?.id, foldersModalProps?.moved]);

  //used for multi step instances
  const [multiStepInstances, setMultiStepInstances] = useState([]);
  const multistepTemplatedExperienceAPI = new MultiStepTemplatedExperienceAPI();
  useEffect(() => {
    if(flags['tx_fis_demo']) { // check feature flag
    multistepTemplatedExperienceAPI.getInstances().then((instances) => {
      setMultiStepInstances(instances);
    })}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Re-render on state change
  useEffect(() => {
    const renderDropdown = (status: string, data: TExperienceInstance) => {
      let optionGroups: Array<Array<DropdownOptions>> = [[
        // {
        //   key: 2,
        //   name: ['active','scheduled'].includes(status.toLowerCase()) ? 'Deactivate' : 'Activate',
        //   disabled: status.toLowerCase() === 'draft',
        //   onClick: ['active','scheduled'].includes(status.toLowerCase()) ? () => onDeactivate(data) : () => onActivate(data) ,
        // },
        {
          key: 5,
          name: 'Duplicate',
          disabled: false,
          onClick: () => history.push(`${pathName}/create?instance_id=${data.id}&${queryString.stringify({...filters})}`),
        },
        {
          key: 6,
          name: 'Delete',
          disabled: false,
          onClick: () => onDelete(data),
        },
      ]];

      if (data?.steps?.[0]?.trigger?.type === '') {
        let groups = data.steps[0]?.groups?.map((group: any, idx: number) => {
          let groupData = groupsState.find(g => g.id === group.id);
          return {
            key: idx,
            name: `Group: ${groupData?.localizations?.en?.name}`,
            disabled: false,
            onClick: (() => openGroupedExperiences(group)),
          }
        });

        if (groups?.length) {
          optionGroups[0].unshift({
              key: 3,
              name: 'Manage Groups',
              disabled: false,
              onClick: () => onManageGroups(data),
            },
            {
              key: 4,
              name: 'Manage Priority',
              disabled: false,
              onClick: (!groups || groups?.length > 1) ? () => {
              } : () => openGroupedExperiences(data.steps[0]?.groups[0] || ''),
              children: groups?.length > 1 ? [groups] : undefined,
            });
        }

        if (flags['tx_groups'] && !groups?.length) {
          optionGroups[0].unshift({
            key: 2,
            name: 'Add to Group(s)',
            disabled: false,
            onClick: () => onManageGroups(data),
          });
        }
      }

      if(!data.rootStepID) { // if folder
        const folderData = {
          id: data.id,
          name: data.name,
          parentFolderID: data.parentFolderID
        }

        optionGroups = [[
          {
            key: 1,
            name: 'Rename',
            disabled: false,
            onClick: (() => handleRenameFolder(folderData)),
          },
          {
            key: 2,
            name: 'Delete',
            disabled: false,
            onClick: (() => onDeleteFolder(folderData)),
          }
        ]];
      }


      return (
        <FlightOverflowMenu
          className={'templated-experiences-index__dropdown'}
          direction={'bottom'}
          isOpenRight={false}
          disabled={false}
          optionGroups={optionGroups}
        />
      )
    }

    const renderMultiDropDown = (status: string, data: TExperienceInstance) => {
      let optionGroups: Array<Array<DropdownOptions>> = [
        [
          {
            key: 1,
            name: 'Edit',
            disabled: false,
            onClick: () => history.push(`/multi${pathName}/edit/${data.id}`),
          },
          {
            key: 5,
            name: 'Delete',
            disabled: false,
            onClick: () => {
              multistepTemplatedExperienceAPI.deleteInstance(data.id).then((instances: any) => {
                setMultiStepInstances(instances);
              });
            },
          },
        ],
      ];

      return (
        <FlightOverflowMenu
          className={'multi-step-index__dropdown'}
          direction={'bottom'}
          isOpenRight={false}
          disabled={false}
          optionGroups={optionGroups}
        />
      );
    }

    if (reduxTemplatedExperienceState.instances?.data) {
      const pagination = reduxTemplatedExperienceState.instances.pagination;
      const maxPage = Math.ceil(pagination.totalRecords / Number(filters.limit));
      setMaxPage(maxPage >= Number(filters.page) ? maxPage : Number(filters.page));
      const _tableData = reduxTemplatedExperienceState.instances.data.map((data: any) => {
        let startDate = data.schedule?.start;
        if(data.status === 'Inactive' || data.status === 'Active') {
          if(data.activation?.automaticActivationAt > data.activation?.manualActivationAt) {
            startDate = data.activation?.automaticActivationAt
          } else {
            startDate = data.activation?.manualActivationAt;
          }
        }
        let endDate = data.schedule?.end;
        if(data.status === 'Inactive') {
          if(data.activation?.automaticDeactivationAt > data.activation?.manualDeactivationAt) {
            endDate = data.activation?.automaticDeactivationAt
          } else {
            endDate = data.activation?.manualDeactivationAt;
          }
        }
        let timezone = data?.schedule?.timezone ? data.schedule.timezone : moment.tz.guess().replace(/_/g, ' ');
        let local_start_desc = null;
        let local_end_desc = null;
        let scheduleDescr = null;
        let schedule = <FlightTooltip
          className="custom-class-name"
          direction="top"
          isEnabled={true}
          description={'None set'}
          delay={0}
        >None set</FlightTooltip>;

        if(startDate) {
          let convertStart = convertTimezoneToDate(startDate, timezone);
          if(convertStart.local_date+convertStart.local_time !== convertStart.tz_date+convertStart.tz_time) {
            local_start_desc = <span className="templated-experiences-index__user-timezone"><hr/> Your local time: {convertStart.local_time}, {convertStart.local_date}</span>;
          }
          schedule = <FlightTooltip
            className="templated-experiences-index__tooltip"
            direction="top"
            isEnabled={true}
            description={
              <div>
                {(data.status === 'Active' || data.status === 'Inactive') ?
                  (data.schedule?.start && data.activation?.automaticActivationAt > data.activation?.manualActivationAt) ? 'Automatically launched ' : 'Manually launched '
                  : data.status !== 'Draft' ? 'Starts ' : 'Schedule is not in effect because experience is in draft state. Start '}
                at {convertStart.tz_time}, {convertStart.tz_date}<br/>
                Time zone: {timezone}{local_start_desc}
              </div>
            }
            delay={0}
          >{convertStart.tz_date}</FlightTooltip>;
          if(data.status === 'Active' || data.status === 'Scheduled') {
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
              Starts in {moment(data.schedule.start*1000).fromNow(true)}
            </div>;
            if (data.schedule.start < moment().unix() && data.status === 'Active' ) {
              scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
                Automatically launched {moment(data.schedule.start*1000).fromNow(true)} ago
              </div>;
            }
          }
        }

        if(endDate) {
          let convertEnd = convertTimezoneToDate(endDate, timezone);
          if(convertEnd.local_date+convertEnd.local_time !== convertEnd.tz_date+convertEnd.tz_time) {
            local_end_desc = <span className="templated-experiences-index__user-timezone"><hr/>Your local time: {convertEnd.local_time}, {convertEnd.local_date}</span>;
          }
          schedule = <div>{schedule} – <FlightTooltip
            className="custom-class-name"
            direction="top"
            isEnabled={true}
            description={
              <div>
                {data.status === 'Inactive' ?
                  data.activation?.automaticDeactivationAt > data.activation?.manualDeactivationAt ? 'Automatically deactivated ' :
                    data.activation?.manualDeactivationAt ? 'Manually deactivated ' : 'Scheduled end '
                  : data.status !== 'Draft' ? 'Ends ' : 'Schedule is not in effect because experience is in draft state. End '}
                at {convertEnd.tz_time}, {convertEnd.tz_date}<br/>
                Time zone: {timezone}{local_end_desc}
              </div>
            }
            delay={0}
          >{convertEnd.tz_date}</FlightTooltip></div>;
          if (data.status === 'Active' || data.schedule.start < moment().unix()) {
            let endText = 'No end date';
            if (data.schedule?.end > moment().unix()) {
              endText = 'Ends in ' + moment(data.schedule.end * 1000).fromNow(true)
            }
            if (data.schedule?.end < moment().unix()) {
              endText = 'Ended ' + moment(data.schedule.end * 1000).fromNow(true) + ' ago'
            }
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>{endText}</div>;
          }
        } else {
          if(startDate) {
            schedule = <div>{schedule} – <FlightTooltip
              className="custom-class-name"
              direction="top"
              isEnabled={true}
              description={`No end date`}
              delay={0}
            >No end date</FlightTooltip></div>;
          }
        }

        if(data.status === 'Active' && !data.schedule.end) {
          if(data.activation?.automaticActivationAt > data.activation?.manualActivationAt) {
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
              Automatically launched on {moment(data.activation?.automaticActivationAt * 1000).format('MMM D, YYYY')}
            </div>
          } else {
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
              Manually launched on {moment(data.activation?.manualActivationAt * 1000).format('MMM D, YYYY')}
            </div>;
          }
        }
        if(data.status === 'Inactive') {
          if(data.activation?.automaticDeactivationAt > data.activation?.manualDeactivationAt) {
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
              Automatically deactivated on {moment(data.activation?.automaticDeactivationAt * 1000).format('MMM D, YYYY')}
            </div>
          } else {
            scheduleDescr = <div className={'templated-experiences-index__shedule-descr'}>
              Manually deactivated on {moment(data.activation?.manualDeactivationAt * 1000).format('MMM D, YYYY')}
            </div>;
          }
        }


        const path_array = data.pathName?.split('/')?.filter((path:string) => path !== '');
        path_array?.pop(); // delete last element (current folder)
        let instance_path = <div className="templated-experiences-index__path">{path_array?.map((path: string, idx: number) => {
          return <span key={path}>
            <img src={FolderIconGray} alt="Folder" className="templated-experiences-index__path-icon"/>
            {path} {path_array.length > idx+1 ? ' > ' : ''}
          </span>
        })}</div>;

        let tableCells:any = {
          key: data.id,
          name: <div className="templated-experiences-index__name">
            <div className="templated-experiences-index__instance-name">
              {(flags['tx_folders'] && !data.rootStepID) &&
                <img src={FolderIcon} alt="Folder" className="templated-experiences-index__icon"/>
              }
              {(flags['tx_groups'] && data.steps?.[0]?.trigger?.type === '' && !!data.steps[0]?.groups?.length) && <FlightTooltip
                direction="right"
                isEnabled={true}
                description={<div>This experience is part of the group(s):<br/>
                  {data.steps[0]?.groups?.map((group: any, idx: number) => {
                    let separator = '';
                    if (idx < data.steps[0]?.groups?.length - 1) separator = ', ';
                    let groupData = groupsState?.find(g => g.id === group.id);
                    return groupData?.localizations?.en?.name + separator
                  })}</div>}
              >
                <img src={GroupIcon} alt={''} className="templated-experiences-index__icon"/>
              </FlightTooltip>}
              {data.name || 'Untitled'} {(flags['tx_folders'] && !data.rootStepID) && ` (${data.numExperiences || 0})`}
            </div>
            {(filters.search && data.pathName !== '/') && instance_path}
          </div>,
          status: data?.status && <div><FlightTag
            type={defineStatusStyle(data?.status)}
            label={data?.status}
            className="templated-experiences-index__status"
          /></div>,
          schedule: <div>{schedule}{data.status !== 'Draft' ? scheduleDescr : ''}</div>,
          updated_at: moment(data.updatedAt * 1000).format('MMM D, YYYY'),
          created_at: moment(data.createdAt * 1000).format('MMM D, YYYY'),
          edit: renderDropdown(data?.status, data),
          item: data
        }

        let extraLabels = '';
        if(flags['tx_labels']) {
          tableCells = {...tableCells, labels: <div className="templated-experiences-index__labels">
            {data.labels?.map((label:string, idx: number) => {
              if(idx < 2) {
                return <span key={label} className="templated-experiences-index__labels__label">{label}</span>
              } else {
                extraLabels += `${label}${data.labels.length-1 > idx ? ',' : ''} `;
              }
            })}
            {data.labels?.length > 2 && (
              <FlightTooltip
                direction="top"
                isEnabled={true}
                description={extraLabels}
                delay={0}
              >
                <span className="templated-experiences-index__labels__text">+{data.labels.length - 2} more</span>
              </FlightTooltip>
            )}
          </div>};
        }

        return tableCells;
      });

      //used for multistep
      let  _multiTableData: any;
      if (multiStepInstances) {
          _multiTableData = multiStepInstances.map((data: any) => {
          let startDate = data?.entry?.schedule?.start || 0;
          const scheduled =
            moment(startDate * 1000).format('MMM D, YYYY') +
            ' ' +
            '-' +
            ' ' +
            (data?.entry?.schedule?.end !== 0
              ? moment(data?.entry?.schedule?.end * 1000).format('MMM D, YYYY')
              : 'No end date');
          let _multiTableCells:any = {
            key: data.id,
            name: data.name || 'Untitled',
            status: (
              <FlightTag
                type={defineStatusStyle(data?.status)}
                label={data?.status}
                className="multi-step-index__status"
              />
            ),
            schedule: startDate !== 0 ? scheduled : 'None Set',
            updatedAt: moment(data.updatedAt).format('MMM D, YYYY'),
            createdAt: moment(data.createdAt).format('MMM D, YYYY'),
            isMulti: true,
            edit: renderMultiDropDown(data?.status, data),
          };

          if(flags['tx_labels']) {
            _multiTableCells = {..._multiTableCells, labels: <div>label</div>};
          }
          return _multiTableCells
        });
      }
        if(_multiTableData) {
          const combinedData = [..._multiTableData, ..._tableData]
          setTableData(combinedData);
        } else {
          setTableData(_tableData);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reduxTemplatedExperienceState.instances,
    reduxTemplatedExperienceState.instance,
    filters,
    history,
    groupsState
  ])

  const onManageGroups = (data: TExperienceInstance) => {
    setGroupsModalProps({
      ...groupsModalProps,
      experience: data,
      isVisible: true,
      icon: '',
      title: 'Manage Groups',
      cancelButton: {
        name: 'Cancel',
        onClick: () => setGroupsModalProps({
          ...groupsModalProps,
          isVisible: false,
        })
      },
      primaryButton: {
        name: 'Save',
        onClick: (changedGroups: any[], experience: TExperienceInstance, groups: any[]) => onManageGroupsSave(changedGroups, experience, groups)
      }
    });
  }

  const onManageGroupsSave = (changedGroups: any[], experience: TExperienceInstance, groups: any[]) => {

    let groupIds:any = [];
    let groupRequests: any = [];
    const actionId = experience.steps?.[0]?.actions?.find((item: any) => item.actionType === 'experience')?.actionID?.toLocaleUpperCase();
    groups.forEach((group: any) => {
      let isGroupChanged = changedGroups.find((selGroup) => selGroup.id === group.id);
      if(isGroupChanged) {
        if(isGroupChanged.status === 'selected') {
          groupIds.push(group);
        }
        if(isGroupChanged.status === 'unselected') {
          groupIds = groupIds.filter((g:any) => g.id !== group.id);
        }
        groupRequests.push({
          groupID: group.id,
          experienceID: actionId,
          status: isGroupChanged.status
        });
      }
    });
    groupAPI.updateGroup(groupRequests, experience.id);
    experience.steps[0].groups = groupIds;
    dispatch(setInstance({instance: experience}));

    setGroupsModalProps({
      ...groupsModalProps,
      isVisible: false,
    })
  }

  // function onDeactivate(data: TExperienceInstance) {
  //   setModalProps({
  //     ...modalProps,
  //     isVisible: true,
  //     title: 'Deactivate Now',
  //     content: 'Proceeding will deactivate your experience immediately. '+
  //       'This experience will become Inactive and will not be visible to your customers. ' +
  //       'You may activate your experience from the Experiences page at any time.',
  //     primaryButton: {
  //       ...modalProps.primaryButton,
  //       name: 'Confirm',
  //       onClick: () => onDeactivateConfirm(data.id)
  //     }
  //   });
  // }

  // function onDeactivateConfirm(id: string, nextAction: string = '') {
  //   reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(id)
  //     .then(() => {
  //       if (nextAction === 'edit') {
  //         trackEvent({
  //           category: 'Templated Experiences',
  //           action: 'Deactivate for Editing',
  //         });
  //         return history.push(`/templated-experiences/edit/${id!!}`)
  //       } else {
  //         setModalProps({
  //           ...modalProps,
  //           isVisible: false,
  //         });
  //       }
  //       trackEvent({
  //         category: 'Templated Experiences',
  //         action: 'Deactivate',
  //       });
  //     })
  //     .catch(() => alert('In development'));
  // }

  // function onActivate(data: TExperienceInstance) {
  //   let title = 'Activate Now';
  //   let message = 'This experience will become Active and visible to your customers. ' +
  //     'You may deactivate your experience from the Experiences page at any time.';
  //   if(data.schedule.start){
  //     title = 'Activate Schedule';
  //     message = 'This experience will become active and visible to your customers by scheduled start date. ' +
  //       'You may deactivate scheduled experience from the Experiences page at any time.';
  //   }
  //   setModalProps({
  //     ...modalProps,
  //     isVisible: true,
  //     title,
  //     content: message,
  //     primaryButton: {
  //       ...modalProps.primaryButton,
  //       name: 'Confirm',
  //       onClick: () => onActivateConfirm(data.id)
  //     }
  //   });
  // }

  // function onActivateConfirm(id: string) {
  //   reduxTemplatedExperienceAction.activateTemplatedExperienceInstance(id)
  //     .then(() => {
  //       setModalProps({
  //         ...modalProps,
  //         isVisible: false,
  //       });
  //       trackEvent({
  //           category: 'Templated Experiences',
  //           action: 'Activate',
  //         })
  //       }
  //     )
  //     .catch((err: any) => alert('Error: '+err));
  // }

  // function onEditClick(data: TExperienceInstance) {
  //   if (data.status === 'Active') {
  //     setModalProps({
  //       ...modalProps,
  //       isVisible: true,
  //       primaryButton: {
  //         ...modalProps.primaryButton,
  //         onClick: () => onDeactivateConfirm(data.id, 'edit')
  //       }
  //     });
  //     trackEvent({
  //       category: 'Templated Experiences',
  //       action: 'Edit',
  //     });
  //   } else {
  //     history.push(`/templated-experiences/edit/${data.id}`)
  //   }
  // }

  const onPageChange = (page: number) => {
    if (filters.page !== page) {
      setFilters({
        ...filters,
        page
      });
    }
  }

  const onRowPerPageChange = (rowPerPage: number) => {
    if (filters.limit !== rowPerPage) {
      const pagination = reduxTemplatedExperienceState.instances.pagination;
      const _currentPage = (rowPerPage * Number(filters.page)) > pagination.totalRecords ? 1 : Number(filters.page);
      setFilters({
        ...filters,
        page: _currentPage,
        limit: rowPerPage
      });
    }
  }

  const onBackHandle = async() => {
    const storage = new Storage();
    const tenantId = window.location.pathname?.split('/')?.[2]
    let origin = await storage.getItem(`${tenantId}+origin`);
    if (origin) {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Return to Experience Studio (Ember)',
      });
      window.location.href = origin as string;
    } else {
      alert('Temporary Warning: Could not find Ember Route to redirect to. Please enter this route from ember or by using the developer hub.');
      history.push('/developer-hub');
    }
  }

  const onDelete = (instance: TExperienceInstance) => {
    setModalProps({
      ...modalProps,
      isVisible: true,
      title: 'Delete Experience',
      content: <div>Deleting is permanent and can not be undone.
        Any Active and Scheduled experiences will not be shown to customers.</div>,
      primaryButton: {
        ...modalProps.primaryButton,
        name: 'Delete Experience',
        onClick: () => onDeleteConfirm(instance)
      }
    });
  }

  const onDeleteConfirm = (instance: TExperienceInstance) => {
    reduxTemplatedExperienceAction.deleteTemplatedExperienceInstance(instance, filters)
      .then(() => {
        setModalProps({
          ...modalProps,
          isVisible: false,
        });
        trackEvent({
          category: 'Templated Experiences',
          action: 'Delete',
        });
      })
  }

  const handleDataSelect = (e: any) => {
    trackEvent({
      category: 'Templated Experiences',
      action: 'View',
    });

    if(e.item?.rootStepID) { // if instance
      setFilters({...filters});
      if (e.isMulti) {
        history.push(`/multi${pathName}/edit/${e.key}/?${queryString.stringify({...filters})}`);
        return;
      }
      if (e.item.status === 'Active') {
        history.push(`${pathName}/view/${e.item.id}/?${queryString.stringify({...filters})}`);
      } else {
        history.push(`${pathName}/edit/${e.item.id}/?${queryString.stringify({...filters})}`);
      }
    } else { // if folder
      filters.parentFolderID = e.item?.id;
      history.push(`${pathName}?${queryString.stringify({...filters})}`);
    }
  }

  const handleCheckboxMultiSelect = (e: any) => {
    if(selectedExperiences.has(e.key)) {
      selectedExperiences.delete(e.key);
    } else {
      selectedExperiences.add(e.key)
    }
    setSelectedExperiences(new Set(selectedExperiences));
  }

  const handleSelectAll = (e: any) => {
    setSelectedExperiences(e);
  }

  const handleCreateExperience = () => {
    history.push(`${pathName}/library-selection/?${queryString.stringify({...filters})}`)
  }

  const updateSearchText = (e:React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      page: 1,
      search: e.target.value
    });
  }

  const handleHeaderSort = (e:{key:string}) => {
    setFilters({
      ...filters,
      sortby: e.key,
      sortorder: filters.sortorder === 'desc' ? 'asc' : 'desc'
    });
  }

  const handleShowFilters = () => {
    setShowFilters(!showFilters)
  }

  const handleStatusSelect = (e: Status) => {
    const updatedStatuses = statuses.map(status => {
      if(status.key === e.key){
        status.isSelected = !status.isSelected;
      }
      return status;
    })
    setStatuses(updatedStatuses);
    let selectedStatuses:any = [];
    updatedStatuses.forEach(status => {
      if(status.isSelected) {
        selectedStatuses.push(status.key);
      }
    });
    setFilters({
      ...filters,
      status: selectedStatuses
    })
  }

  const handleTemplateTypeSelect = (e: {key: string, name: string, isSelected: boolean}) => {
    const updatedTypes = templateTypes.map(type => {
      if(type.key === e.key){
        type.isSelected = !type.isSelected;
      }
      return type;
    })
    setTemplateTypes(updatedTypes);
    let selectedTypes:any = [];
    updatedTypes.forEach(type => {
      if(type.isSelected) {
        selectedTypes.push(type.key);
      }
    });
    setFilters({
      ...filters,
      type: selectedTypes
    })
  }

  const handleLabelSelect = (e: Status) => {
    const updatedLabels = allLabels?.sort()?.map(label => {
      if(label.key === e.key){
        label.isSelected = !label.isSelected;
      }
      return label;
    })
    setLabels(updatedLabels);
    let selectedLables:any = [];
    updatedLabels?.sort()?.forEach(label => {
      if(label.isSelected) {
        selectedLables.push(label.key);
      }
    });
    setFilters({
      ...filters,
      label: selectedLables
    })
  }

  const handleSearchByLabel = (labelName: string) => {
    const filteredLabels = allLabels?.sort()?.filter(label => label.name.toLowerCase().includes(labelName.toLowerCase()));
    setLabels(filteredLabels);
  }

  const handleSetDateRange = (e:any) => {
    setStartDate(e?.[0]);
    setEndDate(e?.[1]);
    setFilters({
      ...filters,
      startdate: moment(e?.[0]).unix() || 0,
      enddate: moment(e?.[1]).unix() || 0,
    });
  }

  const countActiveFilters = () => {
    const allkeys = union(keys(filters), keys(initialFilters));
    const difference = reduce(allkeys, function (result:any, key:string) {
      if (!isEqual(filters[key], initialFilters[key])) {
        if(key === 'status' || key === 'label' || key === 'enddate' || key === 'type') {
          result[key] = {obj1: filters[key], obj2: initialFilters[key]}
        }
      }
      return result;
    }, {});
    return keys(difference)?.length || 0;
  }

  const clearFilters = () => {
    setFilters(initialFilters);
    setStartDate(null);
    setEndDate(null);
    const clearedStatuses = statuses.map(status => {
      status.isSelected = false;
      return status;
    })
    setStatuses(clearedStatuses);
    const clearedLabels = labels.map(label => {
      label.isSelected = false;
      return label;
    })
    setLabels(clearedLabels);
    const clearedTypes = templateTypes.map(type => {
      type.isSelected = false;
      return type;
    })
    setTemplateTypes(clearedTypes);
  }

  const handleCreateFolder = async () => {
    let parentFolderID = filters.parentFolderID;
    if(!parentFolderID) {
      const foldersTree = await folderAPI.getFoldersTree().then((res: PaginationResponse<Folder>) => res.data);
      parentFolderID = foldersTree?.[0]?.id || '00000000-0000-0000-0000-000000000000';
    }
    setFolderModalProps({
      ...folderModalProps,
      isVisible: true,
      parentFolderID
    });
  }

  const handleMoveToFolder = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setFoldersModalProps({
      ...foldersModalProps,
      isVisible: true,
      entities: selectedExperiences
    })
  }

  const handleAddLabels = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setLabelsModalProps({
      ...labelsModalProps,
      isVisible: true,
      entities: selectedExperiences,
      onCancel: () => setLabelsModalProps({...labelsModalProps, isVisible: false})
    })
  }

  const handleBulkDelete = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    modalProps.isVisible = true;
    modalProps.icon = '';
    modalProps.title = 'Delete';
    modalProps.content = <div>
      <div style={{ marginBottom: '20px' }}>The selected folder(s) and/or Experiences(s) will be permanently deleted.</div>
      <div>This cannot be undone. Do you wish to continue?</div>
    </div>;
    modalProps.errorMessage = '';
    modalProps.primaryButton.name = 'Delete';
    modalProps.primaryButton.disabled = false;
    modalProps.primaryButton.onClick = () => onBulkDeleteConfirm();
    modalProps.cancelButton.onClick = () => setModalProps({...modalProps, isVisible: false, errorMessage: ''});
    setModalProps({...modalProps});
  }

  const onBulkDeleteConfirm = async () => {
    modalProps.primaryButton.disabled = true;
    setModalProps({...modalProps});
    modalProps.primaryButton.disabled = true;
    const foldersTree = await folderAPI.getFoldersTree()
      .then((res: PaginationResponse<Folder>) => res.data)
      .catch((error) => {
        modalProps.primaryButton.disabled = false;
        modalProps.errorMessage = error.message;
        setModalProps({...modalProps});
      });
    const payload: {folders: string[], experiences: string[]} = {
      folders: [],
      experiences: []
    };
    if(!isEmpty(selectedExperiences) && foldersTree) {
      await selectedExperiences.forEach((child:string) => {
        const isFolder = findFolderById(child, foldersTree);
        if(isFolder) {
          payload.folders.push(child);
        } else {
          payload.experiences.push(child);
        }
      });
      folderAPI.bulkDelete(payload).then(() => {
        setSelectedExperiences(new Set([]));
        setFilters({...filters});
        modalProps.primaryButton.disabled = false;
        modalProps.isVisible = false;
        setModalProps({...modalProps});
      }).catch((error) => {
        modalProps.primaryButton.disabled = false;
        modalProps.errorMessage = error.message;
        setModalProps({...modalProps});
      });
    } else {
      modalProps.primaryButton.disabled = false;
      setModalProps({...modalProps});
    }
  }

  const redirectToFolder = (e:React.MouseEvent, folderId: string) => {
    e.preventDefault();
    filters.parentFolderID = folderId;
    setFilters(filters);
    dispatch(setCurrentFolder({ currentFolder: undefined }));
    history.push(`${pathName}?${queryString.stringify({...filters},{arrayFormat: 'none'})}`);
  }

  const onDeleteFolder = (folder: Folder|undefined) => {
    setDeleteFolderModalProps({
      ...deleteFolderModalProps,
      isVisible: true,
      isDeleting: false,
      onDelete: (isCheckboxSelected:string) => handleDeleteFolder(isCheckboxSelected, folder)
    });
  }

  const handleDeleteFolder = async (isCheckboxSelected:string, folder:Folder|undefined) => {
    setDeleteFolderModalProps({...deleteFolderModalProps, isVisible: true, isDeleting: true});
    const folderId = folder?.id || filters?.parentFolderID;
    const foldersTree = await folderAPI.getFoldersTree().then((res: PaginationResponse<Folder>) => res.data);
    const foundFolder = findFolderById(folderId, foldersTree);
    const _folder = folder || foundFolder;
    let parentFolder:Folder|undefined = undefined;
    if(_folder?.parentFolderID) {
      parentFolder = findFolderById(_folder?.parentFolderID, foldersTree);
    }
    if(folderId) {
      if(isCheckboxSelected === 'SELECTED' && folderId) {
        folderAPI.bulkDelete({folders: [folderId], experiences:[]})
          .then(()=>{
            if(folder) { // if delete with dropdown menu option
              reduxTemplatedExperienceAction.fetchFolderChildren(filters.parentFolderID, filters);
            } else {
              filters.parentFolderID = _folder?.parentFolderID === '00000000-0000-0000-0000-000000000000' ? '' : _folder?.parentFolderID;
              setFilters(filters);
              dispatch(setCurrentFolder({currentFolder: parentFolder}));
              history.replace(`${pathName}?${queryString.stringify({...filters}, {arrayFormat: 'none'})}`);
            }
            setDeleteFolderModalProps({
              ...deleteFolderModalProps,
              isVisible: false,
              isDeleting: false
            });
          })
          .catch((error: Error) => {
            setErrorMessage(error.message);
          });
      } else {
        if(foldersTree?.[0]){
          const payload: {destination: string, folders: string[], experiences: string[]} = {
            destination: foldersTree[0]?.id || '',
            folders: [],
            experiences: []
          };
          const children:TExperienceInstance[]|void = await folderAPI.getFolderChildren(folderId, {})
            .then(children => children.data)
            .catch((error: Error) => {
              setErrorMessage(error.message);
            });
          if(children) {
            await children.forEach((child:TExperienceInstance) => {
              if(child?.rootStepID) { // if instance
                payload.experiences.push(child.id);
              } else { // if folder
                payload.folders.push(child?.id!);
              }
            });
          }
          folderAPI.bulkMoveToFolder(payload)
            .then(() => {
              folderAPI.bulkDelete({folders: [folderId], experiences:[]})
                .then(()=>{
                  if(folder) { // if delete with dropdown menu option
                    reduxTemplatedExperienceAction.fetchFolderChildren(filters.parentFolderID, filters);
                  } else {
                    filters.parentFolderID = parentFolder?.id || '';
                    setFilters(filters);
                    dispatch(setCurrentFolder({currentFolder: parentFolder}));
                    history.replace(`${pathName}?${queryString.stringify({...filters}, {arrayFormat: 'none'})}`);
                  }
                  setDeleteFolderModalProps({
                    ...deleteFolderModalProps,
                    isVisible: false,
                    isDeleting: false
                  });
                })
                .catch((error: Error) => {
                  setErrorMessage(error.message);
                });
            })
            .catch((error: Error) => {
              setErrorMessage(error.message);
            });
        }
      }
    }
  }

  const handleRenameFolder = (folder: Folder) => {
    setFolderModalProps({
      ...folderModalProps,
      isVisible: true,
      editFolder: true,
      folder
    })
  }

  return (
    <div className="templated-experiences-index">
      <div className="templated-experiences-index__container">
        <div className="templated-experiences-index__header">
          {!!filters?.parentFolderID && filters?.parentFolderID !== '00000000-0000-0000-0000-000000000000' ? (
            <div className="templated-experiences-index__breadcrumbs">
              <FoldersBreadcrumbs clickable={true} redirectToFolder={redirectToFolder}/>
            </div>
          ) : (
            <div>
              <div className="templated-experiences-index__title">Experiences 2.0</div>
              <div onClick={() => onBackHandle()} className="templated-experiences-index__subtitle">{`<`} Back to Experience Studio</div>
            </div>
          )}
          <div className="templated-experiences-index__buttons-container">
            {flags['tx_folders'] && <>
              {(!!filters?.parentFolderID && filters?.parentFolderID !== '00000000-0000-0000-0000-000000000000') &&  <FlightOverflowMenu
                label="More"
                className="templated-experiences-index__buttons-container__dropdown"
                iconRight="baselineKeyboardArrowDown"
                isOpenRight
                optionGroups={[[
                  {
                    key: 1,
                    name: 'Rename',
                    disabled: false,
                    onClick: (() => handleRenameFolder(reduxTemplatedExperienceState.currentFolder)),
                  },
                  {
                    key: 2,
                    name: 'Delete',
                    disabled: false,
                    onClick: (() => onDeleteFolder(undefined)),
                  }
                ]]}
              />}
              <FlightButton
                label="New folder"
                size="large"
                theme="secondary"
                className="templated-experiences-index__create-button"
                onClick={handleCreateFolder}
              />
            </>}
            <FlightButton
              label="New experience"
              size="large"
              className="templated-experiences-index__create-button"
              onClick={handleCreateExperience}
            />
          </div>
        </div>
        {errorMessage && <div className="templated-experiences-index__error-message">Error: {errorMessage}</div>}
        {flags['tx_filters'] &&
          <>
            <div className="templated-experiences-index__filters">
              <div className="templated-experiences-index__filters__search-container">
                <FlightTextInput
                  width="100%"
                  iconInput="search"
                  value={String(filters.search)}
                  hasClearIcon={true}
                  onChange={updateSearchText}
                  className="templated-experiences-index__filters__search"
                />
                <FlightButton
                  label={
                    <span>Filters {activeFilters>0 ?
                      <strong className="templated-experiences-index__filters__button__label">{activeFilters}</strong> : ''}
                    </span>}
                  size="medium"
                  className={`templated-experiences-index__filters__button ${activeFilters>0 || showFilters ? 'active' : ''}`}
                  onClick={handleShowFilters}
                  theme="secondary"
                  iconRight={showFilters ? "baselineKeyboardArrowUp" : "baselineKeyboardArrowDown"}
                />
                {!!countActiveFilters() && (
                  <FlightButton
                    label="Clear all"
                    size="medium"
                    className="templated-experiences-index__filters__button-clear"
                    onClick={clearFilters}
                    theme="link"
                  />
                )}
              </div>
            </div>
            {showFilters && (
              <div className="templated-experiences-index__filters">
                <div className="templated-experiences-index__filters__search-container">
                  <FlightSelectMulti
                    label="Status"
                    isLabelAlwaysShown={true}
                    className=""
                    options={statuses}
                    handleOptionSelect={handleStatusSelect}
                  />
                  <FlightSelectMulti
                    label="Template type"
                    isLabelAlwaysShown={true}
                    className="templated-experiences-index__filters__search-container__filter"
                    options={templateTypes}
                    handleOptionSelect={handleTemplateTypeSelect}
                  />
                  <FlightSelectMulti
                    label="Labels"
                    isLabelAlwaysShown={true}
                    className="templated-experiences-index__filters__search-container__filter"
                    options={labels}
                    handleOptionSelect={handleLabelSelect}
                    handleSearch={handleSearchByLabel}
                    isSearchEnabled
                  />
                  <div className="templated-experiences-index__filters__search-container__filter">
                    <FlightDateRangePicker
                      onChange={handleSetDateRange}
                      isIncludeCustom
                      value={[startDate, endDate]}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        }
        <div className="templated-experiences-index__main">
          {(flags['tx_folders'] && !!selectedExperiences.size) && <div className="templated-experiences-index__table-actions">
            <span key="counter"  className="templated-experiences-index__table-actions__action counter">{selectedExperiences.size} selected</span>
            <a href="/" key="move-to" className="templated-experiences-index__table-actions__action" onClick={handleMoveToFolder}>Move to</a>
            <a href="/" key="add-labels" className="templated-experiences-index__table-actions__action" onClick={handleAddLabels}>Add labels</a>
            <a href="/" key="delete" className="templated-experiences-index__table-actions__action" onClick={handleBulkDelete}>Delete</a>
          </div>}
          <FlightTable
            tableHeaders={tableHeader}
            tableData={tableData}
            isLoading={reduxTemplatedExperienceState.isLoading}
            loadingRowNumber={Number(filters.limit)}
            hasError={!!reduxTemplatedExperienceState.errorMessage}
            emptyState={<div className="templated-experiences-index__empty">There are no experiences</div>}
            errorState={<div className="templated-experiences-index__error-message">Error: {reduxTemplatedExperienceState.errorMessage}</div>}
            allowRowSelect
            allowMultiSelect={flags['tx_folders']}
            selectedDataKey={selectedExperiences}
            handleDataSelect={handleDataSelect}
            handleHeaderSort={handleHeaderSort}
            handleSelectAll={handleSelectAll}
            handleCheckboxMultiSelect={handleCheckboxMultiSelect}
            hasPaginationBeforeTable={false}
            hasPaginationAfterTable
            sortByKey={String(filters.sortby)}
            sortOrder={String(filters.sortorder)}
            paginationProps={{
              totalPageNumber: maxPage,
              currentPageNumber: Number(filters.page),
              rowsPerPageOptions: [10, 20, 40, 60],
              currentRowsPerPage: Number(filters.limit),
              handlePageChange: onPageChange,
              handleRowsPerPageChange: onRowPerPageChange,
            }}
          />
        </div>
      </div>
      <FolderModal {...folderModalProps} />
      <FoldersModal {...foldersModalProps} />
      <ConfirmModal {...modalProps} />
      <ConfirmDeleteFolder {...deleteFolderModalProps} />
      <GroupsModal {...groupsModalProps} />
      <LabelsModal {...labelsModalProps} />
    </div>
  );

}


import React, { useState, useEffect, useContext } from 'react'
import { FlightRadioButton, FlightNumberInput, FlightSelect } from '@flybits/webapp-design-system-react';
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';
import './SettingEdit.scss';

export default function SettingEdit(props: any) {

  const multiStepContext: any = useContext(MultiStepContext);
  const { entry, message } = multiStepContext.template.entry.setting;

  const periods = [
    {key: 'seconds', name: 'Seconds'},
    {key: 'minutes', name: 'Minutes'},
    {key: 'hours', name: 'Hours'},
    {key: 'days', name: 'Days'},
    {key: 'weeks', name: 'Weeks'},
    {key: 'months', name: 'Months'},
    {key: 'years', name: 'Years'},
  ];

  const [settingData, setSettingData] = useState({
    entry: {
      isUnlimited: entry.isUnlimited,
      max: entry.max
    },
    message: {
      isUnlimited: message.isUnlimited,
      frequency: message.frequency,
      max: message.max,
      repeat: {
        key: message.repeat.key,
        name: message.repeat.name
      }
    }
  })

  useEffect(() => {
    props.onChange(settingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingData]);

  return (
    <div className="setting-edit">
      <h2> Send Settings </h2>
      <div className="block">
        <h3> Entry Limit </h3>
        <small> You can limit the number of times a user re-enters the experience </small>
        <FlightRadioButton
          label="Unlimited entries"
          checked={settingData.entry.isUnlimited}
          disabled={false}
          onSelect={(e: any) => {
            settingData.entry.isUnlimited = true;
            setSettingData({...settingData})
          }}
        />
        <div className="row">
          <FlightRadioButton
            label="Allow each user to enter experience"
            checked={!settingData.entry.isUnlimited}
            disabled={false}
            onSelect={(e: any) => {
              settingData.entry.isUnlimited = false;
              setSettingData({...settingData});
            }}
          />
          <FlightNumberInput
            width={'70px'}
            disabled={settingData.entry.isUnlimited}
            value={settingData.entry.max.toString()}
            onChange={(e) => {
              settingData.entry.max = e.target.value;
              setSettingData({...settingData})
            }}
            className="number-input"
          />
          <p> time(s) in total </p>
        </div>
      </div>
      <div className="block">
        <h3> Message Limit </h3>
        <small> You can limit the number of times a triggered message is sent to the same user </small>
        <FlightRadioButton
          label="Unlimited messages"
          checked={settingData.message.isUnlimited}
          disabled={false}
          onSelect={(e: any) => {
            settingData.message.isUnlimited = true;
            setSettingData({...settingData});
          }}
        />
        <FlightRadioButton
          label="Apply message limit"
          checked={!settingData.message.isUnlimited}
          disabled={false}
          onSelect={(e: any) => {
            settingData.message.isUnlimited = false;
            setSettingData({...settingData});
          }}
        />
        <div className={settingData.message.isUnlimited ? 'block-indent disabled' : 'block-indent'}>
          <div className="row">
            <p> Send no more than one message per user every </p>
            <FlightNumberInput
              width={'70px'}
              disabled={settingData.message.isUnlimited}
              value={settingData.message.frequency.toString()}
              onChange={(e) => {
                settingData.message.frequency = e.target.value;
                setSettingData({...settingData});
              }}
              className="number-input"
            />
            <FlightSelect
              label=""
              options={periods}
              selected={settingData.message.repeat}
              hasLabelAnimation={true}
              handleOptionClick={(option)=> {
                settingData.message.repeat = option;
                setSettingData({...settingData});
              }}
              dropdownMaxHeight={'200px'}
              disabled={settingData.message.isUnlimited}
              className="trigger-edit-modal__select-period"
              dropdownDirection={'bottom'}
            />
          </div>
          <div className="row">
            <p> Allow an absolute maximum of </p>
            <FlightNumberInput
              width={'70px'}
              disabled={settingData.message.isUnlimited}
              value={settingData.message.max.toString()}
              onChange={(e) => {
                settingData.message.max = e.target.value;
                setSettingData({...settingData});
              }}
              className="number-input"
            />
            <p> message(s) per user in total </p>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PushActionEdit from 'components/MultiStepDemo/StepCriteriaEdit/ActionEdit/PushActionEdit/PushActionEdit';
import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';

import './ActionEdit.scss';
import ContentActionEdit from './ContentActionEdit/ContentActionEdit';
import SmsActionEdit from './SmsActionEdit/SmsActionEdit';

interface IProps {
  step: any;
  selectedActionIndex: number;
  setSelectedActionIndex: (index: number) => void;
}

export default function ActionEdit({ step, selectedActionIndex, setSelectedActionIndex }: IProps) {
  type contentT = 'web-content' | 'inapp-content' | 'push' | 'email' | 'sms';
  const dispatch = useDispatch();

  const [contentType, setContentType] = useState<contentT>('push');
  const multiStepContext: any = useContext(MultiStepContext);

  function handleOnApply(type: contentT, data: any) {
    const actionData: any = {
      type: type,
      data,
    };

    // append action to the list
    if (selectedActionIndex !== -1) {
      step.actions[selectedActionIndex] = actionData;
      dispatch({ type: 'SHOW_SNACKBAR', payload: { content: `Successfully updated action`, type: 'success' } });
    } else {
      dispatch({ type: 'SHOW_SNACKBAR', payload: { content: `Successfully created action`, type: 'success' } });
      step.actions.push(actionData);
    }
    multiStepContext.updateStep({ id: step.id, body: step });
    setSelectedActionIndex(-1); // set to unselected mode
  }

  useEffect(() => {
    if (step?.actions[selectedActionIndex]?.type) {
      setContentType(step?.actions[selectedActionIndex]?.type);
    } else {
      setContentType('push');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionIndex]);

  return (
    <div className="action-edit">
      <h2> Action </h2>
      <div className="action-edit-select">
        <ul>
          <li className={contentType === 'web-content' ? 'selected' : ''} onClick={() => setContentType('web-content')}>
            {' '}
            Web Content{' '}
          </li>
          <li
            className={contentType === 'inapp-content' ? 'selected' : ''}
            onClick={() => setContentType('inapp-content')}
          >
            {' '}
            In-App Content{' '}
          </li>
          <li className={contentType === 'push' ? 'selected' : ''} onClick={() => setContentType('push')}>
            {' '}
            Push Notification{' '}
          </li>
          <li className={contentType === 'email' ? 'selected' : ''} onClick={() => setContentType('email')}>
            {' '}
            Email Message{' '}
          </li>
          <li className={contentType === 'sms' ? 'selected' : ''} onClick={() => setContentType('sms')}>
            {' '}
            SMS Message{' '}
          </li>
        </ul>
        <hr />
      </div>
      <div className="action-edit-main">
        {contentType === 'push' && (
          <div className="push">
            <h3> Configure push notification </h3>
            <PushActionEdit
              step={step}
              selectedActionIndex={selectedActionIndex}
              onApply={(data: any) => handleOnApply('push', data)}
            />
          </div>
        )}
        {contentType === 'inapp-content' && (
          <div className="inapp-content">
            <h3>Configure in-app content</h3>
            <ContentActionEdit
              step={step}
              selectedActionIndex={selectedActionIndex}
              onApply={(data: any) => handleOnApply('inapp-content', data)}
            />
          </div>
        )}
        {contentType === 'web-content' && (
          <div className="inapp-content">
            <h3> Configure your Web Content </h3>
            <ContentActionEdit
              step={step}
              selectedActionIndex={selectedActionIndex}
              onApply={(data: any) => handleOnApply('web-content', data)}
            />
          </div>
        )}
        {contentType === 'email' && (
          <div className="inapp-content">
            <h3> Configure Email Content </h3>
            <ContentActionEdit
              step={step}
              initialSearchTerm={'email'}
              selectedActionIndex={selectedActionIndex}
              onApply={(data: any) => handleOnApply('email', data)}
            />
          </div>
        )}
        {contentType === 'sms' && (
          <div className="push">
            <h3> Configure your SMS </h3>
            <SmsActionEdit
              step={step}
              selectedActionIndex={selectedActionIndex}
              onApply={(data: any) => handleOnApply('sms', data)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

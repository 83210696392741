
import BaseAPI from './base.api';
import { Location } from 'interface/location/location.interface';

export default class LocationAPI extends BaseAPI {
  private ROUTES = {
    getLocation: '/context/location/:id'
  }

  getLocation(id: string): Promise<Location> {
    return this.GET(this.buildURL(this.ROUTES.getLocation, {id: id}))
  }
}

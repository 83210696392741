import BaseAPI from './base.api';
import {Group} from 'interface/group.interface';

export default class GroupAPI extends BaseAPI {
  private ROUTES = {
    experience_url: '/kernel/experiences/groups/',
    journey_url: '/kernel/journey/groups/'
  }

  getGroups(page: number, limit: number, search: string = '', searchExact: boolean = false): Promise<Group[]> {
    return this.GET(`${this.ROUTES.experience_url}?sort=priority&sortby=createdAt&sortorder=desc&limit=${limit}&offset=${page * limit}&search=${search}&searchExact=${searchExact}`);
  }

  updateGroup(groups: any, id: string) {
    return this.PUT(`${this.ROUTES.journey_url}${id}`, groups);
  }
}

import React, { useState, useEffect } from 'react';
import './StreamLinedContentPreview.scss';
import { Content } from 'interface/content/content.interface';
import { decodeURIComponentSafe } from 'helpers/safe.decodeuri.helper';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { tokenCreator } from 'helpers/dynamic-attribute-token.helper';
import useSettings from 'hooks/useSetting';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';

interface RenderPreviewProps {
  title: string;
  description: string;
  header: string;
  image: string;
  link: any;
  buttons: any;
  details: any;
}


const RenderCardView = (props: RenderPreviewProps) => {
  const { title='', description='', header='', image='', link='', buttons=[] } = props;
  const decodedPreviewBody = decodeURIComponentSafe(description ? description : '');
  const acceptableLength = 200;
  const truncatedBody = decodedPreviewBody?.substring(0, acceptableLength) || '';
  const fullText = decodedPreviewBody || '';
  const [isShowMore, setShowMore] = useState(false);
  const plugins = useSelector((state: any) => state.plugin.plugins);
  const toggleShowMore = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowMore((isShowMore) => !isShowMore);
  };

  return (
    <div className="streamlined-content__preview">
      {image && <img className="streamlined-content__preview__header-img" src={image} alt="headerimg" />}
      {header && (
        <div className="streamlined-content__preview__header">
          <span className="streamlined-content__preview__header__wrap">
            {ReactHtmlParser(tokenCreator(header, plugins))}
          </span>
        </div>
      )}
      {title && (
        <div className="streamlined-content__preview__title">
          <span className="streamlined-content__preview__title__wrap">
            {ReactHtmlParser(tokenCreator(title, plugins))}
          </span>
        </div>
      )}
      {description && (
        <>
          <div className="streamlined-content__preview__description">
            {ReactHtmlParser(tokenCreator(isShowMore ? fullText : truncatedBody, plugins))}
          </div>
          <span className="streamlined-content__preview__description__showmore" onClick={toggleShowMore}>
            {decodedPreviewBody?.length > acceptableLength && <div>{isShowMore ? 'Show Less' : 'Show More'}</div>}
          </span>
        </>
      )}
      {buttons?.[0] && (
        <div className="streamlined-content__preview__button--primary">
          {buttons?.[0]?.localizations?.[Object.keys(buttons[0].localizations)[0]]?.text}
        </div>
      )}
      {buttons?.[1] && (
        <div className="streamlined-content__preview__button--secondary">
          {buttons?.[1]?.localizations?.[Object.keys(buttons[1].localizations)[0]]?.text}
        </div>
      )}
      {link && <div className="streamlined-content__preview__link">
        {ReactHtmlParser(tokenCreator(link?.localizations?.[Object.keys(link.localizations)[0]].text, plugins))}
      </div>}
    </div>
  );
};


const RenderDetailedView = (props: RenderPreviewProps) => {
  const { title='', description='', header='', image='', details={} } = props
  const decodedPreviewBody = decodeURIComponentSafe(description ? description : '');
  const fullText = decodedPreviewBody || '';
  const detailedText = decodeURIComponentSafe(details ? details?.localizations?.[Object.keys(details?.localizations)[0]]?.body : '');
  const plugins = useSelector((state: any) => state.plugin.plugins);
  return (
    <div className="streamlined-content__preview-detailed">
      {header && <div className="streamlined-content__preview-detailed__header">
        {ReactHtmlParser(tokenCreator(header, plugins))}
      </div>}
      {title && (
        <div className="streamlined-content__preview-detailed__title">
          <span className="streamlined-content__preview-detailed__title__wrap">
            {ReactHtmlParser(tokenCreator(title, plugins))}
          </span>
        </div>
      )}
      {image && <img className="streamlined-content__preview-detailed__header-img" src={image} alt="headerimg" />}
      {description && (
        <>
          <div className="streamlined-content__preview-detailed__description">
            {ReactHtmlParser(tokenCreator(fullText, plugins))}
            {ReactHtmlParser(tokenCreator(detailedText, plugins))}
          </div>
        </>
      )}
    </div>
  );
};


export const StreamlineContentPreview = (props: Content) => {
  const settings = useSettings();
  const [isCardView, setIsCardView] = useState(true);
  const [propsToRender, setPropsToRender] = useState<RenderPreviewProps>(()=>{
    const { details, link, buttons } = props?.content?.data?.[0];
    const { title='', description='', header='' } =
      props?.content?.data?.[0]?.localizations?.[Object.keys(props?.content?.data?.[0]?.localizations)[0]];
    const image = props?.content?.data?.[0]?.localizations?.[Object.keys(props?.content?.data?.[0]?.localizations)[0]]?.image
      || props?.content?.data?.[0]?.media?.localizations?.[Object.keys(props?.content?.data?.[0]?.media?.localizations)[0]]?.resourceURL;
    return { title, description, header, image, link, buttons, details };
  });

  const plugins = useSelector((state: any) => state.plugin.plugins);
  const toggleCardViews = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsCardView((isCardView) => !isCardView);
  };

  useEffect(() => {
    let langCode = getDefaultLanguage(settings.languages);

    if (props?.content?.data?.[0]?.localizations) {
      const langKeys = Object.keys(props.content.data[0].localizations);
      // use default lang index type (if it exists, else default to first)
      langKeys.find((lang) => lang === langCode) ?? (langCode = langKeys[0]);
    }

    setPropsToRender((oldState: RenderPreviewProps) => {
      const { title='', description='', header='' } =
        props?.content?.data?.[0]?.localizations?.[langCode];
      const image = props?.content?.data?.[0]?.localizations?.[langCode]?.image
        || props?.content?.data?.[0]?.media?.localizations?.[langCode]?.resourceURL;

      return {
        ...oldState,
        title,
        description,
        header,
        image,
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="streamlined-content">
      <div className="streamlined-content__title">{ReactHtmlParser(tokenCreator(propsToRender.title, plugins))}</div>
      <>
        {propsToRender.details && propsToRender.details?.localizations && (
          <div className="streamlined-content__tabs">
            <span
              className={`streamlined-content__tabs__first${isCardView ? '--selected' : ''}`}
              onClick={toggleCardViews}
            >
              Card view
            </span>
            <span
              className={`streamlined-content__tabs__second${!isCardView ? '--selected' : ''}`}
              onClick={toggleCardViews}
            >
              Detailed view
            </span>
          </div>
        )}
        {isCardView ? <RenderCardView {...propsToRender} /> : <RenderDetailedView {...propsToRender} />}
        <div className="streamlined-content__disclaimer">Actual appearance may vary</div>
      </>
    </div>
  );
};

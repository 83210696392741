import { AnalyticsContentEngagementBySourceState } from 'redux/analytics/content-engagement-by-source/content-engagement-by-source.type';
import {
  AnalyticsContentEngagementBySourceActionType
} from 'redux/analytics/content-engagement-by-source/content-engagement-by-source.type';

let initialState: AnalyticsContentEngagementBySourceState = {
  totalContentViewsCountFromPush: { value: 0 },
  totalContentClicksCountFromPush: { value: 0 },
  totalContentViewsCountFromExperience: { value: 0 },
  totalContentClicksCountFromExperience: { value: 0 },
  isTotalContentViewsCountFromPushLoading: false,
  isTotalContentClicksCountFromPushLoading: false,
  isTotalContentViewsCountFromExperienceLoading: false,
  isTotalContentClicksCountFromExperienceLoading: false,
  errorMessage: '',
};

export default (state: AnalyticsContentEngagementBySourceState = initialState, action: any) => {
  switch (action.type) {
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_PUSH_DATA:
      return {
        ...state,
        totalContentViewsCountFromPush: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_PUSH_DATA:
      return {
        ...state,
        totalContentClicksCountFromPush: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_EXPERIENCE_DATA:
      return {
        ...state,
        totalContentViewsCountFromExperience: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_EXPERIENCE_DATA:
      return {
        ...state,
        totalContentClicksCountFromExperience: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_PUSH_LOADING:
      return {
        ...state,
        isTotalContentViewsCountFromPushLoading: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_PUSH_LOADING:
      return {
        ...state,
        isTotalContentClicksCountFromPushLoading: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_VIEW_EXPERIENCE_LOADING:
      return {
        ...state,
        isTotalContentViewsCountFromExperienceLoading: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_CONTENT_CLICK_EXPERIENCE_LOADING:
      return {
        ...state,
        isTotalContentClicksCountFromExperienceLoading: action.payload,
      }
    case AnalyticsContentEngagementBySourceActionType.SET_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return {
        ...state
      };
  }
};

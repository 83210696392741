import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FlightButton, FlightTable, FlightOverflowMenu, FlightTag } from '@flybits/webapp-design-system-react';
import { DropdownOptions } from 'interface/shared/dropdown.interface';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import { defineStatusStyle } from 'helpers/templated-experience.helper';

import MultiStepTemplatedExperienceAPI from 'services/api/multistep-templated-experience.api';

import './MultiStepIndex.scss';
import moment from 'moment';

export default function MultiStepindex() {
  const multistepTemplatedExperienceAPI = new MultiStepTemplatedExperienceAPI();
  const history = useHistory();
  const [instances, setInstances] = useState([]);
  const [tableData, setTableData] = useState<any>(undefined);
  const tableHeader = [
    {
      name: 'Name',
      key: 'name',
      isVisible: true,
      isSortable: true,
    },
    {
      name: 'Status',
      key: 'status',
      isVisible: true,
      isSortable: true,
    },
    {
      name: 'Schedule',
      key: 'schedule',
      isVisible: true,
      isSortable: true,
    },
    {
      name: 'Last modified',
      key: 'updatedAt',
      isVisible: true,
      isSortable: true,
    },
    {
      name: 'Date created',
      key: 'createdAt',
      isVisible: true,
      isSortable: true,
    },
    {
      name: '',
      key: 'edit',
      isVisible: true,
      isSortable: false,
    },
  ];

  // Initial Load
  useEffect(() => {
    multistepTemplatedExperienceAPI.getInstances().then((instances) => {
      setInstances(instances);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Re-render on state change
  useEffect(() => {
    function renderDropdown(status: string, data: TExperienceInstance) {
      let optionGroups: Array<Array<DropdownOptions>> = [
        [
          {
            key: 1,
            name: 'Edit',
            disabled: false,
            onClick: () => history.push(`/multi/templated-experiences/edit/${data.id}`),
          },
          {
            key: 5,
            name: 'Delete',
            disabled: false,
            onClick: () => {
              multistepTemplatedExperienceAPI.deleteInstance(data.id).then((instances: any) => {
                setInstances(instances);
              });
            },
          },
        ],
      ];

      return (
        <FlightOverflowMenu
          className={'multi-step-index__dropdown'}
          direction={'bottom'}
          isOpenRight={false}
          disabled={false}
          optionGroups={optionGroups}
        />
      );
    }

    if (instances) {
      const _tableData = instances.map((data: any) => {
        let startDate = data?.entry?.schedule?.start || 0;

        const scheduled =
          moment(startDate * 1000).format('MMM D, YYYY') +
          ' ' +
          '-' +
          ' ' +
          (data.entry.schedule.end !== 0
            ? moment(data?.entry?.schedule?.end * 1000).format('MMM D, YYYY')
            : 'No end date');
        return {
          key: data.id,
          name: data.name || 'Untitled',
          status: (
            <FlightTag
              type={defineStatusStyle(data?.status)}
              label={data?.status}
              className="multi-step-index__status"
            />
          ),
          schedule: startDate !== 0 ? scheduled : 'None Set',
          updatedAt: moment(data.updatedAt).format('MMM D, YYYY'),
          createdAt: moment(data.createdAt).format('MMM D, YYYY'),
          edit: renderDropdown(data?.status, data),
        };
      });
      setTableData(_tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, instances]);


  return (
    <div className="multi-step-index sm">
      <div className="multi-step-index__container">
        <div className="multi-step-index__header">
          <div>
            <div className="multi-step-index__title">Experiences</div>
          </div>
          <FlightButton
            label="New Experience"
            size="large"
            className="multi-step-index__create-button"
            onClick={() => history.push('/multi/templated-experiences/template-selection')}
          />
          {/* TODO (Redirect to Template First) */}
        </div>
        <div className="multi-step-index__main">
          <FlightTable
            tableHeaders={tableHeader}
            tableData={tableData}
            loadingRowNumber={10}
            allowRowSelect
            hasPaginationBeforeTable={false}
            handleDataSelect={(row: any) => history.push('/multi/templated-experiences/edit/' + row.key)}
          />
        </div>
      </div>
    </div>
  );
}

import React, {useState, useEffect, useContext} from 'react';
import {FlightSelectSearchable, FlightButton} from "@flybits/webapp-design-system-react";
import 'components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal.scss'
import {
  getTimezoneList,
  getDuration,
  convertDateToTimezone,
  transformScheduleData,
  cleanupTimezone,
  defineStartDate,
  convertTimezoneToDate
} from "helpers/templated-experience.helper";
import 'date-fns';
import moment from 'moment';
import { FlightDateTimePicker } from '@flybits/webapp-design-system-react';
import momentTZ from "moment-timezone";
import {useSelector} from "react-redux";

import { MultiStepContext } from 'components/MultiStepDemo/Provider/MultiStepProvider';

export interface scheduleDataType {
  start: Date | null,
  end: Date | null,
  timezone: {
    key: number,
    name: string,
    offset: number,
  },
  runtime?: string,
  minDate?: Date,
}

export default function ScheduleEditModal(props: any) {
  const multiStepContext: any = useContext(MultiStepContext);
  const template = multiStepContext.template;
  const { schedule } = template.entry;
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const [scheduleData, setScheduleData] = useState<scheduleDataType>({
    start: null,
    end: null,
    timezone: {
      key: 0,
      name: '',
      offset: 0,
    },
    runtime: 'None',
    minDate: new Date(),
  });
  const [timezones, setTimezones] = useState<any>([]);
  const [startDateExists, setStartDateExists] = useState<boolean>(false);
  const [endDateExists, setEndDateExists] = useState<boolean>(false);
  const dateFormat = "MMM dd yyyy, hh:mma";
  const transformedSchedule = transformScheduleData(scheduleData);
  let convertStart = convertDateToTimezone(transformedSchedule.start!!, scheduleData.timezone.name);
  let convertEnd = convertDateToTimezone(transformedSchedule.end!!, scheduleData.timezone.name);
  const manualActivation = reduxTemplatedExperienceState?.instance?.activation?.manualActivationAt;
  const autoActivation = reduxTemplatedExperienceState?.instance?.activation?.automaticActivationAt;

  function handleTimezoneSelect(option: any) {
    let trSchedule = transformScheduleData({...scheduleData, timezone: option});
    const minDateFormat = momentTZ().tz(cleanupTimezone(trSchedule?.timezone!!)).format('YYYY-MM-DD HH:mm:ss');
    const minDate = new Date(minDateFormat);
    const minDateUnix = moment(minDate).unix();
    let start = scheduleData.start;
    let startUnix = moment(start!!).unix();
    if(minDateUnix > startUnix) {
      start = new Date((minDateUnix+300) * 1000);
    }
    setScheduleData({...scheduleData, start, timezone: {...option}, minDate});
  }

  function handleSearch(searchZone: string) {
    setTimezones(getTimezoneList(searchZone));
  }

  useEffect(() => {
    props.onChange(scheduleData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleData]);

  function changeStartDate(date: any | null) {
    let selectedDate = date;
    let selectedDateUnix = moment(date).unix();
    let startDate = scheduleData.start || date;
    let startDateUnix = moment(startDate).unix();
    let endDate = scheduleData.end || date;
    let endDateUnix = moment(endDate).unix();
    let minDateUnix = moment(scheduleData.minDate).unix();
    if(minDateUnix >= selectedDateUnix) {
      selectedDateUnix = minDateUnix + 300;
      selectedDate = new Date(selectedDateUnix * 1000);
    }
    if(selectedDateUnix >= endDateUnix) {
      endDate = new Date((selectedDateUnix + 300)*1000);
    }
    if(selectedDateUnix !== startDateUnix) {
      selectedDate = new Date(selectedDateUnix*1000);
    }
    let runtime:any = getDuration(selectedDate, endDate);
    if(endDateExists) {
      setScheduleData({...scheduleData, start: selectedDate, runtime, end: endDate});
    } else {
      runtime = 'Ongoing';
      setScheduleData({...scheduleData, start: selectedDate, runtime});
    }
  }

  function changeEndDate(date: any | null) {
    let selectedDate = date;
    let selectedDateUnix = moment(date).unix();
    let minDateUnix = moment(scheduleData.minDate).unix();
    let startDate = scheduleData.start || scheduleData.minDate;
    let startDateUnix = startDate ? moment(startDate).unix() : 0;
    if(minDateUnix >= selectedDateUnix) {
      selectedDateUnix = minDateUnix + 600;
      selectedDate = new Date(selectedDateUnix * 1000);
    }
    if(selectedDateUnix < startDateUnix) {
      selectedDate = new Date((startDateUnix + 600)*1000);
    }
    if((!!manualActivation || !!autoActivation) && template.status === 'Active') {
      startDateUnix = autoActivation > manualActivation ? autoActivation : manualActivation;
      startDate = new Date(startDateUnix * 1000);
    }
    let runtime:any = getDuration(startDate!!, selectedDate);
    setScheduleData({...scheduleData, end: selectedDate, runtime});
  }

  function showStartdate(option: boolean) {
    let myZone = moment.tz.guess().replace(/_/g, ' ');
    let myOffset = moment.tz(myZone).format('Z');
    myZone = myZone+' (UTC '+myOffset+')';
    if(option) {
      let runtime: any = 'Ongoing';
      if(scheduleData.end && !scheduleData.start) {
        runtime = getDuration(new Date((moment().unix()+300)*1000), scheduleData.end);
      }

      convertStart = convertDateToTimezone(moment().unix()+300, myZone);
      setScheduleData({
        ...scheduleData,
        start: scheduleData.start!! ? scheduleData.start : new Date((moment().unix()+300)*1000),
        timezone: {key: 0, name: myZone, offset: parseInt(myOffset)},
        runtime: runtime
      });
    } else {
      setScheduleData({
        ...scheduleData,
        start: null,
        end: null,
        timezone: {key: 0, name: '', offset: 0},
        runtime: 'None',
      });
      setEndDateExists(option);
    }
    setStartDateExists(option);
  }

  function showEndDate(option: boolean) {
    let endDate = scheduleData.end || new Date();
    let startDateUnix = moment(scheduleData.start || new Date()).unix();
    let endDateUnix = moment(endDate).unix();
    let myZone = scheduleData.timezone.name ? cleanupTimezone(scheduleData.timezone.name) : moment.tz.guess().replace(/_/g, ' ');
    let myOffset = moment.tz(myZone).format('Z');
    myZone = myZone+' (UTC '+myOffset+')';

    if(startDateUnix >= endDateUnix) {
      endDate = new Date((startDateUnix + 300)*1000);
      convertEnd = convertDateToTimezone(startDateUnix + 300, scheduleData.timezone.name);
    } else {
      endDate = new Date((moment().unix() + 300)*1000);
      convertEnd = convertDateToTimezone(moment().unix() + 300, scheduleData.timezone.name);
    }
    let runtime: any = 'Ongoing';
    if(option) {
      runtime = getDuration(new Date((startDateUnix)*1000), endDate);
    }
    if(manualActivation && option) {
      runtime = getDuration(new Date((manualActivation)*1000), endDate);
      setScheduleData({
        ...scheduleData,
        timezone: {key: 0, name: myZone, offset: parseInt(myOffset)},
        end: option ? endDate : null,
        runtime: `${runtime}`
      });
    } else {
      setScheduleData({
        ...scheduleData,
        end: option ? endDate : null,
        runtime: `${runtime}`
      });
    }
    setEndDateExists(option);
  }

  function renderAlreadyActivated() {
    if(((!!manualActivation || !!autoActivation) && schedule?.start && !startDateExists) || (!!autoActivation && template.status === 'Active')) {
      const timezone = schedule.timezone ? schedule.timezone : moment.tz.guess().replace(/_/g, ' ');
      const activationDate = autoActivation > manualActivation ? autoActivation : manualActivation;
      const activationTZDate = convertTimezoneToDate(activationDate, timezone);
      return (
        <div className="schedule-edit-modal__manual-activ">
          {autoActivation ? 'Automatically' : 'Manually'} launched {activationTZDate.tz_date} {activationTZDate.tz_time} ({timezone})
        </div>
      )
    }
  }

  useEffect(() => {
    let runtime: any = 'None';
    let startDate = defineStartDate(schedule?.start!!, autoActivation!!, manualActivation!!, template?.status!!);
    let endDate = schedule?.end || 0;
    let convertStartTime = startDate ? convertTimezoneToDate(startDate!!, schedule?.timezone!!) : null;
    let convertEndTime = endDate ? convertTimezoneToDate(endDate!!, schedule?.timezone!!) : null;
    if(convertStartTime) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      convertStart = convertStartTime;
    }
    if(convertEndTime) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      convertEnd = convertEndTime;
    }
    if(startDate) {
      runtime = 'Ongoing';
    }
    if(endDate && convertEnd) {
      runtime = getDuration(new Date(convertStart?.local_unix!!*1000), new Date(convertEnd?.local_unix!!*1000));
    }
    setTimezones(getTimezoneList());
    let minDate = new Date();
    if(schedule?.start!!) {
      const minDateFormat = momentTZ().tz(cleanupTimezone(schedule.timezone!!)).format('YYYY-MM-DD HH:mm:ss');
      minDate = new Date(minDateFormat);
    }

    setScheduleData({
      ...scheduleData,
      start: schedule?.start ? new Date(schedule.start * 1000) : null,
      end: schedule?.end ? new Date(schedule.end * 1000) : null,
      timezone: {
        ...scheduleData.timezone,
        name: schedule?.timezone,
      },
      runtime,
      minDate,
    });
    setStartDateExists(!!schedule?.start && template.status !== 'Active');
    setEndDateExists(schedule?.end!!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[schedule]);


  return (
    <div className="schedule-edit-modal">
      <h2> Schedule this Experience </h2>
      <div className="schedule-edit-modal__runtime">Experience Runtime: {scheduleData.runtime}</div>
      {renderAlreadyActivated()}
      {startDateExists ? (
        <div className="schedule-edit-modal__start">
          <div className="schedule-edit-modal__label">Send at</div>
          <div className="schedule-edit-modal__start-date-box">
            <div className="schedule-edit-modal__datepicker">
              <FlightDateTimePicker
                format={dateFormat}
                value={scheduleData.start}
                onChange={(e:any)=>changeStartDate(e)}
                showTodayButton
                minutesStep={5}
                minDate={scheduleData.minDate}
                isError={convertStart.local_unix < moment().unix()}
              />
            </div>
            <FlightSelectSearchable
              className="schedule-edit-modal__select"
              label=""
              options={timezones}
              selected={scheduleData.timezone}
              handleOptionClick={handleTimezoneSelect}
              handleSearch={handleSearch}
              dropdownMaxHeight="150px"
            />
            {!endDateExists && (
              <FlightButton
                className="schedule-edit-modal__remove-icon"
                theme="minor"
                onClick={()=>showStartdate(false)}
                label="X"
              />
            )}
          </div>
          {convertStart.local_unix > moment().unix() ? (
            <div className="schedule-edit-modal__comment">
              Experience will start at {convertStart.local_time} {convertStart.local_date} your local time.
            </div>
          ) : (
            <div className="schedule-edit-modal__comment__error">
              Start date expired. Edit to current date/time to launch template.
            </div>
          )}
        </div>
      ) : (
        ((!manualActivation && !autoActivation) || template.status !== 'Active') &&
          <FlightButton
            theme="link"
            className="schedule-edit-modal__add-end"
            onClick={()=>showStartdate(true)}
            label="+ Add start date"
          />
      )}
      {endDateExists ? (
        <div className="schedule-edit-modal__end">
          <div className="schedule-edit-modal__label">Ends</div>
          <div className="schedule-edit-modal__start-date-box">
            <div className="schedule-edit-modal__datepicker">
              <FlightDateTimePicker
                format={dateFormat}
                value={scheduleData.end}
                onChange={(e:any)=>changeEndDate(e)}
                showTodayButton
                minutesStep={5}
                minDate={scheduleData.minDate}
                isError={convertEnd.local_unix < moment().unix()}
              />
            </div>
            <FlightSelectSearchable
              className="schedule-edit-modal__select"
              label=""
              options={timezones}
              selected={scheduleData.timezone}
              handleOptionClick={handleTimezoneSelect}
              handleSearch={handleSearch}
              dropdownMaxHeight="150px"
              disabled={!manualActivation}
            />
            <FlightButton
              className="schedule-edit-modal__remove-icon"
              theme="minor"
              onClick={()=>showEndDate(false)}
              label="X"
            />
          </div>
          {convertEnd.local_unix > moment().unix() ? (
            <div className="schedule-edit-modal__comment">
              Experience will end at {convertEnd.local_time} {convertEnd.local_date} your local time.
            </div>
          ) : (
            <div className="schedule-edit-modal__comment__error">
              End date expired. Edit to current date/time to launch template.
            </div>
          )}
        </div>
      ) : (
        (startDateExists || ((!!manualActivation || !!autoActivation) && template.status === 'Active')) && (
          <FlightButton
            theme="link"
            className="schedule-edit-modal__add-end"
            onClick={()=>showEndDate(true)}
            label="+ Add end date"
          />
        )
      )}
    </div>
  )
}

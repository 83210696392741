import React from 'react';
import './LibraryCard.scss';

interface LibraryCardProps {
  name: string;
  onClick?: () => void;
  backgroundImage?: string;
  isSelected?: boolean;
  className?: string;
}

export const LibraryCard = (props: LibraryCardProps) => {
  const { name, onClick, backgroundImage, className, isSelected } = props;
  const MAIN_CLASS = 'library-card';
  return (
    <>
      <div
        className={`${MAIN_CLASS} ${className}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
        onClick={onClick}
      >
        <div className={`${MAIN_CLASS}__img`}>
          <span className={`${MAIN_CLASS}__img__text`}> {name}</span>
        </div>
      </div>
      <div className={isSelected ? "overlay overlay--selected" : 'overlay'}></div>
    </>
  );
};

import BaseAPI from './base.api';
import {isEmpty} from "lodash";
import queryString from "query-string";
import { Folder } from 'interface/folder.interface';
import {PaginationResponse} from "../../interface/shared/api.interface";
import {TExperienceInstance} from "../../interface/templated-experience/templated-experience.interface";

export default class FolderAPI extends BaseAPI {
  private ROUTES = {
    folder: '/kernel/journey/folder',
    tree: '/kernel/journey/folder/tree',
    bulkMove: '/kernel/journey/move',
    bulkDelete: '/kernel/journey/delete',
    instances: '/kernel/journey/instances',
  }

  getFoldersTree(): Promise<PaginationResponse<Folder>> {
    return this.GET(`${this.ROUTES.tree}`);
  }

  getAllFolders(opts:any): Promise<PaginationResponse<TExperienceInstance>> {
    let params:any = {page: 0, limit: 10, offset: 0, search: '', searchExact: false, sortby: '', sortorder: 'desc', startdate: 0, enddate: 0 };
    params = Object.assign(params, opts);
    params.offset = params.page ? params.limit * (params.page - 1) : 0;
    params.search = params.search?.trim();
    for(let key in params) {
      if(!params[key] || (Array.isArray(params[key]) && isEmpty(params[key]))) {
        delete params[key];
      }
    }
    return this.GET(`${this.ROUTES.folder}/${params.search ? 'search' : ''}?${queryString.stringify({...params},{arrayFormat:'none'})}`);
  }

  getFolderChildren(folderID:string, opts:any): Promise<PaginationResponse<TExperienceInstance>> {
    let params:any = {page: 0, limit: 10, offset: 0, search: '', searchExact: false, sortby: '', sortorder: 'desc', startdate: 0, enddate: 0 };
    params = Object.assign(params, opts);
    params.offset = params.page ? params.limit * (params.page - 1) : 0;
    params.search = params.search?.trim();
    for(let key in params) {
      if(!params[key] || (Array.isArray(params[key]) && isEmpty(params[key]))) {
        delete params[key];
      }
    }
    return this.GET(`${this.ROUTES.folder}/${folderID}${params.search ? '/search' : ''}?${queryString.stringify({...params},{arrayFormat:'none'})}`);
  }

  createFolder(folder: {name: string, parentFolderID: string}): Promise<any>  {
    return this.POST(`${this.ROUTES.folder}`, folder);
  }

  updateFolder(folder: Folder): Promise<any>  {
    return this.PUT(`${this.ROUTES.folder}/${folder.id}`, folder);
  }

  bulkMoveToFolder(payload: {destination: string, folders: string[], experiences: string[]}): Promise<any>  {
    return this.PUT(`${this.ROUTES.bulkMove}`, payload);
  }

  fetchSubFolders(folderID: string) {
    return this.GET(`${this.ROUTES.folder}/${folderID}`);
  }

  bulkDelete(payload: {folders: string[], experiences: string[]}): Promise<any> {
    return this.PUT(`${this.ROUTES.bulkDelete}`, payload);
  }

}

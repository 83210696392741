
import React from 'react'
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';
import ScheduleIcon from "assets/icons/calendar-with-clock-24.svg";

interface IProps {
  node: any;
  onClick: () => void;
}

export default function Delay(props: IProps) {
  const delay = props.node.delay || undefined;

  function renderBody() {
    if (!delay) return <p> No Delay </p>
    if (delay.isDelay) {
      return (
        <>
          {delay.time.value} {delay.time.unit.name}
        </>
      )
    } else {
      return <p> No Delay </p>
    }
  }

  return (
    <MiniBox
      {...props}
      icon={ScheduleIcon}
      title="Delay"
      body={renderBody()}
    />
  )
}



const firstTemplate = {
  id: 'ed9057fa-7ac5-412c-b52e-fa1f3c076e0d',
  name: 'Multi Step Template #1',
  description: 'There needs to be some informative description',
  status: 'Draft',
  icon: '',
  entry: {
    audience: {
      ruleStringRepresentation: '',
      ruleBody: {},
      seedRuleStringRepresentation: '',
      seedRuleBody: {},
    },
    schedule: {
      start: 0,
      end: 0,
      timezone: ''
    },
    setting: {
      entry: {
        isUnlimited: true,
        max: 0
      },
      message: {
        isUnlimited: true,
        frequency: 0,
        max: 0,
        repeat: {
          key: 'days',
          name: 'Days'
        }
      }
    },
    edges: ['id2']
  },
  exit: {
    estimateEngagement: 1536
  },
  nodes: {
    'id2': {
      id: 'id2',
      name: `Untitled #2`,
      trigger: [{
        step: {key: "entry-criteria", name: "Entry Criteria"},
        action: undefined,
        content: undefined,
      }],
      delay: null,
      audience: null,
      actions: [],
      edges: [],
    },
  }
}

const secondTemplate = {
  id: 'ed9057fa-bcdef-412c-b52e-fa1f3c076e0d',
  name: 'Multi Step Template #2',
  description: 'There needs to be some informative description',
  status: 'Draft',
  icon: '',
  entry: {
    audience: {
      ruleStringRepresentation: '',
      ruleBody: {},
      seedRuleStringRepresentation: '',
      seedRuleBody: {},
    },
    schedule: {
      start: 0,
      end: 0,
      timezone: ''
    },
    setting: {
      entry: {
        isUnlimited: true,
        max: 0
      },
      message: {
        isUnlimited: true,
        frequency: 0,
        max: 0,
        repeat: {
          key: 'days',
          name: 'Days'
        }
      }
    },
    edges: ['id2', 'id3']
  },
  exit: {
    estimateEngagement: 1536
  },
  nodes: {
    'id2': {
      id: 'id2',
      name: `Untitled #2`,
      trigger: [{
        step: {key: "entry-criteria", name: "Entry Criteria"},
        action: undefined,
        content: undefined,
      }],
      delay: null,
      audience: null,
      actions: [],
      edges: ['id4'],
    },
    'id3': {
      id: 'id3',
      name: `Untitled #3`,
      trigger: [{
        step: {key: "entry-criteria", name: "Entry Criteria"},
        action: undefined,
        content: undefined,
      }],
      delay: null,
      audience: null,
      actions: [],
      edges: [],
    },
    'id4': {
      id: 'id4',
      name: `Untitled #4`,
      trigger: [{
        step: {key: "id2", name: "id2"},
        action: undefined,
        content: undefined,
      }],
      delay: null,
      audience: null,
      actions: [],
      edges: [],
    },
  }
}

export default [firstTemplate, secondTemplate]
import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {FlightButton, FlightModal, FlightTextInput} from "@flybits/webapp-design-system-react";
import FolderAPI from "services/api/folder.api";
import { Folder } from "interface/folder.interface";
import {setCurrentFolder, updateFolderName} from "redux/templated-experience/templated-experience.action";
import './FolderModal.scss';

export interface FolderModalProps {
  parentFolderID: string
  isVisible: boolean
  editFolder: boolean
  newFolder: string
  folder: Folder | undefined
  onCancel: () => void
  onCreate: (folder: Folder) => void
}

export default function FolderModal(props: FolderModalProps) {
  const [folderName, setFolderName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const folderAPI = new FolderAPI();

  const updateNewFolderName = (e:React.ChangeEvent<HTMLInputElement>) => {
    setFolderName(e.target.value);
  }

  const createNewFolder = () => {
    setIsLoading(true);
    folderAPI.createFolder({name: folderName, parentFolderID: props.parentFolderID})
      .then((res) => {
        setFolderName('');
        setIsLoading(false);
        props.onCancel();
        props.onCreate(res);
      })
      .catch((error: Error) => setErrorMessage(error.message));
  }

  const handleCancel = () => {
    setFolderName('');
    props.onCancel();
  }

  const renameFolder = () => {
    const updatedFolder:Folder = {...props.folder!, name: folderName};
    folderAPI.updateFolder(updatedFolder).then(() => {
      dispatch(setCurrentFolder({currentFolder: updatedFolder}));
      dispatch(updateFolderName({folderId: props.folder!.id!, folderName}));
      props.onCancel();
    }).catch((error: Error) => {
      setErrorMessage(error.message)
    });
  }

  useEffect(() => {
    if(props.editFolder) {
      setFolderName(props.folder?.name! || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editFolder]);

  return (
    <FlightModal
      size="medium"
      isVisible={props.isVisible}
      scrollable={true}
      toggleModalShown={handleCancel}
      header={
        <div className="folder-modal__header">
          <h3>{props.editFolder ? 'Rename' : 'Create'} folder</h3>
        </div>
      }
      content={
        <div className="folder-modal__content">
          <FlightTextInput
            label="Folder name"
            hasError={!!errorMessage}
            errorMessage={errorMessage}
            placeholderText="Enter folder name"
            width="100%"
            hasClearIcon
            type="text"
            name="folderName"
            value={folderName}
            onChange={updateNewFolderName}
          />
        </div>
      }
      footer={
        <div className="folder-modal__footer">
          <div> </div>
          <div>
            <FlightButton
              theme="secondary"
              onClick={handleCancel}
              label="Cancel"
            />
            {!props.editFolder && (
              <FlightButton
                onClick={createNewFolder}
                label="Create"
                disabled={!folderName || isLoading}
                loading={isLoading}
              />
            )}
            {props.editFolder && (
              <FlightButton
                onClick={renameFolder}
                label="Rename"
                disabled={!folderName}
              />
            )}
          </div>
        </div>
      }
    />
  )
}


import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SettingAPI from 'services/api/setting.api';

export default function useSettings() {
  const settingAPI = new SettingAPI();
  const state = useSelector((state: any) => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.isInitialized) return;
    settingAPI.getLanguages().then((r: any) => {
      dispatch({type: 'SET_LANGUAGES', payload: { languages: r.settings.data}});
    }).catch((e: any) => {
      dispatch({type: 'SET_LANGUAGES', payload: { languages: [{id: "en", isDefault: true, name: "English"}] }});
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    languages: [
      state.languages.find((language: any) => language.isDefault === true),
      ...state.languages.filter((language: any) => language.isDefault === false),
    ],
    isInitialized: state.isInitialized,
  }
}

import React, { useEffect, useState } from 'react';

import { FlightTextInput, FlightButton, FlightSnackbar } from '@flybits/webapp-design-system-react';
import './ThemeHub.scss';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import FlybitsLogo from 'assets/images/flybits-logo.png';

import SettingsAPI, { ObjectForSettingsApi } from 'services/api/setting.api';
import UseAsyncState from 'hooks/useAsyncState';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

export default function ThemeHub() {
  const settingsApi = new SettingsAPI();

  const [themeData, isThemeDataLoading] = UseAsyncState(
    () => settingsApi.getThemeSettings().then((r) => r.settings),
    [],
  );

  const [appName, setAppName] = useState('Experience Studio');
  const [logoImgUrl, setLogoImgUrl] = useState('');
  const [appNameColor, setAppNameColor] = useState('#37425E');
  const [navbarColor, setNavbarColor] = useState('#ffffff');
  const dispatch = useDispatch();

  useEffect(() => {
    if (themeData && !isThemeDataLoading) {
      const _themeData = JSON.parse(themeData?.themeData);
      if (!isEmpty(_themeData.logoUrl)) {
        setLogoImgUrl(_themeData.logoUrl);
      }
      if (!isEmpty(_themeData.appName)) {
        setAppName(_themeData.appName);
      }
      if (!isEmpty(_themeData.appNameTextColor)) {
        setAppNameColor(_themeData.appNameTextColor);
      }
      if (!isEmpty(_themeData.navbarBgColor)) {
        setNavbarColor(_themeData.navbarBgColor);
      }
    }
    //eslint-disable-next-line
  }, [isThemeDataLoading]);

  const saveTheme = async () => {
    const _objectForApi = {
      logoUrl: logoImgUrl || '',
      appName: appName || '',
      appNameTextColor: appNameColor || '',
      navbarBgColor: navbarColor || '',
    };

    const stringifyObject = JSON.stringify(_objectForApi);
    const objectForApi: ObjectForSettingsApi = {
      config: '{"themeData":' + JSON.stringify(stringifyObject) + '}',
      configName: {
        configName: 'es-2-theme',
      },
    };

    try {
      const resp = await settingsApi.createSettings(objectForApi);
      if (resp) {
        dispatch({type: 'SHOW_SNACKBAR', payload: {content: `Successfully updated theme`, type: 'success'}});
      }
    } catch (error) {
      dispatch({type: 'SHOW_SNACKBAR', payload: {content: `Failed to update theme"`, type: 'danger'}});
    }
  };

  return (
    <>
    <div className="theme-hub">
      <h2> Configure Theme </h2>
      <FlightSnackbar
        type="warning"
        icon="warning"
        isVisible
        isAutoDismiss
        className="theme-hub__snack-bar"
        actionName={null}
        content="Changes made here will affect all users that use this tenant."
      />
      <div className="theme-hub__section">
        <h4> Logo </h4>
        <img src={logoImgUrl || FlybitsLogo} alt="logo-img" height="50" width="50" />
        <FlightTextInput
          label="Image url"
          width="70%"
          value={logoImgUrl}
          onChange={(e: any) => setLogoImgUrl(e.target.value)}
        />

        <h4> App name </h4>
        <FlightTextInput
          placeholderText="Experience Studio"
          width="70%"
          value={appName}
          hasError={appName === ''}
          errorMessage="Required"
          onChange={(e: any) => setAppName(e.target.value)}
        />

        <h4>App name text color</h4>
        <div>
          Hex # <HexColorInput color={appNameColor} onChange={setAppNameColor} />
        </div>
        <HexColorPicker color={appNameColor} onChange={setAppNameColor} className="theme-hub__color-picker" />

        <h4>Navbar background color</h4>
        <div>
          Hex # <HexColorInput color={navbarColor} onChange={setNavbarColor} />
        </div>
        <HexColorPicker color={navbarColor} onChange={setNavbarColor} className="theme-hub__color-picker" />
        <FlightButton onClick={() => saveTheme()} label="Save theme" disabled={appName === ''} />
      </div>
    </div>
    </>
  );
}

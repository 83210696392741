import React, { useEffect, useState } from 'react';
import LocationPreview from 'components/AudiencePreview/LocationPreview/LocationPreview';
import AudienceIcon from 'assets/icons/audience.svg';
import { stringRepFinal } from 'helpers/rule.helper';
import MiniBox from 'components/MultiStepDemo/MiniBox/MiniBox';
import {useSelector} from "react-redux";

interface IProps {
  node: any;
  onClick: () => void;
}

const row = {
  display: 'flex',
};

export default function Audience(props: IProps) {
  const [ruleData, setRuleData] = useState<any>(undefined);
  const ruleStringRepresentation = props.node?.audience?.ruleStringRepresentation || '';
  const plugins = useSelector((state: any) => state.plugin?.plugins);

  const texts: any = {
    and: 'All of the following restrictions define your audience reach',
    or: 'Any of the following restrictions define your audience reach',
  };

  useEffect(() => {
    stringRepFinal(ruleStringRepresentation, props?.node?.audience, plugins).then((r) => setRuleData(r));
  }, [ruleStringRepresentation, props, plugins]);

  function audienceRowType(audienceObj: any) {
    let locationText = 'User is in a location';
    if(audienceObj?.arguments?.[0]?.includes('Approximate')){
      locationText = 'User is in the approximate area of';
    }

    switch (audienceObj.type) {
      case 'location:map':
        return (
          <>
            {' '}
            <span>{locationText} &nbsp; <LocationPreview location={audienceObj.location} args={audienceObj?.arguments}/>{' '}</span>
          </>
        );
      case 'location:label':
        return <> User is in a location with the label {'"' + audienceObj.label + '"'}</>;
      case 'general':
        return (
          <>
            {' '}
            <span>{audienceObj.arguments[0]} {audienceObj.predicate} &nbsp; <b> {audienceObj.arguments[1]} </b>{' '}</span>
          </> // plugin
        );
      default:
        break;
    }
  }

  function audienceRow(stringObj: any, idx: number = 1) {
    return (
      <div key={`stringRep_${idx}`} style={row}>
        <div className="">
          <b> {idx + 1}. &nbsp;</b>
        </div>
        <div className="">
          {Array.isArray(stringObj) ? (
            <> {stringObj[0]} </> // rule
          ) : (
            audienceRowType(stringObj)
          )}
        </div>
      </div>
    );
  }

  function renderBody() {
    const audience = ruleData.predicates.map((stringObj: any, idx: number) => audienceRow(stringObj, idx));
    if (ruleData.predicates.length > 0) {
      return (
        <>
          <div>
            {ruleData.type === 'custom' ? (
              <i> Restrictions {ruleData.toString} defines your audience reach. </i>
            ) : (
              <p> {texts[ruleData.type]} </p>
            )}
          </div>
          <div style={{ paddingTop: '16px' }}>{audience}</div>
        </>
      );
    } else {
      return <div> None Set </div>;
    }
  }

  if (!ruleData) return null;
  return <MiniBox {...props} icon={AudienceIcon} title="Audience" body={renderBody()} />;
}

import { Content } from 'interface/content/content.interface';
import { PaginationResponse } from 'interface/shared/api.interface';
import { TExperience, TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import {
  TExperienceActionType,
  SetTemplateAction,
  SetInstancesAction,
  SetLoadingAction,
  SetErrorAction,
  SetModifiedAction,
  SetInstanceAction,
  UpdateInstancesAction,
  SetTempAction,
  SetInitialAction,
  SetCurrentFolderAction,
  UpdateFolderNameAction,
} from 'redux/templated-experience/templated-experience.type';

import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import FolderAPI from 'services/api/folder.api';
import LabelsAPI from 'services/api/labels.api';
import { getIncompleteCount, validateTemplatedExperience } from 'validator/templated-experience.validator';
import {
  findActionParent,
  convertToInstance,
  getPushPayload,
  generateUUID,
  updateEmptyContentName,
  cleanupTimezone,
  serializeScheduleData,
  deserializeScheduleData,
  deserializeActivationData,
  serializeActivationData,
  serializeDelivery,
  serializeTriggerData,
  deserializeTriggerData,
  getJourneyMode,
} from 'helpers/templated-experience.helper';
import { showSnackbar } from 'redux/snackbar/snackbar.action';
import {ExperienceSchedule, Action} from 'interface/experience/experience.interface';
import {cloneDeep, isEmpty, merge} from 'lodash';
import moment from 'moment';
import { trackEvent } from 'helpers/analytics.helper';
import { ruleToArr } from 'helpers/rule.helper';
import { Folder } from 'interface/folder.interface';
import { JourneyStep } from 'interface/journey.interface';

function setLoading(payload: { flag: boolean }): SetLoadingAction {
  return {
    type: TExperienceActionType.SET_LOADING,
    payload,
  };
}

function setError(payload: { errorMessage: any }): SetErrorAction {
  return {
    type: TExperienceActionType.SET_ERROR,
    payload,
  };
}

export function setPushTypes(payload: any) {
  return {
    type: TExperienceActionType.SET_PUSH_TYPES,
    payload,
  };
}

export function setTemplate(payload: { template: TExperienceInstance | null }): SetTemplateAction {
  return {
    type: TExperienceActionType.SET_TEMPLATE,
    payload,
  };
}

export function setInstance(payload: { instance: TExperienceInstance | any }): SetInstanceAction {
  return {
    type: TExperienceActionType.SET_INSTANCE,
    payload,
  };
}

export function setInstances(payload: { instances: PaginationResponse<TExperienceInstance> }): SetInstancesAction {
  return {
    type: TExperienceActionType.SET_INSTANCES,
    payload,
  };
}

export function setCurrentFolder(payload: { currentFolder: Folder|undefined }): SetCurrentFolderAction {
  return {
    type: TExperienceActionType.SET_CURRENT_FOLDER,
    payload,
  };
}

export function setModified(payload: boolean): SetModifiedAction {
  return {
    type: TExperienceActionType.SET_MODIFIED,
    payload,
  };
}

export function updateInstances(payload: { id: string; status: string }): UpdateInstancesAction {
  return {
    type: TExperienceActionType.UPDATE_INSTANCES,
    payload,
  };
}

export function updateFolderName(payload: { folderId: string; folderName: string }): UpdateFolderNameAction {
  return {
    type: TExperienceActionType.UPDATE_FOLDER_NAME,
    payload,
  };
}

export function setTemp(payload: any): SetTempAction {
  return {
    type: TExperienceActionType.SET_TEMP,
    payload,
  };
}

export function setInitial(payload: any): SetInitialAction {
  return {
    type: TExperienceActionType.SET_INITIAL,
    payload,
  };
}

/**
 * This function is responsible for modifying the value of "actionBody" of action with corresponding actionID.
 * Any modification to the actionBody should be done through this function to streamline the way we access and modify
 * data in templatedExperience data in redux.
 * @param actionID the id of the box/card
 * @param callback the json object that should replace the actionBody of action with actionID
 */
function _updateActionBody(actionID: string, callback: () => object) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { templatedExperience } = getState();
    let { template, instance } = templatedExperience;
    if (!isEmpty(template)) {
      // update template (create route)
      const [parentIdx, childIdx] = findActionParent(template, actionID);
      if (template?.steps?.[parentIdx]?.actions?.[childIdx]?.actionBody!!) {
        template.steps[parentIdx].actions[childIdx].actionBody = callback();
      }
      template = validateTemplatedExperience(template);
      dispatch(
        setTemplate({
          template: {
            ...template,
            incompleteCount: getIncompleteCount(template),
            showError: getIncompleteCount(template) === 0 ? false : template.showError,
          },
        }),
      );
    } else {
      // update instance (edit route)
      const [parentIdx, childIdx] = findActionParent(instance, actionID);
      if(instance?.steps?.[parentIdx]?.actions?.[childIdx]?.actionBody) {
        instance.steps[parentIdx].actions[childIdx].actionBody = callback();
      }
      instance = validateTemplatedExperience(instance);
      dispatch(
        setInstance({
          instance: {
            ...instance,
            incompleteCount: getIncompleteCount(instance),
            showError: getIncompleteCount(instance) === 0 ? false : instance.showError,
          },
        }),
      );
    }
  };
}

export function setSchedule(schedule: TExperienceInstance['schedule']) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { templatedExperience } = getState();
    let { template, instance } = templatedExperience;
    if (!isEmpty(template)) {
      dispatch(
        setTemplate({
          template: {
            ...template,
            schedule,
          },
        }),
      );
    } else {
      dispatch(
        setInstance({
          instance: {
            ...instance,
            schedule,
          },
        }),
      );
    }
  };
}

export function clearTemplate() {
  return (dispatch: any) => {
    dispatch(setTemplate({ template: null }));
  };
}

export function clearInstance() {
  return (dispatch: any) => {
    dispatch(setInstance({ instance: null }));
  };
}

export function fetchInstances(opts: any) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .getTemplatedInstances(opts)
        .then((data: PaginationResponse<TExperienceInstance>) => {
          dispatch(setInstances({ instances: data }));
          resolve(data);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}


export function fetchFoldersAndInstances(opts: any) {
  const folderAPI = new FolderAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      folderAPI.getAllFolders(opts)
        .then((data: PaginationResponse<TExperienceInstance>) => {
          dispatch(setInstances({ instances: data }));
          dispatch(setCurrentFolder({ currentFolder: undefined }));
          resolve(data);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}


export function fetchFolderChildren(parentFolderID: string, opts: any) {
  const folderAPI = new FolderAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      folderAPI.getFolderChildren(parentFolderID, opts)
        .then((data: PaginationResponse<TExperienceInstance>) => {
          dispatch(setInstances({ instances: data }));
          dispatch(setCurrentFolder({ currentFolder: data.currentFolder }));
          resolve(data);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function fetchInstance(id: string, isJourney:boolean = false) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return async (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .getTemplatedExperienceInstance(id)
        .then((instance: TExperienceInstance) => {
          instance.schedule = deserializeScheduleData(instance.schedule);
          instance.steps = deserializeTriggerData(instance.steps, isJourney);
          instance.activation = deserializeActivationData(instance.activation, instance.schedule);
          let _instance = null;
          if(isJourney) {
            _instance = instance;
          } else {
            _instance = validateTemplatedExperience(instance);
            _instance = {
              ..._instance,
              incompleteCount: getIncompleteCount(_instance),
            };
          }
          dispatch(
            setInstance({
              instance: _instance,
            })
          );
          dispatch(
            setTemp({
              viewMode: isJourney ? 'journey' : '',
              instance: _instance,
            }),
          );
          dispatch(
            setInitial({
              instance: cloneDeep(_instance)
            })
          );
          resolve(_instance);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function fetchTemplate(id: string, isJourney:boolean = false) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .getTemplatedExperience(id)
        .then((template: TExperience) => {
          let _template: TExperienceInstance = convertToInstance(template);
          _template.steps = deserializeTriggerData(_template.steps, isJourney);
          _template = {
            ...validateTemplatedExperience(_template),
            status: 'Draft',
            incompleteCount: getIncompleteCount(_template),
          };
          dispatch(
            setTemplate({
              template: _template,
            }),
          );
          dispatch(
            setTemp({
              viewMode: isJourney ? 'journey' : '',
              template: _template,
            }),
          );
          dispatch(
            setInitial({
              template: cloneDeep(_template.steps)
            })
          );
          resolve(_template);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function createTemplatedInstance(
  payload: {
    status: string;
    schedule: ExperienceSchedule;
  } = {
    status: 'Draft',
    schedule: {
      start: 0,
      end: 0,
      timezone: '',
    },
  },
) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();

  return (dispatch: any, getState: any) => {
    let templatedExperience = getState().templatedExperience;
    let template: TExperienceInstance = templatedExperience?.template;
    template = updateEmptyContentName(template, 'Untitled');
    template.schedule = serializeScheduleData(template.schedule);
    template.steps = serializeTriggerData(template.steps, templatedExperience?.temp?.viewMode === 'journey');
    template.status = payload.status;
    template.journeyMode = getJourneyMode(template);
    template = generateUUID(template);
    if(template.steps?.[0]?.trigger?.type === 'nearby-location') {
      template = updateLocationCriteria(template);
    }
    template = validateTemplatedExperience(template);
    template.name =
      template.name && template.name !== 'Untitled'
        ? template.name
        : `Untitled ${moment().format('MMM D, YYYY, hh:mm:ssa')}`;
    template.templateID = template.id; // include template id in the POST
    if(!template.parentFolderID) {
      template.parentFolderID = templatedExperience?.currentFolder?.id || '00000000-0000-0000-0000-000000000000';
    }
    if(!isEmpty(template.labels)) {
      const labelsAPI = new LabelsAPI();
      labelsAPI.putLabels(template.labels);
    }
    if (template.schedule?.start && template.schedule.start > moment().unix() && template.status !== 'Draft') {
      template.status = 'Scheduled';
      trackEvent({
        category: 'Templated Experiences',
        action: 'Create with a schedule',
      });
    } else {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Create and Activate',
      });
    }

    return new Promise((resolve, reject) => {
      dispatch(setTemplate({ template: template })); // update the error state
      if (template.status === 'Draft' || getIncompleteCount(template) === 0) {
        _createTemplatedInstance(template);
      }

      function _createTemplatedInstance(template: any) {
        dispatch(setLoading({ flag: true }));
        templatedExperienceAPI
          .createTemplatedInstance(template)
          .then((res) => {
            let isDraft = template.status === 'Draft' ? ' (DRAFT)' : '';
            dispatch(
              showSnackbar({
                content: `Successfully created experience${isDraft}: "${template.name}"`,
              }),
            );
            dispatch(setModified(false));
            resolve(res);
          })
          .catch((err: any) => {
            dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
            reject();
          })
          .finally(() => {
            dispatch(setLoading({ flag: false }));
          });
      }
    });
  };
}

export function updateInstanceSchedule(payload: { schedule: ExperienceSchedule }) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any, getState: any) => {
    let instance: TExperienceInstance = getState().templatedExperience.instance;
    payload.schedule = serializeScheduleData(payload.schedule);
    instance.activation = serializeActivationData(instance.activation, payload.schedule);
    return new Promise((resolve, reject) => {
      dispatch(setLoading({ flag: true }));
      templatedExperienceAPI
        .updateTemplatedExperienceInstanceSchedule(instance.id, payload.schedule)
        .then((res) => {
          dispatch(
            showSnackbar({
              content: `Successfully updated schedule of the experience: "${instance.name}"`,
            }),
          );
          resolve(res);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        })
        .finally(() => {
          dispatch(setLoading({ flag: false }));
        });
    });
  };
}

export function updateTemplatedInstance(
  payload: { status: string } = { status: 'Draft' },
  queryParams: object | undefined,
) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any, getState: any) => {
    let templatedExperience = getState().templatedExperience;
    let instance: TExperienceInstance = templatedExperience.instance;
    instance.status = payload.status;
    instance.schedule = {
      ...instance.schedule,
      timezone: cleanupTimezone(instance.schedule.timezone || ''),
    };
    instance.name = instance.name || `Untitled ${moment().format('MMM D, YYYY, hh:mm:ssa')}`;
    instance.schedule = serializeScheduleData(instance.schedule);
    instance.steps = serializeTriggerData(instance.steps, templatedExperience?.temp?.viewMode === 'journey');
    instance.journeyMode = getJourneyMode(instance);
    instance.activation = serializeActivationData(instance.activation, instance.schedule);
    if(instance.steps?.[0]?.trigger?.type === 'nearby-location') {
      instance = updateLocationCriteria(instance);
    }
    if(!instance.parentFolderID) {
      instance.parentFolderID = templatedExperience?.currentFolder?.id || '00000000-0000-0000-0000-000000000000';
    }
    if(!isEmpty(instance.labels)) {
      const labelsAPI = new LabelsAPI();
      labelsAPI.putLabels(instance.labels);
    }
    instance = validateTemplatedExperience(instance);
    return new Promise((resolve, reject) => {
      dispatch(setInstance({ instance: instance })); // update the error state
      if (instance.status === 'Draft' || getIncompleteCount(instance) === 0) {
        _updateTemplatedInstance(instance, queryParams);
      }

      function _updateTemplatedInstance(instance: any, queryParams: object | undefined) {
        dispatch(setLoading({ flag: true }));
        templatedExperienceAPI
          .updateTemplatedExperienceInstance(instance, queryParams)
          .then((res) => {
            dispatch(
              showSnackbar({
                content: `Successfully updated experience: "${instance.name}"`,
              }),
            );
            dispatch(setModified(false));
            resolve(res);
          })
          .catch((err: any) => {
            dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
            reject();
          })
          .finally(() => {
            dispatch(setLoading({ flag: false }));
          });
      }
    });
  };
}

export function activateTemplatedExperienceInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .activateTemplatedExperienceInstance(id)
        .then((res: any) => {
          if (res.status === 'Active' || res.status === 'Scheduled') {
            dispatch(updateInstances({ id, status: res.status }));
            let content = `The experience has been successfully ACTIVATED`;
            let type = 'success';
            if (res.status === 'Scheduled') {
              content = `The experience has been successfully SCHEDULED`;
              type = 'info';
            }
            dispatch(showSnackbar({ content, type }));
            resolve(res);
          }
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

export function deactivateTemplatedExperienceInstance(id: string) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .deactivateTemplatedExperienceInstance(id)
        .then((res: any) => {
          if (res.status === 'Inactive') {
            dispatch(updateInstances({ id, status: res.status }));
            dispatch(
              showSnackbar({
                content: `The experience has been DEACTIVATED`,
                type: 'default',
              }),
            );
            resolve(res);
          }
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

export function deleteTemplatedExperienceInstance(
  instance: TExperienceInstance,
  filters: any
) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .deleteTemplatedExperienceInstance(instance.id)
        .then((res: any) => {
          dispatch(fetchFoldersAndInstances(filters));
          dispatch(
            showSnackbar({
              content: `Successfully deleted experience: "${instance.name || 'Untitled'}"`,
              type: 'default',
            }),
          );
          resolve(res);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

export function setAudience(payload: {
  ruleBody: string;
  seedRuleBody: string;
  ruleStringRepresentation: string;
  seedRuleStringRepresentation: string;
}, isJourney: boolean) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { template, instance } = getState().templatedExperience;
    const target: TExperienceInstance = isEmpty(template) ? instance : template;
    if(isJourney) {
      let audience = target?.steps?.find((step: any) => step.type === 'audience');
      if(audience) {
        const stepIndex = target?.steps?.indexOf(audience);
        target.steps[stepIndex].data = {
          ...target.steps[stepIndex].data,
          ...payload,
          hasError: (isEmpty(payload?.ruleStringRepresentation) && isEmpty(payload?.seedRuleStringRepresentation)) || false
        }
      }
    } else {
      target.steps[0].ruleStringRepresentation = payload.ruleStringRepresentation;
      target.steps[0].seedRuleStringRepresentation = payload.seedRuleStringRepresentation;
      target.steps[0].ruleBody = payload.ruleBody || ''; //ME-1002 payload comes in stringified from ember.
      target.steps[0].seedRuleBody = payload.seedRuleBody || '';
      target.steps[0].hasError =
        (isEmpty(payload.ruleStringRepresentation) && isEmpty(payload.seedRuleStringRepresentation)) || false;
    }
    if (!isEmpty(template)) {
      dispatch(
        setTemplate({
          template: {
            ...target,
            incompleteCount: getIncompleteCount(target),
          },
        }),
      );
    }
    if (!isEmpty(instance)) {
      dispatch(
        setInstance({
          instance: {
            ...target,
            incompleteCount: getIncompleteCount(target),
          },
        }),
      );
    }
  };
}

export function setName(name: string) {
  return (dispatch: any, getState: any) => {
    const template: TExperienceInstance = getState().templatedExperience.template;
    template.name = name;
    dispatch(setTemplate({ template }));
  };
}

export function setInstanceName(name: string) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const instance: TExperienceInstance = getState().templatedExperience.instance;
    instance.name = name;
    dispatch(setInstance({ instance }));
  };
}

export function updateLocationCriteria(experience: TExperienceInstance) {

    const contentBlueprint = experience?.steps?.[0]?.actions?.find((action: Action) => action.actionType === 'content-blueprint');
    const [stepIdx, actionIdx] = findActionParent(experience, contentBlueprint?.id!);
    const ruleStringRepresentation:string = experience.steps?.[stepIdx]?.ruleStringRepresentation;
    let zoneIds: Array<string> = [];
    let locationLabels: Array<string> = [];
    let ruleArray = ruleToArr(ruleStringRepresentation);
    if(ruleArray?.data) {
      ruleArray.data?.forEach((rule: any) => {
        let ruleAttributesArray = rule.delimittedRuleStringRepresentation?.[1]?.split('.');
        if(ruleAttributesArray?.[4] === 'inArea' && ruleAttributesArray?.[5]) {
          zoneIds.push(ruleAttributesArray?.[5]);
        }
        if((ruleAttributesArray?.[4] === 'inLocationWithLabel' || ruleAttributesArray?.[4] === 'inAreaWithLabel') && ruleAttributesArray?.[5]) {
          if(ruleAttributesArray?.[5] === 'eventType') {
            locationLabels.push(ruleAttributesArray?.[6]);
          } else {
            locationLabels.push(ruleAttributesArray?.[5]);
          }
        }
      });
    }

    const updatedRuleString = ruleStringRepresentation.split(' ')?.map((slice:string) => {
      if(slice.includes('inLocationWithLabel') || slice.includes('inAreaWithLabel')) {
        slice = slice.replace('boolEq', 'stringFoldEq');
        slice = slice.replace('inLocationWithLabel', 'inAreaWithLabel.eventType');
        slice = slice.replace("true", "'entry'");
      }
      return slice;
    }).join(' ');

    if(ruleStringRepresentation) {
      merge(
        experience.steps?.[stepIdx],
        {
          ...experience.steps?.[stepIdx],
          ruleStringRepresentation: updatedRuleString
        }
      );
    }

    if(experience?.steps?.[stepIdx]?.actions?.[actionIdx]?.actionBody?.templateId) {
      merge(
        experience.steps[stepIdx].actions[actionIdx].actionBody,
        {
          contentCriteria: {
            locationCriteria: {
              labelsFormula: {
                or: locationLabels
              },
              zoneIds
            }
          }
        }
      );
    }

  return experience;
}

export function setContent(actionID: string, content: Content[], isNearByTemplate: boolean, isJourney: boolean) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    let experience = !isEmpty(getState().templatedExperience?.instance) ? getState().templatedExperience?.instance : getState().templatedExperience?.template;
    if(isJourney && actionID) {
      let { template, instance } = getState().templatedExperience;
      if (!isEmpty(template)) {
        let stepContent = template?.steps?.find((expStep: any) => expStep.id === actionID);
        if(isNearByTemplate) {
          stepContent = template?.steps?.find((expStep: JourneyStep) => expStep.data?.actionBody?.templateId === actionID);
        }
        let stepContentIdx = template?.steps?.indexOf(stepContent);
        if(template?.steps?.[stepContentIdx]?.id) {
          template.steps[stepContentIdx].id = content?.[0]?.id || stepContent?.id || '';
        }
        if(content?.[0]?.templateId) {
          stepContent.data.actionBody.templateId = content?.[0]?.templateId || '';
        }
        stepContent.data.actionBody.data = content?.[0]?.data || content?.[0];
        if(template?.steps?.[stepContentIdx]?.data?.actionBody) {
          template.steps[stepContentIdx].data.actionBody = {
            ...template.steps[stepContentIdx].data.actionBody,
            contentIds: [content?.[0]?.id],
            data: {
              ...template.steps[stepContentIdx].data.actionBody.data,
              ...content?.[0]?.data,
              ...content?.[0]
            }
          };
        }
        template = validateTemplatedExperience(template);
        dispatch(
          setTemplate({
            template: {
              ...template,
              incompleteCount: getIncompleteCount(template),
              showError: getIncompleteCount(template) === 0 ? false : template.showError,
            },
          }),
        );
      } else {
        let stepContent = instance?.steps?.find((expStep:JourneyStep) => expStep.id === actionID);
        if(isNearByTemplate) {
          stepContent = instance?.steps?.find((expStep: JourneyStep) => expStep.data?.actionBody?.templateId === actionID);
        }
        let stepContentIdx = instance?.steps?.indexOf(stepContent);
        if(instance?.steps?.[stepContentIdx]?.id) {
          instance.steps[stepContentIdx].id = content?.[0]?.id || stepContent?.id || '';
        }
        stepContent.id = content?.[0]?.id;
        if(instance?.steps?.[stepContentIdx]?.data?.actionBody) {
          instance.steps[stepContentIdx].data.actionBody = {
            ...instance.steps[stepContentIdx].data.actionBody,
            contentIds: [content?.[0]?.id || stepContent?.id || ''],
            templateId: content?.[0]?.templateId || '',
            data: {
              ...instance.steps[stepContentIdx].data.actionBody.data,
              ...content?.[0]?.data,
              ...content?.[0]
            }
          };
        }

        instance = validateTemplatedExperience(instance);

        dispatch(
          setInstance({
            instance: {
              ...instance,
              incompleteCount: getIncompleteCount(instance),
              showError: getIncompleteCount(instance) === 0 ? false : instance.showError,
            },
          }),
        );
      }
    } else {
      if(experience.steps?.[0]?.trigger?.type === 'nearby-location') {
        const contentBlueprint = experience?.steps?.[0]?.actions?.find((action: Action) => action.actionType === 'content-blueprint');
        _updateActionBody(actionID, () => ({
          ...contentBlueprint.actionBody,
          ...content?.[0],
          contents: content,
        }))(dispatch, getState);
      } else {
        const contentIds = content?.[0]?.id ? content?.map((item) => item.id) : null;
        _updateActionBody(actionID, () => ({
          contentIds: contentIds,
          contents: content,
        }))(dispatch, getState);
      }
    }
  };
}

// Form object to action
export function setCustomPush(action: any, form: any, pushTypeId: string) {
  const payload = getPushPayload(action);
  payload.title = form.title;
  payload.alert = form.alert;
  payload.data = { ...form.data, pushPayloadTypeId: pushTypeId, };
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    if (temp?.delivery) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

// Form object to action
export function setContentPush(action: any, form: any, pushTypeId: string) {
  const payload = getPushPayload(action);
  if (form) {
    payload.title = form.title;
    payload.alert = form.alert;
    payload.data = {
      contentId: {
        value: form?.contentId || '',
      },
      pushPayloadTypeId: pushTypeId,
    };
  }
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    if (!isEmpty(temp?.delivery) && temp.delivery.trigger !== '') {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

/**
 * This function is responsible for creating amplifiedPush.
 * @param action is the pushpayload created and sent from calling component
 * @param form validated form
 */
export function setAmplifiedPush(action: any, form: any) {
  const payload = getPushPayload(action);
  if (form) {
    payload.title = form?.title;
    payload.alert = form?.alert;
  }
  return function (dispatch: any, getState: any) {
    const templatedExperience = getState().templatedExperience;
    const temp = templatedExperience?.temp;
    // get existing contentFrom State (1) for instance (update) - or -
    // (2) for template (create)
    let contentInstanceOrTemplate = !isEmpty(templatedExperience?.instance) ?
      templatedExperience?.instance : templatedExperience?.template;
    if (contentInstanceOrTemplate) {
      contentInstanceOrTemplate = contentInstanceOrTemplate?.steps?.[0]?.actions;
    }
  
    // only one push can exist, any changes
    // will have to overwrite existing push attached to the content
    if (contentInstanceOrTemplate?.length  !== 2 && action?.actionID === '00000000-0000-0000-0000-000000000000') {
      contentInstanceOrTemplate?.push(action);
    } 
      
    if (!isEmpty(temp?.delivery)) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.actionID || action.id, () => action.actionBody)(dispatch, getState);
  };
}

export function removeAmplification() {
  return (dispatch: any, getState: any) => {
    let contentInstanceOrTemplate =
      (!isEmpty(getState().templatedExperience?.instance) && getState().templatedExperience?.instance) ||
      getState().templatedExperience?.template;

    const removeAmplification = contentInstanceOrTemplate?.steps?.[0]?.actions?.filter(
      (item: any) => item.actionType !== 'push',
    );
    const getInstanceOrTemplate = isEmpty(getState().templatedExperience?.instance) ? 'template' : 'instance';
    if(contentInstanceOrTemplate?.steps?.[0]?.actions) {
      contentInstanceOrTemplate.steps[0].actions = removeAmplification;
    }
    let removeAmplifyFromObject = validateTemplatedExperience(contentInstanceOrTemplate);

    dispatch(
      getInstanceOrTemplate === 'instance' ?
      setInstance({
        instance: {
          ...removeAmplifyFromObject,
          incompleteCount: getIncompleteCount(contentInstanceOrTemplate),
          showError: getIncompleteCount(contentInstanceOrTemplate) === 0 ? false : contentInstanceOrTemplate.showError,      
        },
      }) :
      setTemplate({
        template: {
          ...removeAmplifyFromObject,
          incompleteCount: getIncompleteCount(contentInstanceOrTemplate),
          showError: getIncompleteCount(contentInstanceOrTemplate) === 0 ? false : contentInstanceOrTemplate.showError,      
        },
      }) ,
    );
  };
}

// Form object to action
export function setWebLinkPush(action: any, form: any, pushTypeId: string) {
  const payload = getPushPayload(action);
  payload.title = form.title;
  payload.alert = form.alert;
  payload.data = {
    url: form.url || {},
    pushPayloadTypeId: pushTypeId,
  };
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    if (temp?.delivery) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

// Form object to action
export function setBasicPush(action: any, form: any) {
  const payload = getPushPayload(action);
  payload.title = form.title;
  payload.alert = form.alert;
  delete payload.data;
  return (dispatch: any, getState: any) => {
    const temp = getState().templatedExperience.temp;
    // get languages and parse it through
    if (temp?.delivery) {
      action.actionBody.delivery = serializeDelivery(temp?.delivery);
    }
    _updateActionBody(action.id, () => action.actionBody)(dispatch, getState);
  };
}

export function showError(type: 'instance' | 'template') {
  return (dispatch: any, getState: any) => {
    if (type === 'instance') {
      const instance: TExperienceInstance = getState().templatedExperience.instance;
      dispatch(setInstance({ instance: { ...instance, showError: true } }));
    } else {
      const template: TExperienceInstance = getState().templatedExperience.template;
      dispatch(setTemplate({ template: { ...template, showError: true } }));
    }
  };
}

export function setTempDelivery(payload: any, isJourney: boolean) {
  return (dispatch: any) => {
    dispatch(setTemp({
      viewMode: isJourney ? 'journey' : '',
      delivery: payload
    }));
  };
}

export function setStepTrigger({ stepId, payload }: any) {
  return (dispatch: any, getState: any) => {
    dispatch(setModified(true));
    const { templatedExperience } = getState();
    let { template, instance } = templatedExperience;
    if (!isEmpty(template)) {
      // update template (create route)
      let stepIndex = template.steps.indexOf(template.steps.find((step: any) => step.id === stepId));
      template.steps[stepIndex].trigger = {
        ...template.steps[stepIndex].trigger,
        ...payload,
      };
      template = validateTemplatedExperience(template); //TODO: add validation for trigger
      dispatch(
        setTemplate({
          template: {
            ...template,
            incompleteCount: getIncompleteCount(template),
            showError: getIncompleteCount(template) === 0 ? false : template.showError,
          },
        }),
      );
    } else {
      // update instance (edit route)
      let stepIndex = instance.steps.indexOf(instance.steps.find((step: any) => step.id === stepId));
      instance.steps[stepIndex].trigger = {
        ...instance.steps[stepIndex].trigger,
        ...payload,
      };
      instance = validateTemplatedExperience(instance);
      dispatch(
        setInstance({
          instance: {
            ...instance,
            incompleteCount: getIncompleteCount(instance),
            showError: getIncompleteCount(instance) === 0 ? false : instance.showError,
          },
        }),
      );
    }
  };
}

export function checkExperienceName(opts: any) {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  return (dispatch: any) => {
    return new Promise((resolve, reject) => {
      templatedExperienceAPI
        .getTemplatedInstances(opts)
        .then((data: PaginationResponse<TExperienceInstance>) => {
          resolve(data);
        })
        .catch((err: any) => {
          dispatch(setError({ errorMessage: err?.response?.data ? err.response.data : err.message }));
          reject();
        });
    });
  };
}

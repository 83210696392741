import { TEAContentTimeline, TEATimeline } from 'interface/templated-experience/analytics.interface';
import { AnalyticsContentPerformanceState, AnalyticsContentPerformanceActionType } from './content-performance.type';

const initialState: AnalyticsContentPerformanceState = {
  viewRecords: [],
  clickRecords: [],
  targetAudienceRecords: { data: [] },
  isViewLoading: false,
  isClickLoading: false,
  errorMessage: ''
};

type ActionType = {
  type: string,
  payload: TEAContentTimeline[] | TEATimeline,
};

export default (state: AnalyticsContentPerformanceState = initialState, action: ActionType) => {
  switch (action.type) {
    case AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_VIEW_DATA:
      return {
        ...state,
        viewRecords: action.payload || initialState.viewRecords,
      };
    case AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_CLICK_DATA:
      return {
        ...state,
        clickRecords: action.payload || initialState.clickRecords,
      };
    case AnalyticsContentPerformanceActionType.SET_CONTENT_TA_PERFORMANCE_DATA:
      return {
        ...state,
        targetAudienceRecords: action.payload || initialState.targetAudienceRecords,
      };
    case AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_VIEW_LOADING:
      return {
        ...state,
        isViewLoading: action.payload,
      };
    case AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_CLICK_LOADING:
      return {
        ...state,
        isClickLoading: action.payload,
      };
    case AnalyticsContentPerformanceActionType.SET_CONTENT_PERFORMANCE_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return {
        ...state
      };
  }
};

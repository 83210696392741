
export function tokenCreator(html: string, pluginAttrsMap1: Array<any>) {
  if (!html) {
    return '';
  }
  let result = '';
  let currIdx = 0;
  const pluginAttrsMap = _dynamicAttrPluginAttrsMap(pluginAttrsMap1);
  while (currIdx >= 0 && currIdx < html.length) {
    const newHTML = html.substr(currIdx);
    const startIdx = newHTML.indexOf('{{');
    const endIdx = startIdx + newHTML.substr(startIdx).indexOf('}}');
    if (startIdx === -1 || endIdx === -1) break;

    result += html.substr(currIdx, startIdx);
    const dynamicAttr = newHTML.substring(startIdx + 2, endIdx).split(',');
    if (pluginAttrsMap[dynamicAttr[0]]) {
      const defaultDynamicValue = dynamicAttr.slice(1, dynamicAttr.length).join(',');
      result += `<span class="dynamic-attr-wrapper"><span class="dynamic-attr__attr" \
                data-mce-dynamic-attribute="${dynamicAttr[0]}">${pluginAttrsMap[dynamicAttr[0]]}</span>\
                ${defaultDynamicValue ? `<q class="dynamic-attr__default-value">${defaultDynamicValue}</q>` : ''}</span>`;
    } else {
      result += newHTML.substring(startIdx, endIdx + 2);
    }
    currIdx += endIdx + 2;
  }
  result += html.substr(currIdx);
  const container = document.createElement('span');
  container.innerHTML = result;

  const dynamicAttrs = [...container.querySelectorAll('span.dynamic-attr')];
  for (let i = 0; i < dynamicAttrs.length; ++i) {
      dynamicAttrs[i].removeAttribute('title');
      dynamicAttrs[i].removeAttribute('contenteditable');
  }
  return container.innerHTML.replace(/\n/g, '<br>');
}

function _dynamicAttrPluginAttrsMap(plugins: Array<any>) {
  const pluginAttrMap:any = {};
  plugins?.forEach((plugin) => {
    if (plugin.values) {
      const attributes = plugin.values;
      for (const attrKey in attributes) {
        if (attributes.hasOwnProperty(attrKey)) {
          pluginAttrMap[attributes[attrKey].uid] =
          `${plugin.name} / ${attributes[attrKey].name}`;
        }
      }
    }
  });
  return pluginAttrMap;
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {useSelector} from 'react-redux';
import queryString from 'query-string';
import moment from 'moment';

// Redux Actions
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';

import { history } from 'redux/store';
import Storage from "services/storage";
import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from "helpers/analytics.helper";

import ControlBar from 'components/ControlBar/ControlBar';
import './TemplatedExperienceEdit.scss';
import ScheduleEditModal, { scheduleDataType } from "components/Modal/EditModal/ScheduleEditModal/ScheduleEditModal";
import {transformScheduleData, getWarningOnLaunchScheduled, convertDateToTimezone} from "helpers/templated-experience.helper";
import useDebounce from "hooks/useDebounce";
import EmptyState from 'components/Performance/EmptyState/EmptyState';
import Performance from "components/Performance/Performance";
import useFeatureFlag from 'hooks/useFeatureFlag';
import SettingsTab from "components/SettingsTab/SettingsTab";
import ActionTab from 'components/ActionTab/ActionTab';

interface param {
  [key: string]: string | undefined
}

const JOURNEY_STATUS_NO_PERFORMANCE = ['Draft', 'Scheduled'];

export default function TemplatedExperienceEdit(props:any) {
  const storage = new Storage();
  const { flags } = useFeatureFlag();
  const { id }: param = useParams();
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const [confirmEmptyAudience, setConfirmEmptyAudience] = useState('UNSELECTED');
  const [isAudienceEmpty, setIsAudienceEmpty] = useState(false);
  const confirmModalProps = {
    isVisible: false,
    isLaunching: false,
    title: 'Save and Activate Now',
    content: 'This experience will save as Active and become visible to your customers. ' +
      'You may deactivate your experience at any time but can not revert it to Draft.',
    primaryButton: {
      name: 'Confirm',
      onClick: () => onActivateHandle(),
      disabled: false,
    },
    secondaryButton: reduxTemplatedExperienceState.status === 'Draft' ? {
      name: 'Save as draft',
      onClick: () => onDraftHandle(),
      disabled: false,
    } : undefined,
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({...modalProps, isVisible: false}),
    },
    confirmEmptyAudience,
    setConfirmEmptyAudience,
    isAudienceEmpty
  }
  const [modalProps, setModalProps] = useState<any>(confirmModalProps);
  const queryParams = queryString.parse(props.location?.search);
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);

  const scheduleModalProps = {
    isVisible: false,
    title: 'Schedule this Experience',
    instance: {},
    primaryButton: {
      name: 'Confirm',
      onClick: (scheduleData: scheduleDataType) => setScheduleData(scheduleData),
      disabled: false,
      loading: false,
    },
    cancelButton: {
      name: '',
      onClick: () => onScheduleCancel(),
    }
  }

  const [scheduleProps, setScheduleProps] = useState<any>(scheduleModalProps);
  const [nameHasError, setNameHasError] = useState('');
  const debouncedName = useDebounce(reduxTemplatedExperienceState?.instance?.name!!, 500);

  const pathName = history.location.pathname.split('/')[2];

  useEffect(() => {
    if(reduxTemplatedExperienceState?.instance?.steps?.length &&
      reduxTemplatedExperienceState?.instance?.steps[0]?.ruleStringRepresentation === '' &&
      reduxTemplatedExperienceState?.instance?.steps[0]?.seedRuleStringRepresentation === '') {
      setIsAudienceEmpty(true);
      setModalProps({...modalProps, isAudienceEmpty: true});
    } else {
      setIsAudienceEmpty(false);
      setModalProps({...modalProps, isAudienceEmpty: false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[reduxTemplatedExperienceState, reduxTemplatedExperienceState?.instance?.steps?.[0].ruleStringRepresentation, reduxTemplatedExperienceState?.instance?.steps?.[0].seedRuleStringRepresentation]);

  useEffect(()=>{
    if(debouncedName !== 'Untitled' && reduxTemplatedExperienceState.isModified) {
      validateExperienceName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[debouncedName]);

  useEffect(() => {
    reduxTemplatedExperienceAction.fetchInstance(id, flags['tx_journey_builder'] && reduxTemplatedExperienceState?.temp?.viewMode === 'journey');
    return () => reduxTemplatedExperienceAction.clearInstance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxTemplatedExperienceState?.temp?.viewMode]);

  useEffect(() => {
    const startDate = (reduxTemplatedExperienceState.instance ? new Date(reduxTemplatedExperienceState.instance.createdAt * 1000) : null);
    const endDate = (reduxTemplatedExperienceState.instance ? new Date() : null);
    if (startDate && endDate) {
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(0, 0, 0, 0);
      setDateRange([startDate, endDate]);
    }
  }, [reduxTemplatedExperienceState.instance]);

  async function validateExperienceName() {
    await setNameHasError('');
    if(debouncedName) {
      return await reduxTemplatedExperienceAction.checkExperienceName({search: debouncedName, searchExact: true})
        .then(async (checkName: any) => {
          if(checkName?.data?.length) {
            let nameIsUnique = true;
            await checkName?.data.forEach((exp: any) => {
              if(exp.id !== id) {
                nameIsUnique = false;
                setNameHasError('Name is not unique. Try another one.');
              }
            });
            return nameIsUnique;
          } else {
            return true;
          }
        });
    }
    if(debouncedName === '') {
      await setNameHasError('Name can not be empty.');
      return false;
    }
    return true;
  }

  async function onActivateHandle() {
    if(await validateExperienceName()) {
      reduxTemplatedExperienceAction.updateTemplatedInstance({status: 'Active'}).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Edit and Activate',
        });
        history.push(`/project/${pathName}${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`);
      });
      setModalProps({...modalProps, isVisible: false});
    }
  }

  async function onLaunchHandle() {
    if(await validateExperienceName()) {
      const schedule = reduxTemplatedExperienceState.instance?.schedule;
      const {startDate, endDate, newTitle, newMessage} = getWarningOnLaunchScheduled(schedule);
      let title = modalProps.title;
      let content = modalProps.content;
      let primaryButton = modalProps.primaryButton;

      let convertStart = convertDateToTimezone(schedule?.start!!, schedule?.timezone!!);
      let convertEnd = convertDateToTimezone(schedule?.end!!, schedule?.timezone!!);
      let curTime = moment().unix();

      if (schedule.start && convertStart.local_unix > curTime) {
        title = newTitle;
        content = (
          <div>
            <div>{newMessage}</div>
            <div className="templated-experiences-edit__warning-schedule">
              <div><strong>Start date:</strong> {startDate}</div>
              <div><strong>End date:</strong> {endDate}</div>
            </div>
          </div>
        );
        primaryButton = {
          ...modalProps.primaryButton,
          onClick: () => {
            reduxTemplatedExperienceAction.updateTemplatedInstance({status: 'Scheduled'}).then(() => {
              trackEvent({
                category: 'Templated Experiences',
                action: 'Edit and Schedule',
              });
              history.push(`/project/${pathName}${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`);
            });
            setModalProps({...modalProps, isVisible: false});
          }
        }
      }

      if ((schedule.start && convertStart.local_unix < curTime) ||
        (schedule?.end!! && convertEnd.local_unix < curTime)) {
        title = 'Expired Schedule';
        content = <div>You can&apos;t launch an experience with an expired schedule.
          To launch, edit your schedule {!schedule.start ? 'to add start date' : 'to a current date/time'}.</div>;
        primaryButton = {
          ...modalProps.primaryButton,
          name: 'Edit Schedule',
          onClick: () => {
            setScheduleProps({...scheduleProps, isVisible: true});
            setModalProps({...modalProps, isVisible: false});
          }
        }
      }

      setModalProps({
        ...modalProps,
        isLaunching: true,
        title,
        content,
        isVisible: true,
        primaryButton,
      });
    }
  }

  async function onSaveHandle() {
    if(await validateExperienceName()) {
      let status = reduxTemplatedExperienceState.instance?.status;
      const schedule = reduxTemplatedExperienceState.instance?.schedule;
      const {startDate} = getWarningOnLaunchScheduled(schedule);
      let title = modalProps.title;
      let content = modalProps.content;
      let isVisible = false;
      let primaryButton = modalProps.primaryButton;
      let convertStart = convertDateToTimezone(schedule?.start!!, schedule?.timezone!!);
      let curTime = moment().unix();

      if (status === 'Scheduled' && !schedule.start && !schedule.end) {
        title = 'Launch Immediately';
        content = <div>This experience does not have a schedule associated with it.
          As a result it will launch <strong>immediately</strong> and be visible to your customers.
          If you wish to add a schedule, click on &quot;Cancel&quot; and add a schedule.</div>;
        isVisible = true;
        status = 'Active';
        primaryButton = {
          ...modalProps.primaryButton,
          onClick: () => {
            reduxTemplatedExperienceAction.updateTemplatedInstance({status})
              .then(() => {
                setModalProps({
                  ...modalProps,
                  isVisible: false,
                });
                trackEvent({
                  category: 'Templated Experiences',
                  action: 'Remove schedule on Scheduled Experience and Activate',
                })
                history.push(`/project/${pathName}${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`);
              })
              .catch((err: any) => alert('Error: ' + err));
          }
        }
      }

      // If scheduled experience becomes active while the user makes editing
      if ((status === 'Scheduled' || status === 'Active') && convertStart.local_unix < curTime) {
        title = 'Your Experience is already Active';
        content = <div>Your Experience has been Active as of {startDate}.
          It can not be modified past at the activation time.
          You need to deactivate your experience in order to edit it.</div>;
        isVisible = true;
        status = 'Active';
        primaryButton = {
          ...modalProps.primaryButton,
          onClick: () => {
            setModalProps({
              ...modalProps,
              isVisible: false,
            });
            history.push(`/project/${pathName}view/${reduxTemplatedExperienceState.instance.id}`);
          }
        }
      }

      if (isVisible) {
        setModalProps({
          ...modalProps,
          title,
          content,
          isVisible,
          primaryButton,
        });
      } else {
        reduxTemplatedExperienceAction.updateTemplatedInstance({status}).then(() => {
          trackEvent({
            category: 'Templated Experiences',
            action: 'Edit and Save',
          });
          history.push(`/project/${pathName}${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`);
        });
        setModalProps({...modalProps, isVisible: false});
      }
    }
  }

  function onCancelHandle() {
    storage.setItem('instance', null);
    history.push(`/project/${pathName}${queryParams ? '/?'+queryString.stringify(queryParams) : ''}`);
  }

  async function onDraftHandle() {
    if(await validateExperienceName()) {
      reduxTemplatedExperienceAction.updateTemplatedInstance({status: 'Draft'}).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Edit and Save as draft',
        });
        history.push(`/project/${pathName}${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`);
      });
    }
  }

  function onNameEditHandle(name: string) {
    setNameHasError('');
    reduxTemplatedExperienceAction.setInstanceName(name);
  }

  function onScheduleHandle() {
    setScheduleProps({
      ...scheduleProps,
      isVisible: true
    });
  }

  function setScheduleData(scheduleData:scheduleDataType) {
    reduxTemplatedExperienceAction.setSchedule(transformScheduleData(scheduleData));
    setScheduleProps({
      ...scheduleProps,
      isVisible: false,
    });
  }

  function onScheduleCancel() {
    setScheduleProps(scheduleModalProps);
  }

  function onDeactivate() {
    setModalProps({
      ...modalProps,
      isVisible: true,
      title: 'Your Experience will be Deactivated',
      content: 'Proceeding will deactivate your experience immediately ' +
        'and it will no longer be visible to your customers per the schedule.',
      primaryButton: {
        ...modalProps.primaryButton,
        name: 'Confirm',
        onClick: () => onDeactivateConfirm()
      }
    });
  }

  function onDeactivateConfirm() {
    setModalProps({
      ...modalProps,
      isVisible: false,
    })
    reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(reduxTemplatedExperienceState.instance?.id)
      .then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Unschedule for Editing',
        });
        reduxTemplatedExperienceState.instance.status = 'Inactive';
        reduxTemplatedExperienceAction.setInstance({instance: reduxTemplatedExperienceState.instance});
      })
      .catch(() => alert('In development'));
  }


  function onTabSwitch(isPerformanceTab: boolean) {
    const queryParamsMap: Record<string, string> = {};
    if (props.location?.search) {
      props.location?.search.slice(1).split('&').forEach((param: string) => {
        const paramNameValuePair = param.split('=');
        queryParamsMap[paramNameValuePair[0]] = paramNameValuePair[1];
      });
    }
    if (isPerformanceTab) {
      queryParamsMap['tab'] = 'performance';
    } else {
      delete queryParamsMap['tab'];
    }

    history.push({
      pathname: props.location.pathname || `/project/${pathName}edit/${reduxTemplatedExperienceState.instance.id}`,
      search: `?${Object.keys(queryParamsMap).map(paramKey => `${paramKey}=${queryParamsMap[paramKey]}`).join('&')}`,
    });
  }

  const displayPerformanceTab = () => {
    return flags['tx_performance_tab_tea_graph'] || flags['tx_ao_enabled'] 
  }

  return reduxTemplatedExperienceState?.instance && (
    <>
      <ControlBar
        name={reduxTemplatedExperienceState.instance?.name || ''}
        status={reduxTemplatedExperienceState.instance?.status || 'Draft'}
        onDeactivate={reduxTemplatedExperienceState.instance?.status === 'Scheduled' ? onDeactivate : undefined}
        onCancel={onCancelHandle}
        onSave={onSaveHandle}
        onLaunch={reduxTemplatedExperienceState.instance?.status !== 'Scheduled' ? onLaunchHandle : undefined}
        onLaunchDisabled={reduxTemplatedExperienceState.instance?.incompleteCount > 0}
        onNameEdit={onNameEditHandle}
        showScheduleModal={onScheduleHandle}
        experience={reduxTemplatedExperienceState.instance}
        count={reduxTemplatedExperienceState.instance.incompleteCount}
        onIncompleteHandle={() => reduxTemplatedExperienceAction.showError('instance')}
        actionTab={<ActionTab />}
        performanceTab={
          displayPerformanceTab() && (
          reduxTemplatedExperienceState.instance?.status &&          
          !JOURNEY_STATUS_NO_PERFORMANCE.includes(reduxTemplatedExperienceState.instance?.status) ?
           <Performance dateRange={dateRange} setDateRange={setDateRange} /> :
          <EmptyState />)
        }
        settingsTab={<SettingsTab/>}
        onTabSwitch={onTabSwitch}
        isShowPerformanceTab={!!queryParams?.tab}
        nameHasError={nameHasError}
      />
      <ConfirmModal {...modalProps} setModalProps={setModalProps} />
      <ScheduleEditModal
        {...scheduleProps}
        type={reduxTemplatedExperienceState.instance?.steps ? reduxTemplatedExperienceState.instance?.steps[0]?.trigger?.type : ''}
        schedule={reduxTemplatedExperienceState.instance.schedule}
      />
    </>
  )
}

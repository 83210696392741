import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import authReducer from 'redux/auth/auth.reducer';
import pluginReducer from 'redux/plugin/plugin.reducer';
import templatedExperienceReducer from './templated-experience/templated-experience.reducer';
import analyticsFunnelReducer from './analytics/experience-funnel/experience-funnel.reducer';
import analyticsPerformanceReducer from './analytics/experience-performance/experience-performance.reducer';
import analyticsContentEngagementReducer from './analytics/content-engagement/content-engagement.reducer';
import analyticsContentPerformanceReducer from './analytics/content-performance/content-performance.reducer';
import analyticsContentEngagementBySourceReducer from './analytics/content-engagement-by-source/content-engagement-by-source.reducer';
import snackbarReducer from './snackbar/snackbar.reducer';
import featureFlagReducer from './feature-flag/feature-flag.reducer';
import settingsReducer from './settings/settings.reducer';

export default combineReducers({
  auth: authReducer,
  plugin: pluginReducer,
  snackbar: snackbarReducer,
  templatedExperience: templatedExperienceReducer,
  featureFlag: featureFlagReducer,
  settings: settingsReducer,
  router: routerReducer,
  analytics: combineReducers({
    analyticsFunnel: analyticsFunnelReducer,
    analyticsPerformance: analyticsPerformanceReducer,
    analyticsContentEngagement: analyticsContentEngagementReducer,
    analyticsContentPerformance: analyticsContentPerformanceReducer,
    analyticsContentEngagementBySource: analyticsContentEngagementBySourceReducer,
  }),
});

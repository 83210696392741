import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {startCase, merge, cloneDeep, orderBy} from 'lodash';

import Slider from '@material-ui/core/Slider';
import {default as MaterialTooltip} from '@material-ui/core/Tooltip';

import AOApi from 'services/api/ao.api';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';

import {FlightButton, FlightSnackbar, FlightTable, getIcon} from '@flybits/webapp-design-system-react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ReferenceArea,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {serializeTriggerData} from 'helpers/templated-experience.helper';
import { ReactComponent as RightArrowBlue } from 'assets/icons/right-arrow-blue.svg';
import {trackEvent} from "helpers/analytics.helper";
import AttributesModal from 'components/Modal/AttributesModal/AttributesModal';
import {Attribute} from 'interface/attribute.interface';
import AOAPI from "services/api/ao.api";
import Skeleton from "react-loading-skeleton";
import { isEmpty } from 'lodash';
import './AnalyticsPerformanceOptimizedChart.scss';



// For now, the content of this graph is hard coded for PNC Demo.
export default function AnalyticsPerformanceOptimizedChart() {
  const AoAPI = new AOAPI();
  const [data, setData] = useState<any>(null);
  const [metadata, setMetadata] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeGraphData, setActiveGraphData] = useState([]);
  const [candidateGraphData, setCandidateGraphData] = useState([]);
  const aoAPI = new AOApi();
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const instance = reduxTemplatedExperienceState?.instance;
  const [chartData, setChartData] = useState<any>([]);
  const [sliderValue, setSliderValue] = useState(reduxTemplatedExperienceState?.instance?.automationBudget?.cutOffPercentage);
  const [prevSliderValue, setPrevSliderValue] = useState(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const [attributes, setAttributes] = React.useState<Array<any>|undefined>([]);
  const [attributesCandidate, setAttributesCandidate] = React.useState<Array<any>|undefined>([]);
  const _modalProps = {
    attributes,
    isVisible: false,
    icon: '',
    title: '',
    content: <div> </div>,
    statusMessage: <div> </div>,
    primaryButton: {
      name: 'Generate new model',
      onClick: (attrs:Array<Attribute>) => onGenerateNewModel(attrs),
      disabled: true
    },
    cancelButton: {
      name: 'Close',
      onClick: () => {}
    },
  };
  let [modalProps, setModalProps] = useState<any>(_modalProps);
  let [isTrainingNewModel, setIsTrainingNewModel] = useState<boolean>(false);
  interface ChartData {x:number, y:number, xOptim:number, yOptim:number}

  // initial load
  useEffect(() => {
    if (reduxTemplatedExperienceState?.instance?.id) {
      setIsLoading(true);
      AoAPI.getMetrics(reduxTemplatedExperienceState.instance?.id).then((response: any) => {
        setData(response);
        setActiveGraphData(response?.activeModelMetrics?.optimizationGraph!);
        setCandidateGraphData(response?.candidateModelMetrics?.optimizationGraph!);
        setChartData(generateDataPoints(
          response?.activeModelMetrics?.optimizationGraph!,
          response?.candidateModelMetrics?.optimizationGraph!
        ));
        setAttributes(updateAttributes(response?.activeModelMetrics?.featureMetrics!));
        setAttributesCandidate(updateAttributes(response?.candidateModelMetrics?.featureMetrics!));
        setModalProps({
          ..._modalProps,
          attributes: getAttributesForModal(
            updateAttributes(response?.activeModelMetrics?.featureMetrics!),
            updateAttributes(response?.candidateModelMetrics?.featureMetrics!)
          )
        })
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      });
      AoAPI.getMetadata(reduxTemplatedExperienceState.instance?.id).then((response: any) => {
        setMetadata(response);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxTemplatedExperienceState?.instance?.id, isTrainingNewModel]);

  useEffect(() => {
    setIsTrainingNewModel(metadata?.devModelStatus?.status?.toLowerCase() === 'inprogress');
    if(metadata?.devModelStatus?.status?.toLowerCase() === 'inprogress') {
      const interval = setInterval(() => {
        AoAPI.getMetadata(reduxTemplatedExperienceState.instance?.id).then((response: any) => {
          if(response?.devModelStatus?.status?.toLowerCase() === '') {
            setMetadata(response);
            clearInterval(interval);
          }
        });
      }, 3000);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata?.devModelStatus?.status]);

  useEffect(() => {
    const sliderAudienceReach = getSliderAudienceReach();
    setSliderValue(sliderAudienceReach);
    setPrevSliderValue(sliderAudienceReach);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  function getAttributesForModal(attributesActive:Attribute[]|undefined, attributesCandidate:Attribute[]|undefined){
    if(!isEmpty(attributesCandidate)){
      return attributesCandidate;
    }
    return attributesActive;
  }

  function updateAttributes(attrs:Array<Attribute>) {
    return attrs?.sort((a:Attribute, b:Attribute) => b.importance! - a.importance!);
  }

  function scrollToCTA() {
    setTimeout(() => {
      document
        .querySelector('.analytics-performance-optimized-chart__control')!!
        .scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }

  // Custon logic for handling tooltip
  function renderTooltip(props: {label:number}) {
    const point = chartData?.find((chartPoint: ChartData) => chartPoint.x === props?.label || chartPoint.xOptim === props?.label);
    return (
      <div className="custom-tooltip">
        {!!activeGraphData?.length && (
          <div className="row highlight">
            <p> Active engagement rate </p>
            <p> {point?.y || 0}% </p>
          </div>
        )}
        {!!candidateGraphData?.length && (
          <div className={`row highlight ${candidateGraphData?.length ? "updated" : ""}`}>
            <p> {activeGraphData?.length && candidateGraphData?.length ? 'Updated engagement rate' : 'Engagement rate'} </p>
            <p> {point?.yOptim || 0}% </p>
          </div>
        )}
        <div className="row">
          <p> Total reach </p>
          <p> {point?.x || point?.xOptim || 0}% </p>
        </div>
      </div>
    );
  }

  const handleSliderChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
  };

  // Let the user click on grap
  function onGraphClickHandler(graphInfo: any) {
    if (isEditing && graphInfo) {
      setSliderValue(graphInfo.activeLabel);
    }
  }

  function updateJourneyMode(journeyMode: 'automation' | 'exploration' | 'manual') {
    reduxTemplatedExperienceState.instance.journeyMode = journeyMode;
    if (journeyMode === 'automation') {
      //set slider val percent as automation budget
      reduxTemplatedExperienceState.instance.automationBudget = {
        cutOffPercentage: getSliderEngagementRate(),
      }; //due to an internal bug with recharts subtract one
    } else {
      reduxTemplatedExperienceState.instance.automationBudget = {
        cutOffPercentage: 0
      };
    }
    if(!reduxTemplatedExperienceState.instance?.templateID && metadata.journeyId) {
      reduxTemplatedExperienceState.instance.templateID! = metadata.journeyId;
    }

    const serializedSteps = serializeTriggerData(reduxTemplatedExperienceState.instance.steps);
    reduxTemplatedExperienceState.instance.steps = serializedSteps;
    templatedExperienceAPI.updateTemplatedExperienceInstance(reduxTemplatedExperienceState.instance);

    // if clicked "Use updated model" or "Start smart targeting"
    if(!isEmpty(candidateGraphData) && !isTrainingNewModel && journeyMode === 'automation') {
      aoAPI.deployModel(instance.id).then(() => {
        setChartData(generateDataPoints(candidateGraphData, []));
        setCandidateGraphData([]);
        setActiveGraphData(candidateGraphData);
        setAttributesCandidate([]);
        setAttributes(attributesCandidate);
        setModalProps({
          ..._modalProps,
          attributes: getAttributesForModal(attributesCandidate,[])
        });
        trackEvent({
          category: 'Audience Optimization',
          action: 'Apply Updated Model',
        });
      });
    }

    // if stop optimization was initiated
    if(journeyMode === 'exploration' || journeyMode === 'manual') {
      setActiveGraphData([]);
      if(!isEmpty(activeGraphData) && isEmpty(candidateGraphData)) {
        setCandidateGraphData(activeGraphData);
        setChartData(generateDataPoints([], activeGraphData));
      } else {
        setChartData(generateDataPoints([], candidateGraphData));
      }
      setModalProps({
        ..._modalProps,
        attributes: getAttributesForModal(attributes, attributesCandidate)
      });
      setData({ ...data, engagementRate: 0 });
      setIsEditing(false);
      setSliderValue(0);
      setPrevSliderValue(0);
      setAttributes(undefined);
      if(!isEmpty(attributes) && isEmpty(attributesCandidate)) {
        setAttributesCandidate(cloneDeep(attributes));
      }
    }
  }

  function generateDataPoints(
    optimizationGraph:Array<{pointX:number, pointY:number}>,
    candidateOptimizationGraph:Array<{pointX:number, pointY:number}>
  ) {
    if(!isEmpty(optimizationGraph)) {
      return optimizationGraph?.map((numb:{pointX:number, pointY:number}, idx:number) => {
        let xOptim = 0;
        let yOptim = 0;
        if (candidateOptimizationGraph) {
          const candPoint = candidateOptimizationGraph.find((candItem: { pointX: number, pointY: number }) => candItem.pointX === numb.pointX);
          if (candPoint) {
            xOptim = Math.ceil(candPoint.pointX * 100);
            yOptim = Math.ceil(candPoint.pointY * 100);
          }
        }
        return {
          percentage: idx + 1,
          x: Math.ceil(optimizationGraph?.[idx]?.pointX * 100),
          y: Math.ceil(optimizationGraph?.[idx]?.pointY * 100),
          xOptim,
          yOptim
        }
      });
    }
    if(!isEmpty(candidateOptimizationGraph) && isEmpty(optimizationGraph)) {
      return candidateOptimizationGraph?.map((numb:any, idx:number) => {
        return {
          percentage: idx + 1,
          xOptim: Math.ceil(candidateOptimizationGraph?.[idx]?.pointX * 100),
          yOptim: Math.ceil(candidateOptimizationGraph?.[idx]?.pointY * 100),
        }
      });
    }

  }

  const isInteger = (value: number) => {
      return Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER;
  } 

  //the graph cannot plot values that are 
 //floats, if we get a floaty value we round off to ceil. 
  const getValue = (graphValue: number) => {
    if (graphValue) {
      const _isInteger = isInteger(graphValue);
      if(_isInteger) return graphValue; 
      return Math.ceil(graphValue)
    }
  };

  const showDetails = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsShowDetails(!isShowDetails);
  }

  const renderBar = (val: number = 0, type='current') => {
    let status = 'analytics-performance-optimized-chart__current-bar'
    if(type === 'updated') { status = 'analytics-performance-optimized-chart__updated-bar'; }
    return !!val && <div style={{width: `${val}%`}} className={status}></div>;
  }

  const mergeAttributes = (attributes: Array<Attribute>=[], attributesCandidate: Array<Attribute>=[]) => {
    let candidateArr:Array<Attribute> = [];
    if(!isEmpty(attributesCandidate)) {
      candidateArr = attributesCandidate?.map((attribute: Attribute) => ({
          feature: attribute?.feature,
          candidateIs_regressor: attribute?.isRegressor,
          candidateImportance: attribute?.importance
      }))
    }
    return orderBy(
      merge(
        orderBy(attributes, 'feature'),
        orderBy(candidateArr, 'feature')
      ),
      ['importance','candidateImportance'],
      ['desc','desc']
    );
  }

  const importanceTableData = () => {
    const tableRows: any = [];
    const mergedAttributes = mergeAttributes(attributes, attributesCandidate);
    mergedAttributes?.forEach((attribute: any) => {
      const attrLength = attribute?.feature?.split('.');
      let categoryName = attrLength?.[2] || '';
      let attrName = attrLength?.[3] || '';
      if(attrName === 'query') {
        attrName = attrLength?.[4] || '';
      }
      tableRows.push({
        key: `${attribute.feature}`,
        attribute: <span className={`analytics-performance-optimized-chart__table__attribute ${(attribute?.candidateIs_regressor! || attribute?.isRegressor!) ? '' : 'inactive'}`}>
          {startCase(categoryName)} / <strong>{startCase(attrName)}</strong>
        </span>,
        importance: <div className="analytics-performance-optimized-chart__table__importance">
          <div className="analytics-performance-optimized-chart__table__lines">
            {attribute?.isRegressor ? renderBar(Math.round(attribute.importance ? attribute.importance * 100 : 0), 'current') : !isEmpty(attributes) && <div className="analytics-performance-optimized-chart__omitted-bar">Omitted</div>}
            {attribute?.candidateIs_regressor ? renderBar(Math.round(attribute?.candidateImportance ? attribute?.candidateImportance * 100 : 0),'updated') : !isEmpty(attributesCandidate) && <div className="analytics-performance-optimized-chart__omitted-bar">Omitted</div>}
          </div>
        </div>,
      })
    })
    return tableRows;
  }


  const onGenerateNewModel = async (attrs: Array<Attribute>) => {
    const biasedFeatures:Array<string> = [];
    attrs?.forEach((attr:any) => {
      if(!attr.isRegressor) return biasedFeatures.push(attr.feature);
    });
    reduxTemplatedExperienceState.instance.trainingSettings = {biasedFeatures}

    if(!reduxTemplatedExperienceState.instance?.templateID && metadata.journeyId) {
      reduxTemplatedExperienceState.instance.templateID! = metadata.journeyId;
    }

    const serializedSteps = serializeTriggerData(reduxTemplatedExperienceState.instance.steps);
    reduxTemplatedExperienceState.instance.steps = serializedSteps;
    await templatedExperienceAPI.updateTemplatedExperienceInstance(reduxTemplatedExperienceState.instance).then(() => {
      trackEvent({
        category: 'Audience Optimization',
        action: 'Generate New Model',
      });
    });

    await aoAPI.startTraining(instance?.id).then(() => {
      setModalProps({
        ...modalProps,
        isVisible: false
      });
      setIsTrainingNewModel(true);
    });
  }

  const onCancelModelTraining = async () => {
    await aoAPI.cancelTraining(instance?.id).then(() => {
      setIsTrainingNewModel(false);
    });
  }

  const showAttributesModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setModalProps({
      ...modalProps,
      isVisible: true,
      title: 'Modify attribute factor list',
      statusMessage: <FlightSnackbar
          isVisible={true}
          isFloating={false}
          animation={true}
          content="Removing context attributes as factors will require the model to be re-trained and may take several minutes to generate the new model."
          type="warning"
          isAutoDismiss={false}
          actionName=""
          className="analytics-performance-optimized-chart__modal__warning"
        />,
      primaryButton: {
        name: 'Generate new model',
        onClick: (attr:Array<Attribute>) => onGenerateNewModel(attr),
        disabled: true,
      },
      cancelButton: {
        name: 'Close',
        onClick: () => setModalProps({
          ...modalProps,
          attributes: getAttributesForModal(cloneDeep(attributes), cloneDeep(attributesCandidate)),
          isVisible: false
        })
      },
    });
  }

  const getAttributesAmount = () => {
    if(!isEmpty(attributesCandidate)) {
      return attributesCandidate?.filter((attr:any) => attr?.isRegressor)?.length;
    }
    if(!isEmpty(attributes)) {
      return attributes?.filter((attr:any) => attr?.isRegressor)?.length;
    }
    return 0;
  }


  const renderChart = (chartData:ChartData[], activeGraphData:any, candidateGraphData:any) => {
    return (
      <div className="chart">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={chartData}
            margin={{ top: 50, left: 10, bottom: 20 }}
            onClick={(graphInfo: any) => onGraphClickHandler(graphInfo)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={renderTooltip} />
            <XAxis
              label={{ value: 'Audience reach', position: 'bottom', className: 'label' }}
              dataKey={!isEmpty(activeGraphData) ? 'x' : 'xOptim'}
              tickLine={false}
              ticks={[0, 100]}
              tickFormatter={(e: any) => e > 0 ? e + '%' : ''}
              type={'number'}
              dx={-5}
            />
            <YAxis
              label={{ value: 'Engagement rate', position: 'insideLeft', angle: -90, className: 'label' }}
              ticks={[0, 100]}
              width={80}
              dataKey={!isEmpty(activeGraphData) ? 'y' : 'yOptim'}
              tickFormatter={(e: any) => e + '%'}
              tickLine={false}
              type={'number'}
            />
            <Legend
              align="left"
              verticalAlign="top"
              height={70}
              margin={{ top: 0 }}
              payload={[
                {
                  value: !!activeGraphData?.length && 'Active model',
                  type: 'line',
                  color: activeGraphData?.length ? '#0851DF' : '',
                  id: 'legend1'
                },
                {
                  value: !!candidateGraphData?.length && (activeGraphData?.length ? 'Updated model' : 'Engagement tradeoff frontier'),
                  type: 'line',
                  color: candidateGraphData?.length ? '#FF8800' : '',
                  id: 'legend2'
                },
                { value: (!!metadata?.engagementRateBasesOnRules || !!metadata?.populationReachBasedOnRules) ? 'Target audience' : '', type: 'circle', color: (!!metadata?.engagementRateBasesOnRules || !!metadata?.populationReachBasedOnRules) ? '#A999FF' : '#FFFFFF', id: 'legend3' }
              ]}
            />
            <Area type="monotone" dataKey="y" stroke="#0851DF" fillOpacity={0} className="line" />
            {!isEmpty(candidateGraphData) && <Area type="monotone" dataKey="yOptim" stroke="#FF8800" fillOpacity={0} className="line" />}
            {/* Updated Audience Target Point */}
            <ReferenceDot
              y={getValue(metadata?.engagementRateBasesOnRules)}
              x={getValue(metadata?.populationReachBasedOnRules)}
              r={8}
              fill="#A999FF"
              fillOpacity={0.8}
              stroke="none"
            />
            <ReferenceArea
              x1={0}
              x2={sliderValue}
              y1={0}
              y2={100}
              className="reference-area"
              stroke="black"
              fill="#00AB50"
              fillOpacity={0.1}
              strokeOpacity={0.1}
            />
          </AreaChart>
        </ResponsiveContainer>
        {isTrainingNewModel && <div>
          <div className="chart__overlay"></div>
          <div className="chart__warning">
            <div className={'performance-loading-state__icon'}>{getIcon('loading', {})}</div>
            <div className="chart__warning__text">Updating performance curve</div>
            <FlightButton
              theme="link"
              label={'Cancel'}
              onClick={() => onCancelModelTraining()}
            />
          </div>
        </div>}
      </div>
    );

  }

  const getSliderEngagementRate = () => {
    let engagementRate = instance?.automationBudget?.cutOffPercentage || 0;
    const sliderPoint = chartData?.find((point:ChartData) => point.x === sliderValue || point.xOptim === sliderValue);
    if(sliderPoint) {
      engagementRate = sliderPoint.y || sliderPoint.yOptim;
    }
    return engagementRate;
  }

  const getSliderAudienceReach = () => {
    let audienceReach = 0;
    const engagementRate = getSliderEngagementRate();
    const sliderPoint = chartData?.find((point:ChartData) => point.y === engagementRate || point.yOptim === engagementRate);
    if(sliderPoint) {
      audienceReach = sliderPoint.x || sliderPoint.xOptim;
    }
    return audienceReach;
  }

  if (isLoading) {
    return <Skeleton width={'100%'} height={500}/>
  } else if(reduxTemplatedExperienceState?.instance?.status !== 'Draft' && (!isEmpty(activeGraphData) || !isEmpty(candidateGraphData))) {
    return (
      <div className="analytics-performance-optimized-chart">
        <div className="analytics-performance-optimized-chart__main">
          {instance.journeyMode !== 'automation' && !isEditing && !isTrainingNewModel && (
            <div className="top">
              <FlightButton
                theme="minor"
                iconRight="baselineArrowRight"
                label={!isEmpty(activeGraphData) && !isEmpty(candidateGraphData) && !isTrainingNewModel ? 'Use updated model' : 'Optimize my audience'}
                className="graph-button"
                onClick={() => {
                  const sliderAudienceReach = getSliderAudienceReach();
                  if (sliderAudienceReach === 0) {
                    setSliderValue(50);
                  } else {
                    setSliderValue(sliderAudienceReach);
                  }
                  setIsEditing(true);
                  scrollToCTA();
                }}
              />
            </div>
          )}
          <div className="status">
            {/* If audience optimization has a value */}
            {instance.journeyMode === 'automation' && !isEditing && !isTrainingNewModel && (
              <FlightSnackbar
                className="snackbar"
                isVisible={true}
                isFloating={false}
                type="info"
                content={`Actively optimizing audience targeting to reach a ${getSliderEngagementRate()}% engagement rate`}
                isAutoDismiss={true}
                actionName="Modify"
                action={() => {
                  setIsEditing(true);
                  const sliderAudienceReach = getSliderAudienceReach();
                  if (sliderAudienceReach === 0) {
                    setSliderValue(50);
                    setPrevSliderValue(0);
                  } else {
                    setSliderValue(sliderAudienceReach);
                    setPrevSliderValue(sliderAudienceReach);
                  }
                  scrollToCTA();
                }}
              />
            )}
          </div>
          {renderChart(chartData, activeGraphData, candidateGraphData)}
        </div>
        <div className="analytics-performance-optimized-chart__control">
          {isEditing && (
            <div className="range-slider">
              <Slider
                ValueLabelComponent={SliderLabelComponent}
                onChange={handleSliderChange}
                min={0}
                max={100}
                value={sliderValue}
                aria-label="slider"
              />
            </div>
          )}
          {isEditing && (
            <div className="button-actions">
              <div>
                <FlightButton
                  theme="secondary"
                  label={'Cancel'}
                  onClick={() => {
                    setIsEditing(false);
                    setSliderValue(prevSliderValue);
                  }}
                />
                <FlightButton
                  label={`Start smart targeting for ${getSliderEngagementRate()}% engagement rate`}
                  onClick={() => {
                    setIsEditing(false);
                    setPrevSliderValue(sliderValue);
                    updateJourneyMode('automation');
                  }}
                />
              </div>
              {instance?.journeyMode === 'automation' && (
                <FlightButton
                  type="warning"
                  label={'Stop smart targeting'}
                  className="remove-button"
                  onClick={() => {
                    updateJourneyMode(instance?.steps?.[0]?.seedRuleStringRepresentation ? 'exploration' : 'manual');
                  }}
                />
              )}
            </div>
          )}
        </div>
        {(!isEmpty(attributes) || !isEmpty(attributesCandidate)) && !isTrainingNewModel && <a href="/"
          className="analytics-performance-optimized-chart__targeting-details"
          onClick={showDetails}
        >
          <RightArrowBlue className={isShowDetails ? 'arrow-right active' : 'arrow-right'}/>
          {isShowDetails ? 'Hide' : 'Show'} targeting details
        </a>}
        {isShowDetails && (!isEmpty(attributes) || !isEmpty(attributesCandidate)) && !isTrainingNewModel && <div>
          <div className="analytics-performance-optimized-chart__details-description">
            What factors are the smart targeting model taking into account
            when positioning this experience to within the restricted audience?
          </div>
          <div className="analytics-performance-optimized-chart__suggested-model">
            {!isEmpty(attributes) && <>
              <div className="analytics-performance-optimized-chart__suggested-model__marker-current"></div>
              <span>Current targeting model</span>
            </>}
            {!isEmpty(attributesCandidate) && <>
                <div className={`analytics-performance-optimized-chart__suggested-model__marker-updated ${!isEmpty(attributes) && 'margin-left'}`}></div>
                <span>Updated targeting model</span>
            </>}
          </div>
          <div className="analytics-performance-optimized-chart__table-container">
            <FlightTable
              className="analytics-performance-optimized-chart__table"
              tableHeaders={[{
                key: 'attribute',
                name: 'Context attributes',
                isVisible: true,
                isSortable: true
              }, {
                key: 'importance',
                name: 'Importance factor (%)',
                isVisible: true,
                isSortable: true
              }]}
              tableData={importanceTableData()}
              isLoading={false}
              loadingRowNumber={2}
              hasError={false}
              hasPaginationAfterTable={false}
              hasPaginationBeforeTable={false}
            />
            <div className="analytics-performance-optimized-chart__attr-info-section">
              <div className="analytics-performance-optimized-chart__attr-info">
                <span>
                  <span
                    className="analytics-performance-optimized-chart__attr-amount"
                    onClick={showAttributesModal}
                  >{getAttributesAmount()}</span>
                  Context attributes
                </span>
              </div>
              <div className="analytics-performance-optimized-chart__attr-descr">
                <div>
                  Were taking into account when determining what types of customers
                  are most likely to engage with your experience.
                </div>
                <div>
                  You can omit attributes that you do not want to be factored in <a href="/" onClick={showAttributesModal}>here</a>.
                </div>
              </div>
            </div>
          </div>
        </div>}
       <AttributesModal {...modalProps} />
      </div>);
    } else {
      return <div className="no-data">
        <div className="analytics-performance-optimized-chart__no-data__center">
          <p>Check back soon as we are gathering the data for Smart Targeting.</p>
          <p>A minimum of <span>{reduxTemplatedExperienceState?.instance?.explorationBudget?.populationSize || 100}</span> qualified user responses are required to generate accurate results.</p>
        </div>
      </div>
    }
}

function SliderLabelComponent(props: any) {
  const { children, value } = props;
  return (
    <MaterialTooltip open={false} enterTouchDelay={0} placement="bottom" title={value + '% Audience reach'}>
      {children}
    </MaterialTooltip>
  );
}

export const decodeURIComponentSafe = (s: string) => {
  if (!s) {
    return s;
  }
  if (s.includes('%')) {
    const _s = s.replace(/%/g, '%25');
    try {
      decodeURIComponent(_s);
    } catch (e) {
      return s;
    }
  }
  try {
    return decodeURIComponent(s);
  } catch (e) {
    return s;
  }
};

import { TEAContentEngagementAnalytics, ComponentEngagement } from 'interface/templated-experience/analytics.interface';
import { AnalyticsContentEngagmentState, AnalyticsContentEngagementActionType } from './content-engagement.type';

const initialState: AnalyticsContentEngagmentState = {
  records: { data: [] },
  isLoading: false,
  errorMessage: ''
};

type ActionType = {
  type: string,
  payload: TEAContentEngagementAnalytics,
};

export default (state: AnalyticsContentEngagmentState = initialState, action: ActionType) => {
  switch (action.type) {
    case AnalyticsContentEngagementActionType.RESET_CONTENT_ENGAGEMENT_DATA:
      return {
        ...state,
        records: { data: [] },
      };
    case AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_DATA:
      let contentKey = 1;
      action.payload.data.forEach(content => {
        content.key = contentKey++;
        const componentsMap = new Map<string, ComponentEngagement>();
        content.components.forEach(component => {
          if (componentsMap.get(component.componentId) !== undefined) {
            componentsMap.get(component.componentId)!.elementName += ` / ${component.elementName}`;
          } else {
            componentsMap.set(component.componentId, component);
          }
        });
        content.components = Array.from(componentsMap, ([_, value]) => value);
      });
      return {
        ...state,
        records: { data: state.records.data.concat(action.payload.data || []) },
      };
    case AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case AnalyticsContentEngagementActionType.SET_CONTENT_ENGAGEMENT_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return {
        ...state
      };
  }
};

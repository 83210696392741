import React from 'react';
import { ReactComponent as EmptyAdd } from 'assets/icons/empty_add_content.svg';
import './MultiActionPushPreview.scss';

export default function MultiActionPushPreview(props: any) {
  return (
    <div className="multiaction-push-preview">
      {props.title || props.message ? (
        <div className="multiaction-push-preview__preview-body">
          <div className="multiaction-push-preview__preview-body__header">
            <img
              src={require('assets/icons/your-app-icon.svg')}
              alt=""
              className="multiaction-push-preview__preview-body__your-app-icon"
            />{' '}
            Your app
          </div>
          <div>
            <br />
            <div className="multiaction-push-preview__preview-body__title">{props.title}</div>
            <div className="multiaction-push-preview__preview-body__body">{props.message}</div>
          </div>
        </div>
      ) : props.placement === 'box' ? (
        <div className="multiaction-push-preview__preview-body__empty-state">
          <EmptyAdd className="multiaction-push-preview__preview-body__empty-state__icon" />
          <div className="multiaction-push-preview__preview-body__empty-state__icon__body">None</div>
        </div>
      ) : null}
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import './ControlBar.scss';
import { FlightButton, FlightTag, FlightTextInput, FlightToggleSwitch } from '@flybits/webapp-design-system-react';
import { defineStatusStyle } from 'helpers/templated-experience.helper';
import { TExperienceInstance } from 'interface/templated-experience/templated-experience.interface';
import ScheduleInline from 'components/ScheduleInline/ScheduleInline';
import IncompleteCounter from 'components/IncompleteCounter/IncompleteCounter';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from 'components/Modal/ConfirmModal/ConfirmModal';
import { trackEvent } from 'helpers/analytics.helper';
import useActions from 'hooks/useActions';
import * as TExperienceActions from 'redux/templated-experience/templated-experience.action';
import SearchBarPage from 'components/Shared/SearchBarPage/SearchBarPage';
import { cloneDeep } from 'lodash';
import useFeatureFlag from "hooks/useFeatureFlag";
import { setTemp } from 'redux/templated-experience/templated-experience.action';
import useClickOutsideDetect from 'hooks/useClickOutsideDetect';

interface IProps {
  name?: string;
  status?: string;
  onLaunch?: () => void;
  onLaunchDisabled?: boolean;
  showSearchBar?: boolean;
  handleSearchInput?: (value: string) => void;
  onSave?: () => void;
  onSaveDisabled?: boolean;
  onCancel?: () => void;
  onDraft?: (schedule: TExperienceInstance['schedule']) => void;
  onDraftDisabled?: boolean;
  onDeactivate?: () => void | undefined;
  onEdit?: (id: string) => void;
  onNameEdit?: (name: string) => void;
  showScheduleModal?: () => void | undefined;
  experience?: TExperienceInstance | undefined;
  template?: TExperienceInstance | undefined;
  count?: () => void | undefined;
  onIncompleteHandle?: () => void | undefined;
  actionTab?: any;
  settingsTab?: any;
  performanceTab?: any;
  onTabSwitch?: (isPerformanceTab: boolean) => void | undefined;
  isShowPerformanceTab?: boolean;
  children?: any;
  nameHasError?: string;
  searchPlaceHolderText?: string;
}

export default function ControlBar(props: IProps) {
  const [selectedTab, setSelectedTab] = useState('');
  const [isNameEditing, setIsNameEditing] = useState(false);
  const inputRef = useRef<any>();
  const { flags } = useFeatureFlag();
  const dispatch = useDispatch();
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const confirmModalProps = {
    isVisible: false,
    icon: 'warning.svg',
    title: 'You have unsaved changes',
    content: 'If you leave this tab, all unsaved changes made will be lost',
    primaryButton: {
      name: 'Save changes',
      onClick: () => {},
      disabled: false,
    },
    cancelButton: {
      name: 'Discard changes',
      onClick: () => {},
    },
  };
  const [modalProps, setModalProps] = useState<any>(confirmModalProps);
  useClickOutsideDetect(inputRef, handleOutsideClick);

  useEffect(() => {
    const input = document.querySelector('input');
    if ((props.template && (props.status === 'Draft' || props.status === '')) || props.nameHasError) {
      input?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.status, props.nameHasError, props.onDraft, props.onSave, props.onLaunch]);

  useEffect(() => {
    if(props.actionTab && !selectedTab) setSelectedTab('first');
    // return ()=>setSelectedTab('');
  },[props.actionTab, selectedTab]);

  function handleOutsideClick() {
    setIsNameEditing(false);
  }

  function onModalConfirm() {
    let status = reduxTemplatedExperienceState?.instance?.status;
    let schedule = reduxTemplatedExperienceState?.instance?.schedule!;
    if (status === 'Active') {
      reduxTemplatedExperienceAction.updateInstanceSchedule({ schedule }).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Change schedule on Active and Save',
        });
        setModalProps({ ...modalProps, isVisible: false });
        setSelectedTab('second');
      });
    } else {
      reduxTemplatedExperienceAction.updateTemplatedInstance({ status }).then(() => {
        trackEvent({
          category: 'Templated Experiences',
          action: 'Edit and Save',
        });
        setModalProps({ ...modalProps, isVisible: false });
        setSelectedTab('second');
      });
    }
  }

  function onModalDiscard() {
    reduxTemplatedExperienceState.isModified = false;
    reduxTemplatedExperienceAction.setInstance({
      instance: cloneDeep(reduxTemplatedExperienceState.initial.instance)
    }); // set initial instance
    setModalProps({ ...modalProps, isVisible: false });
    setSelectedTab('second');
  }

  function onChangeTab(e: React.MouseEvent, tab: string) {
    e?.preventDefault();
    if (reduxTemplatedExperienceState.isModified && tab === 'second') {
      setModalProps({
        ...modalProps,
        isVisible: true,
        primaryButton: {
          ...modalProps.primaryButton,
          onClick: () => onModalConfirm(),
        },
        cancelButton: {
          ...modalProps.cancelButton,
          onClick: () => onModalDiscard(),
        },
      });
    } else {
      setSelectedTab(tab);
    }
    props.onTabSwitch && props.onTabSwitch(tab === 'second');
  }

  const onChangeView = () => {
    const viewMode = reduxTemplatedExperienceState?.temp?.viewMode || '';
    dispatch(setTemp({...reduxTemplatedExperienceState.temp, viewMode: viewMode !== 'journey' ? 'journey': ''}));
  }

  return (
    <div className="control-bar">
      <div className="control-bar__header">
        {props.showSearchBar && props.handleSearchInput && (
          <div className="control-bar__header-left__search">
            <SearchBarPage
              className="control-bar__header-left__search__name"
              handleSearchTermChange={props.handleSearchInput}
              placeholder={props.searchPlaceHolderText}
              width={'503px'}
            />
          </div>
        )}
        <div className="control-bar__header-left">
          {isNameEditing && props.onNameEdit ? (
            <div ref={inputRef}>
              <FlightTextInput
                type="text"
                hasError={!!props.nameHasError}
                errorMessage={props.nameHasError}
                value={props.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onNameEdit!!(e.target.value)}
                placeholderText="Untitled"
                className="control-bar__header-left__name"
                autoFocus={isNameEditing}
              />
            </div>
          ) : <div className="control-bar__header-left__title" onClick={()=>setIsNameEditing(true)}>{props.name}</div>}
        </div>
        <div className="control-bar__header-right">
          {flags['tx_journey_builder'] && selectedTab === 'first' && <FlightToggleSwitch
            label="Journey View"
            checked={reduxTemplatedExperienceState?.temp?.viewMode === 'journey'}
            className="control-bar__toggle-switch"
            onChange={onChangeView}
          />}
          {props.status && (
            <p className="control-bar__status">
              Status:{' '}
              <FlightTag
                type={defineStatusStyle(props.status)}
                label={props.status}
                className="control-bar__status__text"
              />
            </p>
          )}
          {props.onCancel && (
            <FlightButton
              label="Cancel"
              theme="secondary"
              size="large"
              onClick={() => {
                props.onCancel!!();
              }}
            />
          )}
          {props.onDraft && props.status === 'Draft' && (
            <FlightButton
              label="Save as draft"
              type="secondary"
              size="large"
              disabled={props.onDraftDisabled}
              onClick={(schedule) => props.onDraft!!(schedule)}
            />
          )}
          {props.onSave && (selectedTab === 'first' || selectedTab === 'third') && (
            <FlightButton label="Save" size="large" disabled={props.onSaveDisabled} onClick={() => props.onSave!!()} />
          )}
          {props.onDeactivate && (
            <FlightButton label="Deactivate" size="large" onClick={() => props.onDeactivate!!()} />
          )}
          {props.onLaunch && (
            <FlightButton
              label="Launch"
              size="large"
              disabled={props.onLaunchDisabled}
              onClick={() => props.onLaunch!!()}
            />
          )}
          {props.onEdit && <FlightButton label="Edit" size="large" onClick={props.onEdit} />}
        </div>
      </div>
      {selectedTab && (
        <div className="control-bar__tab">
          <div className="tab border">
            <div className="tab__content">
              <div className="tab__content-link">
                <a
                  href="/"
                  className={(!props.isShowPerformanceTab && selectedTab === 'first') ? 'selected' : ''}
                  onClick={(e) => onChangeTab(e,'first')}
                >
                  Actions
                </a>
                {props.performanceTab && (
                  <a
                    href="/"
                    className={(props.isShowPerformanceTab || selectedTab === 'second') ? 'selected' : ''}
                    onClick={(e) => onChangeTab(e,'second')}
                  >
                    Performance
                  </a>
                )}
                {flags['tx_settings_tab'] && (
                  <a
                    href="/"
                    className={(!props.isShowPerformanceTab && selectedTab === 'third') ? 'selected' : ''}
                    onClick={(e) => onChangeTab(e,'third')}
                  >
                    Settings
                  </a>
                )}
              </div>
              <div className={`underline ${!props.performanceTab && selectedTab === 'third' ? 'second' : selectedTab}`}> </div>
            </div>
          </div>
        </div>
      )}
      <div className={`control-bar__main ${selectedTab ? 'spacing-xl' : ''}`}>
        {selectedTab === 'first' && props.actionTab && (
          <>
            {props.showScheduleModal && (
              <>
                <ScheduleInline
                  showScheduleModal={props.showScheduleModal}
                  experience={props.experience!! || props.template!!}
                />
                <IncompleteCounter count={props.count} onClickHandle={props.onIncompleteHandle} />
              </>
            )}
            {props.actionTab}
          </>
        )}
        {selectedTab === 'second' && props.performanceTab}
        {selectedTab === 'third' && flags['tx_settings_tab'] && props.settingsTab}
        {props.children}
      </div>
      <ConfirmModal {...modalProps} setModalProps={setModalProps} />
    </div>
  );
}

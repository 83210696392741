import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import ControlBar from 'components/ControlBar/ControlBar';
import Skeleton from 'react-loading-skeleton';
import PushIcon from 'assets/icons/push.svg';
import InAppIcon from 'assets/icons/in-app.svg';
import './TemplateSelection.scss';
import UseAsyncState from 'hooks/useAsyncState';
import { times } from 'lodash';
import { trackEvent } from 'helpers/analytics.helper';
import queryString from 'query-string';
import MultiStepTemplatedExperienceAPI from 'services/api/multistep-templated-experience.api';
import useFeatureFlag from 'hooks/useFeatureFlag';

interface TemplateSelection {
  audienceOptimizationEligible: boolean;
  automationBudget: { populationSize: number };
  createdAt: number;
  desc: string;
  explorationBudget: { populationSize: number };
  icon: string;
  id: string;
  journeyMode: string;
  name: string;
  rootStepID: string;
  steps: any[];
  tenantID: string;
  updatedAt: number;
  isMulti?: boolean;
}

enum IconTypes {
  'push.svg',
  'targeted.svg',
  'triggerreminder.svg',
  'trigger.svg',
}

export default function TemplateSelection(props: any) {
  const history = useHistory();
  const api = new TemplatedExperienceAPI();
  const multiStepTemplatedExperienceAPI = new MultiStepTemplatedExperienceAPI();
  const { flags } = useFeatureFlag();

  const [arrData, isLoading, isError] = UseAsyncState(() => api.getTemplatedExperiences().then((r) => r.data));
  const queryParams = queryString.parse(props.location?.search);

  const _handleSelectTemplate = (template: TemplateSelection) => {
    trackEvent({
      category: 'Templated Experiences',
      action: 'Select Template',
      label: template.name,
    });
    if (template.isMulti) {
      history.push(`/multi${history.location.pathname}create/${template.id}`);
      return;
    }
    history.push(
      `/templated-experiences/create/?template_id=${template.id}${
        queryParams ? '&' + queryString.stringify(queryParams) : ''
      }`,
    );
  };

  const handleCancel = () => {
    history.push(`/${history.location.pathname}${queryParams ? '/?' + queryString.stringify(queryParams) : ''}`);
  };

  function actionIcons(template: any) {
    return template?.steps[0]?.actions?.map((action: any, idx: number) => {
      if (action.actionType === 'push') {
        return (
          <div
            className="template-selection__container__block__template-item__text-box__elements__element"
            key={`icon_${idx}`}
          >
            <img src={PushIcon} alt="PushIcon" className="flow-box__card-container__card__step-header__question" />
            <div>Notification</div>
          </div>
        );
      } else if (action.actionType === 'experience') {
        return (
          <div
            className="template-selection__container__block__template-item__text-box__elements__element"
            key={`icon_${idx}`}
          >
            <img src={InAppIcon} alt="InAppIcon" className="flow-box__card-container__card__step-header__question" />
            <div>In-app content</div>
          </div>
        );
      } else if (action.actionType === 'multi') {
        return (
          <div
            className="template-selection__container__block__template-item__text-box__elements__element"
            key={`icon_${idx}`}
          >
            <img src={InAppIcon} alt="InAppIcon" className="flow-box__card-container__card__step-header__question" />
            <div>MultiStep</div>
          </div>
        );
      } else {
        return null;
      }
    });
  }

  const [multiStepTemplates, setMultiStepTemplates] = useState<any>([]);
  useEffect(() => {
    if (flags['tx_fis_demo']) {
      // check feature flag
      multiStepTemplatedExperienceAPI.getTemplates().then((templates) => {
        setMultiStepTemplates(templates);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let combinedArray: any;
  if (multiStepTemplates) {
    let _transformMultiToSingleInstance: TemplateSelection[] = [];
    multiStepTemplates.map((x: { id: string; description: string; name: string; icon: string }) => {
      return _transformMultiToSingleInstance.push({
        id: x.id,
        desc: x.description,
        name: x.name,
        icon: x.icon,
        steps: [
          {
            actions: [
              {
                actionType: 'multi',
              },
            ],
          },
        ],

        tenantID: '',
        explorationBudget: { populationSize: 1000 },
        journeyMode: '',
        createdAt: 99999,
        updatedAt: 99999,
        audienceOptimizationEligible: false,
        rootStepID: '',
        automationBudget: { populationSize: 1000 },
        isMulti: true,
      });
    });
    combinedArray = arrData?.concat(_transformMultiToSingleInstance);
  } else {
    combinedArray = arrData;
  }
  
  const GetIconImg = (args: any) => {
    if (args?.icon in IconTypes) {
      return (
        <img
          src={require(`assets/images/${args?.icon ? args?.icon : 'image-placeholder.jpg'}`)}
          className="template-selection__container__block__template-item__image-box__img"
          alt={'icon-img-placeholder'}
        />
      );
    } else {
      return (
        <img
          src={args?.icon}
          className="template-selection__container__block__template-item__image-box__img"
          alt={'icon-img-plcehldr'}
        />
      );
    }
  };

  return (
    <ControlBar onCancel={handleCancel}>
      <div className="template-selection">
        <div className="template-selection__container">
          <div className="template-selection__container__block">
            <div className="template-selection__container__block__title"> Select a Template: </div>
            <div className="template-selection__container__block__list">
              {combinedArray && combinedArray.length ? (
                combinedArray.map((template: any, idx: number) => (
                  <button
                    className="template-selection__container__block__template-item"
                    key={`template_${idx}`}
                    onClick={() => _handleSelectTemplate(template)}
                  >
                    <div className="template-selection__container__block__template-item__image-box">
                      <GetIconImg icon={template.icon} />
                    </div>
                    <div className="template-selection__container__block__template-item__text-box">
                      <div className="template-selection__container__block__template-item__text-box__title">
                        {template.name}
                      </div>
                      <div className="template-selection__container__block__template-item__text-box__description">
                        {template.desc}
                      </div>
                      <div className="template-selection__container__block__template-item__text-box__content">
                        Eligible Audience will be sent:
                      </div>
                      <div className="template-selection__container__block__template-item__text-box__elements">
                        {actionIcons(template)}
                      </div>
                    </div>
                  </button>
                ))
              ) : isLoading ? (
                times(3, (i) => <Skeleton key={i} width="115%" height={200} />)
              ) : isError ? (
                <div className="template-selection__container__block__list__error">Error: {isError}</div>
              ) : (
                <div className="template-selection__container__block__list__empty">There are no templates yet</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ControlBar>
  );
}

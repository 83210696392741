import React from 'react';
import { Position } from 'react-flow-renderer';
import Handler from 'components/Journey/Handler/Handler';
import './Start.scss';


export default function Start() {

  return (
    <button
      className="start"
      onClick={(e) => e.preventDefault()}
    >
      <div className="start__body">
        <div>START</div>
      </div>
      <Handler action="launched" type="source" position={Position.Right} />
    </button>
  );
}

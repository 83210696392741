
import { PluginActionType, PluginState } from 'redux/plugin/plugin.type';

let initialState: PluginState = {
  plugins: null,
}

export default (state: PluginState = initialState, action: any) => {
  switch (action.type) {
    case PluginActionType.SET_PLUGINS:
      return {
        ...state,
        plugins: action.payload.plugins
      }
    default:
      return {
        ...state
      };
  }
};

import React, { useState, useEffect } from 'react';

import { Content } from 'interface/content/content.interface';
import EditModal from 'components/Modal/EditModal/EditModal';

import { useSelector } from 'react-redux';

import './ContentEditorModal.scss';
import { ReactComponent as TextLinkIcon } from 'assets/icons/textlink.svg';
import { ReactComponent as ButtonLinkIcon } from 'assets/icons/buttonlink.svg';
import { ReactComponent as TemplateIcon } from 'assets/icons/template.svg';

import ImgPlaceholder from 'assets/icons/cam-placeholder.svg';
import { FlightImagePicker, FlightTooltip } from '@flybits/webapp-design-system-react';

import ContentForm from './ContentForm';

interface IProps {
  isVisible: boolean;
  onCancel: () => void;
  title: string;
  content: Content | undefined;
  onNext: (args: {}) => void;
}

function contentType(type: string | undefined) {
  switch (type) {
    case 'concierge-card-link':
      return (
        <span className="content-edit-modal__content__type">
          Content Type: <TextLinkIcon />{' '}
          <span className="content-edit-modal__content__type__name"> Text Link Card</span>
        </span>
      );
    case 'concierge-card-buttons':
      return (
        <span className="content-edit-modal__content__type">
          Content Type: <ButtonLinkIcon />{' '}
          <span className="content-edit-modal__content__type__name"> Button Link Card</span>
        </span>
      );
    default:
      return (
        <span className="content-edit-modal__content__type">
          Content Type: <TemplateIcon />
          <span className="content-edit-modal__content__type__name"> Custom Content Template</span>
        </span>
      );
  }
}
export default function ContentEditorModal(props: IProps) {
  const [formValues, setFormValues] = useState<Array<string>>([]);
  const [isImagePickerVisible, setImagePickerVisible] = useState(false);
  const [iconUrl, setIconurl] = useState<string | undefined>('');
  const [isEditorVisisble, setEditorVisible] = useState(false);
  const [isDisable, setDisableSave] = useState(true);

  const token = useSelector((state: any) => state.auth.token);

  const getFormValues = (_formValues: string[]) => {
    setFormValues(_formValues);
  };

  const disableSave = (_isValid: boolean) => {
    setDisableSave(_isValid);
  };

  const handleNext = () => {
    const _labels = [{ ...formValues, id: props.content?.id, iconUrl: iconUrl }];
    props.onNext(_labels);
  };

  const toggleImagePicker = () => {
    if (isImagePickerVisible) {
      setTimeout(() => setEditorVisible(true), 100);
    } else {
      setTimeout(() => setEditorVisible(false), 100);
    }
    setImagePickerVisible((isImagePickerVisible) => !isImagePickerVisible);
  };

  const removeIcon = () => {
    setIconurl(undefined);
  };

  const handleSelectedImage = (img: any) => {
    setIconurl(img.url);
  };

  useEffect(() => {
    if (props.isVisible !== isEditorVisisble) {
      setEditorVisible(props.isVisible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  useEffect(() => {
    setIconurl(props.content?.iconUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!props.isVisible) {
      setIconurl(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  return (
    <>
      <FlightImagePicker
        isVisible={isImagePickerVisible}
        toggleImagePicker={toggleImagePicker}
        pixelPusherUrl={process.env.REACT_APP_PIXELHOST_URL}
        fileManagerUrl={`${process.env.REACT_APP_API_URL}/kernel/file-manager`}
        token={token}
        selectedImage={(img) => handleSelectedImage(img)}
      />
      <EditModal
        size="small"
        icon="in-app.svg"
        title={props.title}
        isVisible={isEditorVisisble}
        className="content-edit-modal"
        primaryButton={{
          name: 'Next',
          onClick: () => handleNext(),
          disabled: isDisable,
        }}
        cancelButton={{
          name: 'Cancel',
          onClick: props.onCancel,
        }}
      >
        <div className="content-edit-modal__content">
          <div>{contentType(props?.content?.type)}</div>
          <div className="content-edit-modal__content__icon">
            {iconUrl ? (
              <FlightTooltip
                direction="right"
                description={
                  <div>
                    <img
                      src={iconUrl ? iconUrl : ImgPlaceholder}
                      alt="content-icon-img"
                      className="content-edit-modal__content__icon__img-tool-tip"
                    />
                  </div>
                }
              >
                <img
                  src={iconUrl ? iconUrl : ImgPlaceholder}
                  alt="content-icon-img"
                  className="content-edit-modal__content__icon__img"
                />
              </FlightTooltip>
            ) : (
              <img src={ImgPlaceholder} alt="content-icon-img" className="content-edit-modal__content__icon__img" />
            )}
            {iconUrl ? (
              <>
                <span className="content-edit-modal__content__icon__options" onClick={toggleImagePicker}>
                  Edit
                </span>
                <span className="content-edit-modal__content__icon__options" onClick={removeIcon}>
                  Remove
                </span>
              </>
            ) : (
              <span className="content-edit-modal__content__icon__options" onClick={toggleImagePicker}>
                + Add an Image
              </span>
            )}
          </div>
          <ContentForm formValues={getFormValues} isValid={disableSave} content={props?.content} />
        </div>
      </EditModal>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from 'redux/store';
import { capitalize, isEmpty } from 'lodash';

import Pages from 'pages';
import { ReactComponent as RectangleSeperator } from 'assets/icons/rectangle.svg';
import FlybitsLogo from 'assets/images/flybits-logo.png';

import GoogleAnalyticsTracker from 'components/GoogleAnalyticsTracker/GoogleAnalyticsTracker';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { FlightButton, FlightNav, FlightDropdown } from '@flybits/webapp-design-system-react';
import SnackBar from 'components/SnackBar/SnackBar';

import './App.scss';
import Notifications from 'components/Notifications/Notifications';
import useFeatureFlag from 'hooks/useFeatureFlag';
import Session from 'services/session';
import SettingAPI from 'services/api/setting.api';
import UseAsyncState from 'hooks/useAsyncState';
import ThemeSwitcher from 'react-css-vars';

export default function App() {
  const user = useSelector((state: any) => state.auth.user);
  const session = new Session();
  const { flags } = useFeatureFlag();
  const settingsApi = new SettingAPI();

  const [themeData, isThemeDataLoading] = UseAsyncState(
    () => settingsApi.getThemeSettings().then((r) => r.settings),
    [],
  );
  let logoUrl;
  let appName;
  let appNameTextColor: string;
  let navbarBgColor: string = '#ffffff';

  const handleSignOut = async () => {
    await session.unauthorizeUser();
    window.location.replace(process.env.REACT_APP_SSO_URL || '');
  };

  const devPortalRedirect = () => {
    const projectId = history.location.pathname.slice('/project/'.length);
    window.open(`${process.env.REACT_APP_DEV_PORTAL_URL}/project/${projectId}`);
  }

  if (themeData && !isThemeDataLoading) {
    const _themeData = JSON.parse(themeData?.themeData) || '';
    if (!isEmpty(_themeData.logoUrl)) {
      logoUrl = _themeData.logoUrl;
    }
    if (!isEmpty(_themeData.appName)) {
      appName = _themeData.appName;
    }
    if (!isEmpty(_themeData.appNameTextColor)) {
      appNameTextColor = _themeData.appNameTextColor;
    }
    if (!isEmpty(_themeData.navbarBgColor)) {
      navbarBgColor = _themeData.navbarBgColor;
    }
  }

  const [theme, setTheme] = useState({
    navbarBgColor: '',
    appNameTextColor: '',
  });

  useEffect(() => {
    setTheme({
      navbarBgColor: navbarBgColor,
      appNameTextColor: appNameTextColor,
    });
    //eslint-disable-next-line
  }, [isThemeDataLoading]);

  return (
    <Router history={history}>
      <ThemeSwitcher theme={theme}>
        <GoogleAnalyticsTracker />
        <div className="app">
          <FlightNav
            user={user}
            project={{
              id: 'id',
              name: appName || process.env.REACT_APP_NAME,
              environment: capitalize(process.env.REACT_APP_ENV),
              image: <img alt="logo" src={logoUrl || FlybitsLogo} />,
            }}
            hasSidebar={false}
            actionables={
              <>
                <FlightDropdown
                  trigger={
                    <FlightButton
                      iconLeft="circleApps"
                      onClick={() => undefined}
                      isPropagateUpperActions
                      theme="minor"
                      label=""
                      ariaLabel=" "
                    />
                  }
                >
                  <FlightButton
                    className="redirect-project-settings"
                    onClick={devPortalRedirect}
                    iconRight="openInNew"
                    label="Project Settings"
                    size="small"
                    theme="minor"
                  />
                </FlightDropdown>
                {flags['tx_ao_notifications'] && (
                  <div>
                    <Notifications />
                    <RectangleSeperator />
                  </div>
                )}
              </>
            }
            handleSignOut={handleSignOut}
          >
            <div className="app__main">
              {/* TODO: Use ErrorBoundary Component to render fallback component on error
                  link: https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
              */}
              <Switch>
                {/* /redirect route is the initial entry point to this application from other external websites */}
                <Route exact path="/redirect" component={Pages.RedirectIndex} />
                <Route exact path="/developer-hub" component={Pages.DeveloperHubIndex} />
                <Route exact path="/no-access" component={Pages.NoAccess} />

                <PrivateRoute exact path="/" component={Pages.HomeIndex} />
                <PrivateRoute exact path="/project/:pid" component={Pages.TemplatedExperienceIndex} />
                <PrivateRoute exact path="/project/:pid/template-selection" component={Pages.TemplateSelection} />
                <PrivateRoute exact path="/project/:pid/library-selection" component={Pages.LibrarySelection} />
                <PrivateRoute exact path="/project/:pid/create" component={Pages.TemplatedExperienceCreate} />
                <PrivateRoute exact path="/project/:pid/edit/:id" component={Pages.TemplatedExperienceEdit} />
                <PrivateRoute exact path="/project/:pid/view/:id" component={Pages.TemplatedExperienceView} />
                <PrivateRoute exact path="/project/:pid/theme" component={Pages.ThemeHub} requiredFlag="tx_theme_settings" />

                {/* Audience Opitmization Demo */}
                <PrivateRoute exact requiredFlag="ao_demo" path="/ao/templated-experiences" component={Pages.AOIndex} />
                <PrivateRoute
                  exact
                  requiredFlag="ao_demo"
                  path="/ao/project/:pid/template-selection"
                  component={Pages.AOSelect}
                />
                <PrivateRoute
                  exact
                  requiredFlag="ao_demo"
                  path="/ao/project/:pid/create/:id"
                  component={Pages.AOCreate}
                />
                <PrivateRoute exact requiredFlag="ao_demo" path="/ao/project/:pid/edit/:id" component={Pages.AOEdit} />

                {/* Multi-Step Journey Demo */}
                <PrivateRoute exact path="/multi/project/:pid" component={Pages.MultiIndex} />
                <PrivateRoute exact path="/multi/project/:pid/template-selection" component={Pages.MultiSelect} />
                <PrivateRoute exact path="/multi/project/:pid/create/:id" component={Pages.MultiCreate} />
                <PrivateRoute exact path="/multi/project/:pid/edit/:id" component={Pages.MultiEdit} />
              </Switch>
              <SnackBar />
            </div>
          </FlightNav>
        </div>
      </ThemeSwitcher>
    </Router>
  );
}

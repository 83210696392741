
import React from 'react';
import './FullPageOverlay.scss';


export default function FullPageOverlay(props: any) {
  let className = "full-page-overlay";
  className += props.isVisible ? ' visible' : ' hidden';
  return (
    <div className={className}>
      {props.isVisible && (
        props.children
      )}
    </div>
  )
}


import React from 'react';
import { FlightModal, FlightButton } from '@flybits/webapp-design-system-react';
import './EditModal.scss';

interface IFormData {
  data: object;
  isValid: boolean;
}

interface IProps {
  size: string;
  isVisible: boolean;
  icon?: string | '';
  title: string;
  children?: React.ReactNode;
  className?: string;
  primaryButton: {
    name: string,
    onClick: (formData: any) => void,
    disabled: boolean,
    loading?: boolean,
  };
  secondaryButton?: {
    name?: string,
    onClick?: () => void | undefined,
    disabled?: boolean,
  };
  cancelButton?: {
    name: string | undefined,
    onClick: () => void | undefined,
  };
  style?: any;
}

export default function Modal(props: IProps) {
  return (
    <FlightModal
      // cast down to large until supported in design system
      size={props.size === 'xlarge' ? 'large' : props.size}
      isVisible={props.isVisible}
      className={props.className}
      scrollable={true}
      toggleModalShown={() => props.cancelButton?.onClick()}
      header={
        <div className="modal__header">
          {props.icon && (
            <img src={require(`assets/icons/${props.icon}`)} className="modal__header-icon" alt="modal icon"/>
          )}
          <h3> {props.title} </h3>
        </div>
      }
      content={
        <div className="modal">
          <div className="modal__content">
            {props.children}
          </div>
        </div>
      }
      footer={
        <div className="modal__footer">
          {props.cancelButton?.name && (
            <FlightButton
              theme="secondary"
              onClick={() => props.cancelButton?.onClick()}
              label={props.cancelButton?.name}
            />
          )}
          {props.secondaryButton && (
            <FlightButton
              type="secondary"
              onClick={() => props.secondaryButton?.onClick!!()}
              label={props.secondaryButton.name}
            />
          )}
          {props.primaryButton && (
            <FlightButton
              onClick={(formData:any) => props.primaryButton.onClick(formData)}
              disabled={props.primaryButton.disabled}
              label={props.primaryButton.name}
              loading={props.primaryButton.loading}
            />
          )}
        </div>
      }
    />
  )
}

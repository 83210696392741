import React from 'react';
import { Handle, HandleType, Position } from 'react-flow-renderer';
import './Handler.scss';

interface IProps {
  type: HandleType
  action: string
  position: Position,
  triggerLevel?: number
}

export default function Handler(props:IProps) {
  return (
    <Handle
      id={props.action}
      type={props.type}
      position={props.position}
      className={`handler ${props.type} ${props.action} level-${props.triggerLevel}`}
    />
  )
}

import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { FlightCard, FlightTooltip } from "@flybits/webapp-design-system-react";
import './FlowBox.scss';

import ArrowIcon from 'assets/icons/arrow__new.svg';
import LockIcon from 'assets/icons/icons8-lock.svg';
import {ReactComponent as EditIcon } from 'assets/icons/edit-pen-no-fill.svg';
import LockIconOtline from 'assets/icons/lock-outline.svg';

import ConfirmModal from "components/Modal/ConfirmModal/ConfirmModal";
import {trackEvent} from "helpers/analytics.helper";
import {history} from "redux/store";
import useActions from "hooks/useActions";
import * as TExperienceActions from "redux/templated-experience/templated-experience.action";
import { getIcon } from '@flybits/webapp-design-system-react';
import { isEmpty } from "lodash";

import pluralize from 'pluralize';

// TODO. add props interface
export default function FlowBox(props: any) {
  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const reduxTemplatedExperienceAction = useActions(TExperienceActions);
  const templatedExperience = !isEmpty(reduxTemplatedExperienceState.template)
    ? reduxTemplatedExperienceState.template
    : reduxTemplatedExperienceState.instance;
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const ErrorIcon = getIcon('warning', '');

  const confirmModalProps = {
    isVisible: false,
    icon: '',
    title: 'Your Experience will be Deactivated',
    content:
      "You can't edit experiences that are Active. " +
      'Proceeding will deactivate your experience immediately and it will no longer be visible to your customers.',
    primaryButton: {
      name: 'Deactivate and Edit',
      onClick: () => onConfirmHandle(),
      disabled: false,
    },
    cancelButton: {
      name: 'Cancel',
      onClick: () => setModalProps({ ...modalProps, isVisible: false }),
    },
  };
  const [modalProps, setModalProps] = useState(confirmModalProps);
  const isDelivery =
    props.type === 'Push notification' &&
    props.trigger.type !== 'broadcast' &&
    !!props.actionBody?.delivery?.frequency?.value;

  let deliveryBody = <div></div>;
  if (props.actionType === 'push' && props.actionBody?.delivery?.frequency?.value) {
    let periodValue = props.actionBody?.delivery?.frequency?.value;
    let periodName = props.actionBody?.delivery?.frequency?.period;
    periodName = props.actionBody?.delivery?.frequency?.value > 1 ? pluralize(periodName) : pluralize.singular(periodName);
    let frequency = `${periodValue} ${periodName}`;
    let messageNumber = 'Send 1 notification ';
    if (props.trigger.type === 'triggered') {
      messageNumber = `Max 1 notification `;
    }
    deliveryBody = (
      <div>
        {messageNumber} every {frequency}
      </div>
    );
    let additionalUser = <div></div>;
    if (props.trigger.type === 'reminder') {
      additionalUser = (
        <div>
          Allow each user to enter experience{' '}
          {props.actionBody?.delivery?.limit ? props.actionBody?.delivery?.limit : 'unlimited'} time(s)
        </div>
      );
    }
    if (props.trigger.type === 'triggered' && props.actionBody?.delivery?.remain) {
      additionalUser = <div>Include users who are part of the audience</div>;
    }

    let periodTotal = props.actionBody?.delivery?.frequency?.total;
    if (props.trigger.type === 'triggered') periodTotal = props.actionBody?.delivery?.limit;
    deliveryBody = (
      <div className="delivery-box__description">
        {deliveryBody}
        <div>{periodTotal ? `Max ${periodTotal}` : 'Unlimited'} notification(s) in total</div>
        {additionalUser}
      </div>
    );
  }
  let deliveryTitle = '';
  if (props.trigger.type === 'triggered') {
    deliveryTitle = 'Notification Limit';
  }
  if (props.trigger.type === 'reminder') {
    deliveryTitle = 'Notification Interval';
  }

  function onConfirmHandle() {
    const instance_id = reduxTemplatedExperienceState.instance?.id;
    reduxTemplatedExperienceAction.deactivateTemplatedExperienceInstance(instance_id).then(() => {
      trackEvent({
        category: 'Templated Experiences',
        action: 'Deactivate and Edit',
      });
      history.push(`/project/${history?.location?.pathname?.split('/')[2]}/edit/${instance_id}`);
    });
    setModalProps({ ...modalProps, isVisible: false });
  }

  let className = 'flow-box__card-container__button';

  if (
    props.type === 'App Home Screen' ||
    props.isNotEditable ||
    (['Target audience', 'Triggered delivery', 'Scheduled delivery'].includes(props?.type) &&
      (templatedExperience?.activation?.manualActivationAt || templatedExperience?.activation?.automaticActivationAt)) ||
    (props?.type === 'Triggered delivery' && props?.trigger?.type === 'reminder') ||
    (props?.type === 'Scheduled delivery' && props?.trigger?.type === 'broadcast') ||
    (props?.type === 'Scheduled delivery' && props?.trigger?.type === 'list-broadcast') ||
    (props?.type === 'Triggered delivery' && props?.trigger?.type === 'nearby-location')
  ) {
    className += ' not-clickable';
  }

  if ((
      props.hasError &&
      templatedExperience?.showError &&
      !props.isRoot
    ) || (
      props.hasError &&
      templatedExperience?.showError &&
      props.type === 'Target audience' &&
      (
        props.trigger?.type === 'triggered' || 'reminder' || '' ||
        props.trigger?.trigger?.key === 'before' ||
        props.trigger?.trigger?.key === 'after'
      )
    )
  ) {
    className += ' error';
  }

  if (props.actionType === 'push' && props.actionBody?.delivery?.frequency?.value) {
    className += ' flow-box__card-container__button__no-right-margin';
  }

  function handleShowEditIcon(option: boolean) {
    if (className.indexOf('not-clickable') === -1) {
      setShowEditIcon(option);
      if(className.indexOf('error') !== -1) {
        setShowErrorIcon(!option);
      }
    }
  }

  useEffect(() => {
    if(className.indexOf('error') !== -1) {
      setShowErrorIcon(true);
    } else {
      setShowErrorIcon(false);
    }
  }, [className]);


  return (
    <div className={`flow-box__card-container`}>
      <button
        className={className}
        onClick={props.onBoxClick}
        onMouseEnter={() => handleShowEditIcon(true)}
        onMouseLeave={() => handleShowEditIcon(false)}
      >
        <FlightCard
          className="flow-box__card-container__card"
          header={
            <div className="flow-box__card-container__card__step-header">
              <div className="flow-box__card-container__card__step-header__type-box">
                <img className="flow-box__card-container__card__step-header__icon" src={props.icon} alt="boxIcon" />
                <div
                className="flow-box__card-container__card__step-header__title"
                > {props.type} </div>
              </div>
              {!!((props.tooltip && props.isNotEditable) || (
                ['Target audience', 'Triggered delivery', 'Scheduled delivery'].includes(props?.type) &&
                (templatedExperience?.activation?.manualActivationAt || templatedExperience?.activation?.automaticActivationAt)
              )) && (
                <FlightTooltip
                  className="flow-box__card-container__card__step-header__tooltip"
                  direction="top"
                  isEnabled={true}
                  description={
                    (
                      ['Target audience', 'Triggered delivery', 'Scheduled delivery'].includes(props?.type) &&
                      (templatedExperience?.activation?.manualActivationAt || templatedExperience?.activation?.automaticActivationAt)
                    )
                      ? "You can't edit this component once the experience becomes active."
                      : props.tooltip
                  }
                  delay={0}
                >
                  <img
                    src={['Target audience', 'Triggered delivery', 'Scheduled delivery'].includes(props?.type) ? LockIcon : LockIconOtline}
                    alt="QuestionIcon"
                    className="flow-box__card-container__card__step-header__question"
                  />
                </FlightTooltip>
              )}
              {showErrorIcon ? (
                <div className="flow-box__card-container__card__step-header__error-icon">{ErrorIcon}</div>
              ) : (
                <EditIcon
                  style={{fill: '#4992FD'}}
                  className={`flow-box__card-container__card__step-header__${showEditIcon ? 'edit-show' : 'edit'}`}
                />
              )}
            </div>
          }
          body={
            <div>
              {/* If there is props.body, we do not render placeholder */}
              {props.body ? (
                <div
                  className={
                    isDelivery
                      ? 'flow-box__card-container__card__step-body'
                      : 'flow-box__card-container__card__step-body flow-box__card-container__card__step-body--space-bottom'
                  }
                >
                  {props.body}
                </div>
              ) : (
                <div
                  className={
                    isDelivery
                      ? 'flow-box__card-container__card__step-body__placeholder'
                      : 'flow-box__card-container__card__step-body__placeholder flow-box__card-container__card__step-body__placeholder--space-bottom'
                  }
                >
                  {props.placeholder}
                </div>
              )}
              {isDelivery && (
                <div className="flow-box__card-container__card__step-body__delivery">
                  <div className="flow-box__card-container__card__step-body__delivery__title">{deliveryTitle}</div>
                  {deliveryBody}
                </div>
              )}
            </div>
          }
          actionables={props.actionables}
        />
      </button>
      {!props.hideArrow && (!props.index || props.index === 0) ? (
        <img className="flow-box__card-container__arrow" src={ArrowIcon} alt="ArrowIcon" />
      ) : null}
      <ConfirmModal {...modalProps} />
    </div>
  );
}

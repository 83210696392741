import React, { useState } from 'react';

import PluginAPI from 'services/api/plugin.api';
import useAsyncState from 'hooks/useAsyncState';

import EditModal from "components/Modal/EditModal/EditModal";
import AudienceEdit from "components/Audience/AudienceEdit/AudienceEdit";

import './AudienceEditModal.scss';

interface IProps {
  action: any;
  isVisible: boolean;
  onApply: (e: any) => void;
  onCancel: () => void;
}

// maybe we should use useContext?
export default function AudienceEditModal(props: IProps) {
  const [audiences, setAudiences] = useState<any>({
    'restriction': {
      condition: undefined,
      data: undefined,
    },
    'preferred': {
      condition: undefined,
      data: undefined,
    },
  });

  const pluginAPI = new PluginAPI();
  const [plugins] = useAsyncState(() => pluginAPI.getPlugins().then((res: any) => res));
  const [selectedTab, setSelectedTab] = useState("first");

  function onChangeHandle(payload: {type: string, condition: string, audiences: any}) {
    audiences[payload.type] = {
      condition: payload.condition,
      data: payload.audiences
    }
    setAudiences({...audiences});
  }

  function concatStringRepresentations(audiences: any) {
    // Restriction Audience
    const ruleStringRepresentation = audiences.restriction.data.map((audience: any) => {
      return audience.stringRep;
    }).join(` ${audiences.restriction.condition.key} `);
    // Preferred criteria
    const seedRuleStringRepresentation = audiences.preferred?.data?.map((audience: any) => {
      return audience.stringRep;
    }).join(` ${audiences.preferred.condition.key} `);
    return {
      ruleStringRepresentation: ruleStringRepresentation ? `(${ruleStringRepresentation})` : "",
      seedRuleStringRepresentation: seedRuleStringRepresentation ? `(${seedRuleStringRepresentation})` : ""
    }
  }

  return (
    <EditModal
      size="xlarge"
      icon="audience.svg"
      title="Configure your audience"
      className="xlarge"
      isVisible={props.isVisible}
      primaryButton={{
        name: 'Apply',
        onClick: () => props.onApply(concatStringRepresentations(audiences)),
        disabled: false
      }}
      cancelButton={{
        name: 'Cancel',
        onClick: props.onCancel
      }}
    >
      <div className="audience-edit-modal">
        <div className="tab">
          <div className="tab__content">
            <div className="tab__content-links">
              <a
                href="# "
                className={selectedTab === 'first' ? 'selected' : ''}
                onClick={() => setSelectedTab("first")}
              >
                Restrictions
              </a>
              <a
                href="# "
                className={selectedTab === 'second' ? 'selected' : ''}
                onClick={() => setSelectedTab("second")}
              >
                Preferred criteria
              </a>
            </div>
            <div className={selectedTab === 'first' ? 'underline left' : 'underline right'}> </div>
          </div>
        </div>
        <AudienceEdit
          type="restriction"
          isVisible={selectedTab === 'first'}
          plugins={plugins}
          ruleStringRepresentation={props.action.ruleStringRepresentation}
          onChange={onChangeHandle}
        />
        <AudienceEdit
          type="preferred"
          isVisible={selectedTab === 'second'}
          plugins={plugins}
          ruleStringRepresentation={props.action.seedRuleStringRepresentation}
          onChange={onChangeHandle}
        />
      </div>
    </EditModal>
  )
}
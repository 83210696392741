import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {times, isEmpty} from "lodash";
import Skeleton from "react-loading-skeleton";
import {FlightButton, FlightSelectSearchable} from "@flybits/webapp-design-system-react";
import GroupAPI from "services/api/group.api";
import {setCurrentFolder, setInstance, setTemplate} from "redux/templated-experience/templated-experience.action";
import FoldersModal from "components/Modal/FoldersModal/FoldersModal";
import './SettingsTab.scss';
import {Group} from 'interface/group.interface';
import {useHistory} from "react-router-dom";
import queryString from "query-string";
import FoldersBreadcrumbs from "components/FoldersBreadcrumbs/FoldersBreadcrumbs";
import { TExperienceInstance } from "interface/templated-experience/templated-experience.interface";
import FolderAPI from "services/api/folder.api";
import LabelInput from "components/LabelInput/LabelInput";
interface SerializedGroup {
  key:string,
  name: string
}
interface ExperienceGroup {
  id:string,
  name: string
}
interface FoldersModalProps {
  experience: TExperienceInstance
  isVisible: boolean
  onCancel: () => void
  onMove?: (parentFolderId: string|undefined) => void
}

export default function SettingsTab() {

  const reduxTemplatedExperienceState = useSelector((state: any) => state.templatedExperience);
  const experience = !isEmpty(reduxTemplatedExperienceState.instance) ? reduxTemplatedExperienceState.instance
    : reduxTemplatedExperienceState.template;
  // const [maxViewers, setMaxViewers] = useState('0'); TODO: Uncomment when the backend is ready
  // const [limitViewers, setLimitViewers] = useState('UNSELECTED'); TODO: Uncomment when the backend is ready
  const [experienceGroups, setExperienceGroups] = useState(experience?.steps?.[0]?.groups || []);
  const [allGroups, setAllGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState<SerializedGroup[]>([]);
  const history = useHistory();
  const queryParams = queryString.parse(history.location?.search);
  const dispatch = useDispatch();
  const groupAPI = new GroupAPI();
  const foldersModalProps:FoldersModalProps = {
    experience,
    isVisible: false,
    onCancel: () => setModalProps({
      ...foldersModalProps,
      isVisible: false
    })
  }
  const [modalProps, setModalProps] = useState(foldersModalProps);
  const pathName = history.location.pathname.replace(/\/$/, "");

  useEffect(() => {
    groupAPI.getGroups(0, 10000, '').then((res: any) => {
      const serializedGroups = res.data?.map((group: Group) => ({
        key: group.id!,
        name: group.localizations?.en?.name! || '',
      })).sort((a:SerializedGroup, b:SerializedGroup) => {
        const x = a.name.toLowerCase();
        const y = b.name.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
      setAllGroups(serializedGroups);
      const groups = serializedGroups?.filter((group:SerializedGroup) =>
        !experienceGroups?.includes(group.name) && !experience?.steps?.[0]?.groups?.find((expGroup:ExperienceGroup) => expGroup.id === group.key)
      );
      setFilteredGroups(groups);
    });

    if(experience.parentFolderID) {
      const folderAPI = new FolderAPI();
      folderAPI.getFolderChildren(experience.parentFolderID, {})
        .then((result:any) => {
          dispatch(setCurrentFolder({ currentFolder: result.currentFolder }));
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    return () => {
      setAllGroups([]);
      setFilteredGroups([]);
      setExperienceGroups([]);
    }
  },[]);


  const updateExperienceGroups = (groups:Array<ExperienceGroup>) => {
    if(reduxTemplatedExperienceState.instance) {
      let steps = reduxTemplatedExperienceState.instance?.steps!;
      if(steps?.[0]?.groups) {
        steps[0].groups = groups;
      }
      dispatch(
        setInstance({
          instance: {
            ...reduxTemplatedExperienceState.instance,
            steps
          },
        }),
      );
    }
    if(reduxTemplatedExperienceState.template) {
      let steps = reduxTemplatedExperienceState.template?.steps!;
      if(steps?.[0]?.groups) {
        steps[0].groups = groups;
      }
      dispatch(
        setTemplate({
          template: {
            ...reduxTemplatedExperienceState.template,
            steps: [
              {
                ...reduxTemplatedExperienceState.template.steps?.[0],
                groups
              },
              ...reduxTemplatedExperienceState.template.steps
            ],
          },
        }),
      );
    }
  }

  const handleSearchGroup = (groupName: string) => {
    const filteredGroups = allGroups.filter((group:SerializedGroup) => group.name?.toLowerCase().includes(groupName.toLowerCase()));
    setFilteredGroups(filteredGroups);
  }

  const handleSelectGroup = (group:SerializedGroup) => {
    const groupExists = experienceGroups.find((expGroup:ExperienceGroup) => expGroup.id === group.key);
    if(!groupExists) {
      setExperienceGroups([...experienceGroups, {id: group.key, name: group.name}]);
      updateExperienceGroups([...experienceGroups, {id: group.key, name: group.name}]);
    }
    setTimeout(() => {
      const updatedGroups = filteredGroups.filter((filteredGroup:SerializedGroup) => filteredGroup.key !== group.key);
      setFilteredGroups(updatedGroups);
    }, 500);
  }

  const handleRemoveGroup = (group:ExperienceGroup) => {
    const updatedGroups = experienceGroups.filter((expGroup:ExperienceGroup) => expGroup.id !== group.id);
    setExperienceGroups(updatedGroups);
    updateExperienceGroups(updatedGroups);
    const sortedFilteredGroups:any = [...filteredGroups, {key: group.id, name: group.name}].sort((a:SerializedGroup, b:SerializedGroup) => {
      const x = a.name.toLowerCase();
      const y = b.name.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
    setFilteredGroups(sortedFilteredGroups);
  }

  const handleFolders = () => {
    setModalProps({
      ...modalProps,
      isVisible: true,
      onMove: (parentFolderId: string|undefined) => {
        queryParams.parentFolderID = parentFolderId || '';
        history.push(`${pathName}?${queryString.stringify({...queryParams},{arrayFormat: 'none'})}`);
      }
    })
  }

  return (
    <div className="settings-tab">
      <div className="settings-tab__main">
        {reduxTemplatedExperienceState.isLoading ? (
          <div className="settings-tab__main__progress">
            {times(3, (i) => (
              <div key={i} style={{ marginRight: 100}}>
                <Skeleton width={300} height={100} />
              </div>
            ))}
          </div>
        ) : experience ? (
          <div className="settings-tab__main__flow">
            <div className="settings-tab__main__settings">
              {/* TODO: Uncomment and update when the backend is ready */ }
              {/*<div className="settings-tab__main__settings__section">*/}
              {/*  <div className="settings-tab__main__settings__title">Maximum content views</div>*/}
              {/*  <div className="settings-tab__main__settings__text">*/}
              {/*    <FlightCheckbox*/}
              {/*      label="Limit the number of users who will receive this experience. Once maximum is reached, experience will deactivate."*/}
              {/*      checkState={limitViewers}*/}
              {/*      onSelect={() => setLimitViewers(limitViewers === 'UNSELECTED' ? 'SELECTED' : 'UNSELECTED')}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*  <div className="settings-tab__main__settings__text">*/}
              {/*    <FlightTextInput*/}
              {/*      label=""*/}
              {/*      type="text"*/}
              {/*      value={maxViewers}*/}
              {/*      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMaxViewers(e.target.value)}*/}
              {/*      width="70px"*/}
              {/*    />*/}
              {/*    <span className="settings-tab__main__settings__text__descr">maximum users</span>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="settings-tab__main__settings__section">
                <div className="settings-tab__main__settings__title">Labels</div>
                <LabelInput experience={experience}/>
              </div>
              <div className="settings-tab__main__settings__section">
                <div className="settings-tab__main__settings__title">Folder</div>
                <div className="settings-tab__main__settings__text">
                  <FoldersBreadcrumbs clickable={false} />
                  <FlightButton
                    theme="link"
                    label={'(Edit)'}
                    onClick={handleFolders}
                    className="settings-tab__main__settings__text__button"
                  />
                </div>
              </div>
              {experience?.steps?.[0]?.trigger?.type === '' &&
                <div className="settings-tab__main__settings__section">
                  <div className="settings-tab__main__settings__title">Priority group</div>
                  <div className="settings-tab__main__settings__text">
                    <FlightSelectSearchable
                      label="Search priority groups"
                      options={filteredGroups}
                      handleOptionClick={handleSelectGroup}
                      handleSearch={handleSearchGroup}
                      dropdownMaxHeight="200px"
                      className="settings-tab__main__settings__search"
                    />
                  </div>
                  <div className="settings-tab__main__settings__text">
                    {experienceGroups?.map((group: ExperienceGroup) =>
                      <div key={group.id} className="settings-tab__main__settings__group">
                        {group.name}
                        <button onClick={() => handleRemoveGroup(group)}>X</button>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        ) : reduxTemplatedExperienceState.errorMessage ? (
          <div className="settings-tab__main__error">Error: {reduxTemplatedExperienceState.errorMsg}</div>
        ) : (
          <div className="settings-tab__main__empty"> There are no data </div>
        )}
      </div>
      <FoldersModal {...modalProps} />
    </div>
  )
}
